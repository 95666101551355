import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import Nav from './nav';
import { collection,query, orderBy } from 'firebase/firestore';
import Navigation from '../No Auth/nav'

export default function Cakes() {
    const [user,setuser] =useState([])
    const [cakes, setCakes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndexes, setCurrentIndexes] = useState({});
    const [search, setsearch] = useState('');


    const filteredSearch = cakes.filter((item) => {
        if (search === '') {
          return true;
        } else if (item.cakeTitle.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        return false;
      })
    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
  

            onSnapshot(doc(firestore,'Users',uid),snapshot=>{
                setuser([{...snapshot.data(),id:snapshot.id}])
                

            })
      
      
      
      
                
      
                  
              
      
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);

      useEffect(() => {
        const fetchCakes = async () => {
          const document = collection(firestore, 'Cakes');
          const q = query(document, orderBy('postedTime'));
          const unsubscribe = onSnapshot(q, (snapshot) => {
            let temp = [];
            snapshot.docs.forEach((doc) => {
              temp.push({ ...doc.data(), id: doc.id });
            });
            setCakes(temp);
            setIsLoading(false);
          }, (error) => {
            console.error('Error fetching cakes:', error);
            setIsLoading(false);
          });
    
          // Clean up the listener when the component unmounts
          return unsubscribe;
        };
    
        fetchCakes();
      }, []);
      useEffect(() => {
        // Initialize currentIndexes state with the first index for each cake
        const initialIndexes = cakes.reduce((acc, cake) => {
          acc[cake.id] = 0;
          return acc;
        }, {});
        setCurrentIndexes(initialIndexes);
      }, [cakes]);
    
      const handlePrevious = (cakePhotos, id) => {
        setCurrentIndexes((prevIndexes) => ({
          ...prevIndexes,
          [id]: (prevIndexes[id] === 0 ? cakePhotos.length - 1 : prevIndexes[id] - 1),
        }));
      };
      
      const handleNext = (cakePhotos, id) => {
        setCurrentIndexes((prevIndexes) => ({
          ...prevIndexes,
          [id]: (prevIndexes[id] === cakePhotos.length - 1 ? 0 : prevIndexes[id] + 1),
        }));
      };
       
  function CakeGallery({ cake, currentIndex, handlePrevious, handleNext }) {
    return (
  
      <div>
          <div id='mobile'>

<div id="gallery" className="relative w-full  " data-carousel="slide">
  <div className="relative overflow-hidden rounded-lg">
    <div className="relative overflow-hidden rounded-lg shadow-xl">
      <div className="duration-700 ease-in-out " data-carousel-item>
        {cake.cakePhotos.map((photo, index) => (
          <img
            key={index}
            src={photo.cakePhotos}
            alt={`Selected file ${index}`}
            style={{ height: '200px', objectFit: 'cover' }}
            className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
          />
        ))}
        {cake.cakePhotos.length === 0 && (
          <img
            className="h-auto w-full rounded-lg"
            style={{ height: '300px', objectFit: 'cover' }}
            src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
            alt="description"
          />
        )}
      </div>

      {cake.cakePhotos.length >= 2 && (
           <button
           type="button"
         style={{padding:'2vw 0vw'}}

           className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
           data-carousel-prev
         >
         <span onClick={() => handlePrevious(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">

             <svg
               style={{width:'4vw',height:'4vw'}}
               className=" text-gray-800 dark:text-gray-800 rtl:rotate-180"
               aria-hidden="true"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 6 10"
             >
               <path
                 stroke="currentColor"
                 stroke-linecap="round"
                 stroke-linejoin="round"
                 stroke-width="2"
                 d="M5 1 1 5l4 4"
               />
             </svg>
             <span className="sr-only">Previous</span>
           </span>
         </button>
     
      )}

      {cake.cakePhotos.length >= 2 && (
         <div
         type="div"
         style={{padding:'2vw 0vw'}}
         className="absolute top-0 end-0 z-30 flex items-center justify-center h-full   group focus:outline-none"
         data-carousel-next
       >
         <span onClick={() => handleNext(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
           <svg
             style={{width:'4vw',height:'4vw'}}
             className=" text-gray-800 dark:text-gray-800 rtl:rotate-180"
             aria-hidden="true"
             xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 6 10"
           >
             <path
               stroke="currentColor"
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="2"
               d="m1 9 4-4-4-4"
             />
           </svg>
           <span className="sr-only">Next</span>
         </span>
       </div>

      )}
    </div>
  </div>
  <div className="flex relative justify-center  flex-col">
<div style={{paddingLeft:'3vw',paddingRight:'3vw'}} className="pb-5 relative flex w-full flex-col rounded-lg border border-gray-100 bg-white shadow-md">
  <h5 className="mt-4 par text-left  text-slate-900" style={{ fontSize: '3.5vw' }}>
    { cake.cakeTitle}
  </h5>
  <div className="mt-2 mb-5 flex items-center justify-start">
    <p>
      <span className="header font-bold text-slate-900" style={{ fontSize: '4vw' }}>
        {cake.cakePrice + ' ETB'}
      </span>
      {
cake.cakeColor && cake.cakeColor.length != 0 &&
  <span className='flex mt-4'>
{cake.cakeColor.map((color, index) => (
<div  class="   mx-1  rounded-full shadow border  " style={{backgroundColor:color,width:'4vw',height:'4vw'}}></div>

))}
</span>
}
    </p>
  </div>
 <Link to={`/cake/${cake.id}`}>
 <button style={{fontSize:'2.5vw',padding:'2vw 4vw'}} className="par w-full flex items-center justify-center rounded-md bg-slate-900  text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
  <svg
  style={{width:'5vw',height:'5vw',marginRight:'1.4vw'}}
    xmlns="http://www.w3.org/2000/svg"
    className=""
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
    />
  </svg>
  Buy Now
</button>

 </Link>
</div>
</div>
</div>
</div>
      <div id='desktop'>
      <div id="gallery" className="relative w-full  " data-carousel="slide">
        <div className="relative overflow-hidden rounded-lg">
          <div className="relative overflow-hidden rounded-lg shadow-xl">
            <div className="duration-700 ease-in-out " data-carousel-item>
              {cake.cakePhotos.map((photo, index) => (
                <img
                  key={index}
                  src={photo.cakePhotos}
                  alt={`Selected file ${index}`}
                  style={{ height: '300px', objectFit: 'cover' }}
                  className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
                />
              ))}
              {cake.cakePhotos.length === 0 && (
                <img
                  className="h-auto w-full rounded-lg"
                  style={{ height: '300px', objectFit: 'cover' }}
                  src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                  alt="description"
                />
              )}
            </div>
  
            {cake.cakePhotos.length >= 2 && (
                 <button
                 type="button"
                 className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-2 cursor-pointer group focus:outline-none"
                 data-carousel-prev
               >
               <span onClick={() => handlePrevious(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
  
                   <svg
                     
                     className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 6 10"
                   >
                     <path
                       stroke="currentColor"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                       stroke-width="2"
                       d="M5 1 1 5l4 4"
                     />
                   </svg>
                   <span className="sr-only">Previous</span>
                 </span>
               </button>
           
            )}
  
            {cake.cakePhotos.length >= 2 && (
               <div
               type="div"
               className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-2  group focus:outline-none"
               data-carousel-next
             >
               <span onClick={() => handleNext(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                 <svg
                   
                   className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                   aria-hidden="true"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 6 10"
                 >
                   <path
                     stroke="currentColor"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                     stroke-width="2"
                     d="m1 9 4-4-4-4"
                   />
                 </svg>
                 <span className="sr-only">Next</span>
               </span>
             </div>
  
            )}
          </div>
        </div>
        <div className="flex relative justify-center  flex-col">
      <div className="px-5 pb-5 relative flex w-full flex-col rounded-lg border border-gray-100 bg-white shadow-md">
        <h5 className="mt-4 par text-left  text-slate-900" style={{ fontSize: '15px' }}>
          { cake.cakeTitle}
        </h5>
        <div className="mt-2 mb-5 flex items-center justify-start">
          <p>
            <span className="header font-bold text-slate-900" style={{ fontSize: '20px' }}>
              {cake.cakePrice + ' ETB'}
            </span>
            {
      cake.cakeColor && cake.cakeColor.length != 0 &&
        <span className='flex mt-4'>
      {cake.cakeColor.map((color, index) => (
      <div  class=" w-7 h-7  mx-1  rounded-full shadow border  " style={{backgroundColor:color}}></div>

    ))}
      </span>
      }
          </p>
        </div>
       <Link to={`/cake/${cake.id}`}>
       <button className="par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        Buy Now
      </button>
  
       </Link>
      </div>
    </div>
      </div>
      </div>

      <div id="tablet">
      <div id="gallery" className="relative w-full  " data-carousel="slide">
        <div className="relative overflow-hidden rounded-lg">
          <div className="relative overflow-hidden rounded-lg shadow-xl">
            <div className="duration-700 ease-in-out " data-carousel-item>
              {cake.cakePhotos.map((photo, index) => (
                <img
                  key={index}
                  src={photo.cakePhotos}
                  alt={`Selected file ${index}`}
                  style={{ height: '100%', objectFit: 'cover' }}
                  className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
                />
              ))}
              {cake.cakePhotos.length === 0 && (
                <img
                  className="h-auto w-full rounded-lg"
                  style={{ height: '100%', objectFit: 'cover' }}
                  src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                  alt="description"
                />
              )}
            </div>
  
            {cake.cakePhotos.length >= 2 && (
                 <button
                 type="button"
                 className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-2 cursor-pointer group focus:outline-none"
                 data-carousel-prev
               >
               <span onClick={() => handlePrevious(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
  
                   <svg
                     
                     className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 6 10"
                   >
                     <path
                       stroke="currentColor"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                       stroke-width="2"
                       d="M5 1 1 5l4 4"
                     />
                   </svg>
                   <span className="sr-only">Previous</span>
                 </span>
               </button>
           
            )}
  
            {cake.cakePhotos.length >= 2 && (
               <div
               type="div"
               className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-2  group focus:outline-none"
               data-carousel-next
             >
               <span onClick={() => handleNext(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                 <svg
                   
                   className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                   aria-hidden="true"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 6 10"
                 >
                   <path
                     stroke="currentColor"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                     stroke-width="2"
                     d="m1 9 4-4-4-4"
                   />
                 </svg>
                 <span className="sr-only">Next</span>
               </span>
             </div>
  
            )}
          </div>
        </div>
        <div className="flex relative justify-center  flex-col">
      <div className="px-3 pb-5 relative flex w-full flex-col rounded-lg border border-gray-100 bg-white shadow-md">
        <h5 className="barlow mt-3 par text-left  text-slate-900" style={{ fontSize: '18px' }}>
          { cake.cakeTitle}
        </h5>
        <div className="mt-2 mb-5 flex items-center justify-start">
          <p>
            <span className="header font-bold text-slate-900" style={{ fontSize: '15px' }}>
              {cake.cakePrice + ' ETB'}
            </span>
            {
      cake.cakeColor && cake.cakeColor.length != 0 &&
        <span className='flex mt-4'>
      {cake.cakeColor.map((color, index) => (
      <div  class=" w-5 h-5  mx-1  rounded-full shadow border  " style={{backgroundColor:color}}></div>

    ))}
      </span>
      }
          </p>
        </div>
       <Link to={`/cake/${cake.id}`}>
       <button className="par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        Buy Now
      </button>
  
       </Link>
      </div>
    </div>
      </div>
      </div>
      </div>
    );
  }
  return (
    isLoading === true ?
    <div>
    <div style={{minHeight:'100vh'}} class="bg-white flex items-center justify-center  rounded  w-full p-10 -mt-5">
    
    <div
    className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
    role="status"></div></div>
            </div>
    :
       <div>
      <div id='desktop'>
        {
          user.length !=0?
      <Nav/>
      :
      <Navigation component={'Cakes'}/>

        }
   <div className='mt-22'>
   <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
<div class="relative mx-auto mb-20" style={{width:'40%'}}>
  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
      <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
      </svg>
  </div>
  <input type="search" id="default-search" onChange={(e)=>setsearch(e.target.value)} style={{height:'55px'}}  class=" block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 " placeholder="Search" required />
</div>

{
filteredSearch.length == 0 &&
<div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cake-pana.svg?alt=media&token=2749019d-15fe-499d-af3e-8342c9ef434a" alt="Certificate" style={{width:"22%"}}/>
<h1 className='fjalla text-center text-gray-900 mt-8 par' style={{fontSize:"20px"}}>  We couldn't find any matches for your search. Try refining it


</h1>

</div>

}
   <div className=" cakeGrid ">
      {filteredSearch.map((cake) => (
        <div key={cake.id}>
          <CakeGallery
            cake={cake}
            currentIndex={currentIndexes[cake.id] || 0}
            handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
            handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
          />
        </div>
      ))}
    </div>
    </div>
      </div>
      <div id='tablet'>
        {
          user.length !=0?
      <Nav/>
      :
      <Navigation component={'Cakes'}/>

        }
   <div className='mt-22'>
   <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
<div class="relative mx-auto mb-20" style={{width:'40%'}}>
  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
      <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
      </svg>
  </div>
  <input type="search" id="default-search" onChange={(e)=>setsearch(e.target.value)} style={{height:'55px'}}  class=" block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 " placeholder="Search" required />
</div>

{
filteredSearch.length == 0 &&
<div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cake-pana.svg?alt=media&token=2749019d-15fe-499d-af3e-8342c9ef434a" alt="Certificate" style={{width:"22%"}}/>
<h1 className='fjalla text-center text-gray-900 mt-8 par' style={{fontSize:"20px"}}>  We couldn't find any matches for your search. Try refining it


</h1>

</div>

}
   <div className=" cakeGrid ">
      {filteredSearch.map((cake) => (
        <div key={cake.id}>
          <CakeGallery
            cake={cake}
            currentIndex={currentIndexes[cake.id] || 0}
            handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
            handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
          />
        </div>
      ))}
    </div>
    </div>
      </div>
      <div id='mobile'>
        {
          user.length !=0?
      <Nav/>
      :
      <Navigation component={'Cakes'}/>

        }
   <div className='mt-22'>
   <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
<div class="relative mx-auto " style={{width:'50%',marginBottom:'10vw',marginTop:'6vw'}}>
  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
      <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
      </svg>
  </div>
  <input type="search" id="default-search" onChange={(e)=>setsearch(e.target.value)} style={{height:'55px'}}  class=" block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 " placeholder="Search" required />
</div>

{
filteredSearch.length == 0 &&
<div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cake-pana.svg?alt=media&token=2749019d-15fe-499d-af3e-8342c9ef434a" alt="Certificate" style={{width:"22%"}}/>
<h1 className='fjalla text-center text-gray-900 mt-8 par' style={{fontSize:"20px"}}>  We couldn't find any matches for your search. Try refining it


</h1>

</div>

}
   <div className=" cakeGrid ">
      {filteredSearch.map((cake) => (
        <div key={cake.id}>
          <CakeGallery
            cake={cake}
            currentIndex={currentIndexes[cake.id] || 0}
            handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
            handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
          />
        </div>
      ))}
    </div>
    </div>
      </div>

  </div>
  )
   
}
