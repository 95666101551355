import React, { useEffect, useState } from 'react'
import Nav from './nav'
import { Link } from 'react-router-dom'
import { addDoc, collection, serverTimestamp, setDoc } from 'firebase/firestore'
import { firestore } from '../firebase'
import Footer from './footer'

export default function Courseprogram() {
    const [loading,setloading] = useState(false)
    const [success,setsuccess] = useState(false)
    const [courseprogramHome,setcourseprogramHome] = useState(true)
    const[fullName,setfullName] = useState('')
    const[fullNameError,setfullNameError] = useState('')
    const[email,setemail] = useState('')
    const[emailError,setemailError] = useState('')
    const[error,seterror] = useState('')

    const[phoneNumber,setphoneNumber] = useState('')
    const[phoneNumberError,setphoneNumberError] = useState('')


    function enrollNow(){
        setTimeout(() => {
            setloading(true)
            setTimeout(() => {
                setcourseprogramHome(false)
            setloading(false)
                
            }, 1000);
            
        }, 1000);
    }

    function emailSet(e){
        setemail(e.target.value)
  
        if(e.target.value.length ===0){
          setemailError('error')
        }
        else{
          setemailError('')
        }
  
      } 

      useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
      }, []);

      function phoneNumberSet(e){
        setphoneNumber(e.target.value)
  
        if(e.target.value.length ===0){
          setphoneNumberError('error')
        }
        else{
          setphoneNumberError('')

        }
  
      } 
      function fullNameSet(e){
        setfullName(e.target.value)
        if(e.target.value.length ===0){
          setfullNameError('error')
        }
        else{
          setfullNameError('')
        }
  
      }

      async function reserveSpot() {
        try {
          if (fullName.length === 0) {
            setfullNameError('error');
          } else {
            setfullNameError('');
            if (email.length === 0) {
              setemailError('error');
            } else {
              setemailError('');
              if (phoneNumber.length === 0) {
                setphoneNumberError('error');
              } else {
                setphoneNumberError('');
                setloading(true)
                try {
                  await addDoc(collection(firestore, 'CourseProgram'), {
                    fullName: fullName,
                    phoneNumber: phoneNumber,
                    email: email,
                    time: serverTimestamp()
                  });
                setsuccess(true)
                setloading(false)


                } catch (error) {
                  setloading(false);
                  seterror('Error adding document to Firestore:', error);
                  console.error('Error adding document to Firestore:', error);
                }
              }
            }
          }
        } catch (error) {
          setloading(false);
          seterror('Error in reserveSpot function:', error);
          console.error('Error in reserveSpot function:', error);
        }
      }

      function removeSuccess(){
        setsuccess(false)
      }
  return (
    <div>
        <div id='desktop'  class="">
        {success !== true &&     <Nav component={'Course Program'}/>}
            {
                loading === true?
                <div  style={{height:'50vh'}}class=" flex items-center justify-center w-full  rounded  ">
   
   <div
   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
   role="status"></div></div>
   :
   success === true?
   <div className='successCourseProgram flex-col' > 
<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<h1 className='header' style={{fontSize:'40px'}}>Your spot has been reserved successfully</h1>
<p className='par'>Our team will get in touch with you as soon as possible to confirm your request </p>

<Link to='/'>
<button
                   type='button'
                  
                   style={{fontSize:'12px',background:'black',height:'55px'}}
                   className='par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                 >
                   Done
                 </button></Link>
</div>
   :
   courseprogramHome === true ?
   <section
   class="">
   <div class=" z-10">

       <div
           class="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
           <svg class="h-[60rem] w-[100rem] flex-none stroke-blue-600 opacity-20" aria-hidden="true">
               <defs>
                   <pattern id="e9033f3e-f665-41a6-84ef-756f6778e6fe" width="200" height="200" x="50%" y="50%"
                       patternUnits="userSpaceOnUse" patternTransform="translate(-100 0)">
                       <path d="M.5 200V.5H200" fill="none"></path>
                   </pattern>
               </defs>
               <svg x="50%" y="50%" class="overflow-visible fill-blue-50">
                   <path d="M-300 0h201v201h-201Z M300 200h201v201h-201Z" stroke-width="0"></path>
               </svg>
               <rect width="100%" height="100%" stroke-width="0" fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)">
               </rect>
           </svg>
       </div>
   </div>
   <div class="relative z-20 mx-auto max-w-7xl px-6 lg:px-8">


<div class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-5">
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-03-22_17-50-28.jpg?alt=media&token=b937467d-3599-4a7b-9b3a-db6111c8c42b" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-03-29_16-47-12.jpg?alt=media&token=0eec9f77-9255-44a8-a23c-9fc59b31c137" alt=""/>
        </div>
       
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_15-53-49.jpg?alt=media&token=a511c350-ed74-410b-a5df-27474321afe6" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_15-59-45.jpg?alt=media&token=a4cdaf62-c870-40a5-8f10-8da74ee3aff0" alt=""/>
        </div>
     
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-03-29_16-50-56.jpg?alt=media&token=06c23213-f8c4-4cc8-b882-ad9004f4e605" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/08.06.2024_16.14.45_REC.png?alt=media&token=2cda741c-5104-4f18-a557-518ec7e4f74c" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/08.06.2024_16.06.10_REC.png?alt=media&token=9f31c5db-324d-4b01-b5e6-4ba9616002c4" alt=""/>
        </div>
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/08.06.2024_16.15.33_REC.png?alt=media&token=869b1d48-ea25-4b10-b82f-7e51868eee51" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/08.06.2024_16.10.28_REC.png?alt=media&token=4626d535-62ae-4ddc-a535-62951a3ed4e9" alt=""/>
        </div>
       
    </div>
</div>

       <div class="mx-auto text-center my-32">
           <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
           Take the first step on your cake journey <br />
               <span class="text-blue-600">Right Here! Right Now!
          </span>
           </h1>
           <h2 class="mt-6 text-lg leading-8 text-gray-600">
           Get started on your cake journey now and we'll help you become an expert in just a month With our easy to follow recipes, you'll learn the basics of cake baking and decorating. Our tutorials will guide you through each step of baking and decorating, so you can master the art of cake making in no time. Start now and you'll be an expert in no time!
           </h2>
           <div class="mt-10 flex items-center justify-center gap-x-6">
           <button onClick={enrollNow} type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-6 py-3 me-2 mb-2 ">Enroll Now</button> 

           </div>
       </div>
      
   </div>
</section>
:
<div>
<section class="bg-white">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl ">Become a Cake Expert in No Time</h1>
        <p style={{fontSize:'13px'}} class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 par">We will take you through the basics of cake making, from selecting the right ingredients and mixing them properly to shaping and decorating the cake. We will also provide you with tips and tricks that will make your cakes stand out from the rest.  </p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
    
         </div>
    </div>
</section>
<div class=" mx-auto" style={{width:'60%'}}>
               <div className={`${error.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{error}
</div>
</div>
</div>
<p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Reserve your spot Now!</p>


        <div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Full Name</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>fullNameSet(e))} required  autoComplete="off" placeholder="kebede Solomon" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${fullNameError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Full Name Can't be empty
</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>emailSet(e))} required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${emailError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>     
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Phone Number</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>phoneNumberSet(e))} required  autoComplete="off" placeholder="0912345678" type="tel" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${phoneNumberError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Phone Number Can't be empty
</div>
</div>
</div>
</div>


<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>

<button
   type='button'
   onClick={reserveSpot}
   style={{fontSize:'12px',margin:'auto',background:'#D9FE00',height:'55px'}}
   className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
 >
  Reserve spot 
 </button>
 </div>
 <div class="w-full flex items-center justify-between py-5">
         <hr class="w-full bg-gray-400"/>
         <p class="text-base font-medium leading-4 px-2.5 text-gray-400 mt-4"></p>
         <hr class="w-full bg-gray-400  "/>
        </div>

  
   
</div>
</div>

            }
         
        </div>
        <div id='tablet'  class="">
        {success !== true &&     <Nav component={'Course Program'}/>}
            {
                loading === true?
                <div  style={{height:'50vh'}}class=" flex items-center justify-center w-full  rounded  ">
   
   <div
   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
   role="status"></div></div>
   :
   success === true?
   <div className='successCourseProgram flex-col' > 
<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<h1 className='header' style={{fontSize:'40px'}}>Your spot has been reserved successfully</h1>
<p className='par'>Our team will get in touch with you as soon as possible to confirm your request </p>

<Link to='/'>
<button
                   type='button'
                  
                   style={{fontSize:'12px',background:'black',height:'55px'}}
                   className='par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                 >
                   Done
                 </button></Link>
</div>
   :
   courseprogramHome === true ?
   <section
   class="">
   <div class=" z-10">

       <div
           class="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
           <svg class="h-[60rem] w-[100rem] flex-none stroke-blue-600 opacity-20" aria-hidden="true">
               <defs>
                   <pattern id="e9033f3e-f665-41a6-84ef-756f6778e6fe" width="200" height="200" x="50%" y="50%"
                       patternUnits="userSpaceOnUse" patternTransform="translate(-100 0)">
                       <path d="M.5 200V.5H200" fill="none"></path>
                   </pattern>
               </defs>
               <svg x="50%" y="50%" class="overflow-visible fill-blue-50">
                   <path d="M-300 0h201v201h-201Z M300 200h201v201h-201Z" stroke-width="0"></path>
               </svg>
               <rect width="100%" height="100%" stroke-width="0" fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)">
               </rect>
           </svg>
       </div>
   </div>
   <div class="relative z-20 mx-auto max-w-7xl px-6 lg:px-8">


<div class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-5">
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-03-22_17-50-28.jpg?alt=media&token=b937467d-3599-4a7b-9b3a-db6111c8c42b" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-03-29_16-47-12.jpg?alt=media&token=0eec9f77-9255-44a8-a23c-9fc59b31c137" alt=""/>
        </div>
       
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_15-53-49.jpg?alt=media&token=a511c350-ed74-410b-a5df-27474321afe6" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_15-59-45.jpg?alt=media&token=a4cdaf62-c870-40a5-8f10-8da74ee3aff0" alt=""/>
        </div>
     
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-03-29_16-50-56.jpg?alt=media&token=06c23213-f8c4-4cc8-b882-ad9004f4e605" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/08.06.2024_16.14.45_REC.png?alt=media&token=2cda741c-5104-4f18-a557-518ec7e4f74c" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/08.06.2024_16.06.10_REC.png?alt=media&token=9f31c5db-324d-4b01-b5e6-4ba9616002c4" alt=""/>
        </div>
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/08.06.2024_16.15.33_REC.png?alt=media&token=869b1d48-ea25-4b10-b82f-7e51868eee51" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/08.06.2024_16.10.28_REC.png?alt=media&token=4626d535-62ae-4ddc-a535-62951a3ed4e9" alt=""/>
        </div>
       
    </div>
</div>

       <div class="mx-auto text-center my-32">
           <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
           Take the first step on your cake journey <br />
               <span class="text-blue-600">Right Here! Right Now!
          </span>
           </h1>
           <h2 class="mt-6 text-lg leading-8 text-gray-600">
           Get started on your cake journey now and we'll help you become an expert in just a month With our easy to follow recipes, you'll learn the basics of cake baking and decorating. Our tutorials will guide you through each step of baking and decorating, so you can master the art of cake making in no time. Start now and you'll be an expert in no time!
           </h2>
           <div class="mt-10 flex items-center justify-center gap-x-6">
           <button onClick={enrollNow} type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-6 py-3 me-2 mb-2 ">Enroll Now</button> 

           </div>
       </div>
      
   </div>
</section>
:
<div>
<section class="bg-white">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl ">Become a Cake Expert in No Time</h1>
        <p style={{fontSize:'13px'}} class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 par">We will take you through the basics of cake making, from selecting the right ingredients and mixing them properly to shaping and decorating the cake. We will also provide you with tips and tricks that will make your cakes stand out from the rest.  </p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
    
         </div>
    </div>
</section>
<div class=" mx-auto" style={{width:'60%'}}>
               <div className={`${error.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{error}
</div>
</div>
</div>
<p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Reserve your spot Now!</p>


        <div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Full Name</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>fullNameSet(e))} required  autoComplete="off" placeholder="kebede Solomon" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${fullNameError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Full Name Can't be empty
</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>emailSet(e))} required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${emailError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>     
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Phone Number</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>phoneNumberSet(e))} required  autoComplete="off" placeholder="0912345678" type="tel" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${phoneNumberError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Phone Number Can't be empty
</div>
</div>
</div>
</div>


<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>

<button
   type='button'
   onClick={reserveSpot}
   style={{fontSize:'12px',margin:'auto',background:'#D9FE00',height:'55px'}}
   className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
 >
  Reserve spot 
 </button>
 </div>
 <div class="w-full flex items-center justify-between py-5">
         <hr class="w-full bg-gray-400"/>
         <p class="text-base font-medium leading-4 px-2.5 text-gray-400 mt-4"></p>
         <hr class="w-full bg-gray-400  "/>
        </div>

  
   
</div>
</div>

            }
         
        </div>
        <div id='mobile'  class="">
        {success !== true &&     <Nav component={'Course Program'}/>}
            {
                loading === true?
                <div  style={{height:'50vh'}}class=" flex items-center justify-center w-full  rounded  ">
   
   <div
   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
   role="status"></div></div>
   :
   success === true?
   <div className='successCourseProgram flex-col' > 
<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<h1 className='header text-center' style={{fontSize:'6vw'}}>Your spot has been reserved successfully</h1>
<p className='par text-center'style={{fontSize:'3vw'}}>Our team will get in touch with you as soon as possible to confirm your request </p>

<Link to='/'>
<button
                   type='button'
                  
                   style={{fontSize:'12px',background:'black',height:'55px'}}
                   className='par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                 >
                   Done
                 </button></Link>
</div>
   :
   courseprogramHome === true ?
   <section
   class="">
   <div class=" z-10">

       <div
           class="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
           <svg class="h-[60rem] w-[100rem] flex-none stroke-blue-600 opacity-20" aria-hidden="true">
               <defs>
                   <pattern id="e9033f3e-f665-41a6-84ef-756f6778e6fe" width="200" height="200" x="50%" y="50%"
                       patternUnits="userSpaceOnUse" patternTransform="translate(-100 0)">
                       <path d="M.5 200V.5H200" fill="none"></path>
                   </pattern>
               </defs>
               <svg x="50%" y="50%" class="overflow-visible fill-blue-50">
                   <path d="M-300 0h201v201h-201Z M300 200h201v201h-201Z" stroke-width="0"></path>
               </svg>
               <rect width="100%" height="100%" stroke-width="0" fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)">
               </rect>
           </svg>
       </div>
   </div>
   <div class="relative z-20 mx-auto max-w-7xl px-6 lg:px-8">


<div class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-5">
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-03-22_17-50-28.jpg?alt=media&token=b937467d-3599-4a7b-9b3a-db6111c8c42b" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-03-29_16-47-12.jpg?alt=media&token=0eec9f77-9255-44a8-a23c-9fc59b31c137" alt=""/>
        </div>
       
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_15-53-49.jpg?alt=media&token=a511c350-ed74-410b-a5df-27474321afe6" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_15-59-45.jpg?alt=media&token=a4cdaf62-c870-40a5-8f10-8da74ee3aff0" alt=""/>
        </div>
     
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-03-29_16-50-56.jpg?alt=media&token=06c23213-f8c4-4cc8-b882-ad9004f4e605" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/08.06.2024_16.14.45_REC.png?alt=media&token=2cda741c-5104-4f18-a557-518ec7e4f74c" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/08.06.2024_16.06.10_REC.png?alt=media&token=9f31c5db-324d-4b01-b5e6-4ba9616002c4" alt=""/>
        </div>
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/08.06.2024_16.15.33_REC.png?alt=media&token=869b1d48-ea25-4b10-b82f-7e51868eee51" alt=""/>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/08.06.2024_16.10.28_REC.png?alt=media&token=4626d535-62ae-4ddc-a535-62951a3ed4e9" alt=""/>
        </div>
       
    </div>
</div>

       <div class="mx-auto text-center my-32">
         
<h1 style={{fontSize:'7vw',lineHeight:'9vw'}}class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
Take the first step on your cake journey <br />
   <mark class="px-2 text-white bg-blue-700 rounded ">Right Here! Right Now!</mark>  </h1>

           <h2 class="mt-6 text-lg leading-8 text-gray-600" style={{fontSize:'3vw'}}>
           Get started on your cake journey now and we'll help you become an expert in just a month With our easy to follow recipes, you'll learn the basics of cake baking and decorating.
           </h2>
           <div class="mt-10 flex items-center justify-center gap-x-6">
           <button onClick={enrollNow} type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-6 py-3 me-2 mb-2 ">Enroll Now</button> 

           </div>
       </div>
      
   </div>
</section>
:
<div>
<section class="bg-white">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
    <h1 style={{fontSize:'9vw',marginTop:'13vw'}}class='mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl'>Become a Cake Expert in <mark class="px-2 text-white bg-blue-700 rounded ">No time.</mark>  </h1>
        <p style={{fontSize:'3vw'}} class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 par">We will take you through the basics of cake making, from selecting the right ingredients and mixing them properly to shaping and decorating the cake. We will also provide you with tips and tricks that will make your cakes stand out from the rest.  </p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
    
         </div>
    </div>
</section>
<div class=" mx-auto" style={{width:'90%'}}>
               <div className={`${error.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{error}
</div>
</div>
</div>
<p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Reserve your spot Now!</p>


        <div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Full Name</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>fullNameSet(e))} required  autoComplete="off" placeholder="kebede Solomon" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${fullNameError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Full Name Can't be empty
</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>emailSet(e))} required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${emailError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>     
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Phone Number</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>phoneNumberSet(e))} required  autoComplete="off" placeholder="0912345678" type="tel" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${phoneNumberError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Phone Number Can't be empty
</div>
</div>
</div>
</div>


<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>

<button
   type='button'
   onClick={reserveSpot}
   style={{fontSize:'12px',margin:'auto',background:'#D9FE00',height:'55px'}}
   className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
 >
  Reserve spot 
 </button>
 </div>
 <div class="w-full flex items-center justify-between py-5">
         <hr class="w-full bg-gray-400"/>
         <p class="text-base font-medium leading-4 px-2.5 text-gray-400 mt-4"></p>
         <hr class="w-full bg-gray-400  "/>
        </div>

  
   
</div>
</div>

            }
         
        </div>

    </div>
  )
}
