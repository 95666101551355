import { collection, deleteDoc, doc, onSnapshot, setDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase'
import { v4 } from 'uuid'
import { onAuthStateChanged,signInWithEmailAndPassword } from 'firebase/auth'
import { signOut } from 'firebase/auth';

export default function CakeDetails() {
    const {cakeId} = useParams()
    const [cake,setcake] = useState([])
    const [cart,setcart] = useState([])
    const [cartError,setcartError] = useState('')
    const [selectedColorError,setselectedColorError] = useState('')
    const [loading,setloading] = useState(false)
    const [deleteCake,setdeleteCake] = useState(false)
    const [deleteCakeId,setdeleteCakeId] = useState('')







    const [uid,setuid] = useState('')
    const[fullName,setfullName] = useState('')
    const[fullNameError,setfullNameError] = useState('')
    const[email,setemail] = useState('')
    const[emailError,setemailError] = useState('')
    const[password,setpassword] = useState('')
    const[passwordError,setpasswordError] = useState('')
    const[signInError,setsignInError] = useState('')
    const[isLoading,setIsLoading] = useState(true)
    const[uploadMessage,setuploadMessage] = useState('')
    const[design,setdesign] = useState([])
    const[designError,setdesignError] = useState('')



    const navigate = useNavigate()

    const [signinpop,setsigninpop] = useState(false)


  const [currentIndexes, setCurrentIndexes] = useState({});
  const [selectedColor, setSelectedColor] = useState('');

  const [screenHeight, setScreenHeight] = useState(window.screen.height);
  const [pop,setpop] =useState(false)
  

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.screen.height);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function navigationPop(){
      if(pop === true){
        setpop(false)
      }
      else{
        setpop(true)
      }
    }

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setselectedColorError('')
  };
useEffect(() => {
  const unsubscribe = onSnapshot(doc(firestore, 'Cakes', cakeId), (snapshot) => {
   if(snapshot.exists()){
    setcake((prevCake) => [{ ...snapshot.data(),id:snapshot.id }]);

   }
   setIsLoading(false)
  });

  return unsubscribe;
}, [cakeId, firestore]);
    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
            setuid(uid)

      
      
      
                
            const userDocRef = doc(firestore, 'Users', uid);
            const cartCollectionRef = collection(userDocRef, 'Cart');
      
            const unsubscribeCartCollection = onSnapshot(cartCollectionRef, (cartSnapshot) => {
              const cartItems = cartSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
              setcart(cartItems);
            });
                  
              
            return () => {
              unsubscribeCartCollection();
            };
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
          
        };
      }, []);

      function handleLogOut(){
        signOut(auth).then(
         navigate('/')
        )
       }
      function handleImgSelect(event) {
        const selectedFiles = (event.target.files[0]);
      
          // Check file size
          
         const maxSize = 5 * 1024 * 1024; // 5MB
        // Check file type
          if (selectedFiles.size > maxSize) {
            setuploadMessage(`File ${selectedFiles.name} exceeds the size limit of 5MB.`);
      
            return false;
      
          }
          else{
            setuploadMessage("")
            setdesign([])
          }
        
        
          setdesign(selectedFiles);
      
      
      
        // Clear the file input value to allow selecting the same file again
        event.target.value = '';
      }

    function addToCart(cakeColor){
      window.scrollTo(0, 0);

      if(cart.length <= 4){
        if(cakeColor.length > 1){
          if(selectedColor.length ===0){
            setselectedColorError('error')

          }
          else{
            setDoc(doc(firestore,'Users',uid,'Cart',cakeId),{
              cakeId:cakeId,
              chosenColor:selectedColor
          })
          }
        }
        else{
          setDoc(doc(firestore,'Users',uid,'Cart',cakeId),{
            cakeId:cakeId,
            chosenColor:selectedColor
        })
        }
       
      }
      else{
        setcartError('Your cart is full. You are only allowed to shop for five cakes')

      }
    }

    function shopItem(cakeColor){
      window.scrollTo(0, 0);

      if(cart.length <= 4){
        if(cakeColor.length > 1){
          if(selectedColor.length ===0){
            setselectedColorError('error')

          }
          else{
            setDoc(doc(firestore,'Users',uid,'Cart',cakeId),{
              cakeId:cakeId,
              chosenColor:selectedColor
          }).then(
            navigate(`/cake/${uid + v4().slice(0,6)}/checkout`)
  
          )
          }
        }
        else{
          setDoc(doc(firestore,'Users',uid,'Cart',cakeId),{
            cakeId:cakeId,
            chosenColor:selectedColor
        }).then(
          navigate(`/cake/${uid + v4().slice(0,6)}/checkout`)

        )
        }
       
      }
      else{
        setcartError('Your cart is full. You are only allowed to shop for five cakes')

      }

    }

   


    function CakeGallery({ cake, currentIndex, handlePrevious, handleNext }) {
      if (cake)
        return (
      
          <div>
            <div id="desktop">
            <div id="gallery" className="relative w-full  " data-carousel="slide">
            <div className="relative overflow-hidden rounded-lg">
              <div className="relative overflow-hidden rounded-lg shadow-xl">
                <div className="duration-700 ease-in-out " data-carousel-item>
                  {cake.length !=0 && cake.cakePhotos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo.cakePhotos}
                      alt={`Selected file ${index}`}
                      style={{ height: '500px',minWidth:'100%', objectFit: 'cover' }}
                      className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
                    />
                  ))}
                  {cake.length !=0 && cake.cakePhotos.length === 0 && (
                    <img
                      className="h-auto w-full rounded-lg"
                      style={{ height: '300px', objectFit: 'cover' }}
                      src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                      alt="description"
                    />
                  )}
                </div>
      
                {cake.length !=0 && cake.cakePhotos.length >= 2 && (
                     <button
                     type="button"
                     className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                     data-carousel-prev
                   >
                   <span onClick={() => handlePrevious(cake.length !=0 && cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
      
                       <svg
                         
                         className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 6 10"
                       >
                         <path
                           stroke="currentColor"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="M5 1 1 5l4 4"
                         />
                       </svg>
                       <span className="sr-only">Previous</span>
                     </span>
                   </button>
               
                )}
      
                {cake.length !=0 && cake.cakePhotos.length >= 2 && (
                   <div
                   type="div"
                   className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4  group focus:outline-none"
                   data-carousel-next
                 >
                   <span onClick={() => handleNext(cake.length !=0 && cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                     <svg
                       
                       className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                       aria-hidden="true"
                       xmlns="http://www.w3.org/2000/svg"
                       fill="none"
                       viewBox="0 0 6 10"
                     >
                       <path
                         stroke="currentColor"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                         stroke-width="2"
                         d="m1 9 4-4-4-4"
                       />
                     </svg>
                     <span className="sr-only">Next</span>
                   </span>
                 </div>
      
                )}
              </div>
            </div>
          
          </div>
            </div>
            <div id="tablet">
            <div id="gallery" className="relative w-full  " data-carousel="slide">
            <div className="relative overflow-hidden rounded-lg">
              <div className="relative overflow-hidden rounded-lg shadow-xl">
                <div className="duration-700 ease-in-out " data-carousel-item>
                  {cake.length !=0 && cake.cakePhotos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo.cakePhotos}
                      alt={`Selected file ${index}`}
                      style={{ height: '500px',minWidth:'100%', objectFit: 'cover' }}
                      className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
                    />
                  ))}
                  {cake.length !=0 && cake.cakePhotos.length === 0 && (
                    <img
                      className="h-auto w-full rounded-lg"
                      style={{ height: '300px', objectFit: 'cover' }}
                      src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                      alt="description"
                    />
                  )}
                </div>
      
                {cake.length !=0 && cake.cakePhotos.length >= 2 && (
                     <button
                     type="button"
                     className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                     data-carousel-prev
                   >
                   <span onClick={() => handlePrevious(cake.length !=0 && cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
      
                       <svg
                         
                         className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 6 10"
                       >
                         <path
                           stroke="currentColor"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="M5 1 1 5l4 4"
                         />
                       </svg>
                       <span className="sr-only">Previous</span>
                     </span>
                   </button>
               
                )}
      
                {cake.length !=0 && cake.cakePhotos.length >= 2 && (
                   <div
                   type="div"
                   className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4  group focus:outline-none"
                   data-carousel-next
                 >
                   <span onClick={() => handleNext(cake.length !=0 && cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                     <svg
                       
                       className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                       aria-hidden="true"
                       xmlns="http://www.w3.org/2000/svg"
                       fill="none"
                       viewBox="0 0 6 10"
                     >
                       <path
                         stroke="currentColor"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                         stroke-width="2"
                         d="m1 9 4-4-4-4"
                       />
                     </svg>
                     <span className="sr-only">Next</span>
                   </span>
                 </div>
      
                )}
              </div>
            </div>
          
          </div>
            </div>
            <div id="mobile">
            <div id="gallery" className="relative w-full px-5 " >
            <div className="relative overflow-hidden rounded-lg">
              <div className="relative overflow-hidden rounded-lg shadow-xl">
                <div className="duration-700 ease-in-out " data-carousel-item>
                  {cake.length !=0 && cake.cakePhotos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo.cakePhotos}
                      alt={`Selected file ${index}`}
                      style={{ height: '500px',minWidth:'100%', objectFit: 'cover' }}
                      className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
                    />
                  ))}
                  {cake.length !=0 && cake.cakePhotos.length === 0 && (
                    <img
                      className="h-auto w-full rounded-lg"
                      style={{ height: '300px', objectFit: 'cover' }}
                      src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                      alt="description"
                    />
                  )}
                </div>
      
                {cake.length !=0 && cake.cakePhotos.length >= 2 && (
                     <button
                     type="button"
                     className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                     data-carousel-prev
                   >
                   <span onClick={() => handlePrevious(cake.length !=0 && cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
      
                       <svg
                         
                         className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 6 10"
                       >
                         <path
                           stroke="currentColor"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="M5 1 1 5l4 4"
                         />
                       </svg>
                       <span className="sr-only">Previous</span>
                     </span>
                   </button>
               
                )}
      
                {cake.length !=0 && cake.cakePhotos.length >= 2 && (
                   <div
                   type="div"
                   className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4  group focus:outline-none"
                   data-carousel-next
                 >
                   <span onClick={() => handleNext(cake.length !=0 && cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                     <svg
                       
                       className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                       aria-hidden="true"
                       xmlns="http://www.w3.org/2000/svg"
                       fill="none"
                       viewBox="0 0 6 10"
                     >
                       <path
                         stroke="currentColor"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                         stroke-width="2"
                         d="m1 9 4-4-4-4"
                       />
                     </svg>
                     <span className="sr-only">Next</span>
                   </span>
                 </div>
      
                )}
              </div>
            </div>
          
          </div>
            </div>
          </div>

        
        );
      }
      useEffect(() => {
        const initialIndexes = cake.reduce((acc, cake) => {
          acc[cake.id] = 0;
          return acc;
        }, {});
        setCurrentIndexes(initialIndexes);
      }, [cake]);
      
      const handlePrevious = (cakePhotos, id) => {
        setCurrentIndexes((prevIndexes) => ({
          ...prevIndexes,
          [id]: (prevIndexes[id] === 0 ? cakePhotos.length - 1 : prevIndexes[id] - 1),
        }));
      };
      
      const handleNext = (cakePhotos, id) => {
        setCurrentIndexes((prevIndexes) => ({
          ...prevIndexes,
          [id]: (prevIndexes[id] === cakePhotos.length - 1 ? 0 : prevIndexes[id] + 1),
        }));
      };

      function signInPopSet(){
        setsigninpop(true)
      }

      function setsigninpopClose(){
        setsigninpop(false)
      }
      function emailSet(e){
        setemail(e.target.value)
        if(e.target.value.length === 0){
          setemailError('error')
  
        }
        else{
          setemailError('')
        }
      }
  
      function passwordSet(e){
        setpassword(e.target.value)
        if(e.target.value.length === 0){
          setpasswordError('error')
  
        }
        else{
          setpasswordError('')
        }
      }
  
      function signIn(e) {
        e.preventDefault();
        
       if(email.length !=0 && password.length !=0){
        signInWithEmailAndPassword(auth, email, password).then(setloading(true))
        .then(() => {
          navigate(`/cake/${cakeId}`)
          setloading(false)
          setsigninpop(false)

      })
      .catch((error) => {
        setloading(false)
          // Handle specific error cases
          if (error.code === "auth/user-not-found") {
              setsignInError('User not found.');
          } else if (error.code === "auth/invalid-credential") {
              setsignInError('Invalid login credentials');
          } else if (error.code === "auth/network-request-failed") {
            setsignInError('Check your Internet Connection');
  
          }
          else if (error.code === "auth/invalid-email") {
            setsignInError('Invalid login credentials');
  
          }
           else {
              setsignInError(error.message);
          }
      });
       }
       
    }

    function formatNumber(num) {
      if(num){
          return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      };
    }

    function deleteCakeClose(){
      setdeleteCake(false)
    }
    function deleteCakeOpen(id){
      setdeleteCakeId(id)
      setdeleteCake(true)
    }

    function handleDeleteCake (){
      deleteDoc(doc(firestore,'Cakes',deleteCakeId)).then(
        navigate('/admin/managecakes')
      )
    }

  return (
    <div>
        <div id='desktop' >
      
        {
                deleteCake === true &&
                <div id="deleteModal" class="">
    <div class="relative p-4 w-full max-w-md h-full md:h-auto">
        <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5">
            <button onClick={deleteCakeClose}type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Close modal</span>
            </button>
            <p class="mb-4 text-gray-900 header">Are you sure you want to Delete this Cake?</p>
            
<div className="relative mx-auto mb-8" style={{width:"90%"}}>


      

</div>
            <div class="flex justify-center items-center space-x-4">
                <button onClick={deleteCakeClose} data-modal-toggle="deleteModal" type="button" class="par py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10   dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    No, cancel
                </button>
                <button onClick={handleDeleteCake} type="submit" class="py-2 px-3 text-sm par font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900">
                    Yes, I'm sure
                </button>
            </div>
        </div>
    </div>
</div>
            }
        {
          isLoading === true ?
          <div>
          <div style={{height:'80vh'}} class="bg-white flex items-center justify-center  rounded  w-full p-10 -mt-5">
          
          <div
          className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
          role="status"></div></div>
                  </div>
          :
          cake.length != 0?
          <div >
    
    <aside class="bg-gray-900 adminNav  fixed inset-0 z-50 my-4 ml-4  rounded-xl transition-transform duration-300 xl:translate-x-0">
    <div class="relative border-b border-white/20">
      <div class="flex items-center gap-4 py-6 px-8" >
        <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
      </div>
   
    </div>
    <div class="m-4">
      <ul class="mb-4 flex flex-col gap-1">
        <li>
        <Link to='/admin'>

        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
             <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
           </button>
           
          </Link>
        </li>
        <li>
        <Link to='/admin/orders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/allorders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
              
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/addcake'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/managecakes'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/messages'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/reserved'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Reserved Students</p>
            </button>
          </Link>
        </li>
      </ul>
      <ul class="mb-4 flex flex-col gap-1">
        <li class="mx-3.5 mt-4 mb-2">
          <p class="barlow block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
        </li>
    
        <li>
            <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
            
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
            </button>
        </li>
      </ul>
    </div>
  </aside>




<div >

<div  >
{
cake && cake.map(i=>{
const{cakeSize,cakeTitle,cakeColor,cakeDescription,cakeIngredients,cakePrice,cakeFlavor,cakeDelivery,id} = i
return(
    <div class="adminBody ">
 
    <div class=" mx-auto px-4 sm:px-6 lg:px-8">
    <div className='flex justify-between w-full'>
         <h1 className='barlow pl-10 pt-10 mb-5 flex items-center capitalize' style={{fontSize:"12px"}}> 
    <Link className='underline' to={`/admin/managecakes`}>Cakes</Link>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {cakeTitle}
</h1>
<button onClick={()=>deleteCakeOpen(id)}  class="mt-10 flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-5 pb-5 rounded-lg fjalla px-3" style={{fontSize:'11px'}}>
                  
                  Delete Cake
                   
                   </button>
         </div>
        <div class="flex items-center">
            <div class="" style={{width:'40%'}}>
      
            {cake && cake.map((cake) => (
<div key={cake.id}>
<CakeGallery
cake={cake}
currentIndex={currentIndexes[cake.id] || 0}
handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
/>
</div>
))}
           
            </div>
            <div class="ml-8" style={{width:'60%'}}>
              <div className="flex w-full justify-between items-center mb-2">
              <h2 class="text-2xl font-bold text-gray-800 dark:text-white  barlow" style={{fontSize:'25px'}}>{cakeTitle}</h2>
              <span class="text-gray-900 header"style={{fontSize:'15px'}}> {cakePrice} ETB</span>

              </div>
                <p class="text-gray-600  text-sm mt-5 par break-all">
                {cakeDescription} </p>
                <div class="flex my-4">
                   
                    <div >
                        <span class="font-bold text-gray-700 ">Cake Flavor:</span>
                        <span class="text-gray-600 par"> {cakeFlavor}</span>
                    </div>
                </div>
                <div class="mb-4">
                   {
                    cakeColor && cakeColor.length > 1 ?
                    <span class="font-bold text-gray-700 ">Choose Cake Color:</span>
                    :
                    <span class="font-bold text-gray-700 "> Color:</span>
                   }
                    <div>
<div className="flex mt-4">
 
{cakeColor && cakeColor.length > 1 ?
cakeColor && cakeColor.map((color, index) => (
<div key={index} className="flex items-center mx-2">
<div class="flex items-center mb-4 ">
<input type="checkbox" id={`color-${index}`}
checked={selectedColor === color}
onChange={() => handleColorSelect(color)} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"/>

<label
htmlFor={`color-${index}`}
className="ml-1 w-7 h-7 rounded-full shadow border"
style={{ backgroundColor: color }}
></label>
</div>

</div>
))
:
cakeColor && cakeColor.map((color, index) => (
  <div key={index} className="flex items-center mx-2">
  <div class="flex items-center mb-4 ">

  <label
  htmlFor={`color-${index}`}
  className="ml-1 w-7 h-7 rounded-full shadow border "
  style={{ backgroundColor: color }}
  ></label>
  </div>
  
  </div>
  ))}
</div>
</div>
                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Cake Size:</span>
                   <div className='mt-2'>

                  {cakeSize}
                   </div>

                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Ingredients:</span>
                   <div >

                   <React.Fragment>
                          {cakeIngredients.map((i, index) => (
                            <React.Fragment key={index}>
                              <span className="text-gray-600 par">{i}</span>
                              {index < cakeIngredients.length - 1 && ', '}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                   </div>

                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Cake Delivery:</span>
                   <div className='mt-2'>

                  {cakeDelivery === 'Shop Pickup' ? `We do not offer delivery services, so you must pick up the cake from our shop`:'Delivery Available'}
                   </div>

                </div>
                <div className='mb-8'>
                   
                </div>


            



            </div>
        </div>
    </div>
</div>

)
})

}
</div>

</div>

</div>
:
<div>
<div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cake-pana.svg?alt=media&token=2749019d-15fe-499d-af3e-8342c9ef434a" alt="No Cake" style={{width:"42%"}}/>
<h1 className='fjalla text-center text-gray-900 mt-8 barlow' style={{fontSize:"30px"}}>  We couldn't find any cakes for you 




</h1>
<Link to='/admin/managecakes'>
<button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-7 py-4 me-2 mb-2">Shop Now</button> 

</Link>
</div>
</div>
        }
           

        </div>
            <div id='tablet' >
            {
                deleteCake === true &&
                <div id="deleteModal" class="">
    <div class="relative p-4 w-full max-w-md h-full md:h-auto">
        <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5">
            <button onClick={deleteCakeClose}type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Close modal</span>
            </button>
            <p class="mb-4 text-gray-900 header">Are you sure you want to Delete this Cake?</p>
            
<div className="relative mx-auto mb-8" style={{width:"90%"}}>


      

</div>
            <div class="flex justify-center items-center space-x-4">
                <button onClick={deleteCakeClose} data-modal-toggle="deleteModal" type="button" class="par py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10   dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    No, cancel
                </button>
                <button onClick={handleDeleteCake} type="submit" class="py-2 px-3 text-sm par font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900">
                    Yes, I'm sure
                </button>
            </div>
        </div>
    </div>
</div>
            }
        {
          isLoading === true ?
          <div>
          <div style={{height:'80vh'}} class="bg-white flex items-center justify-center  rounded  w-full p-10 -mt-5">
          
          <div
          className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
          role="status"></div></div>
                  </div>
          :
          cake.length != 0?
          <div >





<div >
{
     pop === true ?
     <aside style={{height:screenHeight}}class="bg-gray-900     transition-transform duration-300 xl:translate-x-0">
     <div class="relative border-b border-white/20">
<svg onClick={navigationPop} aria-hidden="true" class="w-8 h-8 text-white absolute top-5 right-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
      
       <div class="flex justify-center items-center  py-6 px-2" >
         <h6 class="text-center block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
       </div>
    
     </div>
     <div class="my-4">
       <ul class="m-4 flex flex-col ">
         <li>
         <Link to='/admin'>
 
         <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
              
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
            </button>
           </Link>
         </li>
         <li>
         <Link to='/admin/orders'>
 
             <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
              
               <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
             </button>
           </Link>
         </li>
         <li>
         <Link to='/admin/allorders'>
 
             <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
               
               <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
             </button>
           </Link>
         </li>
         <li>
         <Link to='/admin/addcake'>
 
             <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
              
               <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
             </button>
           </Link>
         </li>
         <li>
         <Link to='/admin/managecakes'>
 
             <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
              
               <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
             </button>
           </Link>
         </li>
         <li>
         <Link to='/admin/messages'>
 
             <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
              
               <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
             </button>
           </Link>
         </li>
         <li>
         <Link to='/admin/reserved'>
 
         <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
              
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Students</p>
            </button>
           </Link>
         </li>
       </ul>
       <ul class="m-4 flex flex-col">
         <li class=" mt-4 mb-2">
           <p class="par block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
         </li>
     
         <li>
             <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
             
               <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
             </button>
         </li>
       </ul>
     </div>
   </aside>
   :
   <div class="p-4   px-8">
   <nav class="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-0 py-1">
     <div class="flex flex-col  gap-6 ">
     
       <div class="flex justify-between w-full items-center mb-4">
<Link style={{width:'13%'}} to='/admin'>
<img style={{width:'100%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />

  </Link>
<button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
 <svg class="w-8 h-8" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
</button>
</div>  <div class="capitalize">
   
   <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-900 barlow" style={{fontSize:'25px'}}>Cake Details</h6>
 </div>
     </div>
   </nav>
{
cake && cake.map(i=>{
const{cakeSize,cakeTitle,cakeColor,cakeDescription,cakeIngredients,cakePrice,cakeFlavor,id} = i
return(
    <div class=" ">
    <div class=" mx-auto px-4 sm:px-6 lg:px-8">
    <div className='flex w-full justify-between'>
 <div></div>
 <button onClick={()=>deleteCakeOpen(id)}  class="mt-10 flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-5 pb-5 rounded-lg fjalla px-3" style={{fontSize:'11px'}}>
                  
                  Delete Cake
                   
                   </button>
   </div>
        <div class="flex items-center">
            <div class="" style={{width:'55%'}}>
   <div className='flex w-full justify-between'>
   <h1 className='barlow pl-10 pt-10 mb-5 flex items-center capitalize' style={{fontSize:"12px"}}> 
    <Link className='underline' to={`/admin/managecakes`}>Cakes</Link>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {cakeTitle}</h1>

   </div>
            {cake && cake.map((cake) => (
<div key={cake.id}>
<CakeGallery
cake={cake}
currentIndex={currentIndexes[cake.id] || 0}
handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
/>
</div>
))}
           
            </div>
            <div class="ml-8" style={{width:'60%'}}>
              <div className="flex w-full justify-between items-center mb-2">
              <h2 class="text-2xl font-bold text-gray-800 dark:text-white  barlow" style={{fontSize:'25px'}}>{cakeTitle}</h2>
              <span class="text-gray-900 header"style={{fontSize:'15px'}}> {cakePrice} ETB</span>

              </div>
                <p class="text-gray-600  text-sm mt-5 par break-all">
                {cakeDescription} </p>
                <div class="flex my-4">
                   
                    <div >
                        <span class="font-bold text-gray-700 ">Cake Flavor:</span>
                        <span class="text-gray-600 par"> {cakeFlavor}</span>
                    </div>
                </div>
                <div class="mb-4">
                   {
                    cakeColor && cakeColor.length > 1 ?
                    <span class="font-bold text-gray-700 ">Choose Cake Color:</span>
                    :
                    <span class="font-bold text-gray-700 "> Color:</span>
                   }
                    <div>
<div className="flex mt-4">
 
{cakeColor && cakeColor.length > 1 ?
cakeColor && cakeColor.map((color, index) => (
<div key={index} className="flex items-center mx-2">
<div class="flex items-center mb-4 ">
<input type="checkbox" id={`color-${index}`}
checked={selectedColor === color}
onChange={() => handleColorSelect(color)} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"/>

<label
htmlFor={`color-${index}`}
className="ml-1 w-7 h-7 rounded-full shadow border"
style={{ backgroundColor: color }}
></label>
</div>

</div>
))
:
cakeColor && cakeColor.map((color, index) => (
  <div key={index} className="flex items-center mx-2">
  <div class="flex items-center mb-4 ">

  <label
  htmlFor={`color-${index}`}
  className="ml-1 w-7 h-7 rounded-full shadow border "
  style={{ backgroundColor: color }}
  ></label>
  </div>
  
  </div>
  ))}
</div>
</div>
                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Cake Size:</span>
                   <div className='mt-2'>

                  {cakeSize}
                   </div>

                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Ingredients:</span>
                   <div >

                
                        <React.Fragment>
                          {cakeIngredients.map((i, index) => (
                            <React.Fragment key={index}>
                              <span className="text-gray-600 par">{i}</span>
                              {index < cakeIngredients.length - 1 && ', '}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                   </div>

                </div>
                <div className='mb-8'>
                   
                </div>


            



            </div>
        </div>
    </div>
</div>

)
})

}
</div>
}

</div>

</div>
:
<div>
<div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cake-pana.svg?alt=media&token=2749019d-15fe-499d-af3e-8342c9ef434a" alt="No Cake" style={{width:"42%"}}/>
<h1 className='fjalla text-center text-gray-900 mt-8 barlow' style={{fontSize:"30px"}}>  We couldn't find any cakes for you 




</h1>
<Link to='/admin/managecakes'>
<button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-7 py-4 me-2 mb-2">Shop Now</button> 

</Link>
</div>
</div>
        }
           

        </div>
        <div id='mobile' >
        {
                deleteCake === true &&
                <div id="deleteModal" class="">
    <div class="relative p-4 w-full max-w-md h-full md:h-full flex justify-center items-center">
        <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5">
            <button onClick={deleteCakeClose}type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Close modal</span>
            </button>
            <p class="mb-4 text-gray-900 header">Are you sure you want to Delete this Cake?</p>
            
<div className="relative mx-auto mb-8" style={{width:"90%"}}>


      

</div>
            <div class="flex justify-center items-center space-x-4">
                <button onClick={deleteCakeClose} data-modal-toggle="deleteModal" type="button" class="par py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10   dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    No, cancel
                </button>
                <button onClick={handleDeleteCake} type="submit" class="py-2 px-3 text-sm par font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900">
                    Yes, I'm sure
                </button>
            </div>
        </div>
    </div>
</div>
            }
        {
          isLoading === true ?
          <div>
  <div style={{height:'80vh'}} class="bg-white flex items-center justify-center  rounded  w-full p-10 -mt-5">
  
  <div
  className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
  role="status"></div></div>
          </div>
          :
          cake != 0 ?
          <div>
            
 {
      pop === true ?
      <aside style={{height:screenHeight}}class=" bg-gray-900 50 my-4 ml-4  rounded-xl transition-transform duration-300 xl:translate-x-0">
      <div class="relative border-b border-white/20">
      <svg onClick={navigationPop} aria-hidden="true" class="w-8 h-8 text-white absolute top-5 right-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
  
        <div class="flex justify-center items-center  py-6 px-2" >
          <h6 class="text-center block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
        </div>
     
      </div>
      <div class="my-4">
        <ul class="m-4 flex flex-col ">
          <li>
          <Link to='/admin'>
  
          <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
               
               <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
             </button>
            </Link>
          </li>
          <li>
          <Link to='/admin/orders'>
  
              <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
               
                <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
              </button>
            </Link>
          </li>
          <li>
          <Link to='/admin/allorders'>
  
              <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                
                <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
              </button>
            </Link>
          </li>
          <li>
          <Link to='/admin/addcake'>
  
              <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
               
                <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
              </button>
            </Link>
          </li>
          <li>
          <Link to='/admin/managecakes'>
  
              <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
               
                <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
              </button>
            </Link>
          </li>
          <li>
          <Link to='/admin/messages'>
  
              <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
               
                <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
              </button>
            </Link>
          </li>
          <li>
          <Link to='/admin/reserved'>
  
          <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
               
               <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Students</p>
             </button>
            </Link>
          </li>
        </ul>
        <ul class="m-4 flex flex-col">
          <li class=" mt-4 mb-2">
            <p class="par block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
          </li>
      
          <li>
              <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
              
                <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
              </button>
          </li>
        </ul>
      </div>
    </aside>
    :
    <div class="p-4  w-full px-8">

    <nav class="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-0 py-1">
<div class="flex flex-col-reverse justify-between gap-6 md:flex-row md:items-center">
<div class="capitalize">

 <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-900 barlow" style={{fontSize:'5vw'}}>Cake Details</h6>
</div>
<div class="flex justify-between w-full items-center mb-4">
  <Link style={{width:'18%'}} to='/admin'>
<img style={{width:'100%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />

  </Link>

<button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
       <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
     </button>
     </div>

</div>
</nav>
    <div  >
    {
        cake && cake.map(i=>{
            const{cakeSize,cakeTitle,cakeColor,id,cakeDescription,cakeIngredients,cakePrice,cakeFlavor} = i
            return(
                <div class=" py-10">
                  <div className='flex w-full justify-between'>
 <div></div>
 <button onClick={()=>deleteCakeOpen(id)}  class="mt-10 flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-5 pb-5 rounded-lg fjalla px-3" style={{fontSize:'11px'}}>
                  
                  Delete Cake
                   
                   </button>
   </div>
                <div class=" mx-auto ">
                <h1 className='barlow pl-10 pt-10 mb-5 flex items-center capitalize' style={{fontSize:"12px"}}> 
<Link className='underline' to={`/admin/managecakes`}>Cakes</Link>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


{cakeTitle}</h1>
  
                    <div class="flex flex-col w-full">
                        <div class="" style={{width:'100%'}}>
                        {cake && cake.map((cake) => (
      <div key={cake.id}>
        <CakeGallery
          cake={cake}
          currentIndex={currentIndexes[cake.id] || 0}
          handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
          handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
        />
      </div>
    ))}
                       
                        </div>
                        <div class="mx-auto" style={{width:'90%'}}>
                          <div className='flex justify-between w-full items-center' style={{paddingTop:'6vw'}}>
                          <h2 style={{fontSize:'4.8vw'}} class="text-2xl font-bold text-gray-900  mb-2 barlow">{cakeTitle}</h2>
                            
                          <p style={{fontSize:'3.5vw'}} class="text-gray-800 header">{formatNumber(cakePrice)} ETB </p>

                          </div>
                            <div className='mb-8'>
                                <p class="text-gray-600  text-sm mt-2 par break-all" style={{fontSize:'2.8vw',lineHeight:'3.2vw'}}>
                            {cakeDescription} </p>
                            </div>
                            <div class="flex mb-4">
                             
                                <div>
                                    <span class="font-bold text-gray-700 "style={{fontSize:'3.5vw'}}>Cake Flavor:</span>
                                    <span class="text-gray-600 par" style={{fontSize:'2.8vw'}}> {cakeFlavor}</span>
                                </div>
                            </div>
                            <div class="mb-4">

                              {
                               cakeColor&& cakeColor.length >= 2? 
                                <span class="font-bold text-gray-700 " style={{fontSize:'3.5vw'}}> Choose Cake Color:</span>
:
<span class="font-bold text-gray-700 " style={{fontSize:'3.5vw'}}> Color:</span>

                              }
                                <div class="flex items-center ">
                                <div>
<div className="flex mt-4">
{cakeColor &&cakeColor&& cakeColor.length >=2 ?
cakeColor && cakeColor.map((color, index) => (
  <div key={index} className="flex items-center mx-2">
    <div class="flex items-center mb-2 ">

    <label
      htmlFor={`color-${index}`}
      className="ml-1 rounded-full shadow border"
      style={{ backgroundColor: color,width:'4vw',height:'4vw' }}
    ></label>
</div>

  </div>


))
:
cakeColor && cakeColor.map((color, index) => (
<div key={index} className="flex items-center mx-2">
  <div class="flex items-center mb-2 ">

  <label
    htmlFor={`color-${index}`}
    className="ml-1 w- h- rounded-full shadow border "
    style={{ backgroundColor: color,width:'4vw',height:'4vw' }}
  ></label>
</div>

</div>


))}
</div>
</div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <span class="font-bold text-gray-700" style={{fontSize:'3.5vw'}}>Cake Size:</span>
                               <div className='text-gray-600 par' style={{fontSize:'2.8vw'}}>

                              {cakeSize}
                               </div>

                            </div>
                            <div class="mb-4" style={{marginBottom:'6vw'}}>
                                <span class="font-bold text-gray-700 mb-2" style={{fontSize:'3.5vw'}}>Ingredients:</span>
                               <div >

                             
                                 <React.Fragment>
                 {cakeIngredients.map((i, index) => (
                   <React.Fragment key={index}>
                     <span className="text-gray-600 par" style={{fontSize:'2.8vw'}}>{i}
                     {index < cakeIngredients.length - 1 && ', '}
                     </span>
                   </React.Fragment>
                 ))}
               </React.Fragment>
                               </div>

                            </div>
                    


        


                        </div>
                    </div>
                </div>
            </div>

            )
        })

}
    </div>
 
    </div>
 }

          
      
 
           
             </div>
             :
             <div>
               <nav class="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-0 py-1">
<div class="flex flex-col-reverse justify-between gap-6 md:flex-row md:items-center">
<div class="capitalize">

 <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-900 barlow" style={{fontSize:'5vw'}}>Cake Details</h6>
</div>
<div class="flex justify-between w-full items-center mb-4">
  <Link style={{width:'18%'}} to='/admin'>
<img style={{width:'100%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />

  </Link>

<button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
       <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
     </button>
     </div>

</div>
</nav>
                <div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cake-pana.svg?alt=media&token=2749019d-15fe-499d-af3e-8342c9ef434a" alt="No Cake" style={{width:"35%"}}/>
<h1 className='fjalla text-center text-gray-900 mt-8 barlow' style={{fontSize:"4vw"}}>  We couldn't find any cakes for you 

       


</h1>
<Link to='/admin/managecakes'>
        <button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-7 py-4 me-2 mb-2">Shop Now</button> 

         </Link>
</div>
             </div>
        }
            

        </div>
        </div>
  )
}
