import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { collection, doc, onSnapshot } from 'firebase/firestore';

export default function Nav() {
    const [user,setuser] =useState([])
    const [cart,setcart] =useState([])
    const [pop,setpop] =useState(false)
    const navigate = useNavigate()


    const [uid,setuid] =useState('')



    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            const uid = user.uid;
      setuid(uid)
            const userDocRef = doc(firestore, 'Users', uid);
            const cartCollectionRef = collection(userDocRef, 'Cart');
      
            const unsubscribeUserDoc = onSnapshot(userDocRef, (userSnapshot) => {
              setuser([{ ...userSnapshot.data(), id: userSnapshot.id }]);
            });
      
            const unsubscribeCartCollection = onSnapshot(cartCollectionRef, (cartSnapshot) => {
              const cartItems = cartSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
              setcart(cartItems);
            });
      
            return () => {
              unsubscribeUserDoc();
              unsubscribeCartCollection();
              unsubscribe();
            };
          } else {
            console.log("logged out");
          }
        });
      }, []);


      function navigationPop(){
        if(pop === true){
          setpop(false)
        }
        else{
          setpop(true)
        }
      }

      function handleLogOut(){
        signOut(auth).then(
         navigate('/')
        )
       }
  return (
 user.map(i=>{
    const {photo,fullName} = i
    return(
        <div>
         
        <div id="desktop">
            <nav>
            <header class="antialiased">
  <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 ">
      <div class="flex flex-wrap justify-between items-center">
          <div class="flex justify-start items-center">
              <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="hidden p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
              </button>
          
              <Link to='/cakes' style={{width:'35%'}} className='flex items-center'>
                             <img src='https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc'alt="Logo" className='ml-5' style={{width:'100%'}} />

                        </Link>
             
            </div>
          <div class="flex items-center lg:order-2">
      <Link to={`/cake/${uid}/checkout`}>

          <button type="button" data-dropdown-toggle="notification-dropdown" class="p-2 mr-1 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100  focus:ring-4 focus:ring-gray-300 #">
    
    <div class="relative ">
  <div class="t-0 absolute left-3">
    <p class="par flex h-1.5 w-1.5 items-center justify-center rounded-full bg-red-500 p-2.5 text-xs text-white">{cart.length}</p>
  </div>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="file:  w-7 h-7">
    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
  </svg>
</div>
</button>
</Link>
  

          
          
            {
              photo && photo.length != 0 ?
                 <Link to='/profile'>
         <button  type="button" class="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 #" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
                  <span class="sr-only">Open user menu</span>
                  <img class="w-8 h-8 rounded-full" src={photo} alt="user photo"/>
              </button></Link>
              :
              <Link to='/profile'>

              <div className={`z-100 profilePageImage flex items-center justify-center w-8 h-8 l bg-blue-600   m-auto rounded-full object-cover `} style={{ position: 'relative' }}>
              <p className="young capitalize text-white" style={{ fontSize: '10px' }}>
                {fullName && fullName[0]}
              </p>
            </div>
            </Link>
            }
      
              
          </div>
      </div>
  </nav>
</header>
            </nav>
            {
              pop === true&&
              <div className='navigationPop'>
              <div className='w-1/2 bg-white px-5 border rounded-b-xl pb-8' style={{width:'25%'}}>
                <div className='flex justify-between w-full mt-3'>
                  <div>
                  </div>
                  <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="hidden p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                  </button>
                </div>
                <div className="">
                  <Link to='/orders' className='par mb-2' ><h1 className='hover:text-blue-700'>Orders</h1></Link>
                  <Link to='/profile' className='par mb-2'><h1 className='hover:text-blue-700'>Profile</h1></Link>
                  
                  <h1 onClick={handleLogOut} className='par hover:text-blue-700 cursor-pointer'>Sign Out</h1>
                </div>
              </div>
            </div>
            }
        </div>
        <div id="tablet">
            <nav>
            <header class="antialiased">
  <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 ">
      <div class="flex flex-wrap justify-between items-center">
          <div class="flex justify-start items-center">
              <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
              </button>
          
              <Link to='/cakes' style={{width:'35%'}} className='flex items-center'>
                             <img src='https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc'alt="Logo" className='ml-5' style={{width:'100%'}} />

                        </Link>
             
            </div>
          <div class="flex items-center lg:order-2">
      <Link to={`/cake/${uid}/checkout`}>

          <button type="button" data-dropdown-toggle="notification-dropdown" class="p-2 mr-1 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100  focus:ring-4 focus:ring-gray-300 #">
    
    <div class="relative ">
  <div class="t-0 absolute left-3">
    <p class="par flex h-1.5 w-1.5 items-center justify-center rounded-full bg-red-500 p-2.5 text-xs text-white">{cart.length}</p>
  </div>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="file:  w-7 h-7">
    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
  </svg>
</div>
</button>
</Link>
  

          
            {
              photo && photo.length != 0 ?
                 <Link to='/profile'>
         <button  type="button" class="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 #" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
                  <span class="sr-only">Open user menu</span>
                  <img class="w-10 h-10 rounded-full" src={photo} alt="user photo"/>
              </button></Link>
              :
              <Link to='/profile'>

              <div className={`z-100 profilePageImage flex items-center justify-center w-10 h-10 l bg-blue-600   m-auto rounded-full object-cover `} style={{ position: 'relative' }}>
              <p className="young capitalize text-white" style={{ fontSize: '10px' }}>
                {fullName && fullName[0]}
              </p>
            </div>
            </Link>
            }
      
              
          </div>
      </div>
  </nav>
</header>
            </nav>
            {
              pop === true&&
              <div className='navigationPop'>
              <div className='w-1/2 bg-white px-5 border rounded-b-xl pb-5' style={{width:'25%'}}>
                <div className='flex justify-between w-full mt-3'>
                  <div>
                  </div>
                  <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                  </button>
                </div>
                <div className="">
                  <Link to='/orders' className='par mb-2' ><h1 className='hover:text-blue-700'>Orders</h1></Link>
                  <Link to='/profile' className='par mb-2'><h1 className='hover:text-blue-700'>Profile</h1></Link>
                  
                  <h1 onClick={handleLogOut} className='par hover:text-blue-700 cursor-pointer'>Sign Out</h1>
                </div>
              </div>
            </div>
            }
        </div>
        <div id="mobile">
            <nav>
            <header class="antialiased">
  <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 ">
      <div class="flex  justify-between items-center">
          <div class="flex justify-start items-center">
              <button onClick={navigationPop} aria-expanded="true" aria-controls="sidebar" class="flex  text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                <svg style={{width:'4vw',height:'4vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
              </button>
          
              <Link to='/cakes' style={{width:'23%'}} className='flex items-center'>
                             <img src='https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc'alt="Logo" className='ml-5' style={{width:'100%'}} />

                        </Link>
             
            </div>
          <div class="flex items-center ">
      <Link className='mr-4' to={`/cake/${uid}/checkout`}>

          <button type="button"style={{width:'3vw',marginRight:'3vw'}} data-dropdown-toggle="notification-dropdown" class="p-2  text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100  focus:ring-4 focus:ring-gray-300 #">
    
    <div class="relative ">
  <div class="t-0 absolute left-3">
    <p style={{fontSize:'2vw'}}class="par flex h-1.5 w-1.5 items-center justify-center rounded-full bg-red-500 p-2.5 text-xs text-white">{cart.length}</p>
  </div>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="file:  w-7 h-7">
    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
  </svg>
</div>
</button>
</Link>
  

           
          
            {
              photo && photo.length != 0 ?
                 <Link to='/profile'>
         <button  type="button" class="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 #" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
                  <span class="sr-only">Open user menu</span>
                  <img style={{width:'8vw',height:'8vw'}} class="w-10 h-10 rounded-full" src={photo} alt="user photo"/>
              </button></Link>
              :
              <Link to='/profile'>

              <div className={`z-100 profilePageImage flex items-center justify-center w-10 h-10 l bg-blue-600   m-auto rounded-full object-cover `} style={{ position: 'relative',width:'8vw',height:'8vw' }}>
              <p className="young capitalize text-white" style={{ fontSize: '4vw' }}>
                {fullName && fullName[0]}
              </p>
            </div>
            </Link>
            }
      
              
          </div>
      </div>
  </nav>
</header>
            </nav>
            {
              pop === true&&
              <div className='navigationPop'>
              <div className='w-1/2 bg-white px-5 border rounded-b-xl' style={{width:'35%'}}>
                <div className='flex justify-between w-full mt-3'>
                  <div>
                  </div>
                  <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex py-2  text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                  </button>
                </div>
                <div className="">
                  <Link to='/orders' className='par mb-2' ><h1 className='hover:text-blue-700'>Orders</h1></Link>
                  <Link to='/profile' className='par mb-2'><h1 className='hover:text-blue-700'>Profile</h1></Link>
                  
                  <h1 onClick={handleLogOut} className='par hover:text-blue-700 cursor-pointer mb-5'>Sign Out</h1>
                </div>
              </div>
            </div>
            }
        </div>
    </div>
    )
 })
  )
}
