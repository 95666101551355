import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { Timestamp, collection, deleteDoc, doc, onSnapshot } from 'firebase/firestore';

export default function CourseReserved() {
  const [students,setstudents] = useState([])
  const [studentsPop,setstudentsPop] = useState(false)
  const navigate = useNavigate()
  const [screenHeight, setScreenHeight] = useState(window.screen.height);
  const [pop,setpop] =useState(false)
  const [isLoading,setisLoading] =useState(true)

  const [selectedMessage,setselectedMessage] =useState({})


  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.screen.height);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function navigationPop(){
      if(pop === true){
        setpop(false)
      }
      else{
        setpop(true)
      }
    }

function studentsPopClose(){
  setstudentsPop(false)

}

function studentsPopOpen(i){
  setselectedMessage(i)
  setstudentsPop(true)
  
}



  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if(user){
      console.log(user)
          const orderCollection = collection(firestore, 'CourseProgram');
         onSnapshot(orderCollection, (snapshot) => {
            setstudents(
              snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }))
            );
            setisLoading(false)
          });
        

       

           

        } else {
        console.log("logged out")
        
      }
    });
  
    
      
  
    return () => {
      unsubscribe();
    };
  }, []);

  function getDateFromTimestamp(timestamp) {
    if (timestamp) {
      if (timestamp instanceof Timestamp) {
        return timestamp.toDate().toISOString().slice(0, 19).replace('T', ' ');
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  function deleteMessage(i){
    const document = doc(firestore,'CourseProgram',i.id)
    deleteDoc(document).then(
      setstudentsPop(false)
    )

  }
  function handleLogOut(){
    signOut(auth).then(
     navigate('/')
    )
   }
  return (
    isLoading === true?
    <div>
    
 

    <div className="flex flex-col items-center justify-center w-full text-surface " style={{height:'100vw'}}>
    <div
      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
      role="status"></div>

    </div>
  
    
  
  </div>
  :
    <div>
      <div id='desktop'>
      
        <div className='flex'>
                       
        <aside class="bg-gray-900 adminNav  fixed inset-0 z-50 my-4 ml-4  rounded-xl transition-transform duration-300 xl:translate-x-0">
    <div class="relative border-b border-white/20">
      <div class="flex items-center gap-4 py-6 px-8" >
        <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
      </div>
   
    </div>
    <div class="m-4">
      <ul class="mb-4 flex flex-col gap-1">
        <li>
        <Link to='/admin'>
        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
             <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
           </button>
      
          </Link>
        </li>
        <li>
        <Link to='/admin/orders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/allorders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
              
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/addcake'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/managecakes'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/messages'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/reserved'>
        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center gap-4 px-4 capitalize" type="button">
            
            <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Reserved Students</p>
          </button>
           
          </Link>
        </li>
      </ul>
      <ul class="mb-4 flex flex-col gap-1">
        <li class="mx-3.5 mt-4 mb-2">
          <p class="barlow block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
        </li>
    
        <li>
            <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
            
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
            </button>
        </li>
      </ul>
    </div>
  </aside>

      <div className="adminBody ">
      {
studentsPop === true &&
<div  class="bg-white orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">
<svg onClick={studentsPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>

<dl class="flex items-center justify-between mb-2 ">
      <dt class="text-base font-normal text-gray-500">Full Name- <span class="text-base font-medium header text-blue-600">
       {selectedMessage &&selectedMessage.fullName}
      </span></dt>
      </dl>
      
<dl class="flex items-center justify-between mb-2 ">
      <dt class="text-base font-normal text-gray-500">Email- <span class="text-base font-medium header text-blue-600">
       {selectedMessage &&selectedMessage.email}
      </span></dt>
      </dl>
      <dl class="flex items-center justify-between mb-2 ">
      <dt class="text-base font-normal text-gray-500">Phone Number- <span class="text-base font-medium header text-blue-600">
       {selectedMessage &&selectedMessage.phoneNumber}
      </span></dt>
      </dl>

   
      <button onClick={()=>deleteMessage(selectedMessage &&selectedMessage)}  class="mt-10 flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-5 pb-5 rounded-lg fjalla px-3" style={{fontSize:'11px'}}>
                  
                  Delete Message
                   
                   </button>
</div>
}

        {
          students.length !==0 &&
          <div className=" mb-5  ">
                  <div className="px-9 pt-5 flex justify-between  min-h-[70px] pb-0 bg-transparent w-full">
                <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                    <span className="mr-3 barlow" style={{fontSize:'25px'}}>Reserved Students</span>
                </h3>
                </div>
          <div className="w-full max-w-full  mb-6  mx-auto">
              <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
              <div className="relative flex flex-col min-w-0 break-words  bg-clip-border rounded-2xl border-stone-200 bg-light/30">
              
                  
                  <div className="flex-auto block py-8  ">
                  <div className="overflow-x-auto">
                      <table className="w-full my-0 align-middle text-dark border-neutral-200">
                      <thead className="align-bottom ">
                          <tr className="font-semibold text-[0.95rem] text-gray-800">
                          <th className="pb-3 text-center  header pr-12 "></th>
  
                          <th className="pb-3 text-center  header pr-12 ">Full Name</th>
                          <th className="pb-3 text-center  header pr-12">Phone Number</th>
                          <th className="pb-3 text-center  header pr-12">Email</th>
                         
                          <th className="pb-3 text-center  header pr-12">Time</th>
                          </tr>
                      </thead>
                      {
      students.sort((a, b) => a.time.valueOf() - b.time.valueOf()).map((i, index) => {
          const { fullName, email, time,phoneNumber } = i;
          return (
              <tbody key={index}>
   
              <tr className=" last:border-b-0 mt-6 py-3 ">
              <td className="pr-12 py-8 pl-0">
                      <div className="flex items-center justify-center">
                          <div className="flex flex-col justify-center">
                              <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                          </div>
                      </div>
                  </td>
                  <td className="pr-12 py-8 pl-0">
                      <div className="flex items-center justify-center">
                          <div className="flex flex-col justify-center">
                              <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {fullName} </p>
                          </div>
                      </div>
                  </td>
                  <td className=" pr-12 text-center ">
                      <span className="barlow text-light-inverse text-md/normal">
                       {phoneNumber}
                      </span>
                  </td>
                  <td className=" pr-12 text-center par">
                      <span className="barlow text-light-inverse text-md/normal">{email} </span>
                  </td>
               
                  <td className="pr-12 text-center">
                      <span className="barlow font-semibold text-light-inverse text-md/normal par text-center">{getDateFromTimestamp(time)}</span>
                  </td>
                  <td className=" pr-12 text-center par">
                <button onClick={()=>studentsPopOpen(i)}    className="ml-auto bg-blue-700  relative hover:text-primary flex items-center border h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none justify-center">
                          <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-white">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                              </svg>
                          </span>
                      </button>
                  </td>
              </tr>
          </tbody>
          );
      })
  }
                    
                      </table>
                  </div>
                  </div>
              </div>
              </div>
          </div>
          </div>
        }

        {
          students.length ===0 &&
          <img style={{width:'40%'}} className='mx-auto' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/students-bro%20(1).svg?alt=media&token=4e0ac9bb-9909-49f7-8715-7fab9967fe1d" alt="" />
        }
     
     
</div>
        </div>

      </div>
      <div id='tablet'>
      
        <div className=''>
        {
studentsPop === true &&
<div  class="bg-white orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">
<svg onClick={studentsPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>

<dl class="flex items-center justify-between mb-2 ">
      <dt class="text-base font-normal text-gray-500">Full Name- <span class="text-base font-medium header text-blue-600">
       {selectedMessage &&selectedMessage.fullName}
      </span></dt>
      </dl>
      
<dl class="flex items-center justify-between mb-2 ">
      <dt class="text-base font-normal text-gray-500">Email- <span class="text-base font-medium header text-blue-600">
       {selectedMessage &&selectedMessage.email}
      </span></dt>
      </dl>
      <dl class="flex items-center justify-between mb-2 ">
      <dt class="text-base font-normal text-gray-500">Phone Number- <span class="text-base font-medium header text-blue-600">
       {selectedMessage &&selectedMessage.phoneNumber}
      </span></dt>
      </dl>

   
      <button onClick={()=>deleteMessage(selectedMessage &&selectedMessage)}  class="mt-10 flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-5 pb-5 rounded-lg fjalla px-3" style={{fontSize:'11px'}}>
                  
                  Delete Message
                   
                   </button>
</div>
}          
      {
    pop === true ?
                      
    <aside style={{minHeight:'100vh'}} class="bg-gray-900 inset-0 z-50 my-4 ml-4  rounded-xl transition-transform duration-300 xl:translate-x-0">
    <div class="relative border-b border-white/20">
      <div class="flex items-center gap-4 py-6 px-8" >
        <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
      </div>
   
    </div>
    <div class="m-4">
      <ul class="mb-4 flex flex-col gap-1">
        <li>
        <Link to='/admin'>
        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
             <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
           </button>
      
          </Link>
        </li>
        <li>
        <Link to='/admin/orders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/allorders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
              
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/addcake'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/managecakes'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/messages'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/reserved'>
        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center gap-4 px-4 capitalize" type="button">
            
            <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Reserved Students</p>
          </button>
           
          </Link>
        </li>
      </ul>
      <ul class="mb-4 flex flex-col gap-1">
        <li class="mx-3.5 mt-4 mb-2">
          <p class="barlow block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
        </li>
    
        <li>
            <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
            
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
            </button>
        </li>
      </ul>
    </div>
  </aside>
:
      <div className="">
         <nav class="px-8 block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all  py-3">
                    <div class="flex flex-col  gap-6 ">
                    
                      <div class="flex justify-between w-full items-center mb-4">
                      <Link to='/admin'style={{width:'18%'}}>
        <img style={{width:'100%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />
</Link>
        <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
              </button>
              </div>  
              </div>  
                  </nav>
        {
          students.length !==0 &&
          <div className=" mb-5  ">
                  <div className="px-9 pt-5 flex justify-between  min-h-[70px] pb-0 bg-transparent w-full">
                <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                    <span className="mr-3 barlow" style={{fontSize:'25px'}}>students</span>
                </h3>
                </div>
          <div className="w-full max-w-full  mb-6  mx-auto">
              <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
              <div className="relative flex flex-col min-w-0 break-words  bg-clip-border rounded-2xl border-stone-200 bg-light/30">
              
                  
                  <div className="flex-auto block py-8  ">
                  <div className="overflow-x-auto">
                      <table className="w-full my-0 align-middle text-dark border-neutral-200">
                      <thead className="align-bottom ">
                          <tr className="font-semibold text-[0.95rem] text-gray-800">
                          <th className="pb-3 text-center  header  "></th>
  
                          <th className="pb-3 text-center  header  ">Full Name</th>
                          <th className="pb-3 text-center  header ">Phone Number</th>
                          <th className="pb-3 text-center  header ">Email</th>
                         
                          <th className="pb-3 text-center min-w-[100px] header ">Time</th>
                          </tr>
                      </thead>
                      {
      students.sort((a, b) => a.time.valueOf() - b.time.valueOf()).map((i, index) => {
          const { fullName, email, message, time,phoneNumber } = i;
          return (
              <tbody key={index}>
     
              <tr className=" last:border-b-0 mt-6 py-3 ">
              <td className=" py-8 pl-0">
                      <div className="flex items-center justify-center">
                          <div className="flex flex-col justify-center">
                              <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                          </div>
                      </div>
                  </td>
                  <td className=" py-8 pl-0">
                      <div className="flex items-center justify-center">
                          <div className="flex flex-col justify-center">
                              <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {fullName} </p>
                          </div>
                      </div>
                  </td>
                  <td className="  text-center ">
                      <span className="barlow text-light-inverse text-md/normal">
                       {phoneNumber}
                      </span>
                  </td>
                  <td className="  text-center par">
                      <span className="barlow text-light-inverse text-md/normal">{email} </span>
                  </td>
               
                  <td className=" text-center">
                      <span className="barlow font-semibold text-light-inverse text-md/normal par text-center">{getDateFromTimestamp(time)}</span>
                  </td>
                  <td className="  text-center par">
                <button onClick={()=>studentsPopOpen(i)}    className="ml-auto bg-blue-700  relative hover:text-primary flex items-center border h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none justify-center">
                          <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-white">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                              </svg>
                          </span>
                      </button>
                  </td>
              </tr>
          </tbody>
          );
      })
  }
                    
                      </table>
                  </div>
                  </div>
              </div>
              </div>
          </div>
          </div>
        }

        {
          students.length ===0 &&
          <img style={{width:'40%'}} className='mx-auto' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/students-bro%20(1).svg?alt=media&token=4e0ac9bb-9909-49f7-8715-7fab9967fe1d" alt="" />
        }
     
     
</div>
}
        </div>

      </div>
      <div id='mobile'>
      
      <div className=''>
      {
studentsPop === true &&
<div  class="bg-white orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">
<svg onClick={studentsPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>

<dl class="flex items-center justify-between mb-2 ">
      <dt class="text-base font-normal text-gray-500">Full Name- <span class="text-base font-medium header text-blue-600">
       {selectedMessage &&selectedMessage.fullName}
      </span></dt>
      </dl>
      
<dl class="flex items-center justify-between mb-2 ">
      <dt class="text-base font-normal text-gray-500">Email- <span class="text-base font-medium header text-blue-600">
       {selectedMessage &&selectedMessage.email}
      </span></dt>
      </dl>
      <dl class="flex items-center justify-between mb-2 ">
      <dt class="text-base font-normal text-gray-500">Phone Number- <span class="text-base font-medium header text-blue-600">
       {selectedMessage &&selectedMessage.phoneNumber}
      </span></dt>
      </dl>

 
      <button onClick={()=>deleteMessage(selectedMessage &&selectedMessage)}  class="mt-10 flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-5 pb-5 rounded-lg fjalla px-3" style={{fontSize:'11px'}}>
                  
                  Delete Message
                   
                   </button>
</div>
}            
    {
  pop === true ?
  <aside style={{height:screenHeight}}class="bg-gray-900     transition-transform duration-300 xl:translate-x-0">
  <div class="relative border-b border-white/20">
<svg onClick={navigationPop} aria-hidden="true" class="w-8 h-8 text-white absolute top-5 right-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
   
    <div class="flex justify-center items-center  py-6 px-2" >
      <h6 class="text-center block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
    </div>
 
  </div>
  <div class="my-4">
    <ul class="m-4 flex flex-col ">
      <li>
      <Link to='/admin'>
      <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
           
           <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
         </button>
      
        </Link>
      </li>
      <li>
      <Link to='/admin/orders'>

          <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
           
            <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
          </button>
        </Link>
      </li>
      <li>
      <Link to='/admin/allorders'>

          <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
            
            <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
          </button>
        </Link>
      </li>
      <li>
      <Link to='/admin/addcake'>

          <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
           
            <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
          </button>
        </Link>
      </li>
      <li>
      <Link to='/admin/managecakes'>
     
          <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
           
            <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
          </button>
        </Link>
      </li>
     
      <li>
      <Link to='/admin/messages'>

      <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
           
           <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
         </button>
        </Link>
      </li>
      <li>
      <Link to='/admin/reserved'>
      <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center  pl-2" type="button">
          
          <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Students</p>
        </button>
      
        </Link>
      </li>
    </ul>
    <ul class="m-4 flex flex-col">
      <li class=" mt-4 mb-2">
        <p class="par block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
      </li>
  
      <li>
          <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
          
            <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
          </button>
      </li>
    </ul>
  </div>
</aside>
:
    <div className="">
       <nav class="px-8 block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all  py-3">
                  <div class="flex flex-col  gap-6 ">
                  
                    <div class="flex justify-between w-full items-center mb-4">
                    <Link to='/admin'style={{width:'18%'}}>
      <img style={{width:'100%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />
</Link>
      <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
              <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
            </button>
            </div>  
            </div>  
                </nav>
      {
        students.length !==0 &&
        <div className=" mb-5  ">
                <div className="px-9 pt-5 flex justify-between  min-h-[70px] pb-0 bg-transparent w-full">
              <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                  <span className="mr-3 barlow" style={{fontSize:'25px'}}>students</span>
              </h3>
              </div>
        <div className="w-full max-w-full  mb-6  mx-auto">
            <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
            <div className="relative flex flex-col min-w-0 break-words  bg-clip-border rounded-2xl border-stone-200 bg-light/30">
            
                
                <div className="flex-auto block py-8  ">
                <div className="overflow-x-auto">
                <table className="w-full my-0 align-middle text-dark border-neutral-200">
                                <thead className="align-bottom ">
                                    <tr className="font-semibold text-[0.95rem] text-gray-800">
                                    <th className="pb-3 text-center  header  "></th>
            
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>Full Name</th>
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>Phone Number</th>
                                    
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>Email</th>
                                    </tr>
                                </thead>
                                {
                students.sort((a, b) => a.time.valueOf() - b.time.valueOf()).slice(0,10).map((i, index) => {
                    const { phoneNumber, fullName, email,message } = i;
                    return (
                        <tbody key={index}>
  
                        <tr className=" last:border-b-0  ">
                        <td className="  pl-0" style={{paddingBottom:'5vw',paddingTop:'5vw'}}>
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p style={{fontSize:'3vw'}}className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                                    </div>
                                </div>
                            </td>
                            <td className="  pl-0" style={{paddingTop:'5vw',paddingBottom:'5vw',paddingLeft:'3vw'}}>
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p style={{fontSize:'3vw'}}className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {fullName} </p>
                                    </div>
                                </div>
                            </td>
                            <td className="  text-center par"style={{paddingTop:'5vw',paddingBottom:'5vw',paddingLeft:'3vw'}}>
                                <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="barlow text-light-inverse text-md/normal">{phoneNumber} </span>
                            </td>
                            <td className="  text-center par"style={{paddingTop:'5vw',paddingBottom:'5vw',paddingLeft:'3vw'}}>
                                <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="barlow text-light-inverse text-md/normal">{email} </span>
                            </td>
                            
                            <td className="  text-center par" style={{paddingTop:'5vw',paddingBottom:'5vw',paddingLeft:'3vw'}}>
                          <button onClick={()=>studentsPopOpen(i)} style={{width:'6vw',height:'6vw'}}  className="ml-auto bg-blue-700  relative hover:text-primary flex items-center border  text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none justify-center">
                                    <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                                        <svg style={{width:'4vw',height:'4vw'}}xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-white">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </button>
                            </td>
                     
                        </tr>
                    </tbody>
                    );
                })
            }
                              
                                </table>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
      }

      {
        students.length ===0 &&
        <img style={{width:'40%'}} className='mx-auto' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/students-bro%20(1).svg?alt=media&token=4e0ac9bb-9909-49f7-8715-7fab9967fe1d" alt="" />
      }
   
   
</div>
}
      </div>

    </div>
    </div>
  )
}
