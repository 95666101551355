import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AddCake from './admin/addCake';
import ManageCakes from './admin/manageCakes';
import Orders from './admin/orders';
import CakeDetails from './admin/cakeDetails';
import AdminDashboard from './admin/adminDashboard';
import Home from './No Auth/home';
import Register from './No Auth/register';
import SignIn from './No Auth/signin';
import Cakes from './Auth/cakes';
import Cake from './Auth/cake';
import Checkout from './Auth/checkout';
import MyOrders from './Auth/myOrders';
import OrderDetails from './Auth/orderDetails';
import TotalOrders from './admin/totalOrders';
import Profile from './Auth/profile';
import Notification from './Auth/notification';
import Courseprogram from './No Auth/courseprogram';
import Aboutus from './No Auth/aboutus';
import Contact from './No Auth/contact';
import Message from './admin/message';
import CourseReserved from './admin/courseReserved';
import ProtectedRoute from './Context/protectedRoute';
import NotFound from './No Auth/NotFound';
import AdminProtected from './Context/adminProtected';

function App() {
  return (

      <BrowserRouter>

    <Routes>
        {/* Admin */}
        <Route
        path='/admin'
        element={
        
          <AdminProtected>
            <AdminDashboard/>
          </AdminProtected>
          
        }
      />
          <Route
        path='/admin/messages'
        element={
        
          <AdminProtected>
          <Message/>
          </AdminProtected>
          
        }
      />


<Route
        path='/admin/reserved'
        element={
        
          <AdminProtected>
          <CourseReserved/>
          </AdminProtected>
          
        }
      />

<Route
        path='/admin/addcake'
        element={
          <AddCake/>
          
        }
      />
<Route
        path='/admin/managecakes'
        element={
        
          <AdminProtected>
          <ManageCakes/>
          </AdminProtected>
          
        }
      />
<Route
        path='/admin/orders'
        element={
        
          <AdminProtected>
          <Orders/>
          </AdminProtected>
          
        }
      />
        <Route
        path='/admin/allorders'
        element={
        
          <AdminProtected>
          <TotalOrders/>
          </AdminProtected>
          
        }
      /><Route
      path='/admin/cake/:cakeId'
      element={
        <AdminProtected>
      
        <CakeDetails/>
        
        </AdminProtected>
      }
    />



        {/* Home */}
        <Route  element={<Home/>} path="/"/>
        <Route  element={<Register/>} path="/register"/>
        <Route  element={<SignIn/>} path="/signin"/>
        <Route  element={<Courseprogram/>} path="/courseprogram"/>
        <Route  element={<Aboutus/>} path="/about"/>
        <Route  element={<Contact/>} path="/contact"/>
        <Route  element={<Cakes/>} path="/cakes"/>
        <Route  element={<Cake/>} path="/cake/:cakeId"/>
        <Route path="*" element={<NotFound />} />


        





        {/* Auth */}
 
             <Route
        path='/profile'
        element={
          <ProtectedRoute>
            <Profile/>
          </ProtectedRoute>
        }
      />
        <Route
        path='/orders'
        element={
          <ProtectedRoute>
            <MyOrders/>
          </ProtectedRoute>
        }
      />
       <Route
        path='/orders/:orderId'
        element={
          <ProtectedRoute>
            <OrderDetails/>
          </ProtectedRoute>
        }
      />


<Route
        path='/cake/:uniqueId/checkout'
        element={
          <ProtectedRoute>
            <Checkout/>
          </ProtectedRoute>
        }
      />
























       
      
        
     
      </Routes>
      </BrowserRouter>
  );
}

export default App;
