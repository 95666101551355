import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { collection, onSnapshot,query,orderBy, doc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { v4 } from 'uuid';
import Nav from './nav';
import Navigation from '../No Auth/nav'


export default function NotFound() {
    const [uid,setuid] = useState('')
    const [user,setuser] = useState([])

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
          //  setcred(uid)
        setuid(uid);
           
    
       
        
    

      const userRef = doc(firestore,"Users",uid);
    
      onSnapshot(userRef,snapshot=>{
       
        let main =[]
    
    
    
          main.push({...snapshot.data(),id:snapshot.id})
          
      setuser(main)
    
        
        
      
    
      })
      
   
    
    
    
    
    
           
      
    
    
    
    
            
    
               
     
      
                
      
      
      
      
      
      
                
      
                  
              
      
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);
  return (
    user.length != 0 ?
    <div>
       {
          user.length != 0 ?
              <Nav/>
            :
            <Navigation/>
      }
    <div class="h-screen  flex items-center" id='desktop'>
<div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        <div class="w-full lg:w-1/2 mx-8">
            <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
        <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
            Sorry we couldn't find the page you're looking for
        </p>
        <Link to='/cakes'>
        <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>

        </Link>
</div>
    <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
    <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
    </div>

</div>
</div>
<div class="h-screen  flex items-center" id='tablet'>
<div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        <div class="w-full lg:w-1/2 mx-8">
            <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
        <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
            Sorry we couldn't find the page you're looking for
        </p>
        <Link to='/cakes'>
        <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>

        </Link>
</div>
    <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
    <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
    </div>

</div>
</div>
<div class="h-screen  flex items-center" id='mobile'>
<div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
    <img style={{width:'70%',margin:'auto'}} src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
    </div>
<div class=" flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        <div class="w-full lg:w-1/2 mx-8">
            <div class="text-7xl text-gray-800 font-dark font-extrabold mb-8" style={{fontSize:'10vw',marginTop:'10%'}}> 404</div>
        <p class="text-2xl md:text-3xl font-light leading-normal mb-8"style={{fontSize:'4vw'}}>
            Sorry we couldn't find the page you're looking for
        </p>
        <Link to='/cakes'>
        <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900 barlow"style={{fontSize:'3vw',width:'80%',height:'13vw'}}>Back to homepage</a>

        </Link>
</div>
   

</div>
</div>
</div>
:
<div>
{
          user.length != 0 ?
              <Nav/>
            :
            <Navigation/>
      }
    <div class="h-screen  flex items-center" id='desktop'>
<div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        <div class="w-full lg:w-1/2 mx-8">
            <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
        <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
            Sorry we couldn't find the page you're looking for
        </p>
        <Link to='/'>
        <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>

        </Link>
</div>
    <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
    <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
    </div>

</div>
</div>
<div class="h-screen  flex items-center" id='tablet'>
<div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        <div class="w-full lg:w-1/2 mx-8">
            <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
        <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
            Sorry we couldn't find the page you're looking for
        </p>
        <Link to='/'>
        <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>

        </Link>
</div>
    <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
    <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
    </div>

</div>
</div>
<div class="h-screen  flex items-center" id='mobile'>
<div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
    <img style={{width:'70%',margin:'auto'}} src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
    </div>
<div class=" flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        <div class="w-full lg:w-1/2 mx-8">
            <div class="text-7xl text-gray-800 font-dark font-extrabold mb-8" style={{fontSize:'10vw',marginTop:'10%'}}> 404</div>
        <p class="text-2xl md:text-3xl font-light leading-normal mb-8"style={{fontSize:'4vw'}}>
            Sorry we couldn't find the page you're looking for
        </p>
        <Link to='/'>
        <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900 barlow"style={{fontSize:'3vw',width:'80%',height:'13vw'}}>Back to homepage</a>

        </Link>
</div>
   

</div>
</div></div>
  )
}
