import React from 'react'
import Nav from './nav'
import Footer from './footer'

export default function Aboutus() {
  return (
    <div>
        <div id='desktop'>
            <Nav component={'About Us'}/>
            <section class="bg-white ">
    <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
    <div class="grid grid-cols-2 gap-4 mt-8 ">
        <img class="w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/shop.png?alt=media&token=ba495868-02ea-4a70-9bf1-c4fa57f7fa94" alt="office content 1"/>
            <img class="mt-4 w-full lg:mt-10 rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/shop32.png?alt=media&token=a7eedc78-6327-4275-9b87-3b31ea4d8da9" alt="office content 2"/>
        
            <img class="w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-bakery.appspot.com/o/photo_2024-06-10_00-51-03.jpg?alt=media&token=d7a31a1d-d34e-49b8-8e19-e8a2626b9281" alt="office content 1"/>
        </div>
        <div class="font-light text-gray-500 sm:text-lg px-12">
            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}>About Shewit Pastry</h2>
            <p class="mb-4">Welcome to Shewit Pastry! We are a cake shop that specializes in making delicious and beautiful cakes for all occasions.</p>
        
            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}> Our Baking Philosophy</h2>
            <p class="mb-4">At the heart of Shewit Pastry is a deep commitment to quality and craftsmanship. We believe that every cake should be a work of art, not just a dessert. That's why we take great pride in using only the freshest, highest-quality ingredients and following traditional baking techniques to ensure perfect texture and flavor in every bite.

Our bakers are highly skilled artisans who pour their hearts and souls into every cake they create. They meticulously measure, mix, and bake each component, paying close attention to every detail to guarantee a consistently exceptional result. From the fluffiest sponges to the silkiest buttercream, every element of our cakes is made with the utmost care and attention.</p>
       

            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}>Customized for Your Celebration</h2>
            <p class="mb-4">We understand that every occasion is unique, and that's why we offer a wide range of customization options to help you create the perfect cake for your event. Whether you're envisioning a classic tiered wedding cake, or a whimsical children's birthday cake, our team of designers will work closely with you to bring your vision to life.

During the design process, we encourage you to share your personal style, color preferences, and any special requests or dietary needs. Our skilled decorators will then use their artistic flair to transform your cake into a stunning centerpiece that perfectly captures the essence of your celebration.</p>
       

            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}>What We Offer</h2>
            <p class="mb-4">At Shewit Pastry, we believe that every occasion deserves a cake that truly shines. Whether you're planning a wedding, birthday, anniversary, or any other special event, we are here to help you create the perfect centerpiece that will leave a lasting impression on your guests.

Our online ordering process makes it easy to customize and order your dream cake, and our reliable delivery service ensures that it arrives fresh and ready to serve. We also offer a range of additional services, such as cake tastings, consultations, and event coordination, to make your experience with us as seamless and enjoyable as possible.

We are committed to providing exceptional customer service and going the extra mile to ensure your satisfaction. Our team is always here to answer your questions, address your concerns, and help you find the perfect cake for any occasion.</p>
       

            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}> Join Our Sweetly Baked Family</h2>
            <p class="mb-4">Whether you're planning a special event or simply craving a delightful treat, we invite you to experience the difference that Shewit Pastry can make. We are passionate about bringing joy and creating unforgettable moments through our exceptional cakes, and we look forward to the opportunity to serve you.

Thank you for considering Shewit Pastry for your next celebration. We can't wait to be a part of your special day and create a cake that will leave a lasting impression. 

Let's get started on your dream cake today!</p>
       
        
        </div>
     
    </div>
</section>

        </div>
        <div id='tablet'>
            <Nav component={'About Us'}/>
            <section class="bg-white ">
    <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
    <div class="grid grid-cols-2 gap-4 mt-8 ">
        <img class="w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/shop.png?alt=media&token=ba495868-02ea-4a70-9bf1-c4fa57f7fa94" alt="office content 1"/>
            <img class="mt-4 w-full lg:mt-10 rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/shop32.png?alt=media&token=a7eedc78-6327-4275-9b87-3b31ea4d8da9" alt="office content 2"/>
        
            <img class="w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-bakery.appspot.com/o/photo_2024-06-10_00-51-03.jpg?alt=media&token=d7a31a1d-d34e-49b8-8e19-e8a2626b9281" alt="office content 1"/>
        </div>
        <div class="font-light text-gray-500 sm:text-lg px-12">
            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}>About Shewit Pastry</h2>
            <p class="mb-4">Welcome to Shewit Pastry! We are a cake shop that specializes in making delicious and beautiful cakes for all occasions.</p>
        
            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}> Our Baking Philosophy</h2>
            <p class="mb-4">At the heart of Shewit Pastry is a deep commitment to quality and craftsmanship. We believe that every cake should be a work of art, not just a dessert. That's why we take great pride in using only the freshest, highest-quality ingredients and following traditional baking techniques to ensure perfect texture and flavor in every bite.

Our bakers are highly skilled artisans who pour their hearts and souls into every cake they create. They meticulously measure, mix, and bake each component, paying close attention to every detail to guarantee a consistently exceptional result. From the fluffiest sponges to the silkiest buttercream, every element of our cakes is made with the utmost care and attention.</p>
       

            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}>Customized for Your Celebration</h2>
            <p class="mb-4">We understand that every occasion is unique, and that's why we offer a wide range of customization options to help you create the perfect cake for your event. Whether you're envisioning a classic tiered wedding cake, or a whimsical children's birthday cake, our team of designers will work closely with you to bring your vision to life.

During the design process, we encourage you to share your personal style, color preferences, and any special requests or dietary needs. Our skilled decorators will then use their artistic flair to transform your cake into a stunning centerpiece that perfectly captures the essence of your celebration.</p>
       

            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}>What We Offer</h2>
            <p class="mb-4">At Shewit Pastry, we believe that every occasion deserves a cake that truly shines. Whether you're planning a wedding, birthday, anniversary, or any other special event, we are here to help you create the perfect centerpiece that will leave a lasting impression on your guests.

Our online ordering process makes it easy to customize and order your dream cake, and our reliable delivery service ensures that it arrives fresh and ready to serve. We also offer a range of additional services, such as cake tastings, consultations, and event coordination, to make your experience with us as seamless and enjoyable as possible.

We are committed to providing exceptional customer service and going the extra mile to ensure your satisfaction. Our team is always here to answer your questions, address your concerns, and help you find the perfect cake for any occasion.</p>
       

            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}> Join Our Sweetly Baked Family</h2>
            <p class="mb-4">Whether you're planning a special event or simply craving a delightful treat, we invite you to experience the difference that Shewit Pastry can make. We are passionate about bringing joy and creating unforgettable moments through our exceptional cakes, and we look forward to the opportunity to serve you.

Thank you for considering Shewit Pastry for your next celebration. We can't wait to be a part of your special day and create a cake that will leave a lasting impression. 

Let's get started on your dream cake today!</p>
       
        
        </div>
     
    </div>
</section>

        </div>
        <div id='mobile'>
            <Nav component={'About Us'}/>
            <section class="bg-white ">
    <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
    <div class="grid grid-cols-2 gap-4 mt-8 ">
        <img class="w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/shop.png?alt=media&token=ba495868-02ea-4a70-9bf1-c4fa57f7fa94" alt="office content 1"/>
            <img class="mt-4 w-full lg:mt-10 rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/shop32.png?alt=media&token=a7eedc78-6327-4275-9b87-3b31ea4d8da9" alt="office content 2"/>
        
            <img class="w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-bakery.appspot.com/o/photo_2024-06-10_00-51-03.jpg?alt=media&token=d7a31a1d-d34e-49b8-8e19-e8a2626b9281" alt="office content 1"/>
        </div>
        <div class="font-light text-gray-500 sm:text-lg px-12">
            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}>About Shewit Pastry</h2>
            <p class="mb-4">Welcome to Shewit Pastry! We are a cake shop that specializes in making delicious and beautiful cakes for all occasions.</p>
        
            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}> Our Baking Philosophy</h2>
            <p class="mb-4">At the heart of Shewit Pastry is a deep commitment to quality and craftsmanship. We believe that every cake should be a work of art, not just a dessert. That's why we take great pride in using only the freshest, highest-quality ingredients and following traditional baking techniques to ensure perfect texture and flavor in every bite.

Our bakers are highly skilled artisans who pour their hearts and souls into every cake they create. They meticulously measure, mix, and bake each component, paying close attention to every detail to guarantee a consistently exceptional result. From the fluffiest sponges to the silkiest buttercream, every element of our cakes is made with the utmost care and attention.</p>
       

            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}>Customized for Your Celebration</h2>
            <p class="mb-4">We understand that every occasion is unique, and that's why we offer a wide range of customization options to help you create the perfect cake for your event. Whether you're envisioning a classic tiered wedding cake, or a whimsical children's birthday cake, our team of designers will work closely with you to bring your vision to life.

During the design process, we encourage you to share your personal style, color preferences, and any special requests or dietary needs. Our skilled decorators will then use their artistic flair to transform your cake into a stunning centerpiece that perfectly captures the essence of your celebration.</p>
       

            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}>What We Offer</h2>
            <p class="mb-4">At Shewit Pastry, we believe that every occasion deserves a cake that truly shines. Whether you're planning a wedding, birthday, anniversary, or any other special event, we are here to help you create the perfect centerpiece that will leave a lasting impression on your guests.

Our online ordering process makes it easy to customize and order your dream cake, and our reliable delivery service ensures that it arrives fresh and ready to serve. We also offer a range of additional services, such as cake tastings, consultations, and event coordination, to make your experience with us as seamless and enjoyable as possible.

We are committed to providing exceptional customer service and going the extra mile to ensure your satisfaction. Our team is always here to answer your questions, address your concerns, and help you find the perfect cake for any occasion.</p>
       

            <h2 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 " style={{fontSize:'25px',marginTop:'35px'}}> Join Our Sweetly Baked Family</h2>
            <p class="mb-4">Whether you're planning a special event or simply craving a delightful treat, we invite you to experience the difference that Shewit Pastry can make. We are passionate about bringing joy and creating unforgettable moments through our exceptional cakes, and we look forward to the opportunity to serve you.

Thank you for considering Shewit Pastry for your next celebration. We can't wait to be a part of your special day and create a cake that will leave a lasting impression. 

Let's get started on your dream cake today!</p>
       
        
        </div>
     
    </div>
</section>

        </div>
        <Footer/>
    </div>
  )
}
