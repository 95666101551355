import { collection, deleteDoc, doc, onSnapshot, setDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase'
import Nav from './nav'
import { v4 } from 'uuid'
import { onAuthStateChanged,signInWithEmailAndPassword,sendPasswordResetEmail } from 'firebase/auth'
import Navigation from '../No Auth/nav'

export default function Cake() {
    const {cakeId} = useParams()
    const [cake,setcake] = useState([])
    const [cart,setcart] = useState([])
    const [cartError,setcartError] = useState('')
    const [selectedColorError,setselectedColorError] = useState('')
    const [loading,setloading] = useState(false)
    const[forgotPassword,setforgotPassword] = useState(true)
    const[sent,setsent] = useState(false)

    const[forgotPasswordError,setforgotPasswordError] = useState('')
    const[forgotPasswordEmail,setforgotPasswordEmail] = useState('')
    const[resetEmailError,setresetEmailError] = useState('')




    const [uid,setuid] = useState('')
    const[fullName,setfullName] = useState('')
    const[fullNameError,setfullNameError] = useState('')
    const[email,setemail] = useState('')
    const[emailError,setemailError] = useState('')
    const[password,setpassword] = useState('')
    const[passwordError,setpasswordError] = useState('')
    const[signInError,setsignInError] = useState('')
    const[isLoading,setIsLoading] = useState(true)
    const[uploadMessage,setuploadMessage] = useState('')
    const[design,setdesign] = useState([])
    const[designError,setdesignError] = useState('')



    const navigate = useNavigate()

    const [signinpop,setsigninpop] = useState(false)


  const [currentIndexes, setCurrentIndexes] = useState({});
  const [selectedColor, setSelectedColor] = useState('');

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setselectedColorError('')
  };
useEffect(() => {
  const unsubscribe = onSnapshot(doc(firestore, 'Cakes', cakeId), (snapshot) => {
   if(snapshot.exists()){
    setcake((prevCake) => [{ ...snapshot.data() }]);

   }
   setIsLoading(false)
  });

  return unsubscribe;
}, [cakeId, firestore]);
    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
            setuid(uid)

      
      
      
                
            const userDocRef = doc(firestore, 'Users', uid);
            const cartCollectionRef = collection(userDocRef, 'Cart');
      
            const unsubscribeCartCollection = onSnapshot(cartCollectionRef, (cartSnapshot) => {
              const cartItems = cartSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
              setcart(cartItems);
            });
                  
              
            return () => {
              unsubscribeCartCollection();
            };
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
          
        };
      }, []);


      function handleImgSelect(event) {
        const selectedFiles = (event.target.files[0]);
      
          // Check file size
          
         const maxSize = 5 * 1024 * 1024; // 5MB
        // Check file type
          if (selectedFiles.size > maxSize) {
            setuploadMessage(`File ${selectedFiles.name} exceeds the size limit of 5MB.`);
      
            return false;
      
          }
          else{
            setuploadMessage("")
            setdesign([])
          }
        
        
          setdesign(selectedFiles);
      
      
      
        // Clear the file input value to allow selecting the same file again
        event.target.value = '';
      }

    function addToCart(cakeColor){
      window.scrollTo(0, 0);

      if(cart.length <= 4){
        if(cakeColor.length > 1){
          if(selectedColor.length ===0){
            setselectedColorError('error')

          }
          else{
            setDoc(doc(firestore,'Users',uid,'Cart',cakeId),{
              cakeId:cakeId,
              chosenColor:selectedColor
          })
          }
        }
        else{
          setDoc(doc(firestore,'Users',uid,'Cart',cakeId),{
            cakeId:cakeId,
            chosenColor:selectedColor
        })
        }
       
      }
      else{
        setcartError('Your cart is full. You are only allowed to shop for five cakes')

      }
    }

    function shopItem(cakeColor){
      window.scrollTo(0, 0);

      if(cart.length <= 4){
        if(cakeColor.length > 1){
          if(selectedColor.length ===0){
            setselectedColorError('error')

          }
          else{
            setDoc(doc(firestore,'Users',uid,'Cart',cakeId),{
              cakeId:cakeId,
              chosenColor:selectedColor
          }).then(
            navigate(`/cake/${uid + v4().slice(0,6)}/checkout`)
  
          )
          }
        }
        else{
          setDoc(doc(firestore,'Users',uid,'Cart',cakeId),{
            cakeId:cakeId,
            chosenColor:selectedColor
        }).then(
          navigate(`/cake/${uid + v4().slice(0,6)}/checkout`)

        )
        }
       
      }
      else{
        setcartError('Your cart is full. You are only allowed to shop for five cakes')

      }

    }

   


    function CakeGallery({ cake, currentIndex, handlePrevious, handleNext }) {
      if (cake)
        return (
      
          <div>
            <div id="desktop">
            <div id="gallery" className="relative w-full  " data-carousel="slide">
            <div className="relative overflow-hidden rounded-lg">
              <div className="relative overflow-hidden rounded-lg shadow-xl">
                <div className="duration-700 ease-in-out " data-carousel-item>
                  {cake.length !=0 && cake.cakePhotos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo.cakePhotos}
                      alt={`Selected file ${index}`}
                      style={{ height: '500px',minWidth:'100%', objectFit: 'cover' }}
                      className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
                    />
                  ))}
                  {cake.length !=0 && cake.cakePhotos.length === 0 && (
                    <img
                      className="h-auto w-full rounded-lg"
                      style={{ height: '300px', objectFit: 'cover' }}
                      src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                      alt="description"
                    />
                  )}
                </div>
      
                {cake.length !=0 && cake.cakePhotos.length >= 2 && (
                     <button
                     type="button"
                     className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                     data-carousel-prev
                   >
                   <span onClick={() => handlePrevious(cake.length !=0 && cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
      
                       <svg
                         
                         className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 6 10"
                       >
                         <path
                           stroke="currentColor"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="M5 1 1 5l4 4"
                         />
                       </svg>
                       <span className="sr-only">Previous</span>
                     </span>
                   </button>
               
                )}
      
                {cake.length !=0 && cake.cakePhotos.length >= 2 && (
                   <div
                   type="div"
                   className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4  group focus:outline-none"
                   data-carousel-next
                 >
                   <span onClick={() => handleNext(cake.length !=0 && cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                     <svg
                       
                       className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                       aria-hidden="true"
                       xmlns="http://www.w3.org/2000/svg"
                       fill="none"
                       viewBox="0 0 6 10"
                     >
                       <path
                         stroke="currentColor"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                         stroke-width="2"
                         d="m1 9 4-4-4-4"
                       />
                     </svg>
                     <span className="sr-only">Next</span>
                   </span>
                 </div>
      
                )}
              </div>
            </div>
          
          </div>
            </div>
            <div id="tablet">
            <div id="gallery" className="relative w-full  " data-carousel="slide">
            <div className="relative overflow-hidden rounded-lg">
              <div className="relative overflow-hidden rounded-lg shadow-xl">
                <div className="duration-700 ease-in-out " data-carousel-item>
                  {cake.length !=0 && cake.cakePhotos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo.cakePhotos}
                      alt={`Selected file ${index}`}
                      style={{ height: '500px',minWidth:'100%', objectFit: 'cover' }}
                      className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
                    />
                  ))}
                  {cake.length !=0 && cake.cakePhotos.length === 0 && (
                    <img
                      className="h-auto w-full rounded-lg"
                      style={{ height: '300px', objectFit: 'cover' }}
                      src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                      alt="description"
                    />
                  )}
                </div>
      
                {cake.length !=0 && cake.cakePhotos.length >= 2 && (
                     <button
                     type="button"
                     className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                     data-carousel-prev
                   >
                   <span onClick={() => handlePrevious(cake.length !=0 && cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
      
                       <svg
                         
                         className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 6 10"
                       >
                         <path
                           stroke="currentColor"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="M5 1 1 5l4 4"
                         />
                       </svg>
                       <span className="sr-only">Previous</span>
                     </span>
                   </button>
               
                )}
      
                {cake.length !=0 && cake.cakePhotos.length >= 2 && (
                   <div
                   type="div"
                   className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4  group focus:outline-none"
                   data-carousel-next
                 >
                   <span onClick={() => handleNext(cake.length !=0 && cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                     <svg
                       
                       className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                       aria-hidden="true"
                       xmlns="http://www.w3.org/2000/svg"
                       fill="none"
                       viewBox="0 0 6 10"
                     >
                       <path
                         stroke="currentColor"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                         stroke-width="2"
                         d="m1 9 4-4-4-4"
                       />
                     </svg>
                     <span className="sr-only">Next</span>
                   </span>
                 </div>
      
                )}
              </div>
            </div>
          
          </div>
            </div>
            <div id="mobile">
            <div id="gallery" className="relative w-full px-5 " >
            <div className="relative overflow-hidden rounded-lg">
              <div className="relative overflow-hidden rounded-lg shadow-xl">
                <div className="duration-700 ease-in-out " data-carousel-item>
                  {cake.length !=0 && cake.cakePhotos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo.cakePhotos}
                      alt={`Selected file ${index}`}
                      style={{ height: '500px',minWidth:'100%', objectFit: 'cover' }}
                      className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
                    />
                  ))}
                  {cake.length !=0 && cake.cakePhotos.length === 0 && (
                    <img
                      className="h-auto w-full rounded-lg"
                      style={{ height: '300px', objectFit: 'cover' }}
                      src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                      alt="description"
                    />
                  )}
                </div>
      
                {cake.length !=0 && cake.cakePhotos.length >= 2 && (
                     <button
                     type="button"
                     className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                     data-carousel-prev
                   >
                   <span onClick={() => handlePrevious(cake.length !=0 && cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
      
                       <svg
                         
                         className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 6 10"
                       >
                         <path
                           stroke="currentColor"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="M5 1 1 5l4 4"
                         />
                       </svg>
                       <span className="sr-only">Previous</span>
                     </span>
                   </button>
               
                )}
      
                {cake.length !=0 && cake.cakePhotos.length >= 2 && (
                   <div
                   type="div"
                   className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4  group focus:outline-none"
                   data-carousel-next
                 >
                   <span onClick={() => handleNext(cake.length !=0 && cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                     <svg
                       
                       className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                       aria-hidden="true"
                       xmlns="http://www.w3.org/2000/svg"
                       fill="none"
                       viewBox="0 0 6 10"
                     >
                       <path
                         stroke="currentColor"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                         stroke-width="2"
                         d="m1 9 4-4-4-4"
                       />
                     </svg>
                     <span className="sr-only">Next</span>
                   </span>
                 </div>
      
                )}
              </div>
            </div>
          
          </div>
            </div>
          </div>

        
        );
      }
      useEffect(() => {
        const initialIndexes = cake.reduce((acc, cake) => {
          acc[cake.id] = 0;
          return acc;
        }, {});
        setCurrentIndexes(initialIndexes);
      }, [cake]);
      
      const handlePrevious = (cakePhotos, id) => {
        setCurrentIndexes((prevIndexes) => ({
          ...prevIndexes,
          [id]: (prevIndexes[id] === 0 ? cakePhotos.length - 1 : prevIndexes[id] - 1),
        }));
      };
      
      const handleNext = (cakePhotos, id) => {
        setCurrentIndexes((prevIndexes) => ({
          ...prevIndexes,
          [id]: (prevIndexes[id] === cakePhotos.length - 1 ? 0 : prevIndexes[id] + 1),
        }));
      };

      function signInPopSet(){
        setsigninpop(true)
      }

      function setsigninpopClose(){
        setsigninpop(false)
      }
      function emailSet(e){
        setemail(e.target.value)
        if(e.target.value.length === 0){
          setemailError('error')
  
        }
        else{
          setemailError('')
        }
      }
  
      function passwordSet(e){
        setpassword(e.target.value)
        if(e.target.value.length === 0){
          setpasswordError('error')
  
        }
        else{
          setpasswordError('')
        }
      }
  
      function signIn(e) {
        e.preventDefault();
        
       if(email.length !=0 && password.length !=0){
        signInWithEmailAndPassword(auth, email, password).then(setIsLoading(true))
        .then(() => {
          if (email.trim() === 'admin@admin.com' && password.trim() === 'admin@admin.com') {
            navigate("/admin");
            setIsLoading(false)
  
          }
          
            else{
          navigate("/cakes");
          setIsLoading(false)
  
          }
      })
      .catch((error) => {
        setIsLoading(false)
          // Handle specific error cases
          if (error.code === "auth/user-not-found") {
              setsignInError('User not found.');
          } else if (error.code === "auth/invalid-credential") {
              setsignInError('Invalid login credentials');
          } else if (error.code === "auth/network-request-failed") {
            setsignInError('Check your Internet Connection');
  
          }
          else if (error.code === "auth/invalid-email") {
            setsignInError('Invalid login credentials');
  
          }
           else {
              setsignInError(error.message);
          }
      });
       }
       
    }
  
    function resetEmail(){
        if (forgotPasswordEmail.length === 0) {
          setforgotPasswordEmail('error');
        } else {
          setIsLoading(true);
      
          sendPasswordResetEmail(auth, forgotPasswordEmail)
            .then(() => {
              setIsLoading(false);
              setsent(true);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              setresetEmailError(errorMessage);
              setIsLoading(false);
            });
        }   if (forgotPasswordEmail.length === 0) {
          setforgotPasswordError('error');
        } else {
          setIsLoading(true);
      
          sendPasswordResetEmail(auth, forgotPasswordEmail)
            .then(() => {
              setIsLoading(false);
              setsent(true);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              setresetEmailError(errorMessage);
              setIsLoading(false);
            });
        }
    }
  
    function successRemove(){
      setsent(false)
      setforgotPassword(false)
    }
    function resetEmailSet(e){
      setforgotPasswordEmail(e.target.value)
      if(e.target.value.length === 0){
       setforgotPasswordError('error')

      }
      else{
       setforgotPasswordError('')
      }
    }

    function openForgotPassword(){
      setTimeout(() => {
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
          setforgotPassword(false)
        }, 1000);
        
      }, 1000);
    }
    function formatNumber(num) {
      if(num){
          return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      };
    }

  return (
    <div>
      <div id='desktop' >
        {
          uid.length !=0?
      <Nav/>
      :
      <Navigation/>

        }
        {
          isLoading === true ?
          <div>
          <div style={{height:'80vh'}} class="bg-white flex items-center justify-center  rounded  w-full p-10 -mt-5">
          
          <div
          className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
          role="status"></div></div>
                  </div>
          :
          cake.length != 0?
          <div >
      <div className={`${selectedColorError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
You must choose a color

</div>
</div>
</div>

          <div className={`${cartError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{cartError}
</div>
</div>
</div>

{
signinpop === true &&
<div className='signInpop'>

{
   isLoading === true ?
   <div style={{height:'80vh',width:'40%'}} class="bg-white flex items-center justify-center shadow border rounded  w-full p-10 -mt-5">
   
   <div
   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
   role="status"></div></div>
   :
   sent ===  true?
   <div onClick={successRemove}style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 -mt-5">
                <div  id='successCourseAddDesktop' style={{display:'flex',justifyContent:'center',alignItems:'center'}} className='flex items-center justify-center'>


<div className="relative p-4 w-full  "style={{width:"100%"}}>
<div className="relative p-4 text-center bg-white rounded-lg   sm:p-5" >
 
   <button   type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
       <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Close modal</span>
   </button>


   <div className="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
       <svg aria-hidden="true" className="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Success</span>
   </div>
   <p className="mb-4 text-lg font-semibold text-gray-900  capitalize">Email has been sent</p>
                <p className='borel'>Please check your email inbox for a password recovery link</p>
</div>
</div>



</div> 
</div>
:
   forgotPassword === false ?
   <div style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 -mt-5">
   <div className={`${resetEmailError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{resetEmailError}
</div>
</div>
</div>
<p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Reset Password</p>



<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input onChange={(e)=>resetEmailSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${forgotPasswordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>

<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>

<button
type='button'
onClick={resetEmail}
style={{fontSize:'12px',margin:'auto',background:'#D9FE00',height:'55px'}}
className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
>
Reset Password
</button>
</div>
<div class="w-full flex items-center justify-between py-5 mt-4">
</div>



</div>
:
   <div style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 -mt-5">
                           <div className={`${signInError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{signInError}
</div>
</div>
</div>
   <p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Log In</p>
  
   

           <div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input onChange={(e)=>emailSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${emailError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Password</p>
<input onChange={(e)=>passwordSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${passwordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Password Can't be empty
</div>
</div>
</div>
</div>
<div className="flex justify-center mt-12 mx-auto flex-col" style={{width:'80%'}}>

<button
      type='button'
      onClick={signIn}
      style={{fontSize:'12px',margin:'auto',background:'#D9FE00',height:'55px'}}
      className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
    >
     Sign In
    </button>
    <p className='par text-center cursor-pointer' style={{marginTop:'20px'}} onClick={openForgotPassword}>Forgot Password?</p>
    </div>
    <div class="w-full flex items-center justify-between py-5 mt-4">
            <hr class="w-full bg-gray-400"/>
            <p class="text-base font-medium leading-4 px-2.5 text-gray-400"></p>
            <hr class="w-full bg-gray-400  "/>
           </div>
 
       <p class="text-center par focus:outline-none text-sm mt-8 font-medium leading-none text-gray-500">Don't have an account? <Link to='/register'><a   class="hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-gray-800 cursor-pointer"> Sign up here</a></Link></p>
     
      
   </div>
}
</div>

}
<div className="flex justify-between px-5 items-center">



</div>

<div >

<div  >
{
cake && cake.map(i=>{
const{cakeSize,cakeTitle,cakeColor,cakeDescription,cakeIngredients,cakePrice,cakeFlavor,cakeDelivery} = i
return(
    <div class=" ">
    <div class=" mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center">
            <div class="" style={{width:'40%'}}>
            <h1 className='barlow pl-10 pt-10 mb-5 flex items-center capitalize' style={{fontSize:"12px"}}> 
    <Link className='underline' to={`/cakes`}>Cakes</Link>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {cakeTitle}</h1>
            {cake && cake.map((cake) => (
<div key={cake.id}>
<CakeGallery
cake={cake}
currentIndex={currentIndexes[cake.id] || 0}
handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
/>
</div>
))}
           
            </div>
            <div class="ml-8" style={{width:'60%'}}>
              <div className="flex w-full justify-between items-center mb-2">
              <h2 class="text-2xl font-bold text-gray-800 dark:text-white  barlow" style={{fontSize:'25px'}}>{cakeTitle}</h2>
              <span class="text-gray-900 header"style={{fontSize:'15px'}}> {cakePrice} ETB</span>

              </div>
                <p class="text-gray-600  text-sm mt-5 par break-all">
                {cakeDescription} </p>
                <div class="flex my-4">
                   
                    <div >
                        <span class="font-bold text-gray-700 ">Cake Flavor:</span>
                        <span class="text-gray-600 par"> {cakeFlavor}</span>
                    </div>
                </div>
                <div class="mb-4">
                   {
                    cakeColor && cakeColor.length > 1 ?
                    <span class="font-bold text-gray-700 ">Choose Cake Color:</span>
                    :
                    <span class="font-bold text-gray-700 "> Color:</span>
                   }
                    <div>
<div className="flex mt-4">
 
{cakeColor && cakeColor.length > 1 ?
cakeColor && cakeColor.map((color, index) => (
<div key={index} className="flex items-center mx-2">
<div class="flex items-center mb-4 ">
<input type="checkbox" id={`color-${index}`}
checked={selectedColor === color}
onChange={() => handleColorSelect(color)} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"/>

<label
htmlFor={`color-${index}`}
className="ml-1 w-7 h-7 rounded-full shadow border cursor-pointer"
style={{ backgroundColor: color }}
></label>
</div>

</div>
))
:
cakeColor && cakeColor.map((color, index) => (
  <div key={index} className="flex items-center mx-2">
  <div class="flex items-center mb-4 ">

  <label
  htmlFor={`color-${index}`}
  className="ml-1 w-7 h-7 rounded-full shadow border "
  style={{ backgroundColor: color }}
  ></label>
  </div>
  
  </div>
  ))}
</div>
</div>
                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Cake Size:</span>
                   <div className='mt-2'>

                  {cakeSize}
                   </div>

                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Ingredients:</span>
                   <div >

                   <React.Fragment>
                          {cakeIngredients.map((i, index) => (
                            <React.Fragment key={index}>
                              <span className="text-gray-600 par">{i}</span>
                              {index < cakeIngredients.length - 1 && ', '}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                   </div>

                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Cake Delivery:</span>
                   <div className='mt-2'>

                  {cakeDelivery === 'Shop Pickup' ? `We do not offer delivery services, so you must pick up the cake from our shop`:'Delivery Available'}
                   </div>

                </div>
                <div className='mb-8'>
                   
                </div>


                {
                  uid.length === 0 ?
                  <button onClick={signInPopSet} className="mt-5  par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-4 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                  Add to cart
                </button>
                : <button onClick={()=>addToCart(cakeColor)} className="mt-5  par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-4 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                Add to cart
              </button>

                }

{
uid.length === 0?
<button type="submit"onClick={signInPopSet} class="flex w-full items-center justify-center rounded-lg bg-blue-700 px-5 py-4 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-primary-600 mt-4">Proceed to Checkout</button>
:
<button type="submit"onClick={()=>shopItem(cakeColor)} class="flex w-full items-center justify-center rounded-lg bg-blue-700 px-5 py-4 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-primary-600 mt-4">Proceed to Checkout</button>

}

            </div>
        </div>
    </div>
</div>

)
})

}
</div>

</div>

</div>
:
<div>
<div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cake-pana.svg?alt=media&token=2749019d-15fe-499d-af3e-8342c9ef434a" alt="No Cake" style={{width:"42%"}}/>
<h1 className='fjalla text-center text-gray-900 mt-8 barlow' style={{fontSize:"30px"}}>  We couldn't find any cakes for you 




</h1>
<Link to='/cakes'>
<button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-7 py-4 me-2 mb-2">Shop Now</button> 

</Link>
</div>
</div>
        }
           

        </div>
            <div id='tablet' >
        {
          uid.length !=0?
      <Nav/>
      :
      <Navigation/>

        }
        {
          isLoading === true ?
          <div>
          <div style={{height:'80vh'}} class="bg-white flex items-center justify-center  rounded  w-full p-10 -mt-5">
          
          <div
          className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
          role="status"></div></div>
                  </div>
          :
          cake.length != 0?
          <div >
  <div className={`${selectedColorError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
You must choose a color

</div>
</div>
</div>
          <div className={`${cartError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{cartError}
</div>
</div>
</div>

{
signinpop === true &&
<div className='signInpop'>

{ loading === true ?
<div style={{height:'50vh'}} class="bg-white flex items-center justify-center shadow border rounded lg:w-1/3  md:w-1/2 w-full p-10 -mt-5">

<div
className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
role="status"></div></div>
:
<div style={{height:'90vh'}}class="relative bg-white shadow border rounded lg:w-1/3  md:w-1/2 w-full p-10 -mt-5">
                <div className={`${signInError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{signInError}
</div>
</div>
</div>
<p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Log In</p>

<svg onClick={setsigninpopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>

<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input onChange={(e)=>emailSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />

<div className={`${emailError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Password</p>
<input onChange={(e)=>passwordSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />

<div className={`${passwordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Password Can't be empty
</div>
</div>
</div>
</div>
<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>

<button
type='button'
onClick={signIn}
style={{fontSize:'12px',margin:'auto',background:'#D9FE00',height:'55px'}}
className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
>
Sign In
</button>
</div>
<div class="w-full flex items-center justify-between py-5 mt-4">
 <hr class="w-full bg-gray-400"/>
 <p class="text-base font-medium leading-4 px-2.5 text-gray-400"></p>
 <hr class="w-full bg-gray-400  "/>
</div>

<p class="text-center par focus:outline-none text-sm mt-8 font-medium leading-none text-gray-500">Don't have an account? <Link to='/register'><a   class="hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-gray-800 cursor-pointer"> Sign up here</a></Link></p>


</div>}
</div>

}
<div className="flex justify-between px-5 items-center">



</div>

<div >

<div  >
{
cake && cake.map(i=>{
const{cakeSize,cakeTitle,cakeColor,cakeDescription,cakeIngredients,cakePrice,cakeFlavor} = i
return(
    <div class=" ">
    <div class=" mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center">
            <div class="" style={{width:'55%'}}>
            <h1 className='barlow pl-10 pt-10 mb-5 flex items-center capitalize' style={{fontSize:"12px"}}> 
    <Link className='underline' to={`/cakes`}>Cakes</Link>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {cakeTitle}</h1>
            {cake && cake.map((cake) => (
<div key={cake.id}>
<CakeGallery
cake={cake}
currentIndex={currentIndexes[cake.id] || 0}
handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
/>
</div>
))}
           
            </div>
            <div class="ml-8" style={{width:'60%'}}>
              <div className="flex w-full justify-between items-center mb-2">
              <h2 class="text-2xl font-bold text-gray-800 dark:text-white  barlow" style={{fontSize:'25px'}}>{cakeTitle}</h2>
              <span class="text-gray-900 header"style={{fontSize:'15px'}}> {cakePrice} ETB</span>

              </div>
                <p class="text-gray-600  text-sm mt-5 par break-all">
                {cakeDescription} </p>
                <div class="flex my-4">
                   
                    <div >
                        <span class="font-bold text-gray-700 ">Cake Flavor:</span>
                        <span class="text-gray-600 par"> {cakeFlavor}</span>
                    </div>
                </div>
                <div class="mb-4">
                   {
                    cakeColor && cakeColor.length > 1 ?
                    <span class="font-bold text-gray-700 ">Choose Cake Color:</span>
                    :
                    <span class="font-bold text-gray-700 "> Color:</span>
                   }
                    <div>
<div className="flex mt-4">
 
{cakeColor && cakeColor.length > 1 ?
cakeColor && cakeColor.map((color, index) => (
<div key={index} className="flex items-center mx-2">
<div class="flex items-center mb-4 ">
<input type="checkbox" id={`color-${index}`}
checked={selectedColor === color}
onChange={() => handleColorSelect(color)} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"/>

<label
htmlFor={`color-${index}`}
className="ml-1 w-7 h-7 rounded-full shadow border cursor-pointer"
style={{ backgroundColor: color }}
></label>
</div>

</div>
))
:
cakeColor && cakeColor.map((color, index) => (
  <div key={index} className="flex items-center mx-2">
  <div class="flex items-center mb-4 ">

  <label
  htmlFor={`color-${index}`}
  className="ml-1 w-7 h-7 rounded-full shadow border "
  style={{ backgroundColor: color }}
  ></label>
  </div>
  
  </div>
  ))}
</div>
</div>
                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Cake Size:</span>
                   <div className='mt-2'>

                  {cakeSize}
                   </div>

                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Ingredients:</span>
                   <div >

                
                        <React.Fragment>
                          {cakeIngredients.map((i, index) => (
                            <React.Fragment key={index}>
                              <span className="text-gray-600 par">{i}</span>
                              {index < cakeIngredients.length - 1 && ', '}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                   </div>

                </div>
                <div className='mb-8'>
                   
                </div>


                {
                  uid.length === 0 ?
                  <button onClick={signInPopSet} className="mt-5  par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-4 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                  Add to cart
                </button>
                : <button onClick={()=>addToCart(cakeColor)} className="mt-5  par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-4 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                Add to cart
              </button>

                }

{
uid.length === 0?
<button type="submit"onClick={signInPopSet} class="flex w-full items-center justify-center rounded-lg bg-blue-700 px-5 py-4 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-primary-600 mt-4">Proceed to Checkout</button>
:
<button type="submit"onClick={()=>shopItem(cakeColor)} class="flex w-full items-center justify-center rounded-lg bg-blue-700 px-5 py-4 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-primary-600 mt-4">Proceed to Checkout</button>

}

            </div>
        </div>
    </div>
</div>

)
})

}
</div>

</div>

</div>
:
<div>
<div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cake-pana.svg?alt=media&token=2749019d-15fe-499d-af3e-8342c9ef434a" alt="No Cake" style={{width:"42%"}}/>
<h1 className='fjalla text-center text-gray-900 mt-8 barlow' style={{fontSize:"30px"}}>  We couldn't find any cakes for you 




</h1>
<Link to='/cakes'>
<button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-7 py-4 me-2 mb-2">Shop Now</button> 

</Link>
</div>
</div>
        }
           

        </div>
        <div id='mobile' >
        {
          uid.length !=0?
      <Nav/>
      :
      <Navigation/>

        }
        {
          isLoading === true ?
          <div>
  <div style={{height:'80vh'}} class="bg-white flex items-center justify-center  rounded  w-full p-10 -mt-5">
  
  <div
  className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
  role="status"></div></div>
          </div>
          :
          cake != 0 ?
          <div>

          <div className={`${cartError.length !== 0 ? "block":"hidden"}`}>
 <div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <span className="sr-only">Info</span>
 <div className='Mooli' style={{fontSize:'3vw'}}>
  
 {cartError}
 
 </div>
 </div>
 </div> 
 <div className={`${selectedColorError.length !== 0 ? "block":"hidden"}`}>
 <div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <span className="sr-only">Info</span>
 <div className='Mooli' style={{fontSize:'3vw'}}>
  
 You must choose a color
 
 </div>
 </div>
 </div> 
          {
           signinpop === true &&
           <div className='signInpop' style={{height:'100%'}}>
 
           {  isLoading === true ?
             <div style={{height:'90vh'}} class=" bg-white flex items-center justify-center shadow border rounded lg:w-1/3  md:w-1/2 w-full p-10 -mt-5">
             
             <div
             className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
             role="status"></div></div>
             :
             <div style={{width:'80%',height:'90vh'}}class="relative bg-white shadow border rounded  w-full p-10 -mt-5">
             <div className={`${signInError.length !== 0 ? "block":"hidden"}`}>
             <div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%',marginBottom:'3vw'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
             <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
             <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
             </svg>
             <span className="sr-only">Info</span>
             <div className='Mooli' style={{fontSize:'3vw'}}>
             {signInError}
             
             </div>
             </div>
             </div> 
             <svg onClick={setsigninpopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
   <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
 </svg>
             <p style={{fontSize:'5vw',marginBottom:'8vw'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Log In</p>
             
             <div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>
             
             <p class="bg-white  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Email</p>
             
             <input   onChange={(e=>emailSet(e))}  type='email'  placeholder='kebede@gmail.com' className=" m-auto form-control par  border  focus:outline-none
             focus:border-black   text-gray-700 block bg-white
             border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
             <div className={`${emailError == 'error' ? "block":"hidden"}`}>
             <div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
             <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
             <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
             </svg>
             <span className="sr-only">Info</span>
             <div className='Mooli' style={{fontSize:'3vw'}}>
             Email Can't be empty
             
             </div>
             </div>
             </div> 
             
             </div>
             
             <div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>
             
             <p class="bg-white  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Password</p>
             
             <input   onChange={(e=>passwordSet(e))}  type='password'  placeholder='********' className=" m-auto form-control par  border  focus:outline-none
             focus:border-black   text-gray-700 block bg-white
             border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
             <div className={`${passwordError == 'error' ? "block":"hidden"}`}>
             <div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
             <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
             <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
             </svg>
             <span className="sr-only">Info</span>
             <div className='Mooli' style={{fontSize:'3vw'}}>
             Password Can't be empty
             
             </div>
             </div>
             </div> 
             
             </div>
             <div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>
             
             <button
               type='button'
               onClick={signIn}
               style={{fontSize:'3vw',width:'100%',margin:'auto',background:'#D9FE00',height:'13vw'}}
               className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
             >
              Sign In
             </button>
             </div>
             <div class="w-full flex items-center justify-between py-5 mt-4">
                     <hr class="w-full bg-gray-400"/>
                     <p class="text-base font-medium leading-4 px-2.5 text-gray-400"></p>
                     <hr class="w-full bg-gray-400  "/>
                    </div>
             
                <p class="text-center par focus:outline-none text-sm mt-8 font-medium leading-none text-gray-500">Don't have an account? <Link to='/register'><a   class="hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-gray-800 cursor-pointer"> Sign up here</a></Link></p>
              
               
             </div>}
           </div>
 
          }
             <div className="flex justify-between px-5 items-center">
 
 
 
             </div>
      
             <div >
 
             <div  >
             {
                 cake && cake.map(i=>{
                     const{cakeSize,cakeTitle,cakeColor,cakeDescription,cakeIngredients,cakePrice,cakeFlavor} = i
                     return(
                         <div class=" py-10">
                         <div class=" mx-auto ">
                         <h1 className='barlow pl-10 pt-10 mb-5 flex items-center capitalize' style={{fontSize:"12px"}}> 
      <Link className='underline' to={`/cakes`}>Cakes</Link>
 <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
   <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
 </svg> 
 
 
  {cakeTitle}</h1>
           
                             <div class="flex flex-col w-full">
                                 <div class="" style={{width:'100%'}}>
                                 {cake && cake.map((cake) => (
               <div key={cake.id}>
                 <CakeGallery
                   cake={cake}
                   currentIndex={currentIndexes[cake.id] || 0}
                   handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                   handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                 />
               </div>
             ))}
                                
                                 </div>
                                 <div class="mx-auto" style={{width:'90%'}}>
                                   <div className='flex justify-between w-full items-center' style={{paddingTop:'6vw'}}>
                                   <h2 style={{fontSize:'4.8vw'}} class="text-2xl font-bold text-gray-900  mb-2 barlow">{cakeTitle}</h2>
                                     
                                   <p style={{fontSize:'3.5vw'}} class="text-gray-800 header">{formatNumber(cakePrice)} ETB </p>
 
                                   </div>
                                     <div className='mb-8'>
                                         <p class="text-gray-600  text-sm mt-2 par break-all" style={{fontSize:'2.8vw',lineHeight:'3.2vw'}}>
                                     {cakeDescription} </p>
                                     </div>
                                     <div class="flex mb-4">
                                      
                                         <div>
                                             <span class="font-bold text-gray-700 "style={{fontSize:'3.5vw'}}>Cake Flavor:</span>
                                             <span class="text-gray-600 par" style={{fontSize:'2.8vw'}}> {cakeFlavor}</span>
                                         </div>
                                     </div>
                                     <div class="mb-4">
 
                                       {
                                        cakeColor&& cakeColor.length >= 2? 
                                         <span class="font-bold text-gray-700 " style={{fontSize:'3.5vw'}}> Choose Cake Color:</span>
 :
 <span class="font-bold text-gray-700 " style={{fontSize:'3.5vw'}}> Color:</span>
 
                                       }
                                         <div class="flex items-center ">
                                         <div>
       <div className="flex mt-4">
         {cakeColor &&cakeColor&& cakeColor.length >=2 ?
         cakeColor && cakeColor.map((color, index) => (
           <div key={index} className="flex items-center mx-2">
             <div class="flex items-center mb-2 ">
     <input type="checkbox" id={`color-${index}`}
               checked={selectedColor === color}
               onChange={() => handleColorSelect(color)} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"/>
             
             <label
               htmlFor={`color-${index}`}
               className="ml-1 rounded-full shadow border cursor-pointer"
               style={{ backgroundColor: color,width:'4vw',height:'4vw' }}
             ></label>
 </div>
 
           </div>
  
 
         ))
       :
       cakeColor && cakeColor.map((color, index) => (
         <div key={index} className="flex items-center mx-2">
           <div class="flex items-center mb-2 ">
 
           <label
             htmlFor={`color-${index}`}
             className="ml-1 w- h- rounded-full shadow border "
             style={{ backgroundColor: color,width:'4vw',height:'4vw' }}
           ></label>
 </div>
 
         </div>
 
 
       ))}
       </div>
     </div>
                                         </div>
                                     </div>
                                     <div class="mb-4">
                                         <span class="font-bold text-gray-700" style={{fontSize:'3.5vw'}}>Cake Size:</span>
                                        <div className='text-gray-600 par' style={{fontSize:'2.8vw'}}>
 
                                       {cakeSize}
                                        </div>
 
                                     </div>
                                     <div class="mb-4" style={{marginBottom:'6vw'}}>
                                         <span class="font-bold text-gray-700 mb-2" style={{fontSize:'3.5vw'}}>Ingredients:</span>
                                        <div >
 
                                      
                                          <React.Fragment>
                          {cakeIngredients.map((i, index) => (
                            <React.Fragment key={index}>
                              <span className="text-gray-600 par" style={{fontSize:'2.8vw'}}>{i}
                              {index < cakeIngredients.length - 1 && ', '}
                              </span>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                                        </div>
 
                                     </div>
                             
        
 
                                     {
                                       uid.length === 0 ?
                                       <button  style={{fontSize:'3vw',width:'100%',height:'13vw'}}onClick={signInPopSet} className="mt-5   w-full flex items-center justify-center rounded-md bg-slate-900 barlow text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                                       <svg
                                         xmlns="http://www.w3.org/2000/svg"
                                         className="mr-2 "
                                         style={{width:'4vw',height:'4vw'}}
                                         fill="none"
                                         viewBox="0 0 24 24"
                                         stroke="currentColor"
                                         strokeWidth="2"
                                       >
                                         <path
                                           strokeLinecap="round"
                                           strokeLinejoin="round"
                                           d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                                         />
                                       </svg>
                                       Add to cart
                                     </button>
                                     : <button style={{fontSize:'3vw',width:'100%',height:'13vw'}} onClick={()=>addToCart(cakeColor)} className="mt-5   w-full flex items-center justify-center rounded-md bg-slate-900 barlow text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                                     <svg
                                       xmlns="http://www.w3.org/2000/svg"
                                       className="mr-2 "
                                       style={{width:'4vw',height:'4vw'}}
 
                                       fill="none"
                                       viewBox="0 0 24 24"
                                       stroke="currentColor"
                                       strokeWidth="2"
                                     >
                                       <path
                                         strokeLinecap="round"
                                         strokeLinejoin="round"
                                         d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                                       />
                                     </svg>
                                     Add to cart
                                   </button>
 
                                     }
                 
                   {
                     uid.length === 0?
       <button style={{fontSize:'3vw',width:'100%',height:'13vw'}} type="submit"onClick={signInPopSet} class="flex barlow w-full items-center justify-center rounded-lg bg-blue-700  text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-primary-600 mt-4">Proceed to Checkout</button>
 :
 <button style={{fontSize:'3vw',width:'100%',height:'13vw'}}type="submit"onClick={()=>shopItem(cakeColor)} class="flex barlow w-full items-center justify-center rounded-lg bg-blue-700  text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-primary-600 mt-4">Proceed to Checkout</button>
 
                   }
 
                                 </div>
                             </div>
                         </div>
                     </div>
 
                     )
                 })
 
 }
             </div>
          
             </div>
           
             </div>
             :
             <div>
                <div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cake-pana.svg?alt=media&token=2749019d-15fe-499d-af3e-8342c9ef434a" alt="No Cake" style={{width:"42%"}}/>
<h1 className='fjalla text-center text-gray-900 mt-8 barlow' style={{fontSize:"5vw"}}>  We couldn't find any cakes for you 

       


</h1>
<Link to='/cakes'>
        <button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-7 py-4 me-2 mb-2">Shop Now</button> 

         </Link>
</div>
             </div>
        }
            

        </div>
        </div>
  )
}
