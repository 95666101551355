import React, { useEffect, useState } from 'react'
import Nav from './nav'
import { onAuthStateChanged } from 'firebase/auth';
import { auth, firestore } from '../firebase';
import { collection, query, where,onSnapshot, Timestamp, orderBy, doc } from 'firebase/firestore';
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function OrderDetails() {
    const [selectedOrder,setselectedOrder] = useState()
    const [orders,setorders] = useState([])
    const [orderPop,setorderPop] = useState(true)
    const [loading,setloading] = useState(false)
    const [currentIndexes, setCurrentIndexes] = useState({});
    const {orderId} =useParams()
    const navigate = useNavigate()

    function orderPopClose(){
        navigate('/orders')
      
    }
    const handlePrevious = (cakePhotos, id) => {
        setCurrentIndexes((prevIndexes) => ({
          ...prevIndexes,
          [id]: (prevIndexes[id] === 0 ? cakePhotos.length - 1 : prevIndexes[id] - 1),
        }));
      };
      function formatNumber(num) {
        if(num){
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        };
      }
      const handleNext = (cakePhotos, id) => {
        setCurrentIndexes((prevIndexes) => ({
          ...prevIndexes,
          [id]: (prevIndexes[id] === cakePhotos.length - 1 ? 0 : prevIndexes[id] + 1),
        }));
      };

      function CakeGallery({ cake, currentIndex, handlePrevious, handleNext }) {
        return (
      
          
          <div id="gallery" className="relative w-full px-12 " data-carousel="slide">
            <div className="relative mb-3 overflow-hidden rounded-lg flex items-center">
              <div className="relative overflow-hidden rounded-lg shadow-xl">
                  {cake.cakeData.cakePhotos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo.cakePhotos}
                      alt={`Selected file ${index}`}
                      style={{ objectFit: 'cover' }}
                      className={`h-20 w-20  rounded  ${index === currentIndex ? 'block' : 'hidden'}`}
                    />
                  ))}
                  {cake.cakeData.cakePhotos.length === 0 && (
                    <img
                      className="h-20 w-20  rounded "
                      style={{  objectFit: 'cover' }}
                      src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                      alt="description"
                    />
                  )}
      
             
      
              
              </div>
            <div className=' ml-3'>
            <Link to={`/admin/cake/${cake.id}`} target="_blank">
              <h3 class="font-medium leading-tight hover:underline">{cake.cakeData.cakeTitle}</h3>

              </Link>
              <p class="text-sm par">Quantity - {cake.quantity}</p>
             
            </div>

            </div>
            
           
          </div>
        );
      }
      function getDateFromTimestamp(timestamp) {
        if (timestamp) {
          if (timestamp instanceof Timestamp) {
            return timestamp.toDate().toISOString().slice(0, 19).replace('T', ' ');
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
   
   
      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            const orderCollection = doc(firestore, 'Users', user.uid, 'Orders', orderId);
      
            const unsubscribeOrder = onSnapshot(orderCollection, (snapshot) => {
              setselectedOrder({ ...snapshot.data(), id: snapshot.id });
            });
      
            return () => {
              unsubscribeOrder();
            };
          } else {
            console.log("logged out");
          }
        });
      
        return () => {
          unsubscribe();
        };
      }, [orderId]);
  return (
    <div>
        <div id='desktop'>
                <Nav/>
                {
                orderPop === true &&
                (loading === true?
                    <div  class="bg-white orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">


                    <div
                      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
                      role="status"></div>
                 
                  
                    </div>
                    :
                     selectedOrder && selectedOrder.status === 'pending'?
                    <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                    <ol class="relative text-gray-500 border-s border-gray-200 "> 
                    <li class="mb-10 ms-6">            
                    <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white ">
    
    <svg class="w-3.5 h-3.5 text-blue-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
</span>
                    <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Payment receipt verification</h3>
                    <p class="text-sm">Our team is reviewing your payment status </p>
                </li>
                
                   
                    <li class="mb-10 ms-6">
                        
                    <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
    <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
        <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
    </svg>
</span>
                    <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Your cake is being prepared</h3>
                    <p class="text-sm">Gathering the cake's ingredients</p>
                </li>
                
                    <li class="mb-10 ms-6">
                    <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
            <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
        </span>
                    <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>We are making your cake</h3>

                        <p class="text-sm">Our team is baking your cake</p>
                    </li>
                    <li class="ms-6">
                    <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
            <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
                        <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Completed pickup</h3>
                        <p class="text-sm">Pay the down payment and pick up the cake</p>
                    </li>
                </ol>
                <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                      
              <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
      </svg>
                
                <div className="">
                  <h1 className='seymour-one-regular ' style={{fontSize:'25px'}}>We are currently reviewing whether your payment was received</h1>
                   
                  <dl class="flex items-center justify-between mb-2 ">
                      <dt class="text-base font-normal text-gray-500">A member of our team will contact you to confirm your order</dt>
                      </dl>
                         <img style={{width:'40%',margin:'auto'}} src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/Plain%20credit%20card.gif?alt=media&token=7e1c82d9-8692-4c9a-b299-3956662cfab1" alt="" />
                                        <div className='flex mt-10 justify-center flex-col'>
                                 
                                   {/* <p onClick={orderCompletedSet} className='text-center hover:underline par cursor-pointer m-2'>Delivered?</p> */}
                                     
                         
                               
                            </div>
                          </div>
                              </div>
                              :
                              selectedOrder &&selectedOrder.status === 'accepted'?
                              <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                              <ol class="relative text-gray-500 border-s border-gray-200 "style={{width:'40%'}}> 
                              <li class="mb-10 ms-6">            
                              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
                              <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Payment receipt verification</h3>
                              <p class="text-sm">Our team is reviewing your payment status </p>
                          </li>
                          
                          
                             
                              <li class="mb-10 ms-6">
                                  
                              <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white ">
              <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                  <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
              </svg>
          </span>
                              <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Your cake is being prepared</h3>
                              <p class="text-sm">Gathering the cake's ingredients</p>
                          </li>
                          
                              <li class="mb-10 ms-6">
                              <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
                      <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                          <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                      </svg>
                  </span>
                              <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>We are making your cake</h3>
          
                                  <p class="text-sm">Our team is baking your cake</p>
                              </li>
                              <li class="ms-6">
                              <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
                      <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                          <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
                      </svg>
                  </span>
                                  <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Completed pickup</h3>
                                  <p class="text-sm">Pay the down payment and pick up the cake</p>
                              </li>
                          </ol>
                          <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                
                        <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                </svg>
                          
                          <div className="">
                            <h1 className='seymour-one-regular ' style={{fontSize:'25px'}}>we are preparing your cake's ingredients</h1>
                            <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Down Payment (ቀሪ ክፍያ) - 
                <span class="text-base font-medium text-green-600"> {
                       selectedOrder.downPayment && selectedOrder.downPayment
                    } ETB</span>
                    </dt>
              </dl>
                            <dl class="flex items-center justify-between mb-2 ">
                                <dt class="text-base font-normal text-gray-500 mb-4">Your cake order has been accepted</dt>
                                </dl>
                                {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                        <div key={cake.id}>
                          <CakeGallery
                            cake={cake}
                            currentIndex={currentIndexes[cake.id] || 0}
                            handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                            handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                          />
                        </div>
                      ))}
                                        
                                    </div>
                                        </div>
                                        :
                                        selectedOrder &&selectedOrder.status === 'preparing cake'?
                                        <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                                        <ol class="relative text-gray-500 border-s border-gray-200 "style={{width:'40%'}}> 
                                        <li class="mb-10 ms-6">            
                                        <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                            <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </span>
                                        <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Payment receipt verification</h3>
                                        <p class="text-sm">Our team is reviewing your payment status </p>
                                    </li>
                                    
                                    
                                       
                                        <li class="mb-10 ms-6">
                                            
                                        <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                            <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </span>
                                        <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Your cake is being prepared</h3>
                                        <p class="text-sm">Gathering the cake's ingredients</p>
                                    </li>
                                    
                                        <li class="mb-10 ms-6">
                                        <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white ">
                                <svg class="w-3.5 h-3.5 text-blue-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                                </svg>
                            </span>
                                        <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>We are making your cake</h3>
                    
                                            <p class="text-sm">Our team is baking your cake</p>
                                        </li>
                                        <li class="ms-6">
                                        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
                                <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
                                </svg>
                            </span>
                                            <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Completed pickup</h3>
                                            <p class="text-sm">Pay the down payment and pick up the cake</p>
                                        </li>
                                    </ol>
                                    <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                          
                                  <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                          </svg>
                                    
                                    <div className="">
                                      <h1 className='seymour-one-regular ' style={{fontSize:'25px'}}>Our team is baking your cake</h1>
                                      <dl class="flex items-center justify-between gap-4 mb-5">
                <dt class="text-base font-normal text-gray-500">Down Payment (ቀሪ ክፍያ) - 
                <span class="text-base font-medium text-green-600"> {
                       selectedOrder.downPayment && selectedOrder.downPayment
                    } ETB</span>
                    </dt>
              </dl>
                                    
                                          {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                                  <div key={cake.id}>
                                    <CakeGallery
                                      cake={cake}
                                      currentIndex={currentIndexes[cake.id] || 0}
                                      handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                                      handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                                    />
                                  </div>
                                ))}
                                                  
                                              </div>
                                                  </div>
                                                       :
                                                       selectedOrder &&selectedOrder.status === 'ready for pickup'?
                                                       <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                                                       <ol class="relative text-gray-500 border-s border-gray-200 "style={{width:'40%'}}> 
                                                       <li class="mb-10 ms-6">            
                                                       <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                           <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                               <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                           </svg>
                                       </span>
                                                       <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Payment receipt verification</h3>
                                                       <p class="text-sm">Our team is reviewing your payment status </p>
                                                   </li>
                                                   
                                                   
                                                      
                                                       <li class="mb-10 ms-6">
                                                           
                                                       <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                           <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                               <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                           </svg>
                                       </span>
                                                       <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Your cake is being prepared</h3>
                                                       <p class="text-sm">Gathering the cake's ingredients</p>
                                                   </li>
                                                   
                                                       <li class="mb-10 ms-6">
                                                       <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                           <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                               <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                           </svg>
                                       </span>
                                                       <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>We are making your cake</h3>
                                   
                                                           <p class="text-sm">Our team is baking your cake</p>
                                                       </li>
                                                       <li class="ms-6">
                                                       <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white ">
                                               <svg class="w-3.5 h-3.5 text-blue-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                                   <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
                                               </svg>
                                           </span>
                                                           <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Completed pickup</h3>
                                                           <p class="text-sm">Pay the down payment and pick up the cake</p>
                                                       </li>
                                                   </ol>
                                                   <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                                         
                                                 <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                                           <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                         </svg>
                                                   
                                                   <div className="" style={{width:'40%'}}>
                                                     <h1 className='seymour-one-regular ' style={{fontSize:'25px'}}>It's time to pick up your cake</h1>
                                                     <dl class="flex items-center justify-between gap-4 mb-5">
                               <dt class="text-base font-normal text-gray-500">Down Payment (ቀሪ ክፍያ) - 
                               <span class="text-base font-medium text-green-600"> {
                                      selectedOrder.downPayment && selectedOrder.downPayment
                                   } ETB</span>
                                   </dt>
                             </dl>
                             <dt class="text-base font-normal text-gray-500 mb-4" style={{width:'90%'}}>In order to pick up your cake, you must provide our team with the phone number or email you used to place your order.</dt>

                                                   
                                                         {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                                                 <div key={cake.id}>
                                                   <CakeGallery
                                                     cake={cake}
                                                     currentIndex={currentIndexes[cake.id] || 0}
                                                     handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                                                     handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                                                   />
                                                 </div>
                                               ))}
                                                                 
                                                             </div>
                                                                 </div>
                                                                   :
                                                                   selectedOrder &&selectedOrder.status === 'completed'?
                                                                   <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                                                                   <ol class="relative text-gray-500 border-s border-gray-200 "style={{width:'40%'}}> 
                                                                   <li class="mb-10 ms-6">            
                                                                   <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                                       <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                                           <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                                       </svg>
                                                   </span>
                                                                   <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Payment receipt verification</h3>
                                                                   <p class="text-sm">Our team is reviewing your payment status </p>
                                                               </li>
                                                               
                                                               
                                                                  
                                                                   <li class="mb-10 ms-6">
                                                                       
                                                                   <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                                       <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                                           <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                                       </svg>
                                                   </span>
                                                                   <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Your cake is being prepared</h3>
                                                                   <p class="text-sm">Gathering the cake's ingredients</p>
                                                               </li>
                                                               
                                                                   <li class="mb-10 ms-6">
                                                                   <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                                       <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                                           <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                                       </svg>
                                                   </span>
                                                                   <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>We are making your cake</h3>
                                               
                                                                       <p class="text-sm">Our team is baking your cake</p>
                                                                   </li>
                                                                   <li class="ms-6">
                                                                   <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                                       <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                                           <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                                       </svg>
                                                   </span>
                                                                       <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Completed pickup</h3>
                                                                       <p class="text-sm">Pay the down payment and pick up the cake</p>
                                                                   </li>
                                                               </ol>
                                                               <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                                                     
                                                             <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                                                       <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                                     </svg>
                                                               
                                                               <div className="" style={{width:'40%'}}>
                                                                 <h1 className='seymour-one-regular ' style={{fontSize:'25px'}}>Order Completed!!</h1>
                                                                 <dl class="flex items-center justify-between gap-4 mb-1">
                                           <dt class="text-base font-normal text-gray-500">Total Payment - 
                                           <span class="text-base font-medium text-green-600"> {
                                                  selectedOrder.totalAmount && formatNumber(selectedOrder.totalAmount)
                                               } ETB</span>
                                               </dt>
                                             
                                         </dl>
                                         <dl class="flex items-center justify-between gap-4 mb-5">
                                       
                                               <dt class="text-base font-normal text-gray-500">Date - 
                                           <span class="text-base font-medium text-green-600"> {
                                                 selectedOrder.completedTime && getDateFromTimestamp(selectedOrder.completedTime)
                                               } </span>
                                               </dt>
                                         </dl>
            
                                                               
                                                                     {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                                                             <div key={cake.id}>
                                                               <CakeGallery
                                                                 cake={cake}
                                                                 currentIndex={currentIndexes[cake.id] || 0}
                                                                 handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                                                                 handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                                                               />
                                                             </div>
                                                           ))}
                                                                             
                                                                         </div>
                                                                             </div>
                                                                             :  selectedOrder &&selectedOrder.status === 'declined'&&
                                                                             <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                                                                             <ol class="relative text-gray-500 border-s border-gray-200 "style={{width:'40%'}}> 
                                                                             <li class="mb-10 ms-6">            
                                                                             <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

                                                                             <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Payment receipt verification</h3>
                                                                             <p class="text-sm">Our team is reviewing your payment status </p>
                                                                         </li>
                                                                         
                                                                         
                                                                            
                                                                             <li class="mb-10 ms-6">
                                                                             <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

                                                                             <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Your cake is being prepared</h3>
                                                                             <p class="text-sm">Gathering the cake's ingredients</p>
                                                                         </li>
                                                                         
                                                                             <li class="mb-10 ms-6">
                                                                             <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

                                                                             <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>We are making your cake</h3>
                                                         
                                                                                 <p class="text-sm">Our team is baking your cake</p>
                                                                             </li>
                                                                             <li class="ms-6">
                                                                             <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

                                                                                 <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Completed pickup</h3>
                                                                                 <p class="text-sm">Pay the down payment and pick up the cake</p>
                                                                             </li>
                                                                         </ol>
                                                                         <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                                                               
                                                                       <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                                                                 <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                                               </svg>
                                                                         
                                                                         <div className="" style={{width:'40%'}}>
                                                                         <div>
            <h1 className='seymour-one-regular text-red-800'>RECIEPT DECLINED</h1>
            <p className='par'>{selectedOrder.reason}</p>
        </div>
                                                     
                                                   <dl class="flex items-center justify-between gap-4 mb-5">
                                                 
                                                         <dt class="text-base font-normal text-gray-500">Declined Time - 
                                                     <span class="text-base font-medium text-red-600"> {
                                                           selectedOrder.declinedTime && getDateFromTimestamp(selectedOrder.declinedTime)
                                                         } </span>
                                                         </dt>
                                                   </dl>
                      
                                                                         
                                                                     
                                                                                       
                                                                                   </div>
                                                                                       </div>
                                        
                    
                    
                    )
            }
        </div>
        <div id='tablet'>
                <Nav/>
                {
                orderPop === true &&
                (loading === true?
                    <div  class="bg-white orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">


                    <div
                      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
                      role="status"></div>
                 
                  
                    </div>
                    :
                     selectedOrder && selectedOrder.status === 'pending'?
                    <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                    <ol style={{width:'40%'}}class="relative text-gray-500 border-s border-gray-200 "> 
                    <li class="mb-10 ms-6">            
                    <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white ">
    
    <svg class="w-3.5 h-3.5 text-blue-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
</span>
                    <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Payment receipt verification</h3>
                    <p class="text-sm">Our team is reviewing your payment status </p>
                </li>
                
                   
                    <li class="mb-10 ms-6">
                        
                    <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
    <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
        <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
    </svg>
</span>
                    <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Your cake is being prepared</h3>
                    <p class="text-sm">Gathering the cake's ingredients</p>
                </li>
                
                    <li class="mb-10 ms-6">
                    <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
            <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
        </span>
                    <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>We are making your cake</h3>

                        <p class="text-sm">Our team is baking your cake</p>
                    </li>
                    <li class="ms-6">
                    <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
            <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
                        <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Completed pickup</h3>
                        <p class="text-sm">Pay the down payment and pick up the cake</p>
                    </li>
                </ol>
                <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                      
              <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
      </svg>
                
                <div style={{width:'45%'}}className="ml-3">
                  <h1 className='seymour-one-regular ' style={{fontSize:'20px'}}>We are currently reviewing whether your payment was received</h1>
                   
                  <dl class="flex items-center justify-between mb-2 ">
                      <dt class="text-base font-normal text-gray-500">A member of our team will contact you to confirm your order</dt>
                      </dl>
                         <img style={{width:'40%',margin:'auto'}} src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/Plain%20credit%20card.gif?alt=media&token=7e1c82d9-8692-4c9a-b299-3956662cfab1" alt="" />
                                        <div className='flex mt-10 justify-center flex-col'>
                                 
                                   {/* <p onClick={orderCompletedSet} className='text-center hover:underline par cursor-pointer m-2'>Delivered?</p> */}
                                     
                         
                               
                            </div>
                          </div>
                              </div>
                              :
                              selectedOrder &&selectedOrder.status === 'accepted'?
                              <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                              <ol class="relative text-gray-500 border-s border-gray-200 "> 
                              <li class="mb-10 ms-6">            
                              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
                              <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Payment receipt verification</h3>
                              <p class="text-sm">Our team is reviewing your payment status </p>
                          </li>
                          
                          
                             
                              <li class="mb-10 ms-6">
                                  
                              <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white ">
              <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                  <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
              </svg>
          </span>
                              <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Your cake is being prepared</h3>
                              <p class="text-sm">Gathering the cake's ingredients</p>
                          </li>
                          
                              <li class="mb-10 ms-6">
                              <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
                      <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                          <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                      </svg>
                  </span>
                              <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>We are making your cake</h3>
          
                                  <p class="text-sm">Our team is baking your cake</p>
                              </li>
                              <li class="ms-6">
                              <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
                      <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                          <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
                      </svg>
                  </span>
                                  <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Completed pickup</h3>
                                  <p class="text-sm">Pay the down payment and pick up the cake</p>
                              </li>
                          </ol>
                          <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                
                        <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                </svg>
                          
                          <div className="">
                            <h1 className='seymour-one-regular ' style={{fontSize:'25px'}}>we are preparing your cake's ingredients</h1>
                            <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Down Payment (ቀሪ ክፍያ) - 
                <span class="text-base font-medium text-green-600"> {
                       selectedOrder.downPayment && selectedOrder.downPayment
                    } ETB</span>
                    </dt>
              </dl>
                            <dl class="flex items-center justify-between mb-2 ">
                                <dt class="text-base font-normal text-gray-500 mb-4">Your cake order has been accepted</dt>
                                </dl>
                                {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                        <div key={cake.id}>
                          <CakeGallery
                            cake={cake}
                            currentIndex={currentIndexes[cake.id] || 0}
                            handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                            handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                          />
                        </div>
                      ))}
                                        
                                    </div>
                                        </div>
                                        :
                                        selectedOrder &&selectedOrder.status === 'preparing cake'?
                                        <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                                        <ol class="relative text-gray-500 border-s border-gray-200 "> 
                                        <li class="mb-10 ms-6">            
                                        <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                            <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </span>
                                        <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Payment receipt verification</h3>
                                        <p class="text-sm">Our team is reviewing your payment status </p>
                                    </li>
                                    
                                    
                                       
                                        <li class="mb-10 ms-6">
                                            
                                        <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                            <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </span>
                                        <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Your cake is being prepared</h3>
                                        <p class="text-sm">Gathering the cake's ingredients</p>
                                    </li>
                                    
                                        <li class="mb-10 ms-6">
                                        <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white ">
                                <svg class="w-3.5 h-3.5 text-blue-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                                </svg>
                            </span>
                                        <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>We are making your cake</h3>
                    
                                            <p class="text-sm">Our team is baking your cake</p>
                                        </li>
                                        <li class="ms-6">
                                        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
                                <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
                                </svg>
                            </span>
                                            <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Completed pickup</h3>
                                            <p class="text-sm">Pay the down payment and pick up the cake</p>
                                        </li>
                                    </ol>
                                    <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                          
                                  <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                          </svg>
                                    
                                    <div className="">
                                      <h1 className='seymour-one-regular ' style={{fontSize:'25px'}}>Our team is baking your cake</h1>
                                      <dl class="flex items-center justify-between gap-4 mb-5">
                <dt class="text-base font-normal text-gray-500">Down Payment (ቀሪ ክፍያ) - 
                <span class="text-base font-medium text-green-600"> {
                       selectedOrder.downPayment && selectedOrder.downPayment
                    } ETB</span>
                    </dt>
              </dl>
                                    
                                          {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                                  <div key={cake.id}>
                                    <CakeGallery
                                      cake={cake}
                                      currentIndex={currentIndexes[cake.id] || 0}
                                      handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                                      handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                                    />
                                  </div>
                                ))}
                                                  
                                              </div>
                                                  </div>
                                                       :
                                                       selectedOrder &&selectedOrder.status === 'ready for pickup'?
                                                       <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                                                       <ol class="relative text-gray-500 border-s border-gray-200 "> 
                                                       <li class="mb-10 ms-6">            
                                                       <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                           <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                               <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                           </svg>
                                       </span>
                                                       <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Payment receipt verification</h3>
                                                       <p class="text-sm">Our team is reviewing your payment status </p>
                                                   </li>
                                                   
                                                   
                                                      
                                                       <li class="mb-10 ms-6">
                                                           
                                                       <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                           <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                               <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                           </svg>
                                       </span>
                                                       <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Your cake is being prepared</h3>
                                                       <p class="text-sm">Gathering the cake's ingredients</p>
                                                   </li>
                                                   
                                                       <li class="mb-10 ms-6">
                                                       <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                           <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                               <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                           </svg>
                                       </span>
                                                       <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>We are making your cake</h3>
                                   
                                                           <p class="text-sm">Our team is baking your cake</p>
                                                       </li>
                                                       <li class="ms-6">
                                                       <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white ">
                                               <svg class="w-3.5 h-3.5 text-blue-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                                   <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
                                               </svg>
                                           </span>
                                                           <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Completed pickup</h3>
                                                           <p class="text-sm">Pay the down payment and pick up the cake</p>
                                                       </li>
                                                   </ol>
                                                   <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                                         
                                                 <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                                           <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                         </svg>
                                                   
                                                   <div className="" style={{width:'40%'}}>
                                                     <h1 className='seymour-one-regular ' style={{fontSize:'25px'}}>It's time to pick up your cake</h1>
                                                     <dl class="flex items-center justify-between gap-4 mb-5">
                               <dt class="text-base font-normal text-gray-500">Down Payment (ቀሪ ክፍያ) - 
                               <span class="text-base font-medium text-green-600"> {
                                      selectedOrder.downPayment && selectedOrder.downPayment
                                   } ETB</span>
                                   </dt>
                             </dl>
                             <dt class="text-base font-normal text-gray-500 mb-4" style={{width:'90%'}}>In order to pick up your cake, you must provide our team with the phone number or email you used to place your order.</dt>

                                                   
                                                         {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                                                 <div key={cake.id}>
                                                   <CakeGallery
                                                     cake={cake}
                                                     currentIndex={currentIndexes[cake.id] || 0}
                                                     handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                                                     handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                                                   />
                                                 </div>
                                               ))}
                                                                 
                                                             </div>
                                                                 </div>
                                                                   :
                                                                   selectedOrder &&selectedOrder.status === 'completed'?
                                                                   <div  className='orderPop shadow-xl border flex  justify-center  items-center ' >
                                                                   <ol class="relative text-gray-500 border-s border-gray-200 "> 
                                                                   <li class="mb-10 ms-6">            
                                                                   <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                                       <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                                           <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                                       </svg>
                                                   </span>
                                                                   <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Payment receipt verification</h3>
                                                                   <p class="text-sm">Our team is reviewing your payment status </p>
                                                               </li>
                                                               
                                                               
                                                                  
                                                                   <li class="mb-10 ms-6">
                                                                       
                                                                   <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                                       <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                                           <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                                       </svg>
                                                   </span>
                                                                   <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Your cake is being prepared</h3>
                                                                   <p class="text-sm">Gathering the cake's ingredients</p>
                                                               </li>
                                                               
                                                                   <li class="mb-10 ms-6">
                                                                   <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                                       <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                                           <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                                       </svg>
                                                   </span>
                                                                   <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>We are making your cake</h3>
                                               
                                                                       <p class="text-sm">Our team is baking your cake</p>
                                                                   </li>
                                                                   <li class="ms-6">
                                                                   <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                                                       <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                                           <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                                       </svg>
                                                   </span>
                                                                       <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Completed pickup</h3>
                                                                       <p class="text-sm">Pay the down payment and pick up the cake</p>
                                                                   </li>
                                                               </ol>
                                                               <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                                                     
                                                             <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                                                       <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                                     </svg>
                                                               
                                                               <div className="" style={{width:'40%'}}>
                                                                 <h1 className='seymour-one-regular ' style={{fontSize:'25px'}}>Order Completed!!</h1>
                                                                 <dl class="flex items-center justify-between gap-4 mb-1">
                                           <dt class="text-base font-normal text-gray-500">Total Payment - 
                                           <span class="text-base font-medium text-green-600"> {
                                                  selectedOrder.totalAmount && formatNumber(selectedOrder.totalAmount)
                                               } ETB</span>
                                               </dt>
                                             
                                         </dl>
                                         <dl class="flex items-center justify-between gap-4 mb-5">
                                       
                                               <dt class="text-base font-normal text-gray-500">Date - 
                                           <span class="text-base font-medium text-green-600"> {
                                                 selectedOrder.completedTime && getDateFromTimestamp(selectedOrder.completedTime)
                                               } </span>
                                               </dt>
                                         </dl>
            
                                                               
                                                                     {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                                                             <div key={cake.id}>
                                                               <CakeGallery
                                                                 cake={cake}
                                                                 currentIndex={currentIndexes[cake.id] || 0}
                                                                 handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                                                                 handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                                                               />
                                                             </div>
                                                           ))}
                                                                             
                                                                         </div>
                                                                             </div>
                                                                             :  selectedOrder &&selectedOrder.status === 'declined'&&
                                                                             <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                                                                             <ol class="relative text-gray-500 border-s border-gray-200 "style={{width:'40%'}}> 
                                                                             <li class="mb-10 ms-6">            
                                                                             <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

                                                                             <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Payment receipt verification</h3>
                                                                             <p class="text-sm">Our team is reviewing your payment status </p>
                                                                         </li>
                                                                         
                                                                         
                                                                            
                                                                             <li class="mb-10 ms-6">
                                                                             <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

                                                                             <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Your cake is being prepared</h3>
                                                                             <p class="text-sm">Gathering the cake's ingredients</p>
                                                                         </li>
                                                                         
                                                                             <li class="mb-10 ms-6">
                                                                             <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

                                                                             <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>We are making your cake</h3>
                                                         
                                                                                 <p class="text-sm">Our team is baking your cake</p>
                                                                             </li>
                                                                             <li class="ms-6">
                                                                             <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

                                                                                 <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Completed pickup</h3>
                                                                                 <p class="text-sm">Pay the down payment and pick up the cake</p>
                                                                             </li>
                                                                         </ol>
                                                                         <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                                                               
                                                                       <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                                                                 <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                                               </svg>
                                                                         
                                                                         <div className="" style={{width:'40%'}}>
                                                                         <div>
            <h1 className='seymour-one-regular text-red-800'>RECIEPT DECLINED</h1>
            <p className='par'>{selectedOrder.reason}</p>
        </div>
                                                     
                                                   <dl class="flex items-center justify-between gap-4 mb-5">
                                                 
                                                         <dt class="text-base font-normal text-gray-500">Declined Time - 
                                                     <span class="text-base font-medium text-red-600"> {
                                                           selectedOrder.declinedTime && getDateFromTimestamp(selectedOrder.declinedTime)
                                                         } </span>
                                                         </dt>
                                                   </dl>
                      
                                                                         
                                                                     
                                                                                       
                                                                                   </div>
                                                                                       </div>
                                        
                    
                    
                    )
            }
        </div>
        <div id='mobile'>
                <Nav/>
                {
                orderPop === true &&
                (loading === true?
                    <div  class="bg-white orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">


                    <div
                      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
                      role="status"></div>
                 
                  
                    </div>
                    :
                     selectedOrder && selectedOrder.status === 'pending'?
                    <div  className='orderPop flex-col shadow-xl border flex  justify-center  items-center '>
                <ol style={{padding:'3vw'}} class="flex items-center justify-center mb-6 w-full text-sm font-medium text-center text-gray-500  sm:text-base">
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
     
    <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
          
        </span>
    </li>
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block ">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12  shrink-0">

            <svg class="w-4 h-4 text-gray-500 lg:w-5 lg:h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
            </svg>
        </span>
    </li>
        <li class=" justify-center flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12  shrink-0">
    <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
       
        </span>
    </li>
    <li class="flex ">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12  shrink-0">
           
            <svg class="w-4 h-4 text-gray-500 lg:w-5 lg:h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
    </li>
</ol>
                <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                      
              <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
      </svg>
                
                <div style={{width:'80%'}}>
                  <h1 style={{fontSize:'4vw'}}className='seymour-one-regular'>We are currently reviewing whether your payment was received</h1>
                   
                  <dl class="flex items-center justify-between mb-2 ">
                      <dt class="text-base font-normal text-gray-500" style={{fontSize:'2.5vw'}}>A member of our team will contact you to confirm your order</dt>
                      </dl>
                         <img style={{width:'60%',margin:'auto'}} src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/Plain%20credit%20card.gif?alt=media&token=7e1c82d9-8692-4c9a-b299-3956662cfab1" alt="" />
                                        <div className='flex mt-10 justify-center flex-col'>
                                 
                                   {/* <p onClick={orderCompletedSet} className='text-center hover:underline par cursor-pointer m-2'>Delivered?</p> */}
                                     
                         
                               
                            </div>
                          </div>
                              </div>
                              :
                              selectedOrder &&selectedOrder.status === 'accepted'?
                              <div  className='orderPop flex-col shadow-xl border flex  justify-center  items-center '>
                              <ol style={{padding:'3vw'}} class="flex items-center justify-center mb-6 w-full text-sm font-medium text-center text-gray-500  sm:text-base">
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
     
   <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
          
        </span>
    </li>
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block ">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">

            <svg class="w-4 h-4 text-blue-600 lg:w-5 lg:h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
            </svg>
        </span>
    </li>
        <li class=" justify-center flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12  shrink-0">
    <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
       
        </span>
    </li>
    <li class="flex ">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12  shrink-0">
           
            <svg class="w-4 h-4 text-gray-500 lg:w-5 lg:h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
    </li>
</ol>
                          <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                
                        <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                </svg>
                          
                          <div className="">
                            <h1 className='seymour-one-regular ' style={{fontSize:'4vw'}}>we are preparing your cake's ingredients</h1>
                            <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500" style={{fontSize:'3vw'}}>Down Payment (ቀሪ ክፍያ) - 
                <span style={{fontSize:'3vw'}} class="text-base font-medium text-green-600"> {
                       selectedOrder.downPayment && selectedOrder.downPayment
                    } ETB</span>
                    </dt>
              </dl>
                            <dl class="flex items-center justify-between mb-2 ">
                                <dt class="text-base font-normal text-gray-500 mb-4"style={{fontSize:'3vw'}}>Your cake order has been accepted</dt>
                                </dl>
                                {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                        <div key={cake.id}>
                          <CakeGallery
                            cake={cake}
                            currentIndex={currentIndexes[cake.id] || 0}
                            handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                            handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                          />
                        </div>
                      ))}
                                        
                                    </div>
                                        </div>
                                        :
                                        selectedOrder &&selectedOrder.status === 'preparing cake'?
                                        <div  className='orderPop flex-col shadow-xl border flex  justify-center  items-center '>
                                      <ol style={{padding:'3vw'}} class="flex items-center justify-center mb-6 w-full text-sm font-medium text-center text-gray-500  sm:text-base">
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
     
   <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
          
        </span>
    </li>
    <li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

<span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
 
<svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
      
    </span>
</li>
        <li class=" justify-center flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
    <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
       
        </span>
    </li>
    <li class="flex ">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12  shrink-0">
           
            <svg class="w-4 h-4 text-gray-500 lg:w-5 lg:h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
    </li>
</ol>
                                    <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                          
                                  <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                          </svg>
                                    
                                    <div className="">
                                      <h1 className='seymour-one-regular ' style={{fontSize:'4vw'}}>Our team is baking your cake</h1>
                                      <dl class="flex items-center justify-between gap-4 mb-5">
                <dt class="text-base font-normal text-gray-500"style={{fontSize:'3vw'}}>Down Payment (ቀሪ ክፍያ) - 
                <span class="text-base font-medium text-green-600"style={{fontSize:'3vw'}}> {
                       selectedOrder.downPayment && selectedOrder.downPayment
                    } ETB</span>
                    </dt>
              </dl>
                                    
                                          {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                                  <div key={cake.id}>
                                    <CakeGallery
                                      cake={cake}
                                      currentIndex={currentIndexes[cake.id] || 0}
                                      handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                                      handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                                    />
                                  </div>
                                ))}
                                                  
                                              </div>
                                                  </div>
                                                       :
                                                       selectedOrder &&selectedOrder.status === 'ready for pickup'?
                                                       <div  className='flex-col orderPop shadow-xl border flex  justify-center  items-center '>
                                                          <ol style={{padding:'3vw'}} class="flex items-center justify-center mb-6 w-full text-sm font-medium text-center text-gray-500  sm:text-base">
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
     
   <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
          
        </span>
    </li>
    <li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

<span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
 
<svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
      
    </span>
</li>
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

<span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
 
<svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
      
    </span>
</li>
    <li class="flex ">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
           
            <svg class="w-4 h-4 text-blue-500 lg:w-5 lg:h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
    </li>
</ol>
                                                   <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                                         
                                                 <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                                           <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                         </svg>
                                                   
                                                   <div className="" style={{width:'80%'}}>
                                                     <h1 className='seymour-one-regular ' style={{fontSize:'4vw'}}>It's time to pick up your cake</h1>
                                                     <dl class="flex items-center justify-between gap-4 mb-5">
                               <dt class="text-base font-normal text-gray-500"style={{fontSize:'3vw'}}>Down Payment (ቀሪ ክፍያ) - 
                               <span class="text-base font-medium text-green-600"style={{fontSize:'3vw'}}> {
                                      selectedOrder.downPayment && selectedOrder.downPayment
                                   } ETB</span>
                                   </dt>
                             </dl>
                             <dt class="text-base font-normal text-gray-500 mb-4" style={{width:'90%',fontSize:'3vw'}}>In order to pick up your cake, you must provide our team with the phone number or email you used to place your order.</dt>

                                                   
                                                         {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                                                 <div key={cake.id}>
                                                   <CakeGallery
                                                     cake={cake}
                                                     currentIndex={currentIndexes[cake.id] || 0}
                                                     handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                                                     handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                                                   />
                                                 </div>
                                               ))}
                                                                 
                                                             </div>
                                                                 </div>
                                                                   :
                                                                   selectedOrder &&selectedOrder.status === 'completed'?
                                                                   <div  className='flex-col orderPop shadow-xl border flex  justify-center  items-center ' >
                                                                   <ol style={{padding:'3vw',marginTop:'5vw'}} class="flex items-center justify-center mb-6 w-full text-sm font-medium text-center text-gray-500  sm:text-base">
    <li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">
        <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
        <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
        </span>
    </li>
    <li class="flex w-full items-center justify-center after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">
        <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
        <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
        </span>
    </li>
    <li class="flex w-full items-center justify-center after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">
        <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
        <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
        </span>
    </li>
    <li class="flex  ">
        <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
        <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
        </span>
    </li>
</ol>
                                                               <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                                                     
                                                             <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                                                       <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                                     </svg>
                                                               
                                                               <div className="" style={{width:'80%'}}>
                                                                 <h1 className='seymour-one-regular ' style={{fontSize:'5vw'}}>Order Completed!!</h1>
                                                                 <dl class="flex items-center justify-between gap-4 mb-1">
                                           <dt class="text-base font-normal text-gray-500"style={{fontSize:'3vw'}}>Total Payment - 
                                           <span class="text-base font-medium text-green-600"style={{fontSize:'3vw'}}> {
                                                  selectedOrder.totalAmount && formatNumber(selectedOrder.totalAmount)
                                               } ETB</span>
                                               </dt>
                                             
                                         </dl>
                                         <dl class="flex items-center justify-between gap-4 mb-5">
                                       
                                               <dt class="text-base font-normal text-gray-500"style={{fontSize:'3vw'}}>Date - 
                                           <span class="text-base font-medium text-green-600"style={{fontSize:'3vw'}}> {
                                                 selectedOrder.completedTime && getDateFromTimestamp(selectedOrder.completedTime)
                                               } </span>
                                               </dt>
                                         </dl>
            
                                                               
                                                                     {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                                                             <div key={cake.id}>
                                                               <CakeGallery
                                                                 cake={cake}
                                                                 currentIndex={currentIndexes[cake.id] || 0}
                                                                 handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                                                                 handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                                                               />
                                                             </div>
                                                           ))}
                                                                             
                                                                         </div>
                                                                             </div>
                                                                             :  selectedOrder &&selectedOrder.status === 'declined'&&
                                                                             <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                                                                             <ol class="relative text-gray-500 border-s border-gray-200 "style={{width:'40%'}}> 
                                                                             <li class="mb-10 ms-6">            
                                                                             <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

                                                                             <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Payment receipt verification</h3>
                                                                             <p class="text-sm">Our team is reviewing your payment status </p>
                                                                         </li>
                                                                         
                                                                         
                                                                            
                                                                             <li class="mb-10 ms-6">
                                                                             <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

                                                                             <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Your cake is being prepared</h3>
                                                                             <p class="text-sm">Gathering the cake's ingredients</p>
                                                                         </li>
                                                                         
                                                                             <li class="mb-10 ms-6">
                                                                             <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

                                                                             <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>We are making your cake</h3>
                                                         
                                                                                 <p class="text-sm">Our team is baking your cake</p>
                                                                             </li>
                                                                             <li class="ms-6">
                                                                             <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

                                                                                 <h3 class="font-medium leading-tight" style={{fontSize:'13px',color:'black'}}>Completed pickup</h3>
                                                                                 <p class="text-sm">Pay the down payment and pick up the cake</p>
                                                                             </li>
                                                                         </ol>
                                                                         <p  className='text-center hover:underline  cursor-pointer m-2 absolute left-6 top-4 header'>Call Us - 0943130725</p>
                                                                               
                                                                       <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
                                                                 <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                                               </svg>
                                                                         
                                                                         <div className="" style={{width:'40%'}}>
                                                                         <div>
            <h1 className='seymour-one-regular text-red-800'>RECIEPT DECLINED</h1>
            <p className='par'>{selectedOrder.reason}</p>
        </div>
                                                     
                                                   <dl class="flex items-center justify-between gap-4 mb-5">
                                                 
                                                         <dt class="text-base font-normal text-gray-500">Declined Time - 
                                                     <span class="text-base font-medium text-red-600"> {
                                                           selectedOrder.declinedTime && getDateFromTimestamp(selectedOrder.declinedTime)
                                                         } </span>
                                                         </dt>
                                                   </dl>
                      
                                                                         
                                                                     
                                                                                       
                                                                                   </div>
                                                                                       </div>
                                        
                    
                    
                    )
            }
        </div>
    </div>
  )
}
