import React, { useEffect, useState } from 'react'
import Nav from './nav'
import { onAuthStateChanged } from 'firebase/auth';
import { auth, firestore } from '../firebase';
import { collection, query, where,onSnapshot, Timestamp, orderBy } from 'firebase/firestore';
import { Link } from 'react-router-dom';

export default function MyOrders() {
    const [orders,setorders] = useState([])
    const [orderPop,setorderPop] = useState(false)
    const [loading,setloading] = useState(false)
    const [currentIndexes, setCurrentIndexes] = useState({});

    const [selectedOrder,setselectedOrder] = useState()


    function formatNumber(num) {
        if(num){
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        };
      }
   
    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if(user){
                const orderCollection = collection(firestore,'Users',user.uid,'Orders')

                onSnapshot(orderCollection,snapshot=>{
                    let temp = []
                    snapshot.docs.forEach(doc=>{
                        temp.push({...doc.data(),id:doc.id})

                        setorders(temp)
                    })
                })

            } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);

    
      function SelectedOrderPopset(i){
       
        setorderPop(true)
        setselectedOrder(i)
      }
      function getDateFromTimestamp(timestamp) {
        if (timestamp) {
          if (timestamp instanceof Timestamp) {
            return timestamp.toDate().toISOString().slice(0, 19).replace('T', ' ');
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
   

   
  return (
    <div>
        <div id='desktop'>
            <Nav/>

        
          {
            orders.length !=0 &&
            <div>       
            <div className="px-9 pt-5 flex justify-between  min-h-[70px] pb-0 bg-transparent w-full">
   <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
       </h3>
       <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
           <span className="mr-3 header text-yellow-800">Recent Orders</span>
       </h3>
  
       </div>
   <div className="flex  mb-5  ">
<div className="w-full max-w-full  mb-6  mx-auto">
   <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
   <div className="relative flex flex-col min-w-0 break-words  bg-clip-border rounded-2xl border-stone-200 bg-light/30">
   
       
       <div className="flex-auto block py-8  ">
       <div className="overflow-x-auto">
       <table className="w-full my-0 align-middle text-dark border-neutral-200">
                                <thead className="align-bottom ">
                                    <tr className="font-semibold text-[0.95rem] text-gray-800">
                                    <th className="pb-3 text-center header pr-3 "></th>
            
                                    <th className="pb-3 text-center header pr-3 ">Full Name</th>
                                    <th className="pb-3 text-center  header pr-3">Cake</th>
                                    <th className="pb-3 text-center  header pr-3">Price</th>
                                    <th className="pb-3 text-center header pr-3">STATUS</th>
                                    <th className="pb-3 text-center  header pr-3">Pickup Date</th>
                                    </tr>
                                </thead>
                                {
                orders.sort((a, b) => a.deliveryDate.valueOf() - b.deliveryDate.valueOf()).map((i, index) => {
                    const { accountHolder, totalAmount, cakes, deliveryDate,status,transactionId } = i;
                    return (
                        <tbody key={index}>
                        <tr className=" last:border-b-0 mt-6 py-3 ">
                        <td className="pr-12 py-8 pl-0">
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                                    </div>
                                </div>
                            </td>
                            <td className="pr-3 py-8 pl-0">
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {accountHolder} </p>
                                    </div>
                                </div>
                            </td>
                            <td className=" pr-3 text-center ">
                                <span className="barlow text-light-inverse text-md/normal">
                                    {cakes.map((cake, cakeIndex) => {
                                        return (
                                            <React.Fragment key={cakeIndex}>
                                                {cake.cakeData.cakeTitle}
                                                {cakeIndex < cakes.length - 1 ? ', ' : ''}
                                            </React.Fragment>
                                        );
                                    })}
                                </span>
                            </td>
                            <td className=" pr-3 text-center par">
                                <span className="barlow text-light-inverse text-md/normal">{totalAmount} ETB</span>
                            </td>
                            <td className=" pr-3 text-center">
                                {
                                    status === 'pending'?
                                <span className="uppercase bg-yellow-200 text-yellow-600 py-2  rounded barlow px-2">{status} </span>
                                    :
                                    status === 'accepted'?
                                    <span className="uppercase bg-purple-200 text-purple-600 py-2  rounded barlow px-2">{status} </span>
                                   
                                    :
                                    status === 'declined'?
                                <span className="uppercase bg-red-200 text-red-600 py-2  rounded barlow px-2">Reciept Declined </span>
                                    :
                                    status === 'ready for pickup'?
                                    <span className="uppercase bg-blue-200 text-blue-600 py-2  rounded barlow px-2">{status} </span>
                                        :
                                        status === 'preparing cake'?
                                        <span className="uppercase bg-pink-200 text-pink-600 py-2  rounded barlow px-2">{status} </span>
                                            :
                                <span  class="uppercase bg-green-200 text-green-600 py-2  rounded barlow px-2" >{status}</span>
                 
                 
                                }
                            </td>
                            <td className="pr-3 text-center">
                                <span className="barlow font-semibold text-light-inverse text-md/normal par text-center">{getDateFromTimestamp(deliveryDate)}</span>
                            </td>
                            <td className=" pr-3 text-center par">
         <Link to={`/orders/${transactionId}`}>
                          
                          <button   className="ml-auto bg-blue-700  relative hover:text-primary flex items-center border h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none justify-center">
                                    <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-white">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </button>
                                </Link>
                            </td>
                        </tr>
                    </tbody>
                    );
                })
            }
                              
                                </table>
       </div>
       </div>
   </div>
   </div>
</div>
</div>
</div>
          }

{
                    orders.length === 0 &&
                    <div>
                          <div className='flex items-center justify-center flex-col mt-28 '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/Order%20food-amico.svg?alt=media&token=f075bbe5-0ae5-4057-947d-cfb0bae0ca5d" alt="No order" style={{width:"20%"}}/>
  <h1 className='fjalla text-center text-gray-900 mt-8 header' style={{fontSize:"20px"}}>We haven't received any orders from you


</h1>

</div>
                    </div>
                }
        </div>
        <div id='tablet'>
            <Nav/>

        
          {
            orders.length !=0 &&
            <div>       
            <div className="px-9 pt-5 flex justify-between  min-h-[70px] pb-0 bg-transparent w-full">
   <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
       </h3>
       <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
           <span className="mr-3 header text-yellow-800">Recent Orders</span>
       </h3>
  
       </div>
   <div className="flex  mb-5  ">
<div className="w-full max-w-full  mb-6  mx-auto">
   <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
   <div className="relative flex flex-col min-w-0 break-words  bg-clip-border rounded-2xl border-stone-200 bg-light/30">
   
       
       <div className="flex-auto block py-8  ">
       <div className="overflow-x-auto">
       <table className="w-full my-0 align-middle text-dark border-neutral-200">
                                <thead className="align-bottom ">
                                                  <tr className="font-semibold text-[0.95rem] text-gray-800">
                                                  <th className="pb-3 text-center  header  "></th>
                          
                                                  <th className="pb-3 text-center  header  ">Full Name</th>
                                                  <th className="pb-3 text-center  header ">Cake</th>
                                                  <th className="pb-3 text-center  header ">Price</th>
                                                  <th className="pb-3 text-center  header ">Pickup Date</th>
                                                  <th className="pb-3 text-center  header ">STATUS</th>
                                                  </tr>
                                              </thead>
                                {
                  orders.sort((a, b) => a.deliveryDate.valueOf() - b.deliveryDate.valueOf()).slice(0,10).map((i, index) => {
                    const { accountHolder, totalAmount, cakes, deliveryDate,status,transactionId } = i;
                    return (
                        <tbody key={index}>
                        <tr className=" last:border-b-0 mt-6 py-3 ">
                        <td className=" py-8 pl-0">
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                                    </div>
                                </div>
                            </td>
                            <td className=" py-8 pl-0">
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {accountHolder} </p>
                                    </div>
                                </div>
                            </td>
                            <td className="  text-center ">
                                <span className="barlow text-light-inverse text-md/normal">
                                    {cakes.map((cake, cakeIndex) => {
                                        return (
                                            <React.Fragment key={cakeIndex}>
                                                {cake.cakeData.cakeTitle}
                                                {cakeIndex < cakes.length - 1 ? <br/> : ''}
                                            </React.Fragment>
                                        );
                                    })}
                                </span>
                            </td>
                            <td className="  text-center par">
                                <span className="barlow text-light-inverse text-md/normal">{formatNumber(totalAmount)} ETB</span>
                            </td>
                            <td className="  text-center par">
                                <span className="barlow text-light-inverse text-md/normal">{getDateFromTimestamp(deliveryDate)}</span>
                            </td>
                            <td className="  text-center">
                                {
                                    status === 'pending'?
                                <span className="uppercase bg-yellow-200 text-yellow-600 py-2  rounded barlow px-2">{status} </span>
                                    :
                                    status === 'accepted'?
                                    <span className="uppercase bg-purple-200 text-purple-600 py-2  rounded barlow px-2">{status} </span>
                                   
                                    :
                                    status === 'declined'?
                                <span className="uppercase bg-red-200 text-red-600 py-2  rounded barlow px-2">Reciept Declined </span>
                                    :
                                    status === 'ready for pickup'?
                                    <span className="uppercase bg-blue-200 text-blue-600 py-2  rounded barlow px-2">{status} </span>
                                        :
                                        status === 'preparing cake'?
                                        <span className="uppercase bg-pink-200 text-pink-600 py-2  rounded barlow px-2">{status} </span>
                                            :
                                <span  class="uppercase bg-green-200 text-green-600 py-2  rounded barlow px-2" >{status}</span>
                 
                 
                                }
                            </td>
                           
                            <td className="  text-center par">
         <Link to={`/orders/${transactionId}`}>
                          
                          <button   className="ml-auto bg-blue-700  relative hover:text-primary flex items-center border h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none justify-center">
                                    <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-white">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </button>
                                </Link>

                            </td>
                        </tr>
                    </tbody>
                    );
                })
            }
                              
                                </table>
       </div>
       </div>
   </div>
   </div>
</div>
</div>
</div>
          }

{
                    orders.length === 0 &&
                    <div>
                          <div className='flex items-center justify-center flex-col mt-28 '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/Order%20food-amico.svg?alt=media&token=f075bbe5-0ae5-4057-947d-cfb0bae0ca5d" alt="No order" style={{width:"20%"}}/>
  <h1 className='fjalla text-center text-gray-900 mt-8 header' style={{fontSize:"20px"}}>We haven't received any orders from you


</h1>

</div>
                    </div>
                }
        </div>
        <div id='mobile'>
            <Nav/>

        
          {
            orders.length !=0 &&
            <div>       
            <div className="px-9 pt-5 flex justify-between  min-h-[70px] pb-0 bg-transparent w-full">
   <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
       </h3>
       <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
           <span className="mr-3 header text-yellow-800">Recent Orders</span>
       </h3>
  
       </div>
   <div className="flex  mb-5  ">
<div className="w-full max-w-full  mb-6  mx-auto">
   <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
   <div className="relative flex flex-col min-w-0 break-words  bg-clip-border rounded-2xl border-stone-200 bg-light/30">
   
       
       <div className="flex-auto block py-8  ">
       <div className="overflow-x-auto">
       <table className="w-full my-0 align-middle text-dark border-neutral-200">
                                <thead className="align-bottom ">
                                    <tr className="font-semibold text-[0.95rem] text-gray-800">
                                    <th className="pb-3 text-center  header  "></th>
            
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>Full Name</th>
                                    
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>Price</th>
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>Pickup Date</th>
                                    
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>STATUS</th>
                                    </tr>
                                </thead>
                                {
                orders.sort((a, b) => a.deliveryDate.valueOf() - b.deliveryDate.valueOf()).slice(0,10).map((i, index) => {
                    const { accountHolder, totalAmount,transactionId, cakes, time,status,deliveryDate } = i;
                    return (
                        <tbody key={index}>
                        <tr className=" last:border-b-0 mt-2 py-3 ">
                        <td className="  pl-0" >
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p style={{fontSize:'3vw'}}className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                                    </div>
                                </div>
                            </td>
                            <td className=" py-6 pl-0" style={{paddingLeft:'3vw'}}>
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p style={{fontSize:'3vw'}}className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {accountHolder} </p>
                                    </div>
                                </div>
                            </td>
                
                            <td className="  text-center par"style={{paddingLeft:'3vw'}}>
                                <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="barlow text-light-inverse text-md/normal">{formatNumber(totalAmount)} ETB</span>
                            </td>
                            <td className="  text-center par"style={{paddingLeft:'3vw'}}>
                                <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="barlow text-light-inverse text-md/normal">{getDateFromTimestamp(deliveryDate)} </span>
                            </td>
                            <td className="  text-center"style={{paddingLeft:'3vw'}}>
                                {
                                    status === 'pending'?
                                <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}} className="uppercase bg-yellow-200 text-yellow-600 py-2  rounded barlow ">{status} </span>
                                    :
                                    status === 'accepted'?
                                    <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}} className="uppercase bg-purple-200 text-purple-600 py-2  rounded barlow ">{status} </span>
                                   
                                    :
                                    status === 'declined'?
                                <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="uppercase bg-red-200 text-red-600 py-2  rounded barlow ">Reciept Declined </span>
                                    :
                                    status === 'ready for pickup'?
                                    <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="uppercase bg-blue-200 text-blue-600 py-2  rounded barlow ">{status} </span>
                                        :
                                        status === 'preparing cake'?
                                        <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="uppercase bg-pink-200 text-pink-600 py-2  rounded barlow ">{status} </span>
                                            :
                                <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}} class="uppercase bg-green-200 text-green-600 py-2  rounded barlow " >{status}</span>
                 
                 
                                }
                            </td>
                            <td className="  text-center par" style={{paddingLeft:'3vw'}}>
                            <Link to={`/orders/${transactionId}`}>

                          <button  style={{width:'6vw',height:'6vw'}}  className="ml-auto bg-blue-700  relative hover:text-primary flex items-center border  text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none justify-center">
                                    <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                                        <svg style={{width:'4vw',height:'4vw'}}xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-white">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </button>
                                </Link>
                            </td>
                     
                        </tr>
                    </tbody>
                    );
                })
            }
                              
                                </table>
       </div>
       </div>
   </div>
   </div>
</div>
</div>
</div>
          }

{
                    orders.length === 0 &&
                    <div>
                          <div className='flex items-center justify-center flex-col mt-28 '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/Order%20food-amico.svg?alt=media&token=f075bbe5-0ae5-4057-947d-cfb0bae0ca5d" alt="No order" style={{width:"20%"}}/>
  <h1 className='fjalla text-center text-gray-900 mt-8 header' style={{fontSize:"20px"}}>We haven't received any orders from you


</h1>

</div>
                    </div>
                }
        </div>
    </div>
  )
}
