import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebase';

const AdminProtected = ({ children }) => {
  const [user, setUser] = useState(null);
  const [email, setemail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setemail(user.email);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  if (loading) {
    return <div>Loading...</div>; // or any other loading indicator
  }

  if(email !== 'admin@admin.com'){
    return <Navigate to="/cakes" />;

  }

  // Render the children components if the user is authenticated
  return children;
};

export default AdminProtected;