import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, query, orderBy,where,doc, Timestamp } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';

export default function AdminDashboard() {
    const [cakes, setCakes] = useState([]);
    const [orders, setorders] = useState([]);
    const [activeOrders, setactiveOrders] = useState([]);
    const [stats, setstats] = useState([]);
    const [users, setusers] = useState([]);
    const [completedOrder, setcompletedOrder] = useState([]);
    const [selectedOrder, setselectedOrder] = useState();
    const [screenHeight, setScreenHeight] = useState(window.screen.height);
    const [pop,setpop] =useState(false)
    

    useEffect(() => {
      const handleResize = () => {
        setScreenHeight(window.screen.height);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    function navigationPop(){
        if(pop === true){
          setpop(false)
        }
        else{
          setpop(true)
        }
      }

    const navigate =useNavigate()




    const [isLoading, setisLoading] = useState(true);

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if(user){
                const cakeDocuments = collection(firestore, 'Cakes');
                const usersCollection = collection(firestore, 'Users');

                const stats = doc(firestore, 'stats','shewitPastry');

          
                const q = query(cakeDocuments, orderBy('postedTime'));
                const orderCollection = collection(firestore,'Orders')
                const que = query(orderCollection, where('status','not-in',['completed','declined']));
                const completedOrder = query(orderCollection, where('status','==','completed'));
                
                onSnapshot(orderCollection,snapshot=>{
                    let temp = []
                    snapshot.docs.forEach(doc=>{
                        temp.push({...doc.data(),id:doc.id})

                        setorders(temp)
                    })
                })

                onSnapshot(que,snapshot=>{
                    let temp = []
                    snapshot.docs.forEach(doc=>{
                        temp.push({...doc.data(),id:doc.id})

                        setactiveOrders(temp)
            

                    })
                })
                onSnapshot(completedOrder,snapshot=>{
                    let temp = []
                    snapshot.docs.forEach(doc=>{
                        temp.push({...doc.data(),id:doc.id})

                        setcompletedOrder(temp)
            

                    })
                })
                onSnapshot(usersCollection,snapshot=>{
                    let temp = []
                    snapshot.docs.forEach(doc=>{
                        temp.push({...doc.data(),id:doc.id})

                        setusers(temp)
            

                    })
                })
                onSnapshot(q,snapshot=>{
                    let temp = []
                    snapshot.docs.forEach(doc=>{
                        temp.push({...doc.data(),id:doc.id})

                        setCakes(temp)
                    })
                })

                onSnapshot(stats,snapshot=>{
                    let temp = []
                    temp.push({...snapshot.data()})
                    setstats(temp)
                    setisLoading(false)
                })

            } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);

      function getDateFromTimestamp(timestamp) {
        if (timestamp) {
          if (timestamp instanceof Timestamp) {
            return timestamp.toDate().toISOString().slice(0, 19).replace('T', ' ');
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
      function SelectedOrderPopset(i){
        // setorderSearch(false)
        // setfilteredOrder([])
        // setorderPop(true)
        setselectedOrder(i)
      }
      function formatNumber(num) {
        if(num){
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        };
      }

      function handleLogOut(){
        signOut(auth).then(
         navigate('/')
        )
       }
       function roundToTwoDecimalPlaces(num) {
        if(num){
          return Math.round(num * 100) / 100;
        }
      }
      
  return (
    isLoading === true?
    <div className="flex flex-col items-center justify-center w-full text-surface " style={{height:'100vw'}}>
    <div
      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
      role="status"></div>
    </div>
  
  :
    stats.map(i=>{
        const {totalRevenue} =i
        return(
        
            <div >
        
        <div id='desktop'>       
             <div class="min-h-screen">
  <aside class="bg-gray-900 adminNav  fixed inset-0 z-50 my-4 ml-4  rounded-xl transition-transform duration-300 xl:translate-x-0">
    <div class="relative border-b border-white/20">
      <div class="flex items-center gap-4 py-6 px-8" >
        <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
      </div>
   
    </div>
    <div class="m-4">
      <ul class="mb-4 flex flex-col gap-1">
        <li>
        <Link to='/admin'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center gap-4 px-4 capitalize" type="button">
            
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/orders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/allorders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
              
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/addcake'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/managecakes'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/messages'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/reserved'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Reserved Students</p>
            </button>
          </Link>
        </li>
      </ul>
      <ul class="mb-4 flex flex-col gap-1">
        <li class="mx-3.5 mt-4 mb-2">
          <p class="barlow block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
        </li>
    
        <li>
            <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
            
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
            </button>
        </li>
      </ul>
    </div>
  </aside>
  <div class="p-4  adminBody px-8">
    <nav class="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-0 py-1">
      <div class="flex flex-col-reverse justify-between gap-6 md:flex-row md:items-center">
        <div class="capitalize">
    
          <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-900 barlow" style={{fontSize:'25px'}}>Dashboard</h6>
        </div>
     
      </div>
    </nav>
    <div class="mt-12">
      <div class="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="w-6 h-6 text-white">
              <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"></path>
              <path fill-rule="evenodd" d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z" clip-rule="evenodd"></path>
              <path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z"></path>
            </svg>
          </div>
          <div class="p-4 text-right">
            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900 ">{totalRevenue === 0 ? 0 :formatNumber(roundToTwoDecimalPlaces(totalRevenue))} ETB</h4>
          </div>
          <div class="border-t border-blue-gray-50 p-4">
            <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
              <strong class="text-green-700 barlow" style={{fontSize:'20px'}}>Total Revenue</strong>&nbsp;
            </p>
          </div>
        </div>
        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-pink-600 to-pink-400 text-white shadow-pink-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="w-6 h-6 text-white">
              <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clip-rule="evenodd"></path>
            </svg>
          </div>
          <div class="p-4 text-right">
            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{users.length}</h4>
          </div>
          <div class="border-t border-blue-gray-50 p-4">
            <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
            <strong class="text-green-700 barlow" style={{fontSize:'20px'}}>Total Customers</strong>&nbsp;

            </p>
          </div>
        </div>
        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-green-600 to-green-400 text-white shadow-green-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="w-6 h-6 text-white">
              <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z"></path>
            </svg>
          </div>
          <div class="p-4 text-right">
            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{orders.length}</h4>
          </div>
          <div class="border-t border-blue-gray-50 p-4">
            <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
            <strong class="text-green-700 barlow" style={{fontSize:'20px'}}>Total Orders</strong>&nbsp;

            </p>
          </div>
        </div>
        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-orange-600 to-orange-400 text-white shadow-orange-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="w-6 h-6 text-white">
              <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z"></path>
            </svg>
          </div>
          <div class="p-4 text-right">
            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{activeOrders.length}</h4>
          </div>
          <div class="border-t border-blue-gray-50 p-4">
            <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
            <strong class="text-red-500 barlow" style={{fontSize:'20px'}}>Active Orders</strong>&nbsp;

            </p>
          </div>
        </div>
      </div>
      
      <div class="">
        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-hidden xl:col-span-2">
         
          {
                    completedOrder.length !==0 &&
                    <div>
 <div className="px-9 pt-5 flex justify-between  min-h-[70px] pb-0 bg-transparent w-full">
                <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                    <span className="mr-3 header">Recent Completed Orders</span>
                </h3>
                {/* <button onClick={orderSearchLookup}  style={{fontSize:"12px",width:'15%'}} class="flex justify-center ml-2 w-full px-5 py-3  mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
          
          Find Order
        </button> */}
                </div>
                    <div className="flex  mb-5  ">
                    <div className="w-full max-w-full  mb-6  mx-auto">
                        <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
                        <div className="relative flex flex-col min-w-0 break-words  bg-clip-border rounded-2xl border-stone-200 bg-light/30">
                        
                            
                            <div className="flex-auto block py-8  ">
                            <div className="overflow-x-auto">
                                <table className="w-full my-0 align-middle text-dark border-neutral-200">
                                <thead className="align-bottom ">
                                    <tr className="font-semibold text-[0.95rem] text-gray-800">
                                    <th className="pb-3 text-center min-w-[175px] header pr-12 "></th>
            
                                    <th className="pb-3 text-center min-w-[175px] header pr-12 ">Full Name</th>
                                    <th className="pb-3 text-center min-w-[100px] header pr-12">Cake</th>
                                    <th className="pb-3 text-center min-w-[100px] header pr-12">Price</th>
                                    <th className="pb-3 text-center min-w-[175px] header pr-12">STATUS</th>
                                    <th className="pb-3 text-center min-w-[100px] header pr-12">Time</th>
                                    </tr>
                                </thead>
                                {
                completedOrder.sort((b, a) => a.completedTime.valueOf() - b.completedTime.valueOf()).slice(0,10).map((i, index) => {
                    const { accountHolder, totalAmount, cakes, completedTime,status } = i;
                    return (
                        <tbody key={index}>
                        <tr className=" last:border-b-0 mt-6 py-3 ">
                        <td className="pr-12 py-8 pl-0">
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                                    </div>
                                </div>
                            </td>
                            <td className="pr-12 py-8 pl-0">
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {accountHolder} </p>
                                    </div>
                                </div>
                            </td>
                            <td className=" pr-12 text-center ">
                                <span className="barlow text-light-inverse text-md/normal">
                                    {cakes.map((cake, cakeIndex) => {
                                        return (
                                            <React.Fragment key={cakeIndex}>
                                                {cake.cakeData.cakeTitle}
                                                {cakeIndex < cakes.length - 1 ? ', ' : ''}
                                            </React.Fragment>
                                        );
                                    })}
                                </span>
                            </td>
                            <td className=" pr-12 text-center par">
                                <span className="barlow text-light-inverse text-md/normal">{formatNumber(totalAmount)} ETB</span>
                            </td>
                            <td className=" pr-12 text-center">
                                {
                                    status === 'pending'?
                                <span className="uppercase bg-yellow-200 text-yellow-600 py-2  rounded barlow px-2">{status} </span>
                                    :
                                    status === 'accepted'?
                                    <span className="uppercase bg-purple-200 text-purple-600 py-2  rounded barlow px-2">{status} </span>
                                   
                                    :
                                    status === 'declined'?
                                <span className="uppercase bg-red-200 text-red-600 py-2  rounded barlow px-2">Reciept Declined </span>
                                    :
                                    status === 'ready for pickup'?
                                    <span className="uppercase bg-blue-200 text-blue-600 py-2  rounded barlow px-2">{status} </span>
                                        :
                                        status === 'preparing cake'?
                                        <span className="uppercase bg-pink-200 text-pink-600 py-2  rounded barlow px-2">{status} </span>
                                            :
                                <span  class="uppercase bg-green-200 text-green-600 py-2  rounded barlow px-2" >{status}</span>
                 
                 
                                }
                            </td>
                            <td className="pr-12 text-center">
                                <span className="barlow font-semibold text-light-inverse text-md/normal par text-center">{getDateFromTimestamp(completedTime)}</span>
                            </td>
                            
                        </tr>
                    </tbody>
                    );
                })
            }
                              
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    </div>

                }
        </div>
      </div>
    </div>
  
  </div>
</div>
    
            </div>
                   
        <div id='tablet'>       
             <div >
                {
                    pop === true ?
                    <aside style={{height:screenHeight}}class="bg-gray-900     transition-transform duration-300 xl:translate-x-0">
                    <div class="relative border-b border-white/20">
    <svg onClick={navigationPop} aria-hidden="true" class="w-8 h-8 text-white absolute top-5 right-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                     
                      <div class="flex justify-center items-center  py-6 px-2" >
                        <h6 class="text-center block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
                      </div>
                   
                    </div>
                    <div class="my-4">
                      <ul class="m-4 flex flex-col ">
                        <li>
                        <Link to='/admin'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center  pl-2" type="button">
                            
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/orders'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/allorders'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                              
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/addcake'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/managecakes'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/messages'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/reserved'>
                
                        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                             <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Students</p>
                           </button>
                          </Link>
                        </li>
                      </ul>
                      <ul class="m-4 flex flex-col">
                        <li class=" mt-4 mb-2">
                          <p class="par block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
                        </li>
                    
                        <li>
                            <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                            
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
                            </button>
                        </li>
                      </ul>
                    </div>
                  </aside>
                  :
                  <div class="p-4   px-8">
                  <nav class="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-0 py-1">
                    <div class="flex flex-col  gap-6 ">
                    
                      <div class="flex justify-between w-full items-center mb-4">
        <img style={{width:'18%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />

        <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
              </button>
              </div>  <div class="capitalize">
                  
                  <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-900 barlow" style={{fontSize:'25px'}}>Dashboard</h6>
                </div>
                    </div>
                  </nav>
                  <div class="mt-12">
                    <div class="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
                      <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                        <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="w-6 h-6 text-white">
                            <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"></path>
                            <path fill-rule="evenodd" d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z" clip-rule="evenodd"></path>
                            <path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z"></path>
                          </svg>
                        </div>
                        <div class="p-4 text-right">
                          <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900 ">{totalRevenue === 0 ? 0 :formatNumber(roundToTwoDecimalPlaces(totalRevenue))} ETB</h4>
                        </div>
                        <div class=" border-blue-gray-50 p-4">
                          <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                            <strong class="text-green-700 barlow" style={{fontSize:'15px'}}>Total Revenue</strong>&nbsp;
                          </p>
                        </div>
                      </div>
                      <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                        <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-pink-600 to-pink-400 text-white shadow-pink-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="w-6 h-6 text-white">
                            <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clip-rule="evenodd"></path>
                          </svg>
                        </div>
                        <div class="p-4 text-right">
                          <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{users.length}</h4>
                        </div>
                        <div class=" border-blue-gray-50 p-4">
                          <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                          <strong class="text-green-700 barlow" style={{fontSize:'15px'}}>Total Customers</strong>&nbsp;
              
                          </p>
                        </div>
                      </div>
                      <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                        <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-green-600 to-green-400 text-white shadow-green-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="w-6 h-6 text-white">
                            <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z"></path>
                          </svg>
                        </div>
                        <div class="p-4 text-right">
                          <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{orders.length}</h4>
                        </div>
                        <div class=" border-blue-gray-50 p-4">
                          <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                          <strong class="text-green-700 barlow" style={{fontSize:'15px'}}>Total Orders</strong>&nbsp;
              
                          </p>
                        </div>
                      </div>
                      <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                        <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-orange-600 to-orange-400 text-white shadow-orange-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="w-6 h-6 text-white">
                            <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z"></path>
                          </svg>
                        </div>
                        <div class="p-4 text-right">
                          <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{activeOrders.length}</h4>
                        </div>
                        <div class=" border-blue-gray-50 p-4">
                          <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                          <strong class="text-red-500 barlow" style={{fontSize:'15px'}}>Active Orders</strong>&nbsp;
              
                          </p>
                        </div>
                      </div>
                    </div>
                    
                    <div class="">
                      <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-hidden xl:col-span-2">
                       
                        {
                                  completedOrder.length !==0 &&
                                  <div>
               <div className="px-9 pt-5 flex justify-between  min-h-[70px] pb-0 bg-transparent w-full">
                              <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                                  <span className="mr-3 header">Recent Completed Orders</span>
                              </h3>
                              {/* <button onClick={orderSearchLookup}  style={{fontSize:"12px",width:'15%'}} class="flex justify-center ml-2 w-full px-5 py-3  mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                        
                        Find Order
                      </button> */}
                              </div>
                                  <div className="flex  mb-5  ">
                                  <div className="w-full max-w-full  mb-6  mx-auto">
                                      <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
                                      <div className="relative flex flex-col min-w-0 break-words  bg-clip-border rounded-2xl border-stone-200 bg-light/30">
                                      
                                          
                                          <div className="flex-auto block py-8  ">
                                          <div className="overflow-x-auto">
                                              <table className="w-full my-0 align-middle text-dark border-neutral-200">
                                              <thead className="align-bottom ">
                                                  <tr className="font-semibold text-[0.95rem] text-gray-800">
                                                  <th className="pb-3 text-center  header  "></th>
                          
                                                  <th className="pb-3 text-center  header  ">Full Name</th>
                                                  <th className="pb-3 text-center  header ">Cake</th>
                                                  <th className="pb-3 text-center  header ">Price</th>
                                                  <th className="pb-3 text-center  header ">STATUS</th>
                                                  </tr>
                                              </thead>
                                              {
                              completedOrder.sort((b, a) => a.completedTime.valueOf() - b.completedTime.valueOf()).slice(0,10).map((i, index) => {
                                  const { accountHolder, totalAmount, cakes, time,status } = i;
                                  return (
                                      <tbody key={index}>
                                      <tr className=" last:border-b-0 mt-6 py-3 ">
                                      <td className=" py-8 pl-0">
                                              <div className="flex items-center justify-center">
                                                  <div className="flex flex-col justify-center">
                                                      <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                                                  </div>
                                              </div>
                                          </td>
                                          <td className=" py-8 pl-0">
                                              <div className="flex items-center justify-center">
                                                  <div className="flex flex-col justify-center">
                                                      <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {accountHolder} </p>
                                                  </div>
                                              </div>
                                          </td>
                                          <td className="  text-center ">
                                              <span className="barlow text-light-inverse text-md/normal">
                                                  {cakes.map((cake, cakeIndex) => {
                                                      return (
                                                          <React.Fragment key={cakeIndex}>
                                                              {cake.cakeData.cakeTitle}
                                                              {cakeIndex < cakes.length - 1 ? ', ' : ''}
                                                          </React.Fragment>
                                                      );
                                                  })}
                                              </span>
                                          </td>
                                          <td className="  text-center par">
                                              <span className="barlow text-light-inverse text-md/normal">{formatNumber(totalAmount)} ETB</span>
                                          </td>
                                          <td className="  text-center">
                                              {
                                                  status === 'pending'?
                                              <span className="uppercase bg-yellow-200 text-yellow-600 py-2  rounded barlow px-2">{status} </span>
                                                  :
                                                  status === 'accepted'?
                                                  <span className="uppercase bg-purple-200 text-purple-600 py-2  rounded barlow px-2">{status} </span>
                                                 
                                                  :
                                                  status === 'declined'?
                                              <span className="uppercase bg-red-200 text-red-600 py-2  rounded barlow px-2">Reciept Declined </span>
                                                  :
                                                  status === 'ready for pickup'?
                                                  <span className="uppercase bg-blue-200 text-blue-600 py-2  rounded barlow px-2">{status} </span>
                                                      :
                                                      status === 'preparing cake'?
                                                      <span className="uppercase bg-pink-200 text-pink-600 py-2  rounded barlow px-2">{status} </span>
                                                          :
                                              <span  class="uppercase bg-green-200 text-green-600 py-2  rounded barlow px-2" >{status}</span>
                               
                               
                                              }
                                          </td>
                                         
                                   
                                      </tr>
                                  </tbody>
                                  );
                              })
                          }
                                            
                                              </table>
                                          </div>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  </div>
                                  </div>
              
                              }
                      </div>
                    </div>
                  </div>
                
                </div>
                }
 
 
</div>
    
            </div>
            <div id='mobile'>       
             <div >
{
    pop === true ?
    <aside style={{height:screenHeight}}class=" bg-gray-900 50 my-4 ml-4  rounded-xl transition-transform duration-300 xl:translate-x-0">
    <div class="relative border-b border-white/20">
    <svg onClick={navigationPop} aria-hidden="true" class="w-8 h-8 text-white absolute top-5 right-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

      <div class="flex justify-center items-center  py-6 px-2" >
        <h6 class="text-center block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
      </div>
   
    </div>
    <div class="my-4">
      <ul class="m-4 flex flex-col ">
        <li>
        <Link to='/admin'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center  pl-2" type="button">
            
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/orders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
             
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/allorders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
              
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/addcake'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
             
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/managecakes'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
             
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/messages'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
             
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/reserved'>

        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
             
             <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Students</p>
           </button>
          </Link>
        </li>
      </ul>
      <ul class="m-4 flex flex-col">
        <li class=" mt-4 mb-2">
          <p class="par block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
        </li>
    
        <li>
            <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
            
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
            </button>
        </li>
      </ul>
    </div>
  </aside>:
  <div class="p-4  w-full px-8">
    <nav class="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-0 py-1">
      <div class="flex flex-col-reverse justify-between gap-6 md:flex-row md:items-center">
        <div class="capitalize">
    
          <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-900 barlow" style={{fontSize:'25px'}}>Dashboard</h6>
        </div>
        <div class="flex justify-between w-full items-center mb-4">
        <img style={{width:'18%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />

        <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
              </button>
              </div>

      </div>
    </nav>
    <div class="mt-12">
      <div class="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute -mt-4 grid  place-items-center"style={{height:'12vw',width:'12vw'}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" style={{width:'6vw',height:'6vw'}} class=" text-white">
              <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"></path>
              <path fill-rule="evenodd" d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z" clip-rule="evenodd"></path>
              <path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z"></path>
            </svg>
          </div>
          <div class="p-4 text-right">
            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900 "style={{fontSize:'4vw'}}>{totalRevenue === 0 ? 0 :formatNumber(roundToTwoDecimalPlaces(totalRevenue))} ETB</h4>
          </div>
          <div class=" border-blue-gray-50 p-4">
            <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
              <strong class="text-green-700 barlow" style={{fontSize:'3.5vw'}}>Total Revenue</strong>&nbsp;
            </p>
          </div>
        </div>
        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-pink-600 to-pink-400 text-white shadow-pink-500/40 shadow-lg absolute -mt-4 grid  place-items-center" style={{height:'12vw',width:'12vw'}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" style={{width:'6vw',height:'6vw'}} class=" text-white">
              <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clip-rule="evenodd"></path>
            </svg>
          </div>
          <div class="p-4 text-right">
            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900"style={{fontSize:'4vw'}}>{users.length}</h4>
          </div>
          <div class=" border-blue-gray-50 p-4">
            <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
            <strong class="text-green-700 barlow" style={{fontSize:'3.5vw'}}>Total Customers</strong>&nbsp;

            </p>
          </div>
        </div>
        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-green-600 to-green-400 text-white shadow-green-500/40 shadow-lg absolute -mt-4 grid  place-items-center" style={{height:'12vw',width:'12vw'}}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" style={{width:'6vw',height:'6vw'}} class=" text-white">
              <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z"></path>
            </svg>
          </div>
          <div class="p-4 text-right">
            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900"style={{fontSize:'4vw'}}>{orders.length}</h4>
          </div>
          <div class=" border-blue-gray-50 p-4">
            <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
            <strong class="text-green-700 barlow" style={{fontSize:'3.5vw'}}>Total Orders</strong>&nbsp;

            </p>
          </div>
        </div>
        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-orange-600 to-orange-400 text-white shadow-orange-500/40 shadow-lg absolute -mt-4 grid  place-items-center"style={{height:'12vw',width:'12vw'}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" style={{width:'6vw',height:'6vw'}} class=" text-white">
              <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z"></path>
            </svg>
          </div>
          <div class="p-4 text-right">
            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900"style={{fontSize:'4vw'}}>{activeOrders.length}</h4>
          </div>
          <div class=" border-blue-gray-50 p-4">
            <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
            <strong class="text-red-500 barlow" style={{fontSize:'3.5vw'}}>Active Orders</strong>&nbsp;

            </p>
          </div>
        </div>
      </div>
      
      <div class="">
        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-hidden xl:col-span-2">
         
          {
                    completedOrder.length !==0 &&
                    <div>
 <div className="pl-9 pt-5 flex justify-between  min-h-[70px] pb-0 bg-transparent w-full">
                <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                    <span className="mr-3 header" style={{fontSize:'4vw'}}>Recent Completed Orders</span>
                </h3>
                {/* <button onClick={orderSearchLookup}  style={{fontSize:"12px",width:'15%'}} class="flex justify-center ml-2 w-full px-5 py-3  mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
          
          Find Order
        </button> */}
                </div>
                <div className="flex  mb-5  ">
                    <div className="w-full max-w-full  mb-6  mx-auto">
                        <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
                        <div className="relative flex flex-col min-w-0 break-words  bg-clip-border rounded-2xl border-stone-200 bg-light/30">
                        
                            
                            <div className="flex-auto block py-8  ">
                            <div className="overflow-x-auto">
                                <table className="w-full my-0 align-middle text-dark border-neutral-200">
                                <thead className="align-bottom ">
                                    <tr className="font-semibold text-[0.95rem] text-gray-800">
                                    <th className="pb-3 text-center  header  "></th>
            
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>Full Name</th>
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>Cake</th>
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>Price</th>
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>STATUS</th>
                                    </tr>
                                </thead>
                                {
                completedOrder.sort((b, a) => a.completedTime.valueOf() - b.completedTime.valueOf()).slice(0,10).map((i, index) => {
                    const { accountHolder, totalAmount, cakes, time,status } = i;
                    return (
                        <tbody key={index}>
                        <tr className=" last:border-b-0 mt-2 py-3 ">
                        <td className="  pl-0">
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p style={{fontSize:'3vw'}}className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                                    </div>
                                </div>
                            </td>
                            <td className=" py-6 pl-0">
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p style={{fontSize:'3vw'}}className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {accountHolder} </p>
                                    </div>
                                </div>
                            </td>
                            <td className="  text-center ">
                                <span style={{fontSize:'3vw'}}className="barlow text-light-inverse text-md/normal">
                                    {cakes.map((cake, cakeIndex) => {
                                        return (
                                            <React.Fragment key={cakeIndex}>
                                                {cake.cakeData.cakeTitle}
                                                {cakeIndex < cakes.length - 1 ? <br/> : ''}
                                            </React.Fragment>
                                        );
                                    })}
                                </span>
                            </td>
                            <td className="  text-center par">
                                <span style={{fontSize:'2.5vw',padding:'2vw 1.5vw'}}className="barlow text-light-inverse text-md/normal">{formatNumber(totalAmount)} ETB</span>
                            </td>
                            <td className="  text-center">
                                {
                                    status === 'pending'?
                                <span style={{fontSize:'2.5vw',padding:'2vw 1.5vw'}} className="uppercase bg-yellow-200 text-yellow-600 py-2  rounded barlow ">{status} </span>
                                    :
                                    status === 'accepted'?
                                    <span style={{fontSize:'2.5vw',padding:'2vw 1.5vw'}} className="uppercase bg-purple-200 text-purple-600 py-2  rounded barlow ">{status} </span>
                                   
                                    :
                                    status === 'declined'?
                                <span style={{fontSize:'2.5vw',padding:'2vw 1.5vw'}}className="uppercase bg-red-200 text-red-600 py-2  rounded barlow ">Reciept Declined </span>
                                    :
                                    status === 'ready for pickup'?
                                    <span style={{fontSize:'2.5vw',padding:'2vw 1.5vw'}}className="uppercase bg-blue-200 text-blue-600 py-2  rounded barlow ">{status} </span>
                                        :
                                        status === 'preparing cake'?
                                        <span style={{fontSize:'2.5vw',padding:'2vw 1.5vw'}}className="uppercase bg-pink-200 text-pink-600 py-2  rounded barlow ">{status} </span>
                                            :
                                <span style={{fontSize:'2.5vw',padding:'2vw 1.5vw'}} class="uppercase bg-green-200 text-green-600 py-2  rounded barlow " >{status}</span>
                 
                 
                                }
                            </td>
                           
                     
                        </tr>
                    </tbody>
                    );
                })
            }
                              
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    </div>

                }
        </div>
      </div>
    </div>
  
  </div>

}
  
</div>
    
            </div>
    
    
        </div>
        )

    })
 
  )
}
