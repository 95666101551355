import { useState, useEffect } from 'react';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

function ManageCakes() {
  const [cakes, setCakes] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [currentIndexes, setCurrentIndexes] = useState({});
  const [limit, setlimit] = useState('');
  const [search, setsearch] = useState('');

  const navigate = useNavigate()

  const [screenHeight, setScreenHeight] = useState(window.screen.height);
  const [pop,setpop] =useState(false)

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.screen.height);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function navigationPop(){
    if(pop === true){
      setpop(false)
    }
    else{
      setpop(true)
    }
  }
  useEffect(() => {
    const fetchCakes = async () => {
      const document = collection(firestore, 'Cakes');
      const q = query(document, orderBy('postedTime'));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        let temp = [];
        snapshot.docs.forEach((doc) => {
          temp.push({ ...doc.data(), id: doc.id });
        });
        setCakes(temp);
        setisLoading(false);
      }, (error) => {
        console.error('Error fetching cakes:', error);
        setisLoading(false);
      });

      // Clean up the listener when the component unmounts
      return unsubscribe;
    };

    fetchCakes();
  }, []);
  useEffect(() => {
    // Initialize currentIndexes state with the first index for each cake
    const initialIndexes = cakes.reduce((acc, cake) => {
      acc[cake.id] = 0;
      return acc;
    }, {});
    setCurrentIndexes(initialIndexes);
  }, [cakes]);
  
  const handlePrevious = (cakePhotos, id) => {
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [id]: (prevIndexes[id] === 0 ? cakePhotos.length - 1 : prevIndexes[id] - 1),
    }));
  };
  
  const handleNext = (cakePhotos, id) => {
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [id]: (prevIndexes[id] === cakePhotos.length - 1 ? 0 : prevIndexes[id] + 1),
    }));
  };
  function handleLogOut(){
    signOut(auth).then(
     navigate('/')
    )
   }
  if (isLoading) {
    return <div className='adminBody'>
        <div>
    
 

    <div className="flex flex-col items-center justify-center w-full text-surface " style={{height:'50vw'}}>
    <div
      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
      role="status"></div>
  
    </div>
  
    
  
  </div>
    </div>;
  }

  return (
    isLoading === true?
    <div className="flex flex-col items-center justify-center w-full text-surface " style={{height:'100vw'}}>
    <div
      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
      role="status"></div>

  
    
  
  </div>
  :
    <div>
      <div id="desktop">
        <div className="flex">
        <aside class="bg-gray-900 adminNav  fixed inset-0 z-50 my-4 ml-4  rounded-xl transition-transform duration-300 xl:translate-x-0">
    <div class="relative border-b border-white/20">
      <div class="flex items-center gap-4 py-6 px-8" >
        <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
      </div>
   
    </div>
    <div class="m-4">
      <ul class="mb-4 flex flex-col gap-1">
        <li>
        <Link to='/admin'>

        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
             <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
           </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/orders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/allorders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
              
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/addcake'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/managecakes'>
        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center gap-4 px-4 capitalize" type="button">
            
            <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
          </button>
       
          </Link>
        </li>
        <li>
        <Link to='/admin/messages'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/reserved'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Reserved Students</p>
            </button>
          </Link>
        </li>
      </ul>
      <ul class="mb-4 flex flex-col gap-1">
        <li class="mx-3.5 mt-4 mb-2">
          <p class="barlow block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
        </li>
    
        <li>
            <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
            
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
            </button>
        </li>
      </ul>
    </div>
  </aside>{
    cakes.length === 0 ?
    <div className='flex items-center justify-center flex-col adminBody '>
        <img style={{width:'10%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />
  <h1 className='fjalla text-center text-gray-900 mt-8 par' style={{fontSize:"20px"}}>You haven't posted any cakes yet

<Link to={'/admin/addcake'}>
<button
                   type='button'
                   style={{fontSize:'14px',background:'#FF1925',height:'53px'}}
                   className='par mt-12  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                 >
                   Post Cakes
                 </button></Link>
</h1>

</div>
:
<div className='adminBody px-8'>
{/* <div className="relative mt-3 mb-5" style={{width:'20%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Sale Limit</p>
<input style={{fontSize:'12px',height:'60px'}} onChange={e=>setlimit(e.target.value)}  required  autoComplete="off" placeholder="50" type="number" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />


</div> */}
 <div className=" pt-5 pl-3 flex justify-between  min-h-[70px] pb-0 bg-transparent w-full">
                <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                    <span className="mr-3 barlow" style={{fontSize:'25px'}}>Manage Cakes</span>
                </h3>
                </div>
<label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only ">Search</label>
{/* <div class="relative mx-auto mb-20" style={{width:'40%'}}>
  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
      <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
      </svg>
  </div>
  <input type="search" id="default-search" onChange={(e)=>setsearch(e.target.value)} style={{height:'55px'}}  class=" block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
</div>   */}

<div className=" cakeGrid ">
            {cakes.map((cake) => (
              <div key={cake.id}>
                <CakeGallery
                  cake={cake}
                  currentIndex={currentIndexes[cake.id] || 0}
                  handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                  handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                />
              </div>
            ))}
          </div>
          </div>
  }
          
        </div>
      </div>
      <div id="tablet">
        {
              pop === true ?
              <aside style={{height:screenHeight}}class="bg-gray-900     transition-transform duration-300 xl:translate-x-0">
              <div class="relative border-b border-white/20">
<svg onClick={navigationPop} aria-hidden="true" class="w-8 h-8 text-white absolute top-5 right-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
               
                <div class="flex justify-center items-center  py-6 px-2" >
                  <h6 class="text-center block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
                </div>
             
              </div>
              <div class="my-4">
                <ul class="m-4 flex flex-col ">
                  <li>
                  <Link to='/admin'>
                  <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                       
                       <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
                     </button>
                     
                    </Link>
                  </li>
                  <li>
                  <Link to='/admin/orders'>
          
                      <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                       
                        <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
                      </button>
                    </Link>
                  </li>
                  <li>
                  <Link to='/admin/allorders'>
          
                      <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                        
                        <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
                      </button>
                    </Link>
                  </li>
                  <li>
                  <Link to='/admin/addcake'>
          
                      <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                       
                        <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
                      </button>
                    </Link>
                  </li>
                  <li>
                  <Link to='/admin/managecakes'>
                  <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center  pl-2" type="button">
                      
                      <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
                    </button>
                
                    </Link>
                  </li>
                  <li>
                  <Link to='/admin/messages'>
          
                      <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                       
                        <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
                      </button>
                    </Link>
                  </li>
                  <li>
                  <Link to='/admin/reserved'>
          
                  <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                       
                       <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Students</p>
                     </button>
                    </Link>
                  </li>
                </ul>
                <ul class="m-4 flex flex-col">
                  <li class=" mt-4 mb-2">
                    <p class="par block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
                  </li>
              
                  <li>
                      <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                      
                        <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
                      </button>
                  </li>
                </ul>
              </div>
            </aside>
            :
        <div >
       {
    cakes.length === 0 ?
    <div className='flex items-center justify-center flex-col px-4'>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Cake" style={{width:"25%"}}/>
  <h1 className='fjalla text-center text-gray-900 mt-8 par' style={{fontSize:"20px"}}>You haven't posted any cakes yet
<Link to={'/admin/addcake'}>
<button
                   type='button'
                   style={{fontSize:'14px',background:'#FF1925',height:'53px'}}
                   className='par mt-12  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                 >
                   Post Cakes
                 </button></Link>
</h1>

</div>
:
<div className=''>
                  <nav class="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-8 py-3">
                    <div class="flex flex-col  gap-6 ">
                    
                      <div class="flex justify-between w-full items-center mb-4">
                      <Link to='/admin'style={{width:'18%'}}>
        <img style={{width:'100%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />
</Link>
        <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
              </button>
              </div> 
                    </div>
                  </nav>

 <div className="px-9 pt-5 flex justify-between  min-h-[70px] pb-0 bg-transparent w-full">
                <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                    <span className="mr-3 barlow" style={{fontSize:'25px'}}>Manage Cakes</span>
                </h3>
                </div>
<label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only ">Search</label>
{/* <div class="relative mx-auto mb-20" style={{width:'40%'}}>
  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
      <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
      </svg>
  </div>
  <input type="search" id="default-search" onChange={(e)=>setsearch(e.target.value)} style={{height:'55px'}}  class=" block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
</div>   */}

<div className=" cakeGrid ">
            {cakes.map((cake) => (
              <div key={cake.id}>
                <CakeGallery
                  cake={cake}
                  currentIndex={currentIndexes[cake.id] || 0}
                  handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                  handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                />
              </div>
            ))}
          </div>
          </div>
  }
          
        </div>
        }
      </div>
      <div id="mobile">
      <nav class="block w-full px-8 max-w-full bg-transparent text-white shadow-none rounded-xl transition-all  py-5">
                    <div class="flex flex-col  gap-6 ">
                    
                      <div class="flex justify-between w-full items-center mb-4">
                      <Link to='/admin'style={{width:'18%'}}>
        <img style={{width:'100%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />
</Link>
        <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
              </button>
              </div> 
                    </div>
                  </nav>
        {
              pop === true ?
              <aside style={{height:screenHeight}}class="bg-gray-900     transition-transform duration-300 xl:translate-x-0">
              <div class="relative border-b border-white/20">
<svg onClick={navigationPop} aria-hidden="true" class="w-8 h-8 text-white absolute top-5 right-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
               
                <div class="flex justify-center items-center  py-6 px-2" >
                  <h6 class="text-center block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
                </div>
             
              </div>
              <div class="my-4">
                <ul class="m-4 flex flex-col ">
                  <li>
                  <Link to='/admin'>
                  <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                       
                       <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
                     </button>
                     
                    </Link>
                  </li>
                  <li>
                  <Link to='/admin/orders'>
          
                      <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                       
                        <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
                      </button>
                    </Link>
                  </li>
                  <li>
                  <Link to='/admin/allorders'>
          
                      <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                        
                        <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
                      </button>
                    </Link>
                  </li>
                  <li>
                  <Link to='/admin/addcake'>
          
                      <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                       
                        <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
                      </button>
                    </Link>
                  </li>
                  <li>
                  <Link to='/admin/managecakes'>
                  <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center  pl-2" type="button">
                      
                      <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
                    </button>
                
                    </Link>
                  </li>
                  <li>
                  <Link to='/admin/messages'>
          
                      <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                       
                        <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
                      </button>
                    </Link>
                  </li>
                  <li>
                  <Link to='/admin/reserved'>
          
                  <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                       
                       <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Students</p>
                     </button>
                    </Link>
                  </li>
                </ul>
                <ul class="m-4 flex flex-col">
                  <li class=" mt-4 mb-2">
                    <p class="par block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
                  </li>
              
                  <li>
                      <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                      
                        <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
                      </button>
                  </li>
                </ul>
              </div>
            </aside>
            :
        <div >
       {
    cakes.length === 0 ?
    <div className='flex items-center justify-center flex-col px-4'>
  <h1 className='fjalla text-center text-gray-900 mt-8 par' style={{fontSize:"5vw"}}>You haven't posted any cakes yet

<Link to={'/admin/addcake'}>
<button
                   type='button'
                   style={{fontSize:'14px',background:'#FF1925',height:'53px'}}
                   className='par mt-12  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                 >
                   Post Cakes
                 </button></Link>
</h1>

</div>
:
<div class="">
                 
{/* <div className="relative mt-3 mb-5" style={{width:'20%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Sale Limit</p>
<input style={{fontSize:'12px',height:'60px'}} onChange={e=>setlimit(e.target.value)}  required  autoComplete="off" placeholder="50" type="number" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />


</div> */}
 <div className="px-9 pt-5 flex justify-between  min-h-[70px] pb-0 bg-transparent w-full">
                <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                    <span className="mr-3 barlow" style={{fontSize:'25px'}}>Manage Cakes</span>
                </h3>
                </div>
<label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only ">Search</label>
{/* <div class="relative mx-auto mb-20" style={{width:'40%'}}>
  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
      <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
      </svg>
  </div>
  <input type="search" id="default-search" onChange={(e)=>setsearch(e.target.value)} style={{height:'55px'}}  class=" block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
</div>   */}

<div className=" cakeGrid ">
            {cakes.map((cake) => (
              <div key={cake.id}>
                <CakeGallery
                  cake={cake}
                  currentIndex={currentIndexes[cake.id] || 0}
                  handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                  handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                />
              </div>
            ))}
          </div>
          </div>
  }
          
        </div>
        }
      </div>
    </div>
  );
}

function CakeGallery({ cake, currentIndex, handlePrevious, handleNext }) {
  return (

    <div>
          <div id='mobile'>

<div id="gallery" className="relative w-full  " data-carousel="slide">
  <div className="relative overflow-hidden rounded-lg">
    <div className="relative overflow-hidden rounded-lg shadow-xl">
      <div className="duration-700 ease-in-out " data-carousel-item>
        {cake.cakePhotos.map((photo, index) => (
          <img
            key={index}
            src={photo.cakePhotos}
            alt={`Selected file ${index}`}
            style={{ height: '100%', objectFit: 'cover' }}
            className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
          />
        ))}
        {cake.cakePhotos.length === 0 && (
          <img
            className="h-auto w-full rounded-lg"
            style={{ height: '300px', objectFit: 'cover' }}
            src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
            alt="description"
          />
        )}
      </div>

      {cake.cakePhotos.length >= 2 && (
           <button
           type="button"
         style={{padding:'2vw 0vw'}}

           className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
           data-carousel-prev
         >
         <span onClick={() => handlePrevious(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">

             <svg
               style={{width:'4vw',height:'4vw'}}
               className=" text-gray-800 dark:text-gray-800 rtl:rotate-180"
               aria-hidden="true"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 6 10"
             >
               <path
                 stroke="currentColor"
                 stroke-linecap="round"
                 stroke-linejoin="round"
                 stroke-width="2"
                 d="M5 1 1 5l4 4"
               />
             </svg>
             <span className="sr-only">Previous</span>
           </span>
         </button>
     
      )}

      {cake.cakePhotos.length >= 2 && (
         <div
         type="div"
         style={{padding:'2vw 0vw'}}
         className="absolute top-0 end-0 z-30 flex items-center justify-center h-full   group focus:outline-none"
         data-carousel-next
       >
         <span onClick={() => handleNext(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
           <svg
             style={{width:'4vw',height:'4vw'}}
             className=" text-gray-800 dark:text-gray-800 rtl:rotate-180"
             aria-hidden="true"
             xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 6 10"
           >
             <path
               stroke="currentColor"
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="2"
               d="m1 9 4-4-4-4"
             />
           </svg>
           <span className="sr-only">Next</span>
         </span>
       </div>

      )}
    </div>
  </div>
  <div className="flex relative justify-center  flex-col">
<div style={{paddingLeft:'3vw',paddingRight:'3vw'}} className="pb-5 relative flex w-full flex-col rounded-lg border border-gray-100 bg-white shadow-md">
  <h5 className="mt-4 par text-left  text-slate-900" style={{ fontSize: '3.5vw' }}>
    { cake.cakeTitle}
  </h5>
  <div className="mt-2 mb-5 flex items-center justify-start">
    <p>
      <span className="header font-bold text-slate-900" style={{ fontSize: '4vw' }}>
        {cake.cakePrice + ' ETB'}
      </span>
      {
cake.cakeColor && cake.cakeColor.length != 0 &&
  <span className='flex mt-4'>
{cake.cakeColor.map((color, index) => (
<div  class="   mx-1  rounded-full shadow border  " style={{backgroundColor:color,width:'4vw',height:'4vw'}}></div>

))}
</span>
}
    </p>
  </div>
 <Link to={`/admin/cake/${cake.id}`}>
 <button style={{fontSize:'2.5vw',padding:'2vw 4vw'}} className="par w-full flex items-center justify-center rounded-md bg-slate-900  text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
  <svg
  style={{width:'5vw',height:'5vw',marginRight:'1.4vw'}}
    xmlns="http://www.w3.org/2000/svg"
    className=""
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
    />
  </svg>
  Buy Now
</button>

 </Link>
</div>
</div>
</div>
</div>
       <div id='desktop'>
      <div id="gallery" className="relative w-full  " data-carousel="slide">
        <div className="relative overflow-hidden rounded-lg">
          <div className="relative overflow-hidden rounded-lg shadow-xl">
            <div className="duration-700 ease-in-out " data-carousel-item>
              {cake.cakePhotos.map((photo, index) => (
                <img
                  key={index}
                  src={photo.cakePhotos}
                  alt={`Selected file ${index}`}
                  style={{ height: '280px', objectFit: 'cover' }}
                  className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
                />
              ))}
              {cake.cakePhotos.length === 0 && (
                <img
                  className="h-auto w-full rounded-lg"
                  style={{ height: '300px', objectFit: 'cover' }}
                  src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                  alt="description"
                />
              )}
            </div>
  
            {cake.cakePhotos.length >= 2 && (
                 <button
                 type="button"
                 className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-2 cursor-pointer group focus:outline-none"
                 data-carousel-prev
               >
               <span onClick={() => handlePrevious(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
  
                   <svg
                     
                     className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 6 10"
                   >
                     <path
                       stroke="currentColor"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                       stroke-width="2"
                       d="M5 1 1 5l4 4"
                     />
                   </svg>
                   <span className="sr-only">Previous</span>
                 </span>
               </button>
           
            )}
  
            {cake.cakePhotos.length >= 2 && (
               <div
               type="div"
               className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-2  group focus:outline-none"
               data-carousel-next
             >
               <span onClick={() => handleNext(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                 <svg
                   
                   className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                   aria-hidden="true"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 6 10"
                 >
                   <path
                     stroke="currentColor"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                     stroke-width="2"
                     d="m1 9 4-4-4-4"
                   />
                 </svg>
                 <span className="sr-only">Next</span>
               </span>
             </div>
  
            )}
          </div>
        </div>
        <div className="flex relative justify-center  flex-col">
      <div className="px-5 pb-5 relative flex w-full flex-col rounded-lg border border-gray-100 bg-white shadow-md">
        <h5 className="mt-4 par text-left  text-slate-900" style={{ fontSize: '15px' }}>
          { cake.cakeTitle}
        </h5>
        <div className="mt-2 mb-5 flex items-center justify-start">
          <p>
            <span className="header font-bold text-slate-900" style={{ fontSize: '20px' }}>
              {cake.cakePrice + ' ETB'}
            </span>
            {
      cake.cakeColor && cake.cakeColor.length != 0 &&
        <span className='flex mt-4'>
      {cake.cakeColor.map((color, index) => (
      <div  class=" w-7 h-7  mx-1  rounded-full shadow border  " style={{backgroundColor:color}}></div>

    ))}
      </span>
      }
          </p>
        </div>
       <Link to={`/admin/cake/${cake.id}`}>
       <button className="par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        Details
      </button>
  
       </Link>
      </div>
    </div>
      </div>
      </div>
      <div id="tablet">
      <div id="gallery" className="relative w-full  " data-carousel="slide">
        <div className="relative overflow-hidden rounded-lg">
          <div className="relative overflow-hidden rounded-lg shadow-xl">
            <div className="duration-700 ease-in-out " data-carousel-item>
              {cake.cakePhotos.map((photo, index) => (
                <img
                  key={index}
                  src={photo.cakePhotos}
                  alt={`Selected file ${index}`}
                  style={{ height: '100%', objectFit: 'cover' }}
                  className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
                />
              ))}
              {cake.cakePhotos.length === 0 && (
                <img
                  className="h-auto w-full rounded-lg"
                  style={{ height: '100%', objectFit: 'cover' }}
                  src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                  alt="description"
                />
              )}
            </div>
  
            {cake.cakePhotos.length >= 2 && (
                 <button
                 type="button"
                 className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-2 cursor-pointer group focus:outline-none"
                 data-carousel-prev
               >
               <span onClick={() => handlePrevious(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
  
                   <svg
                     
                     className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 6 10"
                   >
                     <path
                       stroke="currentColor"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                       stroke-width="2"
                       d="M5 1 1 5l4 4"
                     />
                   </svg>
                   <span className="sr-only">Previous</span>
                 </span>
               </button>
           
            )}
  
            {cake.cakePhotos.length >= 2 && (
               <div
               type="div"
               className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-2  group focus:outline-none"
               data-carousel-next
             >
               <span onClick={() => handleNext(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                 <svg
                   
                   className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                   aria-hidden="true"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 6 10"
                 >
                   <path
                     stroke="currentColor"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                     stroke-width="2"
                     d="m1 9 4-4-4-4"
                   />
                 </svg>
                 <span className="sr-only">Next</span>
               </span>
             </div>
  
            )}
          </div>
        </div>
        <div className="flex relative justify-center  flex-col">
      <div className="px-3 pb-5 relative flex w-full flex-col rounded-lg border border-gray-100 bg-white shadow-md">
        <h5 className="barlow mt-3 par text-left  text-slate-900" style={{ fontSize: '18px' }}>
          { cake.cakeTitle}
        </h5>
        <div className="mt-2 mb-5 flex items-center justify-start">
          <p>
            <span className="header font-bold text-slate-900" style={{ fontSize: '15px' }}>
              {cake.cakePrice + ' ETB'}
            </span>
            {
      cake.cakeColor && cake.cakeColor.length != 0 &&
        <span className='flex mt-4'>
      {cake.cakeColor.map((color, index) => (
      <div  class=" w-5 h-5  mx-1  rounded-full shadow border  " style={{backgroundColor:color}}></div>

    ))}
      </span>
      }
          </p>
        </div>
       <Link to={`/admin/cake/${cake.id}`}>
       <button className="par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        Buy Now
      </button>
  
       </Link>
      </div>
    </div>
      </div>
      </div>
    </div>
    
  );
}

export default ManageCakes;