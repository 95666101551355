import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Nav({component}) {
    const [pop,setpop] =useState(false)

    function navigationPop(){
        if(pop === true){
          setpop(false)
        }
        else{
          setpop(true)
        }
      }
  return (
         <div style={{zIndex:'101'}}>
            <div id='desktop'>
            {
              pop === true&&
              <div id='navPop'  class="">
              <ul className='w-1/2 bg-white px-5 border rounded-b-xl' style={{width:'25%'}}>
              <div className='flex justify-between w-full mt-3'>
                    <div>
                    </div>
                    <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100">
                      <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                    </button>
                  </div>
                  {
                      component === 'Home' ?
                      <li>
                      <Link to='/'><p class="block py-2 pr-4 pl-3 report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Home</p> </Link>
                  </li>
                  :
                  <li>
                  <Link to='/'><p class="block py-2 pr-4 pl-3 report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Home</p> </Link>
              </li>
                  }
                 {
                      component === 'About Us' ?
                      <li>
                      <Link to='/about'><p class="block py-2 pr-4 pl-3 report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">About Us</p> </Link>
                  </li>
                  :
                  <li>
                  <Link to='/about'><p class="block py-2 pr-4 pl-3 report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">About Us</p> </Link>
              </li>
                  }
  
  {
                      component === 'Contact Us' ?
                      <li>
                      <Link to='/contact'><p class="block py-2 pr-4 pl-3 report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Contact </p> </Link>
                  </li>
                  :
                  <li>
                  <Link to='/contact'><p class="block py-2 pr-4 pl-3 report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Contact</p> </Link>
              </li>
                  }
               
               {
                      component === 'Cakes' ?
                      <li>
                      <Link to='/cakes'><p class="block py-2 pr-4 pl-3 report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Cakes </p> </Link>
                  </li>
                  :
                  <li>
                  <Link to='/cakes'><p class="block py-2 pr-4 pl-3 report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Cakes</p> </Link>
              </li>
                  }
             
              
              </ul>
          </div>
                }
            <nav class=" border-gray-200 px-2 lg:px-4 py-2.5 ">
    <div class="flex flex-wrap justify-between items-center mx-auto ">
    <Link to='/' style={{width:'5%'}} className='flex items-center'>
               <img src='https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc'alt="Logo" className='ml-5' style={{width:'100%'}} />
                        </Link>
        
        <div class="flex items-center lg:order-2">
            
           <Link to='/signin'> <p  class="text-gray-800   focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2  focus:outline-none ">Login</p> </Link>
           <Link to='/register'> <p  class="text-white bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2 ">Sign up</p> </Link>
            <button onClick={navigationPop} data-collapse-toggle="mega-menu" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400  dark:focus:ring-gray-600" aria-controls="mega-menu" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
            </button>
        </div>
        <div  class="hidden justify-between items-center w-full text-sm lg:flex lg:w-auto lg:order-1">
            <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                {
                    component === 'Home' ?
                    <li>
                    <Link to='/'><p class="block py-2 pr-4 pl-3 report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Home</p> </Link>
                </li>
                :
                <li>
                <Link to='/'><p class="block py-2 pr-4 pl-3 report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Home</p> </Link>
            </li>
                }
               {
                    component === 'About Us' ?
                    <li>
                    <Link to='/about'><p class="block py-2 pr-4 pl-3 report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">About Us</p> </Link>
                </li>
                :
                <li>
                <Link to='/about'><p class="block py-2 pr-4 pl-3 report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">About Us</p> </Link>
            </li>
                }

{
                    component === 'Contact Us' ?
                    <li>
                    <Link to='/contact'><p class="block py-2 pr-4 pl-3 report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Contact </p> </Link>
                </li>
                :
                <li>
                <Link to='/contact'><p class="block py-2 pr-4 pl-3 report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Contact</p> </Link>
            </li>
                }
             
             {
                    component === 'Cakes' ?
                    <li>
                    <Link to='/cakes'><p class="block py-2 pr-4 pl-3 report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Cakes </p> </Link>
                </li>
                :
                <li>
                <Link to='/cakes'><p class="block py-2 pr-4 pl-3 report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Cakes</p> </Link>
            </li>
                }
           
            
            </ul>
        </div>
    </div>
</nav>
                

            </div>

            <div id='tablet'>
                {
              pop === true&&
              <div id='navPop'  class="">
              <ul className='w-1/2 bg-white px-5 border rounded-b-xl' style={{width:'25%'}}>
              <div className='flex justify-between w-full mt-3'>
                    <div>
                    </div>
                    <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100">
                      <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                    </button>
                  </div>
                  {
                      component === 'Home' ?
                      <li>
                      <Link to='/'><p class="block py-2 pr-4 pl-3 report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Home</p> </Link>
                  </li>
                  :
                  <li>
                  <Link to='/'><p class="block py-2 pr-4 pl-3 report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Home</p> </Link>
              </li>
                  }
                 {
                      component === 'About Us' ?
                      <li>
                      <Link to='/about'><p class="block py-2 pr-4 pl-3 report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">About Us</p> </Link>
                  </li>
                  :
                  <li>
                  <Link to='/about'><p class="block py-2 pr-4 pl-3 report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">About Us</p> </Link>
              </li>
                  }
  
  {
                      component === 'Contact Us' ?
                      <li>
                      <Link to='/contact'><p class="block py-2 pr-4 pl-3 report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Contact </p> </Link>
                  </li>
                  :
                  <li>
                  <Link to='/contact'><p class="block py-2 pr-4 pl-3 report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Contact</p> </Link>
              </li>
                  }
               
               {
                      component === 'Cakes' ?
                      <li>
                      <Link to='/cakes'><p class="block py-2 pr-4 pl-3 report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Cakes </p> </Link>
                  </li>
                  :
                  <li>
                  <Link to='/cakes'><p class="block py-2 pr-4 pl-3 report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Cakes</p> </Link>
              </li>
                  }
             
              
              </ul>
          </div>
                }
         
            <nav class=" border-gray-200 px-2 lg:px-4 py-2.5 ">
    <div class="flex flex-wrap justify-between items-center mx-auto ">
    <Link to='/' style={{width:'8%'}} className='flex items-center'>
               <img src='https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc'alt="Logo" className='ml-5' style={{width:'100%'}} />
                        </Link>
        
        <div class="flex items-center lg:order-2">
            
           <Link to='/signin'> <p  class="text-gray-800   focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2  focus:outline-none ">Login</p> </Link>
           <Link to='/register'> <p  class="text-white bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2 ">Sign up</p> </Link>
            <button onClick={navigationPop} data-collapse-toggle="mega-menu" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="mega-menu" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
            </button>
        </div>
        
    </div>
</nav>
            </div>

            <div id='mobile'>
                {
              pop === true&&
              <div id='navPop'  class="">
              <ul className='w-1/2 bg-white px-5 border rounded-b-xl' style={{width:'25%'}}>
              <div className='flex justify-between w-full mt-3'>
                    <div>
                    </div>
                    <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" style={{width:'6vw',height:'6vw'}} className="flex  text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100">
                      <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                    </button>
                  </div>
                  {
                      component === 'Home' ?
                      <li>
                      <Link to='/'><p style={{marginBottom:'1vw'}} class="block  report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Home</p> </Link>
                  </li>
                  :
                  <li>
                  <Link to='/'><p style={{marginBottom:'1vw'}} class="block  report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Home</p> </Link>
              </li>
                  }
                 {
                      component === 'About Us' ?
                      <li>
                      <Link to='/about'><p style={{marginBottom:'1vw'}} class="block  report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">About Us</p> </Link>
                  </li>
                  :
                  <li>
                  <Link to='/about'><p style={{marginBottom:'1vw'}} class="block  report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">About Us</p> </Link>
              </li>
                  }
  
  {
                      component === 'Contact Us' ?
                      <li>
                      <Link to='/contact'><p style={{marginBottom:'1vw'}} class="block  report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Contact </p> </Link>
                  </li>
                  :
                  <li>
                  <Link to='/contact'><p style={{marginBottom:'1vw'}} class="block  report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Contact</p> </Link>
              </li>
                  }
               
               {
                      component === 'Cakes' ?
                      <li>
                      <Link to='/cakes'><p style={{marginBottom:'1vw'}} class="block  report-card-text text-blue-600  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Cakes </p> </Link>
                  </li>
                  :
                  <li>
                  <Link to='/cakes'><p style={{marginBottom:'1vw'}} class="block  report-card-text text-gray-700  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Cakes</p> </Link>
              </li>
                  }
             
              
              </ul>
          </div>
                }
         
            <nav class=" border-gray-200 px-2 lg:px-4 py-2.5 ">
    <div class="flex flex-wrap justify-between items-center mx-auto ">
    <Link to='/' style={{width:'10%'}} className='flex items-center'>
               <img src='https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc'alt="Logo" className='ml-5' style={{width:'100%'}} />
                        </Link>
        
        <div class="flex items-center lg:order-2">
            
           <Link to='/signin'> <p  class="text-gray-800   focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2  focus:outline-none ">Login</p> </Link>
           <Link to='/register'> <p  class="text-white bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2 ">Sign up</p> </Link>
            <button onClick={navigationPop} data-collapse-toggle="mega-menu" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400  dark:focus:ring-gray-600" aria-controls="mega-menu" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
            </button>
        </div>
        
    </div>
</nav>
            </div>
        </div>
  )
}
