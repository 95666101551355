import { GoogleAuthProvider, createUserWithEmailAndPassword, getAuth, signInWithPopup } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { auth, firestore } from '../firebase';
import { collection, doc, onSnapshot, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import Nav from './nav';

export default function Register() {
    const provider = new GoogleAuthProvider();
    const[fullName,setfullName] = useState('')
    const[fullNameError,setfullNameError] = useState('')
    const[email,setemail] = useState('')
    const[emailError,setemailError] = useState('')
    const[password,setpassword] = useState('')
    const[confirmPassword,setconfirmPassword] = useState('')
    const[confirmPasswordError,setconfirmPasswordError] = useState('')
    const[duplicate,setduplicate] = useState(false)
    const[error,seterror] = useState('')
    const[isLoading,setIsLoading] = useState(false)





    const[passwordError,setpasswordError] = useState('')
    const navigate = useNavigate()

    const [screenHeight, setScreenHeight] = useState(window.screen.height);

    useEffect(() => {
      const handleResize = () => {
        setScreenHeight(window.screen.height);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
   
   
    function passwordSet(e){
      setpassword(e.target.value)
      if(e.target.value.length <= 7){
        setpasswordError('error')
      }
      else{
        setpasswordError('')
      }

    }
    function confirmPasswordSet(e){
      setconfirmPassword(e.target.value)
      if(e.target.value !== password){
        setconfirmPasswordError('error')
      }
      else{
        setconfirmPasswordError('')
      }

    }
    function emailSet(e){
      setemail(e.target.value)
      setduplicate(false)

      if(e.target.value.length ===0){
        setemailError('error')
      }
      else{
        setemailError('')
      }

    } 
    function fullNameSet(e){
      setfullName(e.target.value)
      if(e.target.value.length ===0){
        setfullNameError('error')
      }
      else{
        setfullNameError('')
      }

    }

    function signUp(){
      if(fullName.length ===0){
        setfullNameError('error')
      }
      else{
        setfullNameError('')
          if(email.length == 0 ||emailError =='error' || duplicate == true){

          setemailError('error')
        }
        else{
          setemailError('')
          if(password.length === 0){
            setpasswordError('error')
          }
          else{
            if(confirmPassword.length == 0  || password != confirmPassword){
              setconfirmPasswordError('error')
            }
            else{

              setpasswordError('')
              setIsLoading(true)
              createUserWithEmailAndPassword(auth, email, password)
              .then((credential) => {
                setDoc(doc(firestore, "Users", credential.user.uid), {
                  email: email,
                  fullName: fullName,
                  time: serverTimestamp(),
                  photo: ''
                })
                .then(() => {
                  setIsLoading(false);
                  navigate(`/cakes`);
                })
                .catch((error) => {
                  // Handle errors during the setDoc operation
                  console.error('Error setting user document:', error);
                  // Potentially show an error message to the user
                });
              })
              .catch((error) => {
                // Handle errors during the createUserWithEmailAndPassword operation
                const errorCode = error.code;
                const errorMessage = error.message;
            
                switch (errorCode) {
                  case 'auth/email-already-in-use':
                    seterror('Email already in use')
                    break;
                  case 'auth/invalid-email':
                    seterror('Invalid email address')
                    break;
                  case 'auth/weak-password':
                    seterror('Weak password')
                    break;
                    case 'auth/network-request-failed':
          seterror('Network error occurred during user creation')
  
          break;
                  default:
                    console.error('Error creating user:', errorMessage);
                    seterror(errorMessage)
                    setIsLoading(false);
  
                    break;
                }
            
                setIsLoading(false);
              });
            }
          }
         
        }
      }
    }


  return (
    <div className='bg-gradient-to-r from-slate-50 via-slate-50 to-lime-300 overflow-hidden' style={{minHeight:'100vh'}}>
        <div id="desktop" className='bg-gradient-to-r from-slate-50 via-slate-50 to-lime-300'>
    <Nav/>
            <div class="h-full   w-full  px-4 pb-10 pt-16">

            <div class="flex flex-col items-center justify-start">
{
  isLoading === true ?
  <div style={{height:'80vh',width:'40%'}} class="bg-white flex items-center justify-center shadow border rounded  w-full p-10 -mt-5">
  
  <div
  className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
  role="status"></div></div>
  :
  <div style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 -mt-5">
               <div className={`${error.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{error}
</div>
</div>
</div>
<p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Create an Account</p>


        <div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Full Name</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>fullNameSet(e))} required  autoComplete="off" placeholder="kebede Solomon" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${fullNameError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Full Name Can't be empty
</div>
</div>
</div>
</div>
<div class="relative mx-auto mt-12" style={{width:'80%'}}>
<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>

   
      <input  style={{fontSize:'11px',height:'60px'}} autocomplete="off" onChange={e=>emailSet(e)} placeholder="abebe@gmail.com " type="email" 
      class={`${duplicate == false && "border  focus:outline-none focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white border-gray-300 rounded-md lowercase input-field-signUp" }
      ${duplicate == true && "input-field-signUp lowercase bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg pt-5 pr-4 pb-5 pl-4 focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400" }
      
      `}/>
          <p class={`${duplicate == true && "mt-2 text-sm text-red-600 dark:text-red-500" } ${duplicate == false && "hidden"}`}><span class="font-medium">Oops!</span> Email already
taken!</p>
<div className={`${emailError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Invalid Email
</div>
</div>
</div>
    </div>

<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Password</p>
<input style={{fontSize:'12px',height:'60px'}} onChange={(e=>passwordSet(e))}  required  autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${passwordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Password Can't be empty
</div>
</div>
</div>
</div>

<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Confirm Password</p>
<input style={{fontSize:'12px',height:'60px'}} onChange={(e=>confirmPasswordSet(e))}  required  autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${confirmPasswordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Passwords do not match
</div>
</div>
</div>
</div>
<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>

<button
   type='button'
   onClick={signUp}
   style={{fontSize:'12px',margin:'auto',background:'#D9FE00',height:'55px'}}
   className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
 >
  Register
 </button>
 </div>
 <div class="w-full flex items-center justify-between py-5">
         <hr class="w-full bg-gray-400"/>
         <p class="text-base font-medium leading-4 px-2.5 text-gray-400 mt-4"></p>
         <hr class="w-full bg-gray-400  "/>
        </div>

    <p class="text-center par focus:outline-none text-sm mt-8 font-medium leading-none text-gray-500">Already have an account? <Link to='/signin'><a   class="hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-gray-800 cursor-pointer"> Log In</a></Link></p>
  
   
</div>
} 
             
                
            </div>
        </div>
        </div>
        <div id="tablet" className='h-full bg-gradient-to-r from-slate-50 via-slate-50 to-lime-300'>
    <Nav/>
            <div class="h-full   w-full  px-4 pb-10 pt-16 bg-gradient-to-r from-slate-50 via-slate-50 to-lime-300">

            <div class="flex flex-col items-center justify-start">
{
  isLoading === true ?
  <div style={{height:'50vh',width:'80%'}} class="bg-white flex items-center justify-center shadow border rounded  w-full p-10 -mt-5">
  
  <div
  className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
  role="status"></div></div>
  :
  <div style={{width:'80%'}}class="bg-white shadow border rounded  w-full p-10 -mt-5">
               <div className={`${error.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{error}
</div>
</div>
</div>
<p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Create an Account</p>


        <div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Full Name</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>fullNameSet(e))} required  autoComplete="off" placeholder="kebede Solomon" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${fullNameError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Full Name Can't be empty
</div>
</div>
</div>
</div>
<div class="relative mx-auto mt-12" style={{width:'80%'}}>
<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>

   
      <input  style={{fontSize:'11px',height:'60px'}} autocomplete="off" onChange={e=>emailSet(e)} placeholder="abebe@gmail.com " type="email" 
      class={`${duplicate == false && "border  focus:outline-none focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white border-gray-300 rounded-md lowercase input-field-signUp" }
      ${duplicate == true && "input-field-signUp lowercase bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg pt-5 pr-4 pb-5 pl-4 focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400" }
      
      `}/>
          <p class={`${duplicate == true && "mt-2 text-sm text-red-600 dark:text-red-500" } ${duplicate == false && "hidden"}`}><span class="font-medium">Oops!</span> Email already
taken!</p>
<div className={`${emailError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Invalid Email
</div>
</div>
</div>
    </div>

<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Password</p>
<input style={{fontSize:'12px',height:'60px'}} onChange={(e=>passwordSet(e))}  required  autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${passwordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Password Can't be empty
</div>
</div>
</div>
</div>

<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Confirm Password</p>
<input style={{fontSize:'12px',height:'60px'}} onChange={(e=>confirmPasswordSet(e))}  required  autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${confirmPasswordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Passwords do not match
</div>
</div>
</div>
</div>
<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>

<button
   type='button'
   onClick={signUp}
   style={{fontSize:'12px',margin:'auto',background:'#D9FE00',height:'55px'}}
   className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
 >
  Register
 </button>
 </div>
 <div class="w-full flex items-center justify-between py-5">
         <hr class="w-full bg-gray-400"/>
         <p class="text-base font-medium leading-4 px-2.5 text-gray-400 mt-4"></p>
         <hr class="w-full bg-gray-400  "/>
        </div>

    <p class="text-center par focus:outline-none text-sm mt-8 font-medium leading-none text-gray-500">Already have an account? <Link to='/signin'><a   class="hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-gray-800 cursor-pointer"> Log In</a></Link></p>
  
   
</div>
} 
             
                
            </div>
        </div>
        </div>
        <div id="mobile" className=''>
    <Nav/>
            <div class="h-full   w-full  px-4 pb-10 pt-16">

            <div class="flex flex-col items-center justify-start">
{
  isLoading === true ?
  <div style={{height:'80vh',width:'80%'}} class="bg-white flex items-center justify-center shadow border rounded  w-full p-10 -mt-5">
  
  <div
  className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
  role="status"></div></div>
  :
  <div style={{width:'80%'}}class="bg-white shadow border rounded  w-full p-10 -mt-5">
   <div className={`${error.length !== 0 ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%',marginBottom:'3vw'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
{error}

</div>
</div>
</div> 

<p style={{fontSize:'5vw',marginBottom:'8vw'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Create an Account</p>

<div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>

<p class="bg-white  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Full Name</p>

<input   onChange={(e=>fullNameSet(e))}  type='text'  placeholder='yohannes Kebede' className=" m-auto form-control par  border  focus:outline-none
focus:border-black   text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
<div className={`${fullNameError == 'error' ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
Full Name Can't be empty

</div>
</div>
</div> 

</div>
<div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>
   <p class="bg-white  font-medium text-gray-600 fjalla absolute par "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Email</p>
       


  <input style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} required autocomplete="off" onChange={e=>emailSet(e)} placeholder="abebe@gmail.com " type="email" 
  class={`${duplicate == false && " input-field-signUp m-auto form-control  border  focus:outline-none focus:border-black w-full  text-gray-700 block bg-white border-gray-300 rounded-md" }
  ${duplicate == true && "input-field-signUp lowercase bg-red-50 form-control mx-auto border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  dark:bg-red-100 dark:border-red-400" }
  
  `}/>
      <p style={{fontSize:"3vw",width:'90%'}} class={`${duplicate == true && "mx-auto mt-2 text-sm text-red-600 dark:text-red-500" } ${duplicate == false && "hidden"}`}><span class="font-medium">Oops!</span> Email already
taken!</p>
</div>

<div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>

<p class="bg-white  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Password</p>

<input   onChange={(e=>passwordSet(e))}  type='password'  placeholder='********' className=" m-auto form-control par  border  focus:outline-none
focus:border-black   text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
<div className={`${passwordError == 'error' ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
The password must be at least 8 characters long
 

</div>
</div>
</div> 

</div>

<div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>

<p class="bg-white  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Confirm Password</p>

<input   onChange={(e=>confirmPasswordSet(e))}  type='password'  placeholder='********' className=" m-auto form-control par  border  focus:outline-none
focus:border-black   text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
<div className={`${confirmPasswordError == 'error' ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
Passwords do not match

</div>
</div>
</div> 

</div>
<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>


 <button
  type='button'
  onClick={signUp}
  style={{fontSize:'3vw',width:'100%',margin:'auto',background:'#D9FE00',height:'13vw'}}
  className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
>
  Register
</button>
 </div>
 <div class="w-full flex items-center justify-between py-5">
         <hr class="w-full bg-gray-400"/>
         <p class="text-base font-medium leading-4 px-2.5 text-gray-400 mt-4"></p>
         <hr class="w-full bg-gray-400  "/>
        </div>

    <p class="text-center par focus:outline-none text-sm mt-8 font-medium leading-none text-gray-500">Already have an account? <Link to='/signin'><a   class="hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-gray-800 cursor-pointer"> Log In</a></Link></p>
  
   
</div>
} 
             
                
            </div>
        </div>
        </div>
    </div>
  )
}
