import React, { useState } from 'react'
import Nav from './nav'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { firestore } from '../firebase'
import { Link } from 'react-router-dom'

export default function Contact() {
    const[fullName,setfullName] = useState('')
    const[fullNameError,setfullNameError] = useState('')
    const[email,setemail] = useState('')
    const[emailError,setemailError] = useState('')
    const[message,setmessage] = useState('')
    const[messageError,setmessageError] = useState('')
    const[error,seterror] = useState('')
    const[phoneNumber,setphoneNumber] = useState('')
    const[phoneNumberError,setphoneNumberError] = useState('')
    const[loading,setloading] = useState(false)
    const[success,setsuccess] = useState(false)



    function emailSet(e){
        setemail(e.target.value)
  
        if(e.target.value.length ===0){
          setemailError('error')
        }
        else{
          setemailError('')
        }
  
      } 
      function fullNameSet(e){
        setfullName(e.target.value)
        if(e.target.value.length ===0){
          setfullNameError('error')
        }
        else{
          setfullNameError('')
        }
  
      }

      function messageSet(e){
        setmessage(e.target.value)
        if(e.target.value.length ===0){
          setmessageError('error')
        }
        else{
          setmessageError('')
        }
  
      }
      function phoneNumberSet(e){
        setphoneNumber(e.target.value)
  
        if(e.target.value.length ===0){
          setphoneNumberError('error')
        }
        else{
          setphoneNumberError('')
        }
  
      } 

    async  function sendMessage(){
        try {
            if (fullName.length === 0) {
              setfullNameError('error');
            } else {
              setfullNameError('');
              if (email.length === 0) {
                setemailError('error');
              } else {
                setemailError('');
              if(phoneNumber.length === 0){
                setphoneNumberError('error')
              }
              else{
                setphoneNumberError('')
                if (message.length === 0) {
                    setmessageError('error');
                  } else {
                    setmessageError('');
                    setloading(true)
                    try {
                      await addDoc(collection(firestore, 'Messages'), {
                        fullName: fullName,
                        message: message,
                        phoneNumber:phoneNumber,
                        email: email,
                        time: serverTimestamp()
                      });
                    setsuccess(true)
                    setloading(false)
    
    
                    } catch (error) {
                      setloading(false);
                      seterror('Error adding document to Firestore:', error);
                      console.error('Error adding document to Firestore:', error);
                    }
                  }
              }
              }
            }
          } catch (error) {
            setloading(false);
            seterror('Error in reserveSpot function:', error);
            console.error('Error in reserveSpot function:', error);
          }
      }
  return (
    <div>
        <div id='desktop'>
            {
                                loading === true?
                                <div  style={{height:'50vh'}}class=" flex items-center justify-center w-full  rounded  ">
                   
                   <div
                   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
                   role="status"></div></div>
                   :
                   success === true?
                   <div className='successCourseProgram flex-col' > 
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
                <h1 className='header' style={{fontSize:'40px'}}>The message you sent was successfully delivered</h1>
                <p className='par'>Our team will get in touch with you as soon as possible to confirm your request </p>
                
                <Link to='/'>
                <button
                                   type='button'
                                  
                                   style={{fontSize:'12px',background:'black',height:'55px'}}
                                   className='par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                                 >
                                   Done
                                 </button></Link>
                </div>
:      
      <section class="" id="contact">
                <Nav component={'Contact Us'}/> 

    <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
        <div class="mb-4">
            <div class="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
              
                <h2
                    class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
                    Get in Touch
                </h2>
              
            </div>
        </div>
        <div class="flex items-stretch justify-center">
            <div class="grid md:grid-cols-2">
                <div class="h-full pr-6">
                    <p class="mt-3 mb-12 text-lg text-gray-600 par">
                    Feel free to send us a message and we'll get back to you as soon as possible
                    </p>
                    <ul class="mb-6 md:mb-0">
                        <li class="flex">
                            <div class="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="h-6 w-6">
                                    <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                                    <path
                                        d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z">
                                    </path>
                                </svg>
                            </div>
                            <div class="ml-4 mb-4">
                                <h3 class="mb-2 text-lg font-medium leading-6 text-gray-900 header">Our Address
                                </h3>
                                <p class="text-gray-600 ">Lebu Varnero, Ayham Building.</p>
                                <p class="text-gray-600 ">Addis Ababa, Ethiopia</p>
                            </div>
                        </li>
                        <li class="flex">
                            <div class="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="h-6 w-6">
                                    <path
                                        d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                                    </path>
                                    <path d="M15 7a2 2 0 0 1 2 2"></path>
                                    <path d="M15 3a6 6 0 0 1 6 6"></path>
                                </svg>
                            </div>
                            <div class="ml-4 mb-4">
                                <h3 class="mb-2 text-lg font-medium leading-6 text-gray-900 header">Contact
                                </h3>
                                <p class="text-gray-600 ">Mobile 1: 0943130725</p>

                                <p class="text-gray-600 ">Mobile 2: 0965070909</p>
                            </div>
                        </li>
                        <li class="flex">
                            <div class="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="h-6 w-6">
                                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                    <path d="M12 7v5l3 3"></path>
                                </svg>
                            </div>
                            <div class="ml-4 mb-4">
                                <h3 class="mb-2 text-lg font-medium leading-6 text-gray-900 header">Working
                                    hours</h3>
                                <p class="text-gray-600 ">Monday - Sunday: 7:30 AM–9 PM</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="card h-fit max-w-6xl p-5 md:p-12" id="form">
                    <h2 class="mb-4 text-2xl font-bold ">Send us a message</h2>
                    <form id="contactForm">
                    <div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Full Name</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>fullNameSet(e))} required  autoComplete="off" placeholder="kebede Solomon" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${fullNameError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Full Name Can't be empty
</div>
</div>
</div>
</div>
<div className={`${error.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{error}
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>emailSet(e))} required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${emailError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Phone Number</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>phoneNumberSet(e))} required  autoComplete="off" placeholder="0912345678" type="tel" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${phoneNumberError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Phone Number Can't be empty
</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>
<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Message</p>

<textarea placeholder='Write your message...'  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'12px'}}  onChange={e=>messageSet(e)}  type="text" class="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" maxLength="1000"></textarea>
<p>{message.length !== 0 && message.length + '/1,000'}</p>
      <div className={`${messageError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Message Can't be empty
</div>
</div>
</div>

</div>
                        <div class="text-center mt-12">
           <button onClick={sendMessage} type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-6 py-3 me-2 mb-2">Send Message</button> 
                        
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
}

        </div>
        <div id='tablet'>
            {
                                loading === true?
                                <div  style={{height:'50vh'}}class=" flex items-center justify-center w-full  rounded  ">
                   
                   <div
                   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
                   role="status"></div></div>
                   :
                   success === true?
                   <div className='successCourseProgram flex-col justify-center items-center' > 
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
                <h1 className='header text-center' style={{fontSize:'30px'}}>The message you sent was successfully delivered</h1>
                <p className='par'>Our team will get in touch with you as soon as possible to confirm your request </p>
                
                <Link to='/'>
                <button
                                   type='button'
                                  
                                   style={{fontSize:'12px',background:'black',height:'55px'}}
                                   className='par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                                 >
                                   Done
                                 </button></Link>
                </div>
:      
      <section class="" id="contact">
                <Nav component={'Contact Us'}/> 

    <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
        <div class="mb-4">
            <div class="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
              
                <h2
                    class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
                    Send us a message
                </h2>
              
            </div>
        </div>
        <div class="flex items-stretch justify-center">
            <div class="flex flex-col w-full">
               
                <div class="card h-fit max-w-6xl p-5 md:p-12" id="form">
                    <form id="contactForm">
                    <div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Full Name</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>fullNameSet(e))} required  autoComplete="off" placeholder="kebede Solomon" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${fullNameError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Full Name Can't be empty
</div>
</div>
</div>
</div>
<div className={`${error.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{error}
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>emailSet(e))} required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${emailError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Phone Number</p>
<input style={{fontSize:'12px',height:'60px'}}  onChange={(e=>phoneNumberSet(e))} required  autoComplete="off" placeholder="0912345678" type="tel" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${phoneNumberError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Phone Number Can't be empty
</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>
<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Message</p>

<textarea placeholder='Write your message...'  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'12px'}}  onChange={e=>messageSet(e)}  type="text" class="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" maxLength="1000"></textarea>
<p>{message.length !== 0 && message.length + '/1,000'}</p>
      <div className={`${messageError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Message Can't be empty
</div>
</div>
</div>

</div>
                        <div class="text-center mt-12">
           <button onClick={sendMessage} type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-6 py-3 me-2 mb-2">Send Message</button> 
                        
                        </div>
                    </form>
                </div>
                <div class="h-full pr-6">
             
             <ul class="mb-6 md:mb-0">
                 <li class="flex">
                     <div class="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round" class="h-6 w-6">
                             <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                             <path
                                 d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z">
                             </path>
                         </svg>
                     </div>
                     <div class="ml-4 mb-4">
                         <h3 class="mb-2 text-lg font-medium leading-6 text-gray-900 header">Our Address
                         </h3>
                         <p class="text-gray-600 ">Lebu Varnero, Ayham Building.</p>
                         <p class="text-gray-600 ">Addis Ababa, Ethiopia</p>
                     </div>
                 </li>
                 <li class="flex">
                     <div class="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round" class="h-6 w-6">
                             <path
                                 d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                             </path>
                             <path d="M15 7a2 2 0 0 1 2 2"></path>
                             <path d="M15 3a6 6 0 0 1 6 6"></path>
                         </svg>
                     </div>
                     <div class="ml-4 mb-4">
                         <h3 class="mb-2 text-lg font-medium leading-6 text-gray-900 header">Contact
                         </h3>
                         <p class="text-gray-600 ">Mobile 1: 0943130725</p>

<p class="text-gray-600 ">Mobile 2: 0965070909</p>
                     </div>
                 </li>
                 <li class="flex">
                     <div class="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round" class="h-6 w-6">
                             <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                             <path d="M12 7v5l3 3"></path>
                         </svg>
                     </div>
                     <div class="ml-4 mb-4">
                         <h3 class="mb-2 text-lg font-medium leading-6 text-gray-900 header">Working
                             hours</h3>
                             <p class="text-gray-600 ">Monday - Sunday: 7:30 AM–9 PM</p>

                     </div>
                 </li>
             </ul>
         </div>
            </div>
        </div>
    </div>
</section>
}

        </div>
        <div id='mobile' style={{height:'100vh'}}>
            {
                                loading === true?
                                <div  style={{height:'100%'}}class=" flex items-center justify-center w-full  rounded  ">
                   
                   <div
                   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
                   role="status"></div></div>
                   :
                   success === true?
                   <div className='successCourseProgram flex-col' > 
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
                <h1 className='header text-center' style={{fontSize:'7vw',lineHeight:'8vw'}}>The message you sent was successfully delivered</h1>
                <p className='par text-center'style={{fontSize:'2.8vw',marginTop:'2vw'}}>Our team will get in touch with you as soon as possible to confirm your request </p>
                
                <Link to='/'>
                <button
                                   type='button'
                                  
                                   style={{fontSize:'12px',background:'black',height:'55px'}}
                                   className='par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                                 >
                                   Done
                                 </button></Link>
                </div>
:      
      <section class="" id="contact">
                <Nav component={'Contact Us'}/> 

    <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
        <div class="mb-4">
            <div class="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
              
                <h2
                style={{fontSize:'6vw'}}
                    class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
                    Send Us A Message
                </h2>
              
            </div>
        </div>
        <div class="flex  justify-center">
            <div class="flex flex-col w-full">
              
                <div class="" id="form">
                    <form id="contactForm">
                    <div className={`${error.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{error}
</div>
</div>
</div>
                    <div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>

                    <p class="bg-white  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Full Name</p>

<input   onChange={(e=>fullNameSet(e))}  type='text'  placeholder='yohannes Kebede' className=" m-auto form-control par  border  focus:outline-none
focus:border-black   text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
 <div className={`${fullNameError == 'error' ? "block":"hidden"}`}>
    <div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli' style={{fontSize:'3vw'}}>
Full Name Can't be empty
     
    </div>
    </div>
    </div> 

</div>

<div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>

<p class="bg-white  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Email</p>

<input   onChange={(e=>emailSet(e))}  type='email'  placeholder='kebede@gmail.com' className=" m-auto form-control par  border  focus:outline-none
focus:border-black   text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
<div className={`${emailError == 'error' ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
Email Can't be empty

</div>
</div>
</div> 

</div>
<div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>

<p class="bg-white  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Phone Number</p>

<input   onChange={(e=>phoneNumberSet(e))}  type='tel'  placeholder='0912345678' className=" m-auto form-control par  border  focus:outline-none
focus:border-black   text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
<div className={`${phoneNumberError == 'error' ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
Phone Number Can't be empty

</div>
</div>
</div> 

</div>

<div className="relative m-auto " style={{width:"90%",marginBottom:"3vw",lineHeight:"4vw"}}>

<p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"1vw",paddingRight:"2vw"}}>Message</p>


<textarea
  style={{
    padding: '3vw 3.5vw',
    fontSize: '3vw',
    minHeight: '50vw',
    maxHeight: '80vw',
  }}
  placeholder="Write your message..."
  onChange={e => messageSet(e)}
  className="border text-gray-600 focus:outline-none focus:border-black w-full text-base block bg-white border-gray-300 rounded-md"
  maxLength={1000}
></textarea>
<p className='fjalla' style={{fontSize: '3vw'}}>{message.length + '/ 1,000'}</p>
   <div className={`${messageError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Message Can't be empty

</div>
</div>
</div> 

</div>

                        <div class="text-center mt-12">
        <button onClick={sendMessage} style={{fontSize:'2.8vw',padding:'3vw 4vw'}}  type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm  me-2 mb-2">Send Message</button> 
                        
                        </div>
                    </form>
                </div>
                <div class="h-full mt-12" >
              
                    <ul class="mb-6 md:mb-0">
                        <li class="flex">
                            <div class="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="h-6 w-6">
                                    <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                                    <path
                                        d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z">
                                    </path>
                                </svg>
                            </div>
                            <div class="ml-4 mb-4">
                                <h3 class="mb-2 text-lg font-medium leading-6 text-gray-900 header" style={{fontSize:'4vw'}}>Our Address
                                </h3>
                                <p class="text-gray-600 ">Lebu Varnero, Ayham Building.</p>
                                <p class="text-gray-600 ">Addis Ababa, Ethiopia</p>
                            </div>
                        </li>
                        <li class="flex">
                            <div class="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="h-6 w-6">
                                    <path
                                        d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                                    </path>
                                    <path d="M15 7a2 2 0 0 1 2 2"></path>
                                    <path d="M15 3a6 6 0 0 1 6 6"></path>
                                </svg>
                            </div>
                            <div class="ml-4 mb-4">
                                <h3 class="mb-2 text-lg font-medium leading-6 text-gray-900 header"style={{fontSize:'4vw'}}>Contact
                                </h3>
                                <p class="text-gray-600 ">Mobile 1: 0943130725</p>

<p class="text-gray-600 ">Mobile 2: 0965070909</p>
                            </div>
                        </li>
                        <li class="flex">
                            <div class="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="h-6 w-6">
                                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                    <path d="M12 7v5l3 3"></path>
                                </svg>
                            </div>
                            <div class="ml-4 mb-4">
                                <h3 class="mb-2 text-lg font-medium leading-6 text-gray-900 header"style={{fontSize:'4vw'}}>Working
                                    hours</h3>
                                    <p class="text-gray-600 ">Monday - Sunday: 7:30 AM–9 PM</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
}

        </div>
    </div>
  )
}
