import { GoogleAuthProvider, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { auth, firestore } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import Nav from './nav';

export default function SignIn() {
    const provider = new GoogleAuthProvider();
    const[fullName,setfullName] = useState('')
    const[fullNameError,setfullNameError] = useState('')
    const[email,setemail] = useState('')
    const[emailError,setemailError] = useState('')
    const[password,setpassword] = useState('')
    const[passwordError,setpasswordError] = useState('')
    const[signInError,setsignInError] = useState('')
    const[isLoading,setIsLoading] = useState('')
    const[forgotPassword,setforgotPassword] = useState(true)
    const[sent,setsent] = useState(false)

    const[forgotPasswordError,setforgotPasswordError] = useState('')
    const[forgotPasswordEmail,setforgotPasswordEmail] = useState('')
    const[resetEmailError,setresetEmailError] = useState('')



    const navigate = useNavigate()

    const [screenHeight, setScreenHeight] = useState(window.screen.height);

    useEffect(() => {
      const handleResize = () => {
        setScreenHeight(window.screen.height);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    function emailSet(e){
      setemail(e.target.value)
      if(e.target.value.length === 0){
        setemailError('error')

      }
      else{
        setemailError('')
      }
    }
    function resetEmailSet(e){
      setforgotPasswordEmail(e.target.value)
      if(e.target.value.length === 0){
       setforgotPasswordError('error')

      }
      else{
       setforgotPasswordError('')
      }
    }

    function openForgotPassword(){
      setTimeout(() => {
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
          setforgotPassword(false)
        }, 1000);
        
      }, 1000);
    }
    function passwordSet(e){
      setpassword(e.target.value)
      if(e.target.value.length === 0){
        setpasswordError('error')

      }
      else{
        setpasswordError('')
      }
    }

    function signIn(e) {
      e.preventDefault();
      
     if(email.length !=0 && password.length !=0){
      signInWithEmailAndPassword(auth, email, password).then(setIsLoading(true))
      .then(() => {
        if (email.trim() === 'admin@admin.com' && password.trim() === 'admin@admin.com') {
          navigate("/admin");
          setIsLoading(false)

        }
        
          else{
        navigate("/cakes");
        setIsLoading(false)

        }
    })
    .catch((error) => {
      setIsLoading(false)
        // Handle specific error cases
        if (error.code === "auth/user-not-found") {
            setsignInError('User not found.');
        } else if (error.code === "auth/invalid-credential") {
            setsignInError('Invalid login credentials');
        } else if (error.code === "auth/network-request-failed") {
          setsignInError('Check your Internet Connection');

        }
        else if (error.code === "auth/invalid-email") {
          setsignInError('Invalid login credentials');

        }
         else {
            setsignInError(error.message);
        }
    });
     }
     
  }

  function resetEmail(){
      if (forgotPasswordEmail.length === 0) {
        setforgotPasswordEmail('error');
      } else {
        setIsLoading(true);
    
        sendPasswordResetEmail(auth, forgotPasswordEmail)
          .then(() => {
            setIsLoading(false);
            setsent(true);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setresetEmailError(errorMessage);
            setIsLoading(false);
          });
      }   if (forgotPasswordEmail.length === 0) {
        setforgotPasswordError('error');
      } else {
        setIsLoading(true);
    
        sendPasswordResetEmail(auth, forgotPasswordEmail)
          .then(() => {
            setIsLoading(false);
            setsent(true);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setresetEmailError(errorMessage);
            setIsLoading(false);
          });
      }
  }

  function successRemove(){
    setsent(false)
    setforgotPassword(false)
  }
  return (
    <div className='bg-gradient-to-r from-slate-50 via-slate-50 to-lime-300' style={{minHeight:screenHeight}}>
       
       <div id="desktop" className=''>

    <Nav/>
            <div class="h-full   w-full  px-4 pb-10 pt-16">

            <div class="flex flex-col items-center justify-start">
{
   isLoading === true ?
   <div style={{height:'80vh',width:'40%'}} class="bg-white flex items-center justify-center shadow border rounded  w-full p-10 -mt-5">
   
   <div
   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
   role="status"></div></div>
   :
   sent ===  true?
   <div onClick={successRemove}style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 -mt-5">
                <div  id='successCourseAddDesktop' style={{display:'flex',justifyContent:'center',alignItems:'center'}} className='flex items-center justify-center'>


<div className="relative p-4 w-full  "style={{width:"100%"}}>
<div className="relative p-4 text-center bg-white rounded-lg   sm:p-5" >
 
   <button   type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
       <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Close modal</span>
   </button>


   <div className="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
       <svg aria-hidden="true" className="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Success</span>
   </div>
   <p className="mb-4 text-lg font-semibold text-gray-900  capitalize">Email has been sent</p>
                <p className='borel'>Please check your email inbox for a password recovery link</p>
</div>
</div>



</div> 
</div>
:
   forgotPassword === false ?
   <div style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 -mt-5">
   <div className={`${resetEmailError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{resetEmailError}
</div>
</div>
</div>
<p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Reset Password</p>



<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input onChange={(e)=>resetEmailSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${forgotPasswordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>

<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>

<button
type='button'
onClick={resetEmail}
style={{fontSize:'12px',margin:'auto',background:'#D9FE00',height:'55px'}}
className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
>
Reset Password
</button>
</div>
<div class="w-full flex items-center justify-between py-5 mt-4">
</div>



</div>
:
   <div style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 -mt-5">
                           <div className={`${signInError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{signInError}
</div>
</div>
</div>
   <p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Log In</p>
  
   

           <div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input onChange={(e)=>emailSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${emailError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Password</p>
<input onChange={(e)=>passwordSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${passwordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Password Can't be empty
</div>
</div>
</div>
</div>
<div className="flex justify-center mt-12 mx-auto flex-col" style={{width:'80%'}}>

<button
      type='button'
      onClick={signIn}
      style={{fontSize:'12px',margin:'auto',background:'#D9FE00',height:'55px'}}
      className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
    >
     Sign In
    </button>
    <p className='par text-center cursor-pointer' style={{marginTop:'20px'}} onClick={openForgotPassword}>Forgot Password?</p>
    </div>
    <div class="w-full flex items-center justify-between py-5 mt-4">
            <hr class="w-full bg-gray-400"/>
            <p class="text-base font-medium leading-4 px-2.5 text-gray-400"></p>
            <hr class="w-full bg-gray-400  "/>
           </div>
 
       <p class="text-center par focus:outline-none text-sm mt-8 font-medium leading-none text-gray-500">Don't have an account? <Link to='/register'><a   class="hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-gray-800 cursor-pointer"> Sign up here</a></Link></p>
     
      
   </div>
}

               
                
            </div>
        </div>
        </div>
        <div id="tablet" className='h-full '>

<Nav/>
        <div class="h-full   w-full  px-4 pb-10 pt-16">

        <div class="flex flex-col items-center justify-start">
{
isLoading === true ?
<div style={{height:'80vh',width:'80%'}} class="bg-white flex items-center justify-center shadow border rounded  w-full p-10 -mt-5">

<div
className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
role="status"></div></div>
:
<div style={{width:'80%'}}class="bg-white shadow border rounded  w-full p-10 -mt-5">
                       <div className={`${signInError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{signInError}
</div>
</div>
</div>
<p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Log In</p>



       <div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input onChange={(e)=>emailSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${emailError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Password</p>
<input onChange={(e)=>passwordSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${passwordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Password Can't be empty
</div>
</div>
</div>
</div>
<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>

<button
  type='button'
  onClick={signIn}
  style={{fontSize:'12px',margin:'auto',background:'#D9FE00',height:'55px'}}
  className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
>
 Sign In
</button>
</div>
<div class="w-full flex items-center justify-between py-5 mt-4">
        <hr class="w-full bg-gray-400"/>
        <p class="text-base font-medium leading-4 px-2.5 text-gray-400"></p>
        <hr class="w-full bg-gray-400  "/>
       </div>

   <p class="text-center par focus:outline-none text-sm mt-8 font-medium leading-none text-gray-500">Don't have an account? <Link to='/register'><a   class="hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-gray-800 cursor-pointer"> Sign up here</a></Link></p>
 
  
</div>
}

           
            
        </div>
    </div>
    </div>
    <div id="mobile" className=''>

<Nav/>
        <div class="h-full   w-full  px-4 pb-10 pt-16">

        <div class="flex flex-col items-center justify-start">
{
isLoading === true ?
<div style={{width:'80%'}} class="bg-white flex items-center justify-center shadow border rounded  w-full p-10 -mt-5">

<div
className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
role="status"></div></div>
:
<div style={{width:'80%'}}class="bg-white shadow border rounded  w-full p-10 -mt-5">
<div className={`${signInError.length !== 0 ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%',marginBottom:'3vw'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
{signInError}

</div>
</div>
</div> 

<p style={{fontSize:'5vw',marginBottom:'8vw'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Log In</p>

<div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>

<p class="bg-white  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Email</p>

<input   onChange={(e=>emailSet(e))}  type='email'  placeholder='kebede@gmail.com' className=" m-auto form-control par  border  focus:outline-none
focus:border-black   text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
<div className={`${emailError == 'error' ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
Email Can't be empty

</div>
</div>
</div> 

</div>

<div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>

<p class="bg-white  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Password</p>

<input   onChange={(e=>passwordSet(e))}  type='password'  placeholder='********' className=" m-auto form-control par  border  focus:outline-none
focus:border-black   text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
<div className={`${passwordError == 'error' ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
Password Can't be empty

</div>
</div>
</div> 

</div>
<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>

<button
  type='button'
  onClick={signIn}
  style={{fontSize:'3vw',width:'100%',margin:'auto',background:'#D9FE00',height:'13vw'}}
  className='par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
>
 Sign In
</button>
</div>
<div class="w-full flex items-center justify-between py-5 mt-4">
        <hr class="w-full bg-gray-400"/>
        <p class="text-base font-medium leading-4 px-2.5 text-gray-400"></p>
        <hr class="w-full bg-gray-400  "/>
       </div>

   <p class="text-center par focus:outline-none text-sm mt-8 font-medium leading-none text-gray-500">Don't have an account? <Link to='/register'><a   class="hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-gray-800 cursor-pointer"> Sign up here</a></Link></p>
 
  
</div>
}

           
            
        </div>
    </div>
    </div>
    </div>
  )
}
