import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import TagsInput from 'react-tagsinput'
import { firestore,auth } from '../firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { collection, addDoc, serverTimestamp, setDoc, doc, } from 'firebase/firestore';
import { storage } from '../firebase';
import { HexColorPicker } from "react-colorful";
import { signOut } from 'firebase/auth';
import { SketchPicker } from 'react-color';

export default function AddCake() {
    const [cakeTitle,setCakeTitle] = useState('')
    const [color, setColor] = useState("");
    const [error, setError] = useState("");
    const [colors, setColors] = useState([]);

    const [cakeTitleError,setCakeTitleError] = useState('')
    const [pop,setpop] = useState(false)

    const [cakeFlavor,setCakeFlavor] = useState('')
    const [cakeFlavorError,setCakeFlavorError] = useState('')
    const [cakeDescription,setCakeDescription] = useState('')
    const [cakeDescriptionError,setCakeDescriptionError] = useState('')
    const [cakeSize,setCakeSize] = useState('')
    const [cakeSizeError,setCakeSizeError] = useState('')
    const [cakePriceError,setCakePriceError] = useState('')
    const [cakePrice,setCakePrice] = useState('')
    const [cakeDeliveryError,setCakeDeliveryError] = useState('')
    const [cakeDelivery,setCakeDelivery] = useState('')
    const [uploadMessage,setuploadMessage] = useState('')
    const [cakePhoto,setcakePhoto] = useState([])
    const [cakeIngredients,setcakeIngredients] = useState([])
    const [cakeIngredientsError,setcakeIngredientsError] = useState('')
    const [success,setsuccess] = useState(false)
    const [loading,setloading] = useState(false)
    const [progress,setprogress] = useState()





    const [cakePhotoError,setCakePhotoError] = useState('')
    const [currentIndex,setCurrentIndex] = useState(0)

    const navigate = useNavigate()
    const [screenHeight, setScreenHeight] = useState(window.screen.height);

    useEffect(() => {
      const handleResize = () => {
        setScreenHeight(window.screen.height);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    const handleColorClick = () => {
      if (colors.length < 5) {
        setColors([...colors, color]);
      } else {
        alert('Maximum of 5 colors allowed.');
      }
    };
    function removeSuccess(){
      setsuccess(false)
      setcakePhoto([])
      setcakeIngredients([])
      setColors([])
      setCakeTitle('')
      setCakePrice('')
      setCakeFlavor('')
      setCakeSize('')
      setCakeDescription('')
      setCakeDelivery('')
    }
    function navigationPop(){
      if(pop === true){
        setpop(false)
      }
      else{
        setpop(true)
      }
    }
    const handleCakeIngredientsChange = value => {
      setcakeIngredients(value);
      if (value.length ===  0){
        setcakeIngredientsError("error")
    }
    else{
        setcakeIngredientsError("")
    
    }
    }
    function handleLogOut(){
      signOut(auth).then(
       navigate('/')
      )
     }
    async function addCake(e) {
      e.preventDefault()
      const document = collection(firestore, 'Cakes');
      const cakeDetails = {
        cakeTitle: cakeTitle,
        cakeDescription: cakeDescription,
        cakeFlavor: cakeFlavor,
        cakeIngredients: cakeIngredients,
        cakePhotos: [], // Initialize empty cakePhotos array
        cakePrice: cakePrice,
        cakeSize: cakeSize,
        postedTime: serverTimestamp(),
        cakeColor:colors,
        cakeDelivery:cakeDelivery
      };
    
      // Input Validation
      if (cakePhoto.length === 0) {
        setCakePhotoError('error');
        window.scrollTo(0, 0);
        return; // Exit the function if no photos are provided
      }
    
      if (cakeTitle.length === 0) {
        setCakeTitleError('error');
        window.scrollTo(0, 0);
        return;
      }
    
      if (cakePrice.length === 0) {
        setCakePriceError('error');
        window.scrollTo(0, 0);
        return;
      }
    
      if (cakeFlavor.length === 0) {
        setCakeFlavorError('error');
        window.scrollTo(0, 0);
        return;
      }
    
      if (cakeSize.length === 0) {
        setCakeSizeError('error');
        window.scrollTo(0, 0);
        return;
      }
    
      if (cakeIngredients.length === 0) {
        setcakeIngredientsError('error');
        window.scrollTo(0, 0);
        return;
      }
    
      if (cakeDescription.length === 0) {
        setCakeDescriptionError('error');
        window.scrollTo(0, 0);
        return;
      }
      if (cakeDelivery.length === 0) {
        setCakeDeliveryError('error');
        window.scrollTo(0, 0);
        return;
      }
      try {
        setloading(true);
        setprogress(0);
    
        // Upload each image and get the download URL
        const uploadPromises = cakePhoto.map(async (photo) => {
          const reff = ref(storage, `cakes/${photo.name}`);
          const uploadTask = uploadBytesResumable(reff, photo);
    
          uploadTask.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setprogress(Math.round(progress));
          });
    
          try {
            await uploadTask;
            const downloadURL = await getDownloadURL(reff);
            return downloadURL;
          } catch (error) {
            console.log(error);
            setloading(false);
            return null;
          }
        });
    
        const downloadURLs = await Promise.all(uploadPromises);
        const successfulUploads = downloadURLs.filter(Boolean);
    
        // Add the cake document with photo URLs
        const cakeDocRef = await addDoc(document, cakeDetails);
    
        // Update the cakePhotos array with the document ID as the photo ID
        cakeDetails.cakePhotos = successfulUploads.map(url => ({ photoId: cakeDocRef.id, cakePhotos: url }));
    
        // Update the cake document with the updated cakePhotos array
        await setDoc(doc(firestore, 'Cakes', cakeDocRef.id), cakeDetails);
    
        setloading(false);
        setsuccess(true);
      } catch (error) {
        console.error('An error occurred while adding the cake:', error);
        setloading(false);
        setError((prevErrors) => ({
          ...prevErrors,
          general: 'An error occurred while adding the cake. Please try again.',
        }));
      }
    }
  


    function cakeTitleSet(e){
        setCakeTitle(e.target.value)
        if(e.target.value.length === 0){
            setCakeTitleError('error')
        }
        else{
            setCakeTitleError('')
        } 

    }
    
    function cakeDeliverySet(e){
      setCakeDelivery(e.target.value)
      if(e.target.value.length === 0){
          setCakeDeliveryError('error')
      }
      else{
          setCakeDeliveryError('')
      } 

  }
    function cakeFlavorSet(e){
      setCakeFlavor(e.target.value)
      if(e.target.value.length === 0){
          setCakeFlavorError('error')
      }
      else{
          setCakeFlavorError('')
      } 

  }

    function cakeSizeSet(e){
      setCakeSize(e.target.value)
      if(e.target.value.length === 0){
          setCakeSizeError('error')
      }
      else{
          setCakeSizeError('')
      } 

  }

  function cakeDescriptionSet(e){
    setCakeDescription(e.target.value)
    if(e.target.value.length === 0){
        setCakeDescriptionError('error')
    }
    else{
        setCakeDescriptionError('')
    } 

}

    function cakePriceSet(e){
        setCakePrice(e.target.value)
        if(e.target.value.length === 0){
            setCakePriceError('error')
        }
        else{
            setCakePriceError('')
        } 

    }

    function handleImgSelect(event) {
        const selectedFiles = event.target.files;
      console.log(cakePhoto)
        // Check if any files are selected
        if (selectedFiles.length > 0) {
          let hasInvalidFiles = false;
          const maxSize = 10 * 1024 * 1024; // 10MB
          const newCakePhotos = [...cakePhoto]; // Create a copy of the existing cakePhoto array
      
          // Loop through the selected files
          for (let i = 0; i < selectedFiles.length; i++) {
            const selectedFile = selectedFiles[i];
      
            // Check file type
            if (!selectedFile.type.startsWith('image/')) {
              setuploadMessage(`File ${selectedFile.name} is not a valid image file.`);
              hasInvalidFiles = true;
              continue;
            }
      
            // Check file size
            if (selectedFile.size > maxSize) {
              setuploadMessage(`File ${selectedFile.name} exceeds the size limit of 10MB.`);
              hasInvalidFiles = true;
              continue;
            }
      
            // Add the selected file to the newCakePhotos array
            newCakePhotos.push(selectedFile);
          }
      
          if (!hasInvalidFiles) {
            setuploadMessage('');
            setcakePhoto(newCakePhotos);
          }
        } else {
          setuploadMessage('');
          setcakePhoto(null);
        }
      
        setCakePhotoError('');
      
        // Clear the file input value to allow selecting the same file again
        event.target.value = '';
      }

      const handlePrevious = () => {
        setCurrentIndex((currentIndex - 1 + cakePhoto.length) % cakePhoto.length);
      };
    
      const handleNext = () => {
        setCurrentIndex((currentIndex + 1) % cakePhoto.length);
      };

  return (
    
    <div>
        <div id='desktop'>
            <div className='flex'>

          
            <aside class="bg-gray-900 adminNav  fixed inset-0 z-50 my-4 ml-4  rounded-xl transition-transform duration-300 xl:translate-x-0">
    <div class="relative border-b border-white/20">
      <div class="flex items-center gap-4 py-6 px-8" >
        <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
      </div>
   
    </div>
    <div class="m-4">
      <ul class="mb-4 flex flex-col gap-1">
        <li>
        <Link to='/admin'>
        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
             <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
           </button>
          
          </Link>
        </li>
        <li>
        <Link to='/admin/orders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/allorders'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
              
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/addcake'>
        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center gap-4 px-4 capitalize" type="button">
            
            <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
          </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/managecakes'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/messages'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/reserved'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Reserved Students</p>
            </button>
          </Link>
        </li>
      </ul>
      <ul class="mb-4 flex flex-col gap-1">
        <li class="mx-3.5 mt-4 mb-2">
          <p class="barlow block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
        </li>
    
        <li>
            <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
            
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
            </button>
        </li>
      </ul>
    </div>
  </aside>
  <div className="adminBody ">
{
  loading && error.length === 0 === true ?
  <div>
    
 

  <div className="flex flex-col items-center justify-center w-full text-surface dark:text-white" style={{height:'50vw'}}>
  <div
    className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
    role="status"></div>
      <div className=" bg-gray-200 rounded-full dark:bg-gray-700 mt-5">
    <div className=" text-xs font-medium text-gray-800 text-center p-0.5 leading-none rounded-full" style={{width:progress,background:'#D9FE00'}}> {progress}%</div>
  </div>
  </div>

  

</div>
:
success === true ?
<div className='success flex-col' > 
<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<h1 className='header' style={{fontSize:'40px'}}>Successfully Posted your New Cake</h1>

<button
                   type='button'
                   onClick={removeSuccess}
                   style={{fontSize:'12px',background:'black',height:'55px',width:'50%'}}
                   className='par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                 >
                   Done
                 </button>
</div>
:

<div >
  <h3 className="flex flex-col items-start justify-center  ml-0 font-medium text-xl/tight text-dark">
                    <span className="barlow ml-12  mt-8" style={{fontSize:'25px'}}>Add Cake</span>
                
                </h3>
 <div className='flex items-center' >

 <div className={`${error.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{error}
</div>
</div>
</div>

 <div id="gallery" className="relative w-full px-12" data-carousel="slide" style={{width:'40%'}}>
  <div className="relative overflow-hidden rounded-lg">
    <div className="relative overflow-hidden rounded-lg">
      <div className="duration-700 ease-in-out" data-carousel-item>
        {cakePhoto.map((file, index) => (
          <img
            key={index}
            src={URL.createObjectURL(file)}
            alt={`Selected file ${index}`}
            style={{height:'300px',objectFit:'cover'}}
            className={`rounded-t-xl w-full h-auto ${
              index === currentIndex ? 'block' : 'hidden'
            }`}
          />
        ))}
        {
          cakePhoto.length === 0 &&
    <img className="h-auto w-full rounded-lg" 
    style={{height:'300px',objectFit:'cover'}}
    src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="description"/>

        }
      </div>

      {cakePhoto.length >= 2 && (
        <button
          type="button"
          className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-prev
        >
          <span onClick={handlePrevious} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50  group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
              
              className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
            <span className="sr-only">Previous</span>
          </span>
        </button>
      )}

      {cakePhoto.length >= 2 && (
        <button
          type="button"
          className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-next
        >
          <span onClick={handleNext} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50  group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
              
              className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
            <span className="sr-only">Next</span>
          </span>
        </button>
      )}
    </div>
  </div>

  <div className="flex relative justify-center items-center flex-col">
    <div className="px-5 pb-5 relative flex w-full flex-col rounded-lg border border-gray-100 bg-white shadow-md">
      <h5 className="mt-4 par tracking-tight text-slate-900" style={{ fontSize: '15px' }}>
        {cakeTitle.length === 0 ? 'Cake Title' : cakeTitle}
      </h5>
      <div className="mt-2 mb-5 flex items-center justify-between">
        <p>
          <span className="header font-bold text-slate-900" style={{ fontSize: '20px' }}>
            {cakePrice.length === 0 ? 'Cake Price' : cakePrice + ' ETB'}
          </span>
          {
            colors.length != 0 &&
            <span className='flex mt-4'>
          {colors.map((color, index) => (
          <div  class=" w-5 h-5  rounded-full shadow border  " style={{backgroundColor:color}}></div>

        ))}
          </span>
          }
          
        </p>
      </div>
      <button className="par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        Add to cart
      </button>
    </div>
  </div>
</div>

<div  className='flex flex-col items-center py-10' style={{width:'70%'}}>
<div className="relative mx-auto mt-20" style={{width:'80%'}}>
  <input
    accept="image/jpeg, image/png, image/gif"
    type="file"
    id="hidden-input-img"
    className="hidden"
    multiple
    onChange={handleImgSelect}
  />

  <button
    className="par px-5 py-2 mt-2 text-white rounded-sm par bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
    onClick={() => {
      document.getElementById('hidden-input-img').click();
    }}
  >
    Cake Photo
  </button>

  <div className={`${cakePhotoError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Cake Photo Can't be empty
</div>
</div>
</div>
<div className={`${uploadMessage.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{uploadMessage}
</div>
</div>
</div>
</div>


<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Cake Title</p>
<input style={{fontSize:'12px',height:'60px'}} onChange={e=>cakeTitleSet(e)}  required  autoComplete="off" placeholder="white chocolate cake" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />

<div className={`${cakeTitleError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Cake Title Can't be empty
</div>
</div>
</div>
</div>

<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'12px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Cake Price</p>
<input style={{fontSize:'11px',height:'60px'}} onChange={e=>cakePriceSet(e)}  required  autoComplete="off" placeholder="1,000 ETB" type="number" className="border  focus:outline-none
focus:border-black w-full pt-5  pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />

<div className={`${cakePriceError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Cake price Can't be empty
</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'12px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Cake Flavor</p>
<input style={{fontSize:'11px',height:'60px'}} onChange={e=>cakeFlavorSet(e)}  required  autoComplete="off" placeholder="Chocolate" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5  pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />

<div className={`${cakeFlavorError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Cake Flavor Can't be empty
</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'12px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Cake Size</p>
<input style={{fontSize:'11px',height:'60px'}} onChange={e=>cakeSizeSet(e)}  required  autoComplete="off" placeholder="Large" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5  pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />

<div className={`${cakeSizeError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Cake size Can't be empty
</div>
</div>
</div>
</div>
<div className="relative m-auto mt-12" style={{width:'80%'}}>
<p style={{fontSize:'12px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Cake Ingredients</p>

<TagsInput
 inputProps={{
     placeholder: 'Vanilla, Strawberry',
     className: 'form-control borel text-black skillClass m-auto focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md',
 }}
 tabindex="5"
 className='form-control text-black border skillClass m-auto lowercase focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md'
 maxTags={10}
 value={cakeIngredients}
 onChange={handleCakeIngredientsChange}
 renderTag={({ tag, key, disabled, onRemove }) => (
     <span key={key} style={{ margin:"3px",fontSize:'11px' }} className='par px-5 py-2 capitalize skillGrid  bg-blue-800'>
         {tag}
         {!disabled && <span onClick={() => onRemove(key)} className='cursor-pointer'>X</span>}
     </span>
 )}
/>
<div className={`${cakeIngredientsError  === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Cake Ingredients Can't be empty

</div>
</div>
</div>
</div>
<div className="relative mx-auto mt-12" style={{width:"80%"}}>

<p style={{fontSize:'12px'}} className="bg-white par pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Cake Description</p>
<textarea placeholder='The cake has two layers. Between the layers is chocolate frosting. The whole cake is covered in more chocolate frosting.'  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'12px'}}   onChange={e=>cakeDescriptionSet(e)}  type="text" className="border  focus:outline-none
      focus:border-black borel w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md" maxLength="5000"></textarea>
      <div className={`${cakeDescriptionError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Description Can't be empty
</div>
</div>
</div>

</div>
<div className="relative mx-auto mt-12" style={{width:'80%'}}>


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Delivery Option</p>
<select onChange={(e)=>cakeDeliverySet(e)} style={{fontSize:'11px',height:'60px'}} name="" id="" className='border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md'>
  <option value="">Select Option</option>

  <option value="Deliver">Deliver</option>
  <option value="Shop Pickup">Shop Pickup</option>

</select>


<div className={`${cakeDeliveryError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Cake Delivery Can't be empty
</div>
</div>
</div>
</div>
<div className="flex items-center mx-auto mt-12" style={{width:"80%"}}>
<div>
<p style={{fontSize:'12px'}} className="bg-white par pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 ">Cake Color (Optional)</p>

 <input type="color" onChange={(e)=>setColor(e.target.value)} />

</div>
{
  color !== '' &&
<button onClick={handleColorClick} class="w-10 h-10  rounded-full shadow border  ml-4" style={{backgroundColor:color}}></button>

}
{colors.map((color, index) => (
          <div  class="w-10 h-10  rounded-full shadow border  ml-4" style={{backgroundColor:color}}></div>

        ))}
<p className='par hover:underline cursor-pointer ml-4' onClick={()=>{setColors([]); setColor('')}}>Reset</p>
</div>

<div className="flex justify-center mt-12" style={{width:'80%'}}>

<button
                   type='button'
                   onClick={addCake}
                   style={{fontSize:'12px',margin:'auto',background:'#D9FE00',height:'55px'}}
                   className='par  w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                 >
                   Post Cake
                 </button>
                 </div>

</div>
 </div>

  </div>
}
  
  </div>

</div>

        </div>
        <div id='tablet'>
            <div className=''>

      {
        pop === true ?
        <aside style={{minHeight:screenHeight}}class="bg-gray-900     transition-transform duration-300 xl:translate-x-0">
                    <div class="relative border-b border-white/20">
    <svg onClick={navigationPop} aria-hidden="true" class="w-8 h-8 text-white absolute top-5 right-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                     
                      <div class="flex justify-center items-center  py-6 px-2" >
                        <h6 class="text-center block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
                      </div>
                   
                    </div>
                    <div class="my-4">
                      <ul class="m-4 flex flex-col ">
                        <li>
                        <Link to='/admin'>
                        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                             <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
                           </button>
                           
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/orders'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/allorders'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                              
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/addcake'>
                        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center  pl-2" type="button">
                            
                            <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
                          </button>
                        
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/managecakes'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/messages'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/reserved'>
                
                        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                             <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Students</p>
                           </button>
                          </Link>
                        </li>
                      </ul>
                      <ul class="m-4 flex flex-col">
                        <li class=" mt-4 mb-2">
                          <p class="par block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
                        </li>
                    
                        <li>
                            <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                            
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
                            </button>
                        </li>
                      </ul>
                    </div>
                  </aside>
                  :
                  <div class="p-4   px-8">

                      <nav class="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-0 py-1">
                    <div class="flex flex-col  gap-6 ">
                    
                      <div class="flex justify-between w-full items-center mb-4">
              <Link to='/admin'style={{width:'18%'}}>
        <img style={{width:'100%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />
</Link>
        <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
              </button>
              </div>  
                    </div>
                  </nav>
                  {
                    loading && error.length === 0 === true ?
                    <div>
                      
                   
                  
                    <div className="flex flex-col items-center justify-center w-full text-surface dark:text-white" style={{height:'50vw'}}>
                    <div
                      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                      role="status"></div>
                        <div className=" bg-gray-200 rounded-full dark:bg-gray-700 mt-5">
                      <div className=" text-xs font-medium text-gray-800 text-center p-0.5 leading-none rounded-full" style={{width:progress,background:'#D9FE00'}}> {progress}%</div>
                    </div>
                    </div>
                  
                    
                  
                  </div>
                  :
                  success === true ?
                  <div className='success flex-col' > 
                  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </svg>
                  <h1 className='header' style={{fontSize:'40px'}}>Successfully Posted your New Cake</h1>
                  
                  <button
                                     type='button'
                                     onClick={removeSuccess}
                                     style={{fontSize:'12px',background:'black',height:'55px',width:'50%'}}
                                     className='par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                                   >
                                     Done
                                   </button>
                  </div>
                  :
                  
                  <div >
                    <h3 className="flex flex-col items-start justify-center  ml-0 font-medium text-xl/tight text-dark">
                                      <span className="barlow ml-12  mt-8" style={{fontSize:'25px'}}>Add Cake</span>
                                  
                                  </h3>
                   <div className='flex flex-col items-center' >
                  
                   <div className={`${error.length !== 0 ? "block":"hidden"}`}>
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className='Mooli'>
                  {error}
                  </div>
                  </div>
                  </div>
                  
                   
                  
                  <div  className='flex flex-col items-center py-10' style={{width:'90%'}}>
                  <div className="relative mx-auto " style={{width:'80%'}}>
                    <input
                      accept="image/jpeg, image/png, image/gif"
                      type="file"
                      id="hidden-input-img"
                      className="hidden"
                      multiple
                      onChange={handleImgSelect}
                    />
                  
                    <button
                      className="par px-5 py-2 mt-2 text-white rounded-sm par bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
                      onClick={() => {
                        document.getElementById('hidden-input-img').click();
                      }}
                    >
                      Cake Photo
                    </button>
                  
                    <div className={`${cakePhotoError === "error" ? "block":"hidden"}`}>
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className='Mooli'>
                  Cake Photo Can't be empty
                  </div>
                  </div>
                  </div>
                  <div className={`${uploadMessage.length !== 0 ? "block":"hidden"}`}>
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className='Mooli'>
                  {uploadMessage}
                  </div>
                  </div>
                  </div>
                  </div>
                  
                  
                  <div className="relative mx-auto mt-12" style={{width:'80%'}}>
                  
                  
                  <p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Cake Title</p>
                  <input style={{fontSize:'12px',height:'60px'}} onChange={e=>cakeTitleSet(e)}  required  autoComplete="off" placeholder="white chocolate cake" type="text" className="border  focus:outline-none
                  focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                  border-gray-300 rounded-md" />
                  
                  <div className={`${cakeTitleError === "error" ? "block":"hidden"}`}>
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className='Mooli'>
                  Cake Title Can't be empty
                  </div>
                  </div>
                  </div>
                  </div>
                  
                  <div className="relative mx-auto mt-12" style={{width:'80%'}}>
                  
                  
                  <p style={{fontSize:'12px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Cake Price</p>
                  <input style={{fontSize:'11px',height:'60px'}} onChange={e=>cakePriceSet(e)}  required  autoComplete="off" placeholder="1,000 ETB" type="number" className="border  focus:outline-none
                  focus:border-black w-full pt-5  pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                  border-gray-300 rounded-md" />
                  
                  <div className={`${cakePriceError === "error" ? "block":"hidden"}`}>
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className='Mooli'>
                  Cake price Can't be empty
                  </div>
                  </div>
                  </div>
                  </div>
                  <div className="relative mx-auto mt-12" style={{width:'80%'}}>
                  
                  
                  <p style={{fontSize:'12px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Cake Flavor</p>
                  <input style={{fontSize:'11px',height:'60px'}} onChange={e=>cakeFlavorSet(e)}  required  autoComplete="off" placeholder="Chocolate" type="text" className="border  focus:outline-none
                  focus:border-black w-full pt-5  pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                  border-gray-300 rounded-md" />
                  
                  <div className={`${cakeFlavorError === "error" ? "block":"hidden"}`}>
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className='Mooli'>
                  Cake Flavor Can't be empty
                  </div>
                  </div>
                  </div>
                  </div>
                  <div className="relative mx-auto mt-12" style={{width:'80%'}}>
                  
                  
                  <p style={{fontSize:'12px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Cake Size</p>
                  <input style={{fontSize:'11px',height:'60px'}} onChange={e=>cakeSizeSet(e)}  required  autoComplete="off" placeholder="Large" type="text" className="border  focus:outline-none
                  focus:border-black w-full pt-5  pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                  border-gray-300 rounded-md" />
                  
                  <div className={`${cakeSizeError === "error" ? "block":"hidden"}`}>
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className='Mooli'>
                  Cake size Can't be empty
                  </div>
                  </div>
                  </div>
                  </div>
                  <div className="relative m-auto mt-12" style={{width:'80%'}}>
                  <p style={{fontSize:'12px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Cake Ingredients</p>
                  
                  <TagsInput
                   inputProps={{
                       placeholder: 'Vanilla, Strawberry',
                       className: 'form-control borel text-black skillClass m-auto focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md',
                   }}
                   tabindex="5"
                   className='form-control text-black border skillClass m-auto lowercase focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md'
                   maxTags={10}
                   value={cakeIngredients}
                   onChange={handleCakeIngredientsChange}
                   renderTag={({ tag, key, disabled, onRemove }) => (
                       <span key={key} style={{ margin:"3px",fontSize:'11px' }} className='par px-5 py-2 capitalize skillGrid  bg-blue-800'>
                           {tag}
                           {!disabled && <span onClick={() => onRemove(key)} className='cursor-pointer'>X</span>}
                       </span>
                   )}
                  />
                  <div className={`${cakeIngredientsError  === "error" ? "block":"hidden"}`}>
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className='Mooli'>
                  Cake Ingredients Can't be empty
                  
                  </div>
                  </div>
                  </div>
                  </div>
                  <div className="relative mx-auto mt-12" style={{width:"80%"}}>
                  
                  <p style={{fontSize:'12px'}} className="bg-white par pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Cake Description</p>
                  <textarea placeholder='The cake has two layers. Between the layers is chocolate frosting. The whole cake is covered in more chocolate frosting.'  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'12px'}}   onChange={e=>cakeDescriptionSet(e)}  type="text" className="border  focus:outline-none
                        focus:border-black borel w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                        border-gray-300 rounded-md" maxLength="5000"></textarea>
                        <div className={`${cakeDescriptionError === "error" ? "block":"hidden"}`}>
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className='Mooli'>
                  Description Can't be empty
                  </div>
                  </div>
                  </div>
                  
                  </div>
                  <div className="relative mx-auto mt-12" style={{width:'80%'}}>
                  
                  
                  <p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Delivery Option</p>
                  <select onChange={(e)=>cakeDeliverySet(e)} style={{fontSize:'11px',height:'60px'}} name="" id="" className='border  focus:outline-none
                  focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                  border-gray-300 rounded-md'>
                    <option value="">Select Option</option>
                  
                    <option value="Deliver">Deliver</option>
                    <option value="Shop Pickup">Shop Pickup</option>
                  
                  </select>
                  
                  
                  <div className={`${cakeDeliveryError === "error" ? "block":"hidden"}`}>
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className='Mooli'>
                  Cake Delivery Can't be empty
                  </div>
                  </div>
                  </div>
                  </div>
                  <div className="flex items-center mx-auto mt-12" style={{width:"80%"}}>
                  <div>
                  <p style={{fontSize:'12px'}} className="bg-white par pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 ">Cake Color (Optional)</p>
                  
                  <HexColorPicker color={color} onChange={setColor} />;
                  </div>
                  {
                    color !== '' &&
                  <button onClick={handleColorClick} class="w-10 h-10  rounded-full shadow border  ml-4" style={{backgroundColor:color}}></button>
                  
                  }
                  {colors.map((color, index) => (
                            <div  class="w-10 h-10  rounded-full shadow border  ml-4" style={{backgroundColor:color}}></div>
                  
                          ))}
                  <p className='par hover:underline cursor-pointer ml-4' onClick={()=>{setColors([]); setColor('')}}>Reset</p>
                  </div>
                  
                  <div className="flex justify-center mt-12" style={{width:'80%'}}>
                  
                  <button
                                     type='button'
                                     onClick={addCake}
                                     style={{fontSize:'12px',margin:'auto',background:'#D9FE00',height:'55px'}}
                                     className='par  w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                                   >
                                     Post Cake
                                   </button>
                                   </div>
                  
                  </div>
                  <div id="gallery" className="relative w-full " data-carousel="slide" style={{width:'40%'}}>
                    <div className="relative overflow-hidden rounded-lg">
                      <div className="relative overflow-hidden rounded-lg">
                        <div className="duration-700 ease-in-out" data-carousel-item>
                          {cakePhoto.map((file, index) => (
                            <img
                              key={index}
                              src={URL.createObjectURL(file)}
                              alt={`Selected file ${index}`}
                              style={{height:'300px',objectFit:'cover'}}
                              className={`rounded-t-xl w-full h-auto ${
                                index === currentIndex ? 'block' : 'hidden'
                              }`}
                            />
                          ))}
                          {
                            cakePhoto.length === 0 &&
                      <img className="h-auto w-full rounded-lg" 
                      style={{height:'300px',objectFit:'cover'}}
                      src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="description"/>
                  
                          }
                        </div>
                  
                        {cakePhoto.length >= 2 && (
                          <button
                            type="button"
                            className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                            data-carousel-prev
                          >
                            <span onClick={handlePrevious} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50  group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                              <svg
                                
                                className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 6 10"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 1 1 5l4 4"
                                />
                              </svg>
                              <span className="sr-only">Previous</span>
                            </span>
                          </button>
                        )}
                  
                        {cakePhoto.length >= 2 && (
                          <button
                            type="button"
                            className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                            data-carousel-next
                          >
                            <span onClick={handleNext} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50  group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                              <svg
                                
                                className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 6 10"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m1 9 4-4-4-4"
                                />
                              </svg>
                              <span className="sr-only">Next</span>
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  
                    <div className="flex relative justify-center items-center flex-col">
                      <div className="px-5 pb-5 relative flex w-full flex-col rounded-lg border border-gray-100 bg-white shadow-md">
                        <h5 className="mt-4 par tracking-tight text-slate-900" style={{ fontSize: '15px' }}>
                          {cakeTitle.length === 0 ? 'Cake Title' : cakeTitle}
                        </h5>
                        <div className="mt-2 mb-5 flex items-center justify-between">
                          <p>
                            <span className="header font-bold text-slate-900" style={{ fontSize: '20px' }}>
                              {cakePrice.length === 0 ? 'Cake Price' : cakePrice + ' ETB'}
                            </span>
                            {
                              colors.length != 0 &&
                              <span className='flex mt-4'>
                            {colors.map((color, index) => (
                            <div  class=" w-5 h-5  rounded-full shadow border  " style={{backgroundColor:color}}></div>
                  
                          ))}
                            </span>
                            }
                            
                          </p>
                        </div>
                        <button className="par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2 h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                            />
                          </svg>
                          Add to cart
                        </button>
                      </div>
                    </div>
                  </div>
                   </div>
                  
                    </div>
                  }
                    
                    </div>
      }    
      


</div>

        </div>
        <div id='mobile'>
            <div className=''>

      {
        pop === true ?
        <aside style={{minHeight:screenHeight}}class="bg-gray-900     transition-transform duration-300 xl:translate-x-0">
                    <div class="relative border-b border-white/20">
    <svg onClick={navigationPop} aria-hidden="true" class="w-8 h-8 text-white absolute top-5 right-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                     
                      <div class="flex justify-center items-center  py-6 px-2" >
                        <h6 class="text-center block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
                      </div>
                   
                    </div>
                    <div class="my-4">
                      <ul class="m-4 flex flex-col ">
                        <li>
                        <Link to='/admin'>
                        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                             <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
                           </button>
                           
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/orders'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/allorders'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                              
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/addcake'>
                        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center  pl-2" type="button">
                            
                            <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
                          </button>
                        
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/managecakes'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/messages'>
                
                            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
                            </button>
                          </Link>
                        </li>
                        <li>
                        <Link to='/admin/reserved'>
                
                        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                             
                             <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Students</p>
                           </button>
                          </Link>
                        </li>
                      </ul>
                      <ul class="m-4 flex flex-col">
                        <li class=" mt-4 mb-2">
                          <p class="par block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
                        </li>
                    
                        <li>
                            <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                            
                              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
                            </button>
                        </li>
                      </ul>
                    </div>
                  </aside>
                  :
                  <div class="p-4   ">

                      <nav class="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-8 py-3">
                    <div class="flex flex-col  gap-6 ">
                    
                      <div class="flex justify-between w-full items-center mb-4">
              <Link to='/admin'style={{width:'18%'}}>
        <img style={{width:'100%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />
</Link>
        <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
              </button>
              </div>  
                    </div>
                  </nav>
                  {
                    loading && error.length === 0 === true ?
                    <div>
                      
                   
                  
                    <div className="flex flex-col items-center justify-center w-full text-surface dark:text-white" style={{height:'50vw'}}>
                    <div
                      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                      role="status"></div>
                        <div className=" bg-gray-200 rounded-full dark:bg-gray-700 mt-5">
                      <div className=" text-xs font-medium text-gray-800 text-center p-0.5 leading-none rounded-full" style={{width:progress,background:'#D9FE00'}}> {progress}%</div>
                    </div>
                    </div>
                  
                    
                  
                  </div>
                  :
                  success === true ?
                  <div className='success flex-col' > 
                  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </svg>
                  <h1 className='header text-center' style={{fontSize:'8vw'}}>Successfully Posted your New Cake</h1>
                  
                  <button
                                     type='button'
                                     onClick={removeSuccess}
                                     style={{fontSize:'12px',background:'black',height:'55px',width:'50%'}}
                                     className='par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                                   >
                                     Done
                                   </button>
                  </div>
                  :
                  <div >
                    <h3 className="flex flex-col items-start justify-center  ml-0 font-medium text-xl/tight text-dark">
                                      <span className="barlow ml-12  mt-8" style={{fontSize:'25px'}}>Add Cake</span>
                                  
                                  </h3>
                   <div className='flex flex-col items-center' >
                  
                   <div className={`${error.length !== 0 ? "block":"hidden"}`}>
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className='Mooli'>
                  {error}
                  </div>
                  </div>
                  </div>
                  
                   
                  
                  <div  className='flex flex-col items-center py-10' style={{width:'90%'}}>
                  <div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",lineHeight:"4vw"}}>
                  
                    <input
                      accept="image/jpeg, image/png, image/gif"
                      type="file"
                      id="hidden-input-img"
                      className="hidden"
                      multiple
                      onChange={handleImgSelect}
                    />
                  
                    <button
                      className="par px-5 py-2 mt-2 text-white rounded-sm par bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
                      onClick={() => {
                        document.getElementById('hidden-input-img').click();
                      }}
                    >
                      Cake Photo
                    </button>
                  
                    <div className={`${cakePhotoError === "error" ? "block":"hidden"}`}>
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className='Mooli'>
                  Cake Photo Can't be empty
                  </div>
                  </div>
                  </div>
                  <div className={`${uploadMessage.length !== 0 ? "block":"hidden"}`}>
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className='Mooli'>
                  {uploadMessage}
                  </div>
                  </div>
                  </div>
                  </div>
                  
                  
                  <div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",lineHeight:"4vw"}}>

<p class="bg-white barlow  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Cake Title</p>

<input   onChange={(e=>cakeTitleSet(e))}  type='text'  placeholder='White chocolate cake' className=" m-auto form-control par  border  focus:outline-none
focus:border-black   text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
<div className={`${cakeTitleError == 'error' ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
Cake Title Can't be empty

</div>
</div>
</div> 

</div>
             
                  <div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",lineHeight:"4vw"}}>

<p class="bg-white barlow  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Cake Price</p>

<input   onChange={(e=>cakePriceSet(e))}  type='number'  placeholder='300' className=" m-auto form-control par  border  focus:outline-none
focus:border-black   text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
<div className={`${cakePriceError == 'error' ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
Cake Price Can't be empty

</div>
</div>
</div> 

</div>
<div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",lineHeight:"4vw"}}>

<p class="bg-white barlow  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Cake Flavor</p>

<input   onChange={(e=>cakeFlavorSet(e))}  type='text'  placeholder='vanilla' className=" m-auto form-control par  border  focus:outline-none
focus:border-black   text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
<div className={`${cakeFlavorError == 'error' ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
Cake Flavor Can't be empty

</div>
</div>
</div> 

</div>        
                  
<div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",lineHeight:"4vw"}}>

<p class="bg-white barlow  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Cake Size</p>

<input   onChange={(e=>cakeSizeSet(e))}  type='text'  placeholder='Large' className=" m-auto form-control par  border  focus:outline-none
focus:border-black   text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp " style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} />
<div className={`${cakeSizeError == 'error' ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
Cake Size Can't be empty

</div>
</div>
</div> 

</div>     
<form className='w-full'>
<div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",lineHeight:"4vw"}}>
<p class="bg-white  font-medium text-gray-600 absolute barlow"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"1vw",paddingRight:"2vw"}}>Cake Ingredients(Max 10)</p>

<TagsInput
 inputProps={{
  placeholder: 'Vanilla, Strawberry',
  className: 'form-control borel text-black skillClass m-auto focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md',
}}
 tabindex="5"
 className=' text-black border skillClass m-auto lowercase focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md'
 maxTags={10}
 value={cakeIngredients}

 onChange={handleCakeIngredientsChange}

 renderTag={({ tag, key, disabled, onRemove }) => (
     <span key={key} style={{margin:"2vw" }} className=' py-2 skillGrid  text-gray-800'>
         <p className='fjalla ' style={{fontSize:'3vw'}}>{tag}</p>
         {!disabled && <span onClick={() => onRemove(key)} className='cursor-pointer'>X</span>}
     </span>
 )}
/>

<div className={`${cakeIngredientsError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Cake Ingredients Can't be empty

</div>
</div>
</div> 
</div></form>
<div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",lineHeight:"4vw"}}>
<p class="bg-white barlow  font-medium text-gray-600 absolute par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Delivery Option</p>
 
                  
                  <select style={{paddingTop:"6vw",width:'100%',paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} onChange={(e)=>cakeDeliverySet(e)}  name="" id="" className='border  focus:outline-none
                  focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                  border-gray-300 rounded-md'>
                    <option value="">Select Option</option>
                  
                    <option value="Deliver">Deliver</option>
                    <option value="Shop Pickup">Shop Pickup</option>
                  
                  </select>
                  
                  <div className={`${cakeDeliveryError == 'error' ? "block":"hidden"}`}>
<div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'3vw'}}>
Cake Delivery Can't be empty

</div>
</div>
</div> 
                 
                  </div>   
                  <div className="relative m-auto " style={{width:"90%",marginBottom:"10vw",lineHeight:"4vw"}}>

<p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"1vw",paddingRight:"2vw"}}>Cake Description</p>


<textarea
  style={{
    padding: '3vw 3.5vw',
    fontSize: '3vw',
    minHeight: '50vw',
    maxHeight: '80vw',
  }}
  placeholder="The cake has two layers. Between the layers is chocolate frosting. The whole cake is covered in more chocolate frosting."
  onChange={e => cakeDescriptionSet(e)}
  className="border text-gray-600 focus:outline-none focus:border-black w-full text-base block bg-white border-gray-300 rounded-md"
  maxLength={1000}
></textarea>
<p className='fjalla' style={{fontSize: '3vw'}}>{cakeDescription.length + '/ 1,000'}</p>
   <div className={`${cakeDescriptionError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Description Can't be empty

</div>
</div>
</div> 

</div>

                  <div className="flex flex-col items-center mx-auto mt-12" style={{width:"90%"}}>
                  <div>
<p class="bg-white barlow  font-medium text-gray-600  par"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Cake Color (Optional)</p>
                  
                  <HexColorPicker color={color} onChange={setColor} />;
                  </div>
          <div className='flex items-center'>
          {
                    color !== '' &&
                  <button onClick={handleColorClick} class="w-10 h-10  rounded-full shadow border  " style={{backgroundColor:color,width:'7vw',height:'7vw',marginLeft:'1vw'}}></button>
                  
                  }
                  {colors.map((color, index) => (
                            <div  class="w-10 h-10  rounded-full shadow border  " style={{backgroundColor:color,width:'5vw',height:'5vw',marginLeft:'1vw'}}></div>
                  
                          ))}
                  <p className='par hover:underline cursor-pointer 'style={{fontSize:'3vw'}} onClick={()=>{setColors([]); setColor('')}}>Reset</p>
                 
            </div> </div>
                  
                  <div className="flex justify-center mt-12" style={{width:'80%'}}>
                  
                  <button
                                     type='button'
                                     onClick={addCake}
                                     style={{fontSize:'3vw',margin:'auto',background:'#D9FE00',height:'13vw'}}
                                     className='par  w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                                   >
                                     Post Cake
                                   </button>
                                   </div>
                  
                  </div>
                  <div id="gallery" className="relative w-full " data-carousel="slide" style={{width:'80%'}}>
                    <div className="relative overflow-hidden rounded-lg">
                      <div className="relative overflow-hidden rounded-lg">
                        <div className="duration-700 ease-in-out" data-carousel-item>
                          {cakePhoto.map((file, index) => (
                            <img
                              key={index}
                              src={URL.createObjectURL(file)}
                              alt={`Selected file ${index}`}
                              style={{minHeight:'200px',objectFit:'cover'}}
                              className={`rounded-t-xl w-full h-auto ${
                                index === currentIndex ? 'block' : 'hidden'
                              }`}
                            />
                          ))}
                          {
                            cakePhoto.length === 0 &&
                      <img className="h-auto w-full rounded-lg" 
                      style={{minHeight:'200px',objectFit:'cover'}}
                      src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="description"/>
                  
                          }
                        </div>
                  
                        {cakePhoto.length >= 2 && (
                          <button
                            type="button"
                            className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                            data-carousel-prev
                          >
                            <span onClick={handlePrevious} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50  group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                              <svg
                                
                                className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 6 10"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 1 1 5l4 4"
                                />
                              </svg>
                              <span className="sr-only">Previous</span>
                            </span>
                          </button>
                        )}
                  
                        {cakePhoto.length >= 2 && (
                          <button
                            type="button"
                            className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                            data-carousel-next
                          >
                            <span onClick={handleNext} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50  group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                              <svg
                                
                                className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 6 10"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m1 9 4-4-4-4"
                                />
                              </svg>
                              <span className="sr-only">Next</span>
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  
                    <div className="flex relative justify-center items-center flex-col">
                      <div className="px-5 pb-5 relative flex w-full flex-col rounded-lg border border-gray-100 bg-white shadow-md">
                        <h5 className="mt-4 par tracking-tight text-slate-900" style={{ fontSize: '3.3vw' }}>
                          {cakeTitle.length === 0 ? 'Cake Title' : cakeTitle}
                        </h5>
                        <div className="mt-2 mb-5 flex items-center justify-between">
                          <p>
                            <span className="header font-bold text-slate-900" style={{ fontSize: '4.3vw' }}>
                              {cakePrice.length === 0 ? 'Cake Price' : cakePrice + ' ETB'}
                            </span>
                            {
                              colors.length != 0 &&
                              <span className='flex mt-4'>
                            {colors.map((color, index) => (
                            <div  class="   rounded-full shadow border  " style={{backgroundColor:color,width:'4vw',height:'4vw'}}></div>
                  
                          ))}
                            </span>
                            }
                            
                          </p>
                        </div>
                        <button className="par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2 h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                            />
                          </svg>
                          Add to cart
                        </button>
                      </div>
                    </div>
                  </div>
                   </div>
                  
                    </div>
                  }
                    
                    </div>
      }    
      


</div>

        </div>
    </div>
  )
}
