import { onAuthStateChanged, signOut } from 'firebase/auth'
import { Timestamp, collection, doc, getDoc, onSnapshot, orderBy, query, serverTimestamp, setDoc, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { auth, firestore } from '../firebase'

export default function TotalOrders() {
    const [orders,setorders] = useState([])
    const [orderPop,setorderPop] = useState(false)
    const [selectedOrder,setselectedOrder] = useState()
    const [orderDetails,setorderDetails] = useState(false)
    const [loading,setloading] = useState(false)
    const [paymentConfirmed,setpaymentConfirmed] = useState(false)
    const [cakeMade,setcakeMade] = useState(false)
    const [readyToBeDelivered,setreadyToBeDelivered] = useState(false)
    const [declined,setdeclined] = useState(false)
    const [isLoading,setisLoading] = useState(true)

    const [success,setsuccess] = useState(false)
    const [orderSearch,setorderSearch] = useState(false)
    const [search,setsearch] = useState('')
    const [filteredOrder,setfilteredOrder] = useState([])
    const [reason,setreason] = useState('')
    const [reasonError,setreasonError] = useState('')

    const [screenHeight, setScreenHeight] = useState(window.screen.height);
    const [pop,setpop] =useState(false)

  

    const navigate =useNavigate()




    const [currentIndexes, setCurrentIndexes] = useState({});


    const [transactionDetails,settransactionDetails] = useState(true)
    const [confirmTransactionPop,setconfirmTransactionPop] = useState(false)
    const [declineTransactionPop,setdeclineTransactionPop] = useState(false)
    const [allSet,setallSet] = useState(false)
    const [orderCompleted,setorderCompleted] = useState(false)

    function orderCompletedSet(){
        setorderCompleted(true)
    }
    function orderCompletedClose(){
        setorderCompleted(false)
    }
    function navigationPop(){
        if(pop === true){
          setpop(false)
        }
        else{
          setpop(true)
        }
      }
      function handleLogOut(){
        signOut(auth).then(
         navigate('/')
        )
       }
       useEffect(() => {
        const handleResize = () => {
          setScreenHeight(window.screen.height);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    function handleGoToAllSet(){
        setTimeout(() => {
            setloading(true)
            setTimeout(() => {
                setallSet(true)
                setloading(false)
            }, 1000);
            
        }, 1000);
    }

    function orderSearchLookup(){
        setorderSearch(true)
    }

    function reasonSet(e){
        setreason(e.target.value)
        if(e.target.value.length === 0){
            setreasonError('error')
        }
        else{
            setreasonError('')
        }
    }

    async function handleOrderComplete() {
        setorderCompleted(false);
        setloading(true);
      
        const orderDoc = doc(firestore, 'Orders', selectedOrder.id);
        const userOrderDoc = doc(firestore, 'Users', selectedOrder.userId, 'Orders', selectedOrder.id);
      
        // Update the order status
        await setDoc(orderDoc, { status: 'completed',completedTime:serverTimestamp() }, { merge: true });
        await setDoc(userOrderDoc, { status: 'completed',completedTime:serverTimestamp() }, { merge: true });
      
        // Update the user's totalRevenue
        const userDoc = doc(firestore, 'stats', 'shewitPastry');
        const userData = await getDoc(userDoc);
        const currentTotalRevenue = userData.data().totalRevenue || 0;
        const orderTotalAmount = parseFloat(selectedOrder.totalAmount);
        const newTotalRevenue = isNaN(orderTotalAmount) ? currentTotalRevenue : currentTotalRevenue + orderTotalAmount/2;
        await setDoc(userDoc, { totalRevenue: newTotalRevenue }, { merge: true });
      
        setloading(false);
        setsuccess(true);
      }
    function orderPopClose(){
        setorderPop(false)
        setallSet(false)
        setorderSearch(false)
        setreadyToBeDelivered(false)
        setcakeMade(false)
        setpaymentConfirmed(false)
        setsuccess(false)
        setfilteredOrder([])
    }
    useEffect(() => {
        const unsubscribe = onSnapshot(
          query(collection(firestore, 'Orders'), where('status', '!=', 'completed')),
          (snapshot) => {
            setfilteredOrder(
              snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }))
            );
          }
        );
      
        return unsubscribe;
      }, [ search]);


      const queriedOrder = filteredOrder.filter((item) => {
      if (item.phoneNumber === (search)) {
          return true;
        }
        return false;
      })


    function orderDetailsOpen(){
        if(orderDetails === false){
            setorderDetails(true)
            settransactionDetails(false)

        }
        else{
            settransactionDetails(false)

        }
    }

    function transactionDetailsOpen(){
        if(transactionDetails === false){
            settransactionDetails(true)
            setorderDetails(false)

        }
        else{
            setorderDetails(false)

        }
    }
    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if(user){
                const orderCollection = collection(firestore, 'Orders');
              
                const unsubscribe = onSnapshot(orderCollection, (snapshot) => {
                  setorders(
                    snapshot.docs.map((doc) => ({
                      ...doc.data(),
                      id: doc.id,
                    }))
                    
                  );
                  setisLoading(false)

                });
              
                return unsubscribe;

            } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);

      function getDateFromTimestamp(timestamp) {
        if (timestamp) {
          if (timestamp instanceof Timestamp) {
            return timestamp.toDate().toISOString().slice(0, 19).replace('T', ' ');
          } else {
            return null;
          }
        } else {
          return null;
        }
      }

      function SelectedOrderPopset(i){
        setorderSearch(false)
        setfilteredOrder([])
        setorderPop(true)
        setselectedOrder(i)
      }

      function backToTransactionConfirm(){
        setTimeout(() => {
            setloading(true)
            setTimeout(() => {
                setpaymentConfirmed(false)
                setloading(false)
            }, 1000);
            
        }, 1000);
      }

      function confirmTransaction(){
        setconfirmTransactionPop(true)

      }
      function confirmTransactionClose(){
        setconfirmTransactionPop(false)
      }

      function declineTransaction(){
        setdeclineTransactionPop(true)

      }
      function declineTransactionClose(){
        setdeclineTransactionPop(false)
        setreason('')
        setreasonError('')
      }

     async function handleConfirmTransaction(){
        setloading(true)
        setconfirmTransactionPop(false)
        const document = doc(firestore,'Orders',selectedOrder.id)
        const document2 = doc(firestore,'Users',selectedOrder.userId,'Orders',selectedOrder.id)
        const data = {status: 'accepted'}
        const userDoc = doc(firestore, 'stats', 'shewitPastry');
        const statsData = await getDoc(userDoc);
        const currentTotalRevenue = statsData.data().totalRevenue || 0;
        const orderTotalAmount = parseFloat(selectedOrder.totalAmount);
        const newTotalRevenue = isNaN(orderTotalAmount) ? currentTotalRevenue : currentTotalRevenue + orderTotalAmount/2;
        
        
        await setDoc(userDoc, { totalRevenue: newTotalRevenue }, { merge: true });
        await setDoc(document,data,{merge:true})
        await setDoc(document2,data,{merge:true})
        setloading(false)
        setpaymentConfirmed(true)
       
      }
     async function handleDeclineTransaction(){
   if(reason.length ===0){
    setreasonError('error')
   }
   else{
    setreasonError('')
    setloading(true)
    setdeclineTransactionPop(false)
    const document = doc(firestore,'Orders',selectedOrder.id)
    const document2 = doc(firestore,'Users',selectedOrder.userId,'Orders',selectedOrder.id)
    const data = {status: 'declined',reason:reason,declinedTime:serverTimestamp()}

    await setDoc(document,data,{merge:true})
    await setDoc(document2,data,{merge:true})
    setloading(false)
    setdeclined(true)
    setreason('')
    orderPopClose()

   }
      }

      function handlegoToCakeDetails(){
        setTimeout(() => {
            setloading(true)
            setcakeMade(false)
            setTimeout(() => {
                setpaymentConfirmed(true)
                
                setloading(false)
            }, 1000);
            
        }, 1000);

      }
      async function handleGoToCakeConfirmation(){
        setpaymentConfirmed(false)
        setloading(true)
        const document = doc(firestore,'Orders',selectedOrder.id)
        const document2 = doc(firestore,'Users',selectedOrder.userId,'Orders',selectedOrder.id)
        const data = {status: 'preparing cake'}
        await setDoc(document,data,{merge:true})
        await setDoc(document2,data,{merge:true})
        setloading(false)
        setcakeMade(true)


      }
     
      async function handleGoToCakeDelivery(){
        setcakeMade(false)

        setloading(true)
        const document = doc(firestore,'Orders',selectedOrder.id)
        const document2 = doc(firestore,'Users',selectedOrder.userId,'Orders',selectedOrder.id)
        const data = {status: 'ready for pickup'}
        await setDoc(document,data,{merge:true})
        await setDoc(document2,data,{merge:true})
        setreadyToBeDelivered(true)
        setloading(false)
     

      }

      const handlePrevious = (cakePhotos, id) => {
        setCurrentIndexes((prevIndexes) => ({
          ...prevIndexes,
          [id]: (prevIndexes[id] === 0 ? cakePhotos.length - 1 : prevIndexes[id] - 1),
        }));
      };
      
      const handleNext = (cakePhotos, id) => {
        setCurrentIndexes((prevIndexes) => ({
          ...prevIndexes,
          [id]: (prevIndexes[id] === cakePhotos.length - 1 ? 0 : prevIndexes[id] + 1),
        }));
      };
      function CakeGallery({ cake, currentIndex, handlePrevious, handleNext }) {
        return (
      <div>
            <div id='desktop'>
            <div id="gallery" className="relative w-full px-12 " data-carousel="slide">
            <div className="relative mb-3 overflow-hidden rounded-lg flex items-center">
              <div className="relative overflow-hidden rounded-lg shadow-xl">
                  {cake.cakeData.cakePhotos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo.cakePhotos}
                      alt={`Selected file ${index}`}
                      style={{ objectFit: 'cover' }}
                      className={`h-20 w-20  rounded  ${index === currentIndex ? 'block' : 'hidden'}`}
                    />
                  ))}
                  {cake.cakeData.cakePhotos.length === 0 && (
                    <img
                      className="h-20 w-20  rounded "
                      style={{  objectFit: 'cover' }}
                      src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                      alt="description"
                    />
                  )}
      
             
      
              
              </div>
            <div className=' ml-3'>
            <Link to={`/admin/cake/${cake.id}`} target="_blank">
              <h3 class="font-medium leading-tight hover:underline">{cake.cakeData.cakeTitle}</h3>

              </Link>
              <p class="text-sm par">Quantity - {cake.quantity}</p>
             
            </div>

            </div>
            
           
          </div>
            </div>
            <div id='tablet'>
            <div id="gallery" className="relative w-full px-12 " data-carousel="slide">
            <div className="relative mb-3 overflow-hidden rounded-lg flex items-center">
              <div className="relative overflow-hidden rounded-lg shadow-xl">
                  {cake.cakeData.cakePhotos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo.cakePhotos}
                      alt={`Selected file ${index}`}
                      style={{ objectFit: 'cover' }}
                      className={`h-20 w-20  rounded  ${index === currentIndex ? 'block' : 'hidden'}`}
                    />
                  ))}
                  {cake.cakeData.cakePhotos.length === 0 && (
                    <img
                      className="h-20 w-20  rounded "
                      style={{  objectFit: 'cover' }}
                      src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                      alt="description"
                    />
                  )}
      
             
      
              
              </div>
            <div className=' ml-3'>
            <Link to={`/admin/cake/${cake.id}`} target="_blank">
              <h3 class="font-medium leading-tight hover:underline">{cake.cakeData.cakeTitle}</h3>

              </Link>
              <p class="text-sm par">Quantity - {cake.quantity}</p>
             
            </div>

            </div>
            
           
          </div>
            </div>
            <div id='mobile'>
            <div id="gallery" className="relative w-full px-12 " data-carousel="slide">
            <div className="relative mb-3 overflow-hidden rounded-lg flex items-center">
              <div className="relative overflow-hidden rounded-lg shadow-xl">
                  {cake.cakeData.cakePhotos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo.cakePhotos}
                      alt={`Selected file ${index}`}
                      style={{ objectFit: 'cover',width:'28vw',height:'"28vw' }}
                      className={`  rounded  ${index === currentIndex ? 'block' : 'hidden'}`}
                    />
                  ))}
                  {cake.cakeData.cakePhotos.length === 0 && (
                    <img
                      className="  rounded "
                      style={{  objectFit: 'cover',width:'28vw',height:'"28vw' }}
                      src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                      alt="description"
                    />
                  )}
      
             
      
              
              </div>
            <div className=' ml-3'>
            <Link to={`/admin/cake/${cake.id}`} target="_blank">
              <h3 class="font-medium leading-tight hover:underline" style={{fontSize:'4vw'}}>{cake.cakeData.cakeTitle}</h3>

              </Link>
              <p class="text-sm par"style={{fontSize:'3vw'}}>Quantity - {cake.quantity}</p>
             
            </div>

            </div>
            
           
          </div>
            </div>
          
         
      </div>

        );
      }
      function formatNumber(num) {
        if(num){
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        };
      }
  return (
    isLoading === true?
    <div>
    
 

    <div className="flex flex-col items-center justify-center w-full text-surface " style={{height:'100vw'}}>
    <div
      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
      role="status"></div>

    </div>
  
    
  
  </div>
  :
    <div>
        <div id='desktop' className='relative'>
            {
                orderPop === true &&
                (
              loading === true ?
              <div  class="bg-white orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">


              <div
                className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                role="status"></div>
           
            
              </div>
              :
              success === true ?
              <div style={{backgroundColor:'#00ff59'}} class=" orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">
   <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<h1 className='header' style={{fontSize:'40px'}}>Order Completed</h1>
<p className='par'>{selectedOrder.totalAmount} ETB</p>

            </div>

              :
              allSet === true ?
              <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
              <ol class="relative text-gray-500 border-s border-gray-200 mr-40"> 
              <li class="mb-10 ms-6">            
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Confirm Payment</h3>
              <p class="text-sm">Verify that the customer has paid </p>
          </li>
          
          
             
              <li class="mb-10 ms-6">
                  
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Cake Details</h3>
              <p class="text-sm">Details about the cake order</p>
          </li>
          
              <li class="mb-10 ms-6">
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Bake the cake</h3>
                  <p class="text-sm">Make the cake for the customer</p>
              </li>
              <li class="ms-6">
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
                  <h3 class="font-medium leading-tight">Confirmation</h3>
                  <p class="text-sm">Cake Ready for pickup</p>
              </li>
          </ol>
                
        <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
          
          <div className="">
            <h1 className='seymour-one-regular ' style={{fontSize:'25px'}}>Cake Baked! Waiting to be picked up.</h1>
             
            <dl class="flex items-center justify-between mb-2 ">
                <dt class="text-base font-normal text-gray-500">Customer's Phone Number - <span class="text-base font-medium header text-blue-600">
                 {selectedOrder&& selectedOrder.phoneNumber}
                </span></dt>
                </dl>
                      {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                        <div key={cake.id}>
                          <CakeGallery
                            cake={cake}
                            currentIndex={currentIndexes[cake.id] || 0}
                            handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                            handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                          />
                        </div>
                      ))}
                                  <div className='flex mt-10 justify-center flex-col'>
                                  <button onClick={orderPopClose}  class="mt-10 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-5 pb-5 rounded-lg fjalla px-3" style={{fontSize:'11px'}}>
                            
                            All Set
                             
                             </button>
                             <p onClick={orderCompletedSet} className='text-center hover:underline par cursor-pointer m-2'>Delivered?</p>
                                                            <p onClick={declineTransaction} className='text-center hover:underline par cursor-pointer m-2'>Decline</p>
                               
                   
                         
                      </div>
                    </div>
                        </div>
                        :

              paymentConfirmed=== true ?
                <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
    <ol class="relative text-gray-500 border-s border-gray-200 mr-40"> 
    <li class="mb-10 ms-6">            
    <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
        <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
        </svg>
    </span>
    <h3 class="font-medium leading-tight">Confirm Payment</h3>
    <p class="text-sm">Verify that the customer has paid </p>
</li>


   
    <li class="mb-10 ms-6">
        
    <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white ">
        <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
            <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
        </svg>
    </span>
    <h3 class="font-medium leading-tight">Cake Details</h3>
    <p class="text-sm">Details about the cake order</p>
</li>

    <li class="mb-10 ms-6">
        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
            <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
        </span>
        <h3 class="font-medium leading-tight">Review</h3>
        <p class="text-sm">Cake Ready for pickup</p>
    </li>
    <li class="ms-6">
        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
            <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
        <h3 class="font-medium leading-tight">Confirmation</h3>
        <p class="text-sm">Cake Ready for pickup</p>
    </li>
</ol>
<svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<div className="">
            {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
              <div key={cake.id}>
                <CakeGallery
                  cake={cake}
                  currentIndex={currentIndexes[cake.id] || 0}
                  handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                  handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                />
              </div>
            ))}
                        <div className='flex mt-10 justify-center flex-col'>
                        <button onClick={handleGoToCakeConfirmation}  class="mt-10 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                  
                  Prepare Cake
                   
                   </button>
                        <button  onClick={backToTransactionConfirm}  class="mt-5 flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                   
                   Back
                    
                    </button>
        
               
            </div>
          </div>
              </div>
              :
              cakeMade === true ?
              <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
              <ol class="relative text-gray-500 border-s border-gray-200 mr-40"> 
              <li class="mb-10 ms-6">            
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Confirm Payment</h3>
              <p class="text-sm">Verify that the customer has paid </p>
          </li>
          
          
             
              <li class="mb-10 ms-6">
                  
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Cake Details</h3>
              <p class="text-sm">Details about the cake order</p>
          </li>
          
              <li class="mb-10 ms-6">
                  <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white dark:ring-blue-900 dark:bg-blue-700">
                      <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                          <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                      </svg>
                  </span>
                  <h3 class="font-medium leading-tight">Bake the cake</h3>
                  <p class="text-sm">Make the cake for the customer</p>
              </li>
              <li class="ms-6">
                
                  <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
                      <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                          <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
                      </svg>
                  </span>
                  <h3 class="font-medium leading-tight">Confirmation</h3>
                  <p class="text-sm">Cake Ready for pickup</p>
              </li>
          </ol>
          <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
          
          <div className="">
          <div>
            <h1 className='seymour-one-regular'>Now is the time to prepare the cake!</h1>
        </div>
                  <img style={{width:'70%',margin:'auto'}} src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cake.gif?alt=media&token=8646f696-e66f-45fc-b5f8-0adbf7cd3ff3" alt="" />
                                  <div className='flex mt-10 justify-center flex-col'>
                       
                      <button onClick={handleGoToCakeDelivery}  class="mt-4 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                            
                      The cake is ready
                             
                             </button>
                             <button  onClick={handlegoToCakeDetails}  class="mt-5 flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                             
                             Back
                              
                              </button>
                      </div>
                    </div>
                        </div>
                        :
              readyToBeDelivered === true ?
              <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                  <ol class="relative text-gray-500 border-s border-gray-200 mr-40"> 
              <li class="mb-10 ms-6">            
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Confirm Payment</h3>
              <p class="text-sm">Verify that the customer has paid </p>
          </li>
          
          
             
              <li class="mb-10 ms-6">
                  
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Cake Details</h3>
              <p class="text-sm">Details about the cake order</p>
          </li>
          
              <li class="mb-10 ms-6">
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
                  <h3 class="font-medium leading-tight">Bake the cake</h3>
                  <p class="text-sm">Make the cake for the customer</p>
              </li>
              <li class="ms-6">
                
                  <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white dark:ring-blue-900 dark:bg-blue-700">
                      <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                          <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
                      </svg>
                  </span>
                  <h3 class="font-medium leading-tight">Confirmation</h3>
                  <p class="text-sm">Cake Ready for pickup</p>
              </li>
          </ol>
          <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
          <div className="">
          <div>
            <h1 className='seymour-one-regular '>Tell the customer the cake is ready to be picked up now</h1>
            <p className='par mb-4 text-gray-600'>You are all set, just wait for the customer to pick it up</p>
        
            <dl class="flex items-center justify-between ">
                <dt class="text-base font-normal text-gray-500">Customer's Phone Number - <span class="text-base font-medium header text-blue-600">
                 {selectedOrder&& selectedOrder.phoneNumber}
                </span></dt>
                
              </dl>
          
        </div>
                                  <div className='flex mt-10 justify-center flex-col'>
                            
               <div onClick={handleGoToAllSet}  class="mt-10 mb-5 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{fontSize:'11px'}}>
                            
                            Done
                                   
                                   </div>
                             <div  onClick={handlegoToCakeDetails}  class=" flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{fontSize:'11px'}}>
                             
                             Back
                              
                              </div>
                         
                      </div>
                    </div>
                  </div>   
                  :   

                <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
        
        <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
           


{
    selectedOrder.status === 'completed'?
    <ol class="relative text-gray-500 border-s border-gray-200 mr-40"> 
    
    <li class="mb-10 ms-6">            
    <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
    <h3 class="font-medium leading-tight">Confirm Payment</h3>
    <p class="text-sm">Verify that the customer has paid </p>
</li>
              
   

<li class="mb-10 ms-6">
<span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
<h3 class="font-medium leading-tight">Cake Details</h3>
<p class="text-sm">Details about the cake order</p>
</li>
    <li class="mb-10 ms-6">
    <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
        <h3 class="font-medium leading-tight">Bake the cake</h3>
                  <p class="text-sm">Make the cake for the customer</p>
    </li>
    <li class="ms-6">
    <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
        <h3 class="font-medium leading-tight">Confirmation</h3>
        <p class="text-sm">Cake Ready for pickup</p>
    </li>
</ol>:
    selectedOrder.status === 'declined'?
    <ol class="relative text-gray-500 border-s border-gray-200 mr-40"> 
    
    <li class="mb-10 ms-6">            
    <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

    <h3 class="font-medium leading-tight">Confirm Payment</h3>
    <p class="text-sm">Verify that the customer has paid </p>
</li>
              
   

<li class="mb-10 ms-6">
<img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

<h3 class="font-medium leading-tight">Cake Details</h3>
<p class="text-sm">Details about the cake order</p>
</li>
    <li class="mb-10 ms-6">
    <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

        <h3 class="font-medium leading-tight">Bake the cake</h3>
                  <p class="text-sm">Make the cake for the customer</p>
    </li>
    <li class="ms-6">
    <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

        <h3 class="font-medium leading-tight">Confirmation</h3>
        <p class="text-sm">Cake Ready for pickup</p>
    </li>
</ol>:

    <ol class="relative text-gray-500 border-s border-gray-200 mr-40"> 
    
    <li class="mb-10 ms-6">            
    <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white ">
       
    <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
    </span>
    <h3 class="font-medium leading-tight">Confirm Payment</h3>
    <p class="text-sm">Verify that the customer has paid </p>
</li>
              
   

<li class="mb-10 ms-6">
<span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
    <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
        <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
    </svg>
</span>
<h3 class="font-medium leading-tight">Cake Details</h3>
<p class="text-sm">Details about the cake order</p>
</li>
    <li class="mb-10 ms-6">
        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
            <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
        </span>
        <h3 class="font-medium leading-tight">Bake the cake</h3>
                  <p class="text-sm">Make the cake for the customer</p>
    </li>
    <li class="ms-6">
        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
            <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
        <h3 class="font-medium leading-tight">Confirmation</h3>
        <p class="text-sm">Cake Ready for pickup</p>
    </li>
</ol>
}


<div>
    {
        selectedOrder.status && selectedOrder.status ==='accepted' &&
        <div>
            <h1 className='seymour-one-regular'>Transaction Accepted!</h1>
            <p className='par'>It's time to start baking</p>
        </div>
    }

{
        selectedOrder.status && selectedOrder.status ==='declined' &&
        <div>
            <h1 className='seymour-one-regular text-red-800'>RECIEPT DECLINED</h1>
            <p className='par'>{selectedOrder.reason}</p>
        </div>
    }
       {
        selectedOrder.status && selectedOrder.status ==='completed' &&
        <div>
            <h1 className='seymour-one-regular'>Order Completed!!</h1>
        </div>
    }

{
        selectedOrder.status && selectedOrder.status ==='pending' &&
        <div>
            <h1 className='seymour-one-regular'>Transaction Details</h1>
            <p className='par'>Verify whether the transfer has been made by the customer</p>
        </div>
    }

{
        selectedOrder.status && selectedOrder.status ==='preparing cake' &&
        <div>
            <h1 className='seymour-one-regular'>Preparing Cake!</h1>
        </div>
    }

{
        selectedOrder.status && selectedOrder.status =='ready for pickup' &&
        <div>
            <h1 className='seymour-one-regular'>Ready for pickup!</h1>
        </div>
    }
            {
                transactionDetails === true &&
                <div>
              <dl class="flex items-center justify-between gap-4 mt-8">
                <dt class="text-base font-normal text-gray-500">Account Holder</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.accountHolder && selectedOrder.accountHolder
                    }</dd>
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Account Number</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.accountNumber && selectedOrder.accountNumber
                    }</dd>
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Transaction Id</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.transactionId && selectedOrder.transactionId

                    }</dd>
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Screenshot</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.transactionId &&  <a rel="noreferrer" href={selectedOrder.screenshot} className='text-base font-medium text-green-600 cursor-pointer' target='_blank'>Image</a>

                    }</dd>
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Phone Number</dt>
                <dd class="text-base font-medium text-green-600">{
                    selectedOrder.phoneNumber && selectedOrder.phoneNumber

                    }</dd>
                    
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Status</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.status && selectedOrder.status
                    }</dd>
                    
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Total Amount</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.totalAmount && selectedOrder.totalAmount 
                    } ETB</dd>
                    
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Prepayment</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.prePayment && selectedOrder.prePayment 
                    } ETB</dd>
                    
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Down Payment</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.downPayment && selectedOrder.downPayment 
                    } ETB</dd>
                    
              </dl>
              <dl class="flex items-center justify-between gap-4 mb-12">
                <dt class="text-base font-normal text-gray-500">Time</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.deliveryDate && getDateFromTimestamp(selectedOrder.deliveryDate)
                    }</dd>
              </dl>
           {
            selectedOrder.status && selectedOrder.status === 'pending'?
            <div className='flex flex-col'>
            <button onClick={confirmTransaction}  class="mt-10 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                  
                  Confirm Transaction
                   
                   </button>
                   <button  onClick={declineTransaction}  class="mt-10 flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                   
                   Decline Transaction
                    
                    </button>
            </div>
            :
            selectedOrder.status === 'accepted' ?
            <div className='flex flex-col'>
            <button onClick={handlegoToCakeDetails}  class="mt-10 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                  
                  Cake Details
                   
                   </button>
            </div>
            :
            selectedOrder.status === 'ready for pickup' ?

            <div className='flex flex-col'>
            <button onClick={handleGoToAllSet}  class="mt-10 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                  
                 Review Order
                   
                   </button>
                  
            </div>
            :
            
            <div className='flex flex-col'>
           
                  
            </div>
           }
    
                    
            

                </div>
            }
</div>


            {
                orderDetails === true &&
                <div>

                <span className="par text-light-inverse text-md/normal">
                               {selectedOrder.cakes.map((cake, cakeIndex) => {
                                   return (
                                       <React.Fragment key={cakeIndex}>
                                           <Link to={`/admin/cake/${cake.id}`}>
                                           {cake.cakeData.cakeTitle}
                                           {cakeIndex < selectedOrder.cakes.length - 1 ? ', ' : ''}</Link>
                                       </React.Fragment>
                                   );
                               })}
                           </span>
                   </div>
            }
                </div>
               )

            }

            {
                orderSearch === true &&
              <div  class="bg-white orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">
     <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
 <div class="relative mx-auto mb-20" style={{width:'40%'}}>
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input type="search" id="default-search" onChange={(e)=>setsearch(e.target.value)} style={{height:'55px'}}  class=" block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
    </div>

    {
        queriedOrder.map((i, index) => {
            const { accountHolder, totalAmount, cakes, time,status,deliveryDate } = i;
            return (
                <tbody key={index}>
                    <tr className=" last:border-b-0 mt-6 py-3 ">
                    <td className="pr-12 py-8 pl-0">
                            <div className="flex items-center justify-center">
                                <div className="flex flex-col justify-center">
                                    <p className="mb-1 par transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                                </div>
                            </div>
                        </td>
                        <td className="pr-12 py-8 pl-0">
                            <div className="flex items-center justify-center">
                                <div className="flex flex-col justify-center">
                                    <p className="mb-1 par transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {accountHolder} </p>
                                </div>
                            </div>
                        </td>
                
                        <td className=" pr-12 text-center par">
                            <span className="par text-light-inverse text-md/normal">{totalAmount} ETB</span>
                        </td>
                        <td className=" pr-12 text-center">
                            {
                                status === 'pending'?
                            <span className="text-center capitalize align-baseline inline-flex px-4 py-3 mr-auto items-center font-semibold text-[.95rem] leading-none text-yellow-900 bg-yellow-200 rounded-lg">{status} </span>
                                :
                                status === 'accepted'?
                                <span className="text-center capitalize align-baseline inline-flex px-4 py-3 mr-auto items-center font-semibold text-[.95rem] leading-none text-primary bg-primary-light rounded-lg">{status} </span>
                                :
                                status === 'declined'?
                            <span className="text-center capitalize align-baseline inline-flex px-4 py-3 mr-auto items-center font-semibold text-[.95rem] leading-none text-red bg-red-light rounded-lg">{status} </span>
                                :
                            <span className="text-center capitalize align-baseline inline-flex px-4 py-3 mr-auto items-center font-semibold text-[.95rem] leading-none text-green bg-green-light rounded-lg">{status} </span>
    
                            }
                        </td>
                
                        <td className=" pr-12 text-center par">
                            <button onClick={()=>SelectedOrderPopset(i)} style={{ backgroundColor: '#D9FE00' }} className="ml-auto relative hover:text-primary flex items-center border h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none justify-center">
                                <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-gray-900">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                    </svg>
                                </span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            );
        })
    }

</div>
            }



{
                declineTransactionPop === true &&
                <div id="deleteModal" class="">
    <div class="relative p-4 w-full max-w-md h-full md:h-auto">
        <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5">
            <button onClick={declineTransactionClose}type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Close modal</span>
            </button>
            <p class="mb-4 text-gray-900 header">Are you sure you want to Decline this Transaction?</p>
            
<div className="relative mx-auto mb-8" style={{width:"90%"}}>

<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Reason</p>
<textarea onChange={(e)=>reasonSet(e)} placeholder='The screenshot does not include the transaction ID'  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'12px'}}   type="text" class="border  focus:outline-none
      focus:border-black borel w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md" maxLength="5000"></textarea>
      <div className={`${reasonError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Reason Can't be empty
</div>
</div>
</div>

</div>
            <div class="flex justify-center items-center space-x-4">
                <button onClick={declineTransactionClose} data-modal-toggle="deleteModal" type="button" class="par py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10   dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    No, cancel
                </button>
                <button onClick={handleDeclineTransaction} type="submit" class="py-2 px-3 text-sm par font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900">
                    Yes, I'm sure
                </button>
            </div>
        </div>
    </div>
</div>
            }
            {
                confirmTransactionPop === true &&
                    <div id="deleteModal" class="">
        <div class="relative p-4 w-full max-w-md h-full md:h-auto">
            <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5">
                <button onClick={confirmTransactionClose}type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <p class="mb-4 text-gray-900 header">Are you sure you want to confirm this transaction?</p>
                <div class="flex justify-center items-center space-x-4">
                    <button onClick={confirmTransactionClose} data-modal-toggle="deleteModal" type="button" class="par py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10   dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        No, cancel
                    </button>
                    <button onClick={handleConfirmTransaction} type="submit" class="py-2 px-3 text-sm par font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-900">
                        Yes, I'm sure
                    </button>
                </div>
            </div>
        </div>
    </div>
            }

{
                orderCompleted === true &&
                    <div id="deleteModal" class="">
        <div class="relative p-4 w-full max-w-md h-full md:h-auto">
            <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5">
                <button onClick={orderCompletedClose}type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <p class="mb-4 text-gray-900 header">Could you please confirm that the customer picked up the cake ?</p>
                <div class="flex justify-center items-center space-x-4">
                    <button onClick={orderCompletedClose} data-modal-toggle="deleteModal" type="button" class="par py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10   dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        No, cancel
                    </button>

                    <button onClick={handleOrderComplete} type="submit" class="py-2 px-3 text-sm par font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-900">
                        Yes, I'm sure
                    </button>
                </div>
            </div>
        </div>
    </div>
            }
            {

            }
            <div className='flex'>

          
            <aside class="bg-gray-900 adminNav  fixed inset-0 z-50 my-4 ml-4  rounded-xl transition-transform duration-300 xl:translate-x-0">
    <div class="relative border-b border-white/20">
      <div class="flex items-center gap-4 py-6 px-8" >
        <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
      </div>
   
    </div>
    <div class="m-4">
      <ul class="mb-4 flex flex-col gap-1">
        <li>
        <Link to='/admin'>

        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
             <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
           </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/orders'>
        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
              
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
            </button>
       
          </Link>
        </li>
        <li>
        <Link to='/admin/allorders'>
        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center gap-4 px-4 capitalize" type="button">
            
            <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Order</p>
          </button>
          
          </Link>
        </li>
        <li>
        <Link to='/admin/addcake'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/managecakes'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/messages'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/reserved'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
             
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Reserved Students</p>
            </button>
          </Link>
        </li>
      </ul>
      <ul class="mb-4 flex flex-col gap-1">
        <li class="mx-3.5 mt-4 mb-2">
          <p class="barlow block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
        </li>
    
        <li>
            <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize" type="button">
            
              <p class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
            </button>
        </li>
      </ul>
    </div>
  </aside>
  <div className="adminBody ">
 
                {
                    orders.length !==0 &&
                    <div>
 <div className="px-9 pt-5 flex justify-between  min-h-[70px] pb-0 bg-transparent w-full">
                <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                    <span className="mr-3 barlow" style={{fontSize:'25px'}}>All Orders</span>

                </h3>
                <button onClick={orderSearchLookup}  style={{fontSize:"12px",width:'15%'}} class="flex justify-center ml-2 w-full px-5 py-3  mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
          
          Find Order
        </button>
                </div>
                    <div className="flex  mb-5  ">
                    <div className="w-full max-w-full  mb-6  mx-auto">
                        <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
                        <div className="relative flex flex-col min-w-0 break-words  bg-clip-border rounded-2xl border-stone-200 bg-light/30">
                        
                            
                            <div className="flex-auto block py-8  ">
                            <div className="overflow-x-auto">
                                <table className="w-full my-0 align-middle text-dark border-neutral-200">
                                <thead className="align-bottom ">
                                    <tr className="font-semibold text-[0.95rem] text-gray-800">
                                    <th className="pb-3 text-center min-w-[175px] header pr-12 "></th>
            
                                    <th className="pb-3 text-center min-w-[175px] header pr-12 ">Full Name</th>
                                    <th className="pb-3 text-center min-w-[100px] header pr-12">Cake</th>
                                    <th className="pb-3 text-center min-w-[100px] header pr-12">Price</th>
                                    <th className="pb-3 text-center min-w-[175px] header pr-12">STATUS</th>
                                    <th className="pb-3 text-center min-w-[100px] header pr-12">Delivery Date</th>
                                    </tr>
                                </thead>
                                {
                orders.sort((a, b) => a.deliveryDate - b.deliveryDate).map((i, index) => {
                    const { accountHolder, totalAmount, cakes, time,status,deliveryDate } = i;
                    return (
                        <tbody key={index}>
                        <tr className=" last:border-b-0 mt-6 py-3 ">
                        <td className="pr-12 py-8 pl-0">
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                                    </div>
                                </div>
                            </td>
                            <td className="pr-12 py-8 pl-0">
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {accountHolder} </p>
                                    </div>
                                </div>
                            </td>
                            <td className=" pr-12 text-center ">
                                <span className="barlow text-light-inverse text-md/normal">
                                    {cakes.map((cake, cakeIndex) => {
                                        return (
                                            <React.Fragment key={cakeIndex}>
                                                {cake.cakeData.cakeTitle}
                                                {cakeIndex < cakes.length - 1 ? ', ' : ''}
                                            </React.Fragment>
                                        );
                                    })}
                                </span>
                            </td>
                            <td className=" pr-12 text-center par">
                                <span className="barlow text-light-inverse text-md/normal">{totalAmount} ETB</span>
                            </td>
                            <td className=" pr-12 text-center">
                                {
                                    status === 'pending'?
                                <span className="uppercase bg-yellow-200 text-yellow-600 py-2  rounded barlow px-2">{status} </span>
                                    :
                                    status === 'accepted'?
                                    <span className="uppercase bg-purple-200 text-purple-600 py-2  rounded barlow px-2">{status} </span>
                                   
                                    :
                                    status === 'declined'?
                                <span className="uppercase bg-red-200 text-red-600 py-2  rounded barlow px-2">Reciept Declined </span>
                                    :
                                    status === 'ready for pickup'?
                                    <span className="uppercase bg-blue-200 text-blue-600 py-2  rounded barlow px-2">{status} </span>
                                        :
                                        status === 'preparing cake'?
                                        <span className="uppercase bg-pink-200 text-pink-600 py-2  rounded barlow px-2">{status} </span>
                                            :
                                <span  class="uppercase bg-green-200 text-green-600 py-2  rounded barlow px-2" >{status}</span>
                 
                 
                                }
                            </td>
                            <td className="pr-12 text-center">
                                <span className="barlow font-semibold text-light-inverse text-md/normal par text-center">{getDateFromTimestamp(deliveryDate)}</span>
                            </td>
                            <td className=" pr-12 text-center par">
                          <button onClick={()=>SelectedOrderPopset(i)}   className="ml-auto bg-blue-700  relative hover:text-primary flex items-center border h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none justify-center">
                                    <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-white">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    );
                })
            }
                              
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    </div>

                }

                {
                    orders.length === 0 &&
                    <div>
                          <div className='flex items-center justify-center flex-col mt-28 '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/Order%20food-amico.svg?alt=media&token=f075bbe5-0ae5-4057-947d-cfb0bae0ca5d" alt="No order" style={{width:"25%"}}/>
  <h1 className='fjalla text-center text-gray-900 mt-8 par' style={{fontSize:"20px"}}>There are no orders 


</h1>

</div>
                    </div>
                }
   

  </div>

</div>

        </div>
        <div id='tablet' className='relative'>
            {
                orderPop === true &&
                (
              loading === true ?
              <div  class="bg-white orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">


              <div
                className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                role="status"></div>
           
            
              </div>
              :
              success === true ?
              <div style={{backgroundColor:'#00ff59'}} class=" orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">
   <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<h1 className='header' style={{fontSize:'40px'}}>Order Completed</h1>
<p className='par'>{selectedOrder.totalAmount} ETB</p>

            </div>

              :
              allSet === true ?
              <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
              <ol class="relative text-gray-500 border-s border-gray-200 "style={{width:'30%'}}> 
              <li class="mb-10 ms-6">            
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Confirm Payment</h3>
              <p class="text-sm">Verify that the customer has paid </p>
          </li>
          
          
             
              <li class="mb-10 ms-6">
                  
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Cake Details</h3>
              <p class="text-sm">Details about the cake order</p>
          </li>
          
              <li class="mb-10 ms-6">
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Bake the cake</h3>
                  <p class="text-sm">Make the cake for the customer</p>
              </li>
              <li class="ms-6">
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
                  <h3 class="font-medium leading-tight">Confirmation</h3>
                  <p class="text-sm">Cake Ready for pickup</p>
              </li>
          </ol>
                
        <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
          
          <div className="" style={{width:'50%'}}>
            <h1 className='seymour-one-regular ' style={{fontSize:'25px'}}>Cake Baked! Waiting to be picked up.</h1>
             
            <dl class="flex items-center justify-between mb-2 ">
                <dt class="text-base font-normal text-gray-500">Customer's Phone Number - <span class="text-base font-medium header text-blue-600">
                 {selectedOrder&& selectedOrder.phoneNumber}
                </span></dt>
                </dl>
                      {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                        <div key={cake.id}>
                          <CakeGallery
                            cake={cake}
                            currentIndex={currentIndexes[cake.id] || 0}
                            handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                            handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                          />
                        </div>
                      ))}
                                  <div className='flex mt-10 justify-center flex-col'>
                                  <button onClick={orderPopClose}  class="mt-10 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-5 pb-5 rounded-lg fjalla px-3" style={{fontSize:'11px'}}>
                            
                            All Set
                             
                             </button>
                             <p onClick={orderCompletedSet} className='text-center hover:underline par cursor-pointer m-2'>Delivered?</p>
                                                            <p onClick={declineTransaction} className='text-center hover:underline par cursor-pointer m-2'>Decline</p>
                               
                   
                         
                      </div>
                    </div>
                        </div>
                        :

              paymentConfirmed=== true ?
                <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
    <ol class="relative text-gray-500 border-s border-gray-200 " style={{width:'30%'}}> 
    <li class="mb-10 ms-6">            
    <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
        <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
        </svg>
    </span>
    <h3 class="font-medium leading-tight">Confirm Payment</h3>
    <p class="text-sm">Verify that the customer has paid </p>
</li>


   
    <li class="mb-10 ms-6">
        
    <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white ">
        <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
            <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
        </svg>
    </span>
    <h3 class="font-medium leading-tight">Cake Details</h3>
    <p class="text-sm">Details about the cake order</p>
</li>

    <li class="mb-10 ms-6">
        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
            <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
        </span>
        <h3 class="font-medium leading-tight">Review</h3>
        <p class="text-sm">Cake Ready for pickup</p>
    </li>
    <li class="ms-6">
        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
            <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
        <h3 class="font-medium leading-tight">Confirmation</h3>
        <p class="text-sm">Cake Ready for pickup</p>
    </li>
</ol>
<svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<div className=""style={{width:'50%'}}>
            {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
              <div key={cake.id}>
                <CakeGallery
                  cake={cake}
                  currentIndex={currentIndexes[cake.id] || 0}
                  handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                  handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                />
              </div>
            ))}
                        <div className='flex mt-10 justify-center flex-col'>
                        <button onClick={handleGoToCakeConfirmation}  class="mt-10 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                  
                  Prepare Cake
                   
                   </button>
                        <button  onClick={backToTransactionConfirm}  class="mt-5 flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                   
                   Back
                    
                    </button>
        
               
            </div>
          </div>
              </div>
              :
              cakeMade === true ?
              <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
              <ol class="relative text-gray-500 border-s border-gray-200 " style={{width:'30%'}}> 
              <li class="mb-10 ms-6">            
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Confirm Payment</h3>
              <p class="text-sm">Verify that the customer has paid </p>
          </li>
          
          
             
              <li class="mb-10 ms-6">
                  
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Cake Details</h3>
              <p class="text-sm">Details about the cake order</p>
          </li>
          
              <li class="mb-10 ms-6">
                  <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white dark:ring-blue-900 dark:bg-blue-700">
                      <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                          <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                      </svg>
                  </span>
                  <h3 class="font-medium leading-tight">Bake the cake</h3>
                  <p class="text-sm">Make the cake for the customer</p>
              </li>
              <li class="ms-6">
                
                  <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
                      <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                          <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
                      </svg>
                  </span>
                  <h3 class="font-medium leading-tight">Confirmation</h3>
                  <p class="text-sm">Cake Ready for pickup</p>
              </li>
          </ol>
          <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
          
          <div className=""style={{width:'50%'}}>
          <div>
            <h1 className='seymour-one-regular'>Now is the time to prepare the cake!</h1>
        </div>
                  <img style={{width:'70%',margin:'auto'}} src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cake.gif?alt=media&token=8646f696-e66f-45fc-b5f8-0adbf7cd3ff3" alt="" />
                                  <div className='flex mt-10 justify-center flex-col'>
                       
                      <button onClick={handleGoToCakeDelivery}  class="mt-4 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                            
                      The cake is ready
                             
                             </button>
                             <button  onClick={handlegoToCakeDetails}  class="mt-5 flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                             
                             Back
                              
                              </button>
                      </div>
                    </div>
                        </div>
                        :
              readyToBeDelivered === true ?

              <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
                  <ol class="relative text-gray-500 border-s border-gray-200 "style={{width:'30%'}}> 
              <li class="mb-10 ms-6">            
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Confirm Payment</h3>
              <p class="text-sm">Verify that the customer has paid </p>
          </li>
          
          
             
              <li class="mb-10 ms-6">
                  
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
              <h3 class="font-medium leading-tight">Cake Details</h3>
              <p class="text-sm">Details about the cake order</p>
          </li>
          
              <li class="mb-10 ms-6">
              <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
                  <h3 class="font-medium leading-tight">Bake the cake</h3>
                  <p class="text-sm">Make the cake for the customer</p>
              </li>
              <li class="ms-6">
                
                  <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white dark:ring-blue-900 dark:bg-blue-700">
                      <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                          <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
                      </svg>
                  </span>
                  <h3 class="font-medium leading-tight">Confirmation</h3>
                  <p class="text-sm">Cake Ready for pickup</p>
              </li>
          </ol>
          <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
          <div className=""style={{width:'50%'}}>
          <div>
            <h1 className='seymour-one-regular '>Tell the customer the cake is ready to be picked up now</h1>
            <p className='par mb-4 text-gray-600'>You are all set, just wait for the customer to pick it up</p>
        
            <dl class="flex items-center justify-between ">
                <dt class="text-base font-normal text-gray-500">Customer's Phone Number - <span class="text-base font-medium header text-blue-600">
                 {selectedOrder&& selectedOrder.phoneNumber}
                </span></dt>
                
              </dl>
          
        </div>
                                  <div className='flex mt-10 justify-center flex-col'>
                            
               <div onClick={handleGoToAllSet}  class="mt-10 mb-5 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{fontSize:'11px'}}>
                            
                            Done
                                   
                                   </div>
                             <div  onClick={handlegoToCakeDetails}  class=" flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{fontSize:'11px'}}>
                             
                             Back
                              
                              </div>
                         
                      </div>
                    </div>
                  </div>   
                  :   

                <div  className='orderPop shadow-xl border flex  justify-center  items-center '>
        
        <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
           


{
    selectedOrder.status === 'completed'?
    <ol class="relative text-gray-500 border-s border-gray-200 "style={{width:'30%'}}> 
    
    <li class="mb-10 ms-6">            
    <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
    <h3 class="font-medium leading-tight">Confirm Payment</h3>
    <p class="text-sm">Verify that the customer has paid </p>
</li>
              
   

<li class="mb-10 ms-6">
<span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
<h3 class="font-medium leading-tight">Cake Details</h3>
<p class="text-sm">Details about the cake order</p>
</li>
    <li class="mb-10 ms-6">
    <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
        <h3 class="font-medium leading-tight">Bake the cake</h3>
                  <p class="text-sm">Make the cake for the customer</p>
    </li>
    <li class="ms-6">
    <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg class="w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
              </span>
        <h3 class="font-medium leading-tight">Confirmation</h3>
        <p class="text-sm">Cake Ready for pickup</p>
    </li>
</ol>:
    selectedOrder.status === 'declined'?
    <ol class="relative text-gray-500 border-s border-gray-200 "style={{width:'30%'}}> 
    
    <li class="mb-10 ms-6">            
    <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

    <h3 class="font-medium leading-tight">Confirm Payment</h3>
    <p class="text-sm">Verify that the customer has paid </p>
</li>
              
   

<li class="mb-10 ms-6">
<img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

<h3 class="font-medium leading-tight">Cake Details</h3>
<p class="text-sm">Details about the cake order</p>
</li>
    <li class="mb-10 ms-6">
    <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

        <h3 class="font-medium leading-tight">Bake the cake</h3>
                  <p class="text-sm">Make the cake for the customer</p>
    </li>
    <li class="ms-6">
    <img className='absolute flex -start-4 items-center justify-center w-8 h-8 bg-green-200 rounded-full' src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

        <h3 class="font-medium leading-tight">Confirmation</h3>
        <p class="text-sm">Cake Ready for pickup</p>
    </li>
</ol>:

    <ol class="relative text-gray-500 border-s border-gray-200 " style={{width:'30%'}}> 
    
    <li class="mb-10 ms-6">            
    <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-4 ring-white ">
       
    <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
    </span>
    <h3 class="font-medium leading-tight">Confirm Payment</h3>
    <p class="text-sm">Verify that the customer has paid </p>
</li>
              
   

<li class="mb-10 ms-6">
<span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
    <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
        <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
    </svg>
</span>
<h3 class="font-medium leading-tight">Cake Details</h3>
<p class="text-sm">Details about the cake order</p>
</li>
    <li class="mb-10 ms-6">
        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
            <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
        </span>
        <h3 class="font-medium leading-tight">Bake the cake</h3>
                  <p class="text-sm">Make the cake for the customer</p>
    </li>
    <li class="ms-6">
        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white ">
            <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
        <h3 class="font-medium leading-tight">Confirmation</h3>
        <p class="text-sm">Cake Ready for pickup</p>
    </li>
</ol>
}


<div  style={{width:'50%'}}>
    {
        selectedOrder.status && selectedOrder.status ==='accepted' &&
        <div>
            <h1 className='seymour-one-regular'>Transaction Accepted!</h1>
            <p className='par'>It's time to start baking</p>
        </div>
    }

{
        selectedOrder.status && selectedOrder.status ==='declined' &&
        <div>
            <h1 className='seymour-one-regular text-red-800'>RECIEPT DECLINED</h1>
            <p className='par'>{selectedOrder.reason}</p>
        </div>
    }
       {
        selectedOrder.status && selectedOrder.status ==='completed' &&
        <div>
            <h1 className='seymour-one-regular'>Order Completed!!</h1>
        </div>
    }

{
        selectedOrder.status && selectedOrder.status ==='pending' &&
        <div>
            <h1 className='seymour-one-regular'>Transaction Details</h1>
            <p className='par'>Verify whether the transfer has been made by the customer</p>
        </div>
    }

{
        selectedOrder.status && selectedOrder.status ==='preparing cake' &&
        <div>
            <h1 className='seymour-one-regular'>Preparing Cake!</h1>
        </div>
    }

{
        selectedOrder.status && selectedOrder.status =='ready for pickup' &&
        <div>
            <h1 className='seymour-one-regular'>Ready for pickup!</h1>
        </div>
    }
            {
                transactionDetails === true &&
                <div>
              <dl class="flex items-center justify-between gap-4 mt-8">
                <dt class="text-base font-normal text-gray-500">Account Holder</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.accountHolder && selectedOrder.accountHolder
                    }</dd>
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Account Number</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.accountNumber && selectedOrder.accountNumber
                    }</dd>
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Transaction Id</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.transactionId && selectedOrder.transactionId

                    }</dd>
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Screenshot</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.transactionId &&  <a rel="noreferrer" href={selectedOrder.screenshot} className='text-base font-medium text-green-600 cursor-pointer' target='_blank'>Image</a>

                    }</dd>
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Phone Number</dt>
                <dd class="text-base font-medium text-green-600">{
                    selectedOrder.phoneNumber && selectedOrder.phoneNumber

                    }</dd>
                    
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Status</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.status && selectedOrder.status
                    }</dd>
                    
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Total Amount</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.totalAmount && selectedOrder.totalAmount 
                    } ETB</dd>
                    
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Prepayment</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.prePayment && selectedOrder.prePayment 
                    } ETB</dd>
                    
              </dl>
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500">Down Payment</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.downPayment && selectedOrder.downPayment 
                    } ETB</dd>
                    
              </dl>
              <dl class="flex items-center justify-between gap-4 mb-12">
                <dt class="text-base font-normal text-gray-500">Time</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.deliveryDate && getDateFromTimestamp(selectedOrder.deliveryDate)
                    }</dd>
              </dl>
           {
            selectedOrder.status && selectedOrder.status === 'pending'?
            <div className='flex flex-col'>
            <button onClick={confirmTransaction}  class="mt-10 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                  
                  Confirm Transaction
                   
                   </button>
                   <button  onClick={declineTransaction}  class="mt-10 flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                   
                   Decline Transaction
                    
                    </button>
            </div>
            :
            selectedOrder.status === 'accepted' ?
            <div className='flex flex-col'>
            <button onClick={handlegoToCakeDetails}  class="mt-10 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                  
                  Cake Details
                   
                   </button>
            </div>
            :
            selectedOrder.status === 'ready for pickup' ?

            <div className='flex flex-col'>
            <button onClick={handleGoToAllSet}  class="mt-10 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'11px'}}>
                  
                 Review Order
                   
                   </button>
                  
            </div>
            :
            
            <div className='flex flex-col'>
           
                  
            </div>
           }
    
                    
            

                </div>
            }
</div>


            {
                orderDetails === true &&
                <div>

                <span className="par text-light-inverse text-md/normal">
                               {selectedOrder.cakes.map((cake, cakeIndex) => {
                                   return (
                                       <React.Fragment key={cakeIndex}>
                                           <Link to={`/admin/cake/${cake.id}`}>
                                           {cake.cakeData.cakeTitle}
                                           {cakeIndex < selectedOrder.cakes.length - 1 ? ', ' : ''}</Link>
                                       </React.Fragment>
                                   );
                               })}
                           </span>
                   </div>
            }
                </div>
               )

            }

            {
                orderSearch === true &&
              <div  class="bg-white orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">
     <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
 <div class="relative mx-auto mb-20" style={{width:'40%'}}>
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input type="search" id="default-search" onChange={(e)=>setsearch(e.target.value)} style={{height:'55px'}}  class=" block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
    </div>

    {
        queriedOrder.map((i, index) => {
            const { accountHolder, totalAmount, cakes, time,status,deliveryDate } = i;
            return (
                <tbody key={index}>
                    <tr className=" last:border-b-0 mt-6 py-3 ">
                    <td className="pr-12 py-8 pl-0">
                            <div className="flex items-center justify-center">
                                <div className="flex flex-col justify-center">
                                    <p className="mb-1 par transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                                </div>
                            </div>
                        </td>
                        <td className="pr-12 py-8 pl-0">
                            <div className="flex items-center justify-center">
                                <div className="flex flex-col justify-center">
                                    <p className="mb-1 par transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {accountHolder} </p>
                                </div>
                            </div>
                        </td>
                
                        <td className=" pr-12 text-center par">
                            <span className="par text-light-inverse text-md/normal">{totalAmount} ETB</span>
                        </td>
                        <td className=" pr-12 text-center">
                            {
                                status === 'pending'?
                            <span className="text-center capitalize align-baseline inline-flex px-4 py-3 mr-auto items-center font-semibold text-[.95rem] leading-none text-yellow-900 bg-yellow-200 rounded-lg">{status} </span>
                                :
                                status === 'accepted'?
                                <span className="text-center capitalize align-baseline inline-flex px-4 py-3 mr-auto items-center font-semibold text-[.95rem] leading-none text-primary bg-primary-light rounded-lg">{status} </span>
                                :
                                status === 'declined'?
                            <span className="text-center capitalize align-baseline inline-flex px-4 py-3 mr-auto items-center font-semibold text-[.95rem] leading-none text-red bg-red-light rounded-lg">{status} </span>
                                :
                            <span className="text-center capitalize align-baseline inline-flex px-4 py-3 mr-auto items-center font-semibold text-[.95rem] leading-none text-green bg-green-light rounded-lg">{status} </span>
    
                            }
                        </td>
                
                        <td className=" pr-12 text-center par">
                            <button onClick={()=>SelectedOrderPopset(i)} style={{ backgroundColor: '#D9FE00' }} className="ml-auto relative hover:text-primary flex items-center border h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none justify-center">
                                <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-gray-900">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                    </svg>
                                </span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            );
        })
    }

</div>
            }



{
                declineTransactionPop === true &&
                <div id="deleteModal" class="">
    <div class="relative p-4 w-full max-w-md h-full md:h-auto">
        <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5">
            <button onClick={declineTransactionClose}type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Close modal</span>
            </button>
            <p class="mb-4 text-gray-900 header">Are you sure you want to Decline this Transaction?</p>
            
<div className="relative mx-auto mb-8" style={{width:"90%"}}>

<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Reason</p>
<textarea onChange={(e)=>reasonSet(e)} placeholder='The screenshot does not include the transaction ID'  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'12px'}}   type="text" class="border  focus:outline-none
      focus:border-black borel w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md" maxLength="5000"></textarea>
      <div className={`${reasonError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Reason Can't be empty
</div>
</div>
</div>

</div>
            <div class="flex justify-center items-center space-x-4">
                <button onClick={declineTransactionClose} data-modal-toggle="deleteModal" type="button" class="par py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10   dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    No, cancel
                </button>
                <button onClick={handleDeclineTransaction} type="submit" class="py-2 px-3 text-sm par font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900">
                    Yes, I'm sure
                </button>
            </div>
        </div>
    </div>
</div>
            }
            {
                confirmTransactionPop === true &&
            
                    <div id="deleteModal" class="">
        <div class="relative p-4 w-full max-w-md h-full md:h-auto">
            <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5">
                <button onClick={confirmTransactionClose}type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <p class="mb-4 text-gray-900 header">Are you sure you want to confirm this transaction?</p>
                <div class="flex justify-center items-center space-x-4">
                    <button onClick={confirmTransactionClose} data-modal-toggle="deleteModal" type="button" class="par py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10   dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        No, cancel
                    </button>
                    <button onClick={handleConfirmTransaction} type="submit" class="py-2 px-3 text-sm par font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-900">
                        Yes, I'm sure
                    </button>
                </div>
            </div>
        </div>
    </div>
            }

{
                orderCompleted === true &&
            
                    <div id="deleteModal" class="">
        <div class="relative p-4 w-full max-w-md h-full md:h-auto">
            <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5">
                <button onClick={orderCompletedClose}type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <p class="mb-4 text-gray-900 header">Could you please confirm that the customer picked up the cake ?</p>
                <div class="flex justify-center items-center space-x-4">
                    <button onClick={orderCompletedClose} data-modal-toggle="deleteModal" type="button" class="par py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10   dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        No, cancel
                    </button>

                    <button onClick={handleOrderComplete} type="submit" class="py-2 px-3 text-sm par font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-900">
                        Yes, I'm sure
                    </button>
                </div>
            </div>
        </div>
    </div>
            }
            {

            }
            <div >

  {
        pop === true ?
        <aside style={{height:screenHeight}}class="bg-gray-900     transition-transform duration-300 xl:translate-x-0">
        <div class="relative border-b border-white/20">
<svg onClick={navigationPop} aria-hidden="true" class="w-8 h-8 text-white absolute top-5 right-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
         
          <div class="flex justify-center items-center  py-6 px-2" >
            <h6 class="text-center block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
          </div>
       
        </div>
        <div class="my-4">
          <ul class="m-4 flex flex-col ">
            <li>
            <Link to='/admin'>
    
            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                 
                 <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
               </button>
              </Link>
            </li>
            <li>
            <Link to='/admin/orders'>
            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center  pl-2" type="button">
                
                <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Order</p>
              </button>
             
              </Link>
            </li>
            <li>
            <Link to='/admin/allorders'>
    
                <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                  
                  <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Orders</p>
                </button>
              </Link>
            </li>
            <li>
            <Link to='/admin/addcake'>
    
                <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                 
                  <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
                </button>
              </Link>
            </li>
            <li>
            <Link to='/admin/managecakes'>
    
                <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                 
                  <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
                </button>
              </Link>
            </li>
            <li>
            <Link to='/admin/messages'>
    
                <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                 
                  <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
                </button>
              </Link>
            </li>
            <li>
            <Link to='/admin/reserved'>
    
            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                 
                 <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Students</p>
               </button>
              </Link>
            </li>
          </ul>
          <ul class="m-4 flex flex-col">
            <li class=" mt-4 mb-2">
              <p class="par block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
            </li>
        
            <li>
                <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
                
                  <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
                </button>
            </li>
          </ul>
        </div>
      </aside>
      :        
      
  <div >
    <nav class="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-0 py-1">
                    <div class="flex flex-col  gap-6 ">
                    
                      <div class="flex justify-between w-full items-center mb-4 px-5 mt-2">
                      <Link to='/admin'style={{width:'18%'}} >
        <img style={{width:'100%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />
</Link>
        <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
              </button>
              </div>  <div class="flex justify-between mx-5">
                  
                  <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-900 barlow " style={{fontSize:'25px'}}>Active Orders</h6>
                  <button onClick={orderSearchLookup}  style={{fontSize:"11px",width:'25%'}} class="flex justify-center ml-2 w-full px-5 py-2.5  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
          
          Find Order
        </button>
                </div>
                    </div>
                  </nav>
                {
                    orders.length !==0 &&
                    <div class="">
 
                    <div className="flex  mb-5  ">
                    <div className="w-full max-w-full  mb-6  mx-auto">
                        <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
                        <div className="relative flex flex-col min-w-0 break-words  bg-clip-border rounded-2xl border-stone-200 bg-light/30">
                        
                            
                            <div className="flex-auto block py-8  ">
                            <div className="overflow-x-auto">
                                <table className="w-full my-0 align-middle text-dark border-neutral-200">
                                <thead className="align-bottom ">
                                                  <tr className="font-semibold text-[0.95rem] text-gray-800">
                                                  <th className="pb-3 text-center  header  "></th>
                          
                                                  <th className="pb-3 text-center  header  ">Full Name</th>
                                                  <th className="pb-3 text-center  header ">Cake</th>
                                                  <th className="pb-3 text-center  header ">Price</th>
                                                  <th className="pb-3 text-center  header ">Delivery Date</th>
                                                  <th className="pb-3 text-center  header ">STATUS</th>
                                                  </tr>
                                              </thead>
                                {
                  orders.sort((a, b) => a.deliveryDate - b.deliveryDate).slice(0,10).map((i, index) => {
                    const { accountHolder, totalAmount, cakes, time,status,deliveryDate } = i;
                    return (
                        <tbody key={index}>
                        <tr className=" last:border-b-0 mt-6 py-3 ">
                        <td className=" py-8 pl-0">
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                                    </div>
                                </div>
                            </td>
                            <td className=" py-8 pl-0">
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {accountHolder} </p>
                                    </div>
                                </div>
                            </td>
                            <td className="  text-center ">
                                <span className="barlow text-light-inverse text-md/normal">
                                    {cakes.map((cake, cakeIndex) => {
                                        return (
                                            <React.Fragment key={cakeIndex}>
                                                {cake.cakeData.cakeTitle}
                                                {cakeIndex < cakes.length - 1 ? <br/> : ''}
                                            </React.Fragment>
                                        );
                                    })}
                                </span>
                            </td>
                            <td className="  text-center par">
                                <span className="barlow text-light-inverse text-md/normal">{formatNumber(totalAmount)} ETB</span>
                            </td>
                            <td className="  text-center par">
                                <span className="barlow text-light-inverse text-md/normal">{getDateFromTimestamp(deliveryDate)}</span>
                            </td>
                            <td className="  text-center">
                                {
                                    status === 'pending'?
                                <span className="uppercase bg-yellow-200 text-yellow-600 py-2  rounded barlow px-2">{status} </span>
                                    :
                                    status === 'accepted'?
                                    <span className="uppercase bg-purple-200 text-purple-600 py-2  rounded barlow px-2">{status} </span>
                                   
                                    :
                                    status === 'declined'?
                                <span className="uppercase bg-red-200 text-red-600 py-2  rounded barlow px-2">Reciept Declined </span>
                                    :
                                    status === 'ready for pickup'?
                                    <span className="uppercase bg-blue-200 text-blue-600 py-2  rounded barlow px-2">{status} </span>
                                        :
                                        status === 'preparing cake'?
                                        <span className="uppercase bg-pink-200 text-pink-600 py-2  rounded barlow px-2">{status} </span>
                                            :
                                <span  class="uppercase bg-green-200 text-green-600 py-2  rounded barlow px-2" >{status}</span>
                 
                 
                                }
                            </td>
                           
                            <td className="  text-center par">
                          <button onClick={()=>SelectedOrderPopset(i)}   className="ml-auto bg-blue-700  relative hover:text-primary flex items-center border h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none justify-center">
                                    <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-white">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    );
                })
            }
                              
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    </div>

                }

                {
                    orders.length === 0 &&
                    <div>
                          <div className='flex items-center justify-center flex-col mt-28 '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/Order%20food-amico.svg?alt=media&token=f075bbe5-0ae5-4057-947d-cfb0bae0ca5d" alt="No order" style={{width:"25%"}}/>
  <h1 className='fjalla text-center text-gray-900 mt-8 par' style={{fontSize:"20px"}}>There are no orders 


</h1>

</div>
                    </div>
                }
   

  </div>
}
</div>

        </div>
        <div id='mobile'>       
             <div >
{
    pop === true ?
    <aside style={{height:screenHeight}}class=" bg-gray-900 50 my-4 ml-4  rounded-xl transition-transform duration-300 xl:translate-x-0">
    <div class="relative border-b border-white/20">
    <svg onClick={navigationPop} aria-hidden="true" class="w-8 h-8 text-white absolute top-5 right-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

      <div class="flex justify-center items-center  py-6 px-2" >
        <h6 class="text-center block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white header">Shewit Pastry Admin</h6>
      </div>
   
    </div>
    <div class="my-4">
      <ul class="m-4 flex flex-col ">
        <li>
        <Link to='/admin'>
        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
             
             <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Dashboard</p>
           </button>
       
          </Link>
        </li>
        <li>
        <Link to='/admin/orders'>
       
        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
              
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Active Orders</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/allorders'>
        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center  pl-2" type="button">
            
            <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">All Order</p>
          </button>
           
          </Link>
        </li>
        <li>
        <Link to='/admin/addcake'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
             
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Add New Cake</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/managecakes'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
             
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Manage Cakes</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/messages'>

            <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
             
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Messages</p>
            </button>
          </Link>
        </li>
        <li>
        <Link to='/admin/reserved'>

        <button class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
             
             <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">Students</p>
           </button>
          </Link>
        </li>
      </ul>
      <ul class="m-4 flex flex-col">
        <li class=" mt-4 mb-2">
          <p class="par block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">Auth pages</p>
        </li>
    
        <li>
            <button onClick={handleLogOut} class="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 pl-2" type="button">
            
              <p style={{fontSize:'12px'}} class="barlow block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">sign out</p>
            </button>
        </li>
      </ul>
    </div>
  </aside>:
  <div className=''>
     <nav style={{padding:'2vw 2vw'}}class="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-0 py-1">
      <div class="flex flex-col-reverse justify-between gap-6 md:flex-row md:items-center">
        <div class="capitalize flex items-center w-full justify-between" style={{padding:'0 2vw'}}>
    
          <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-900 barlow" style={{fontSize:'5vw',paddingLeft:'4vw'}}>All Orders</h6>
          <button onClick={orderSearchLookup}  style={{fontSize:"2.6vw",height:'10vw',width:'25%'}} class="flex items-center justify-center ml-2 w-full px-5 py-2.5  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
          
          Find Order
        </button>
        </div>
        <div class="flex justify-between w-full items-center mb-4">
        <Link to='/admin'style={{width:'15%'}}>
        <img style={{width:'100%'}}src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/images__2_-removebg-preview.png?alt=media&token=0b8ae9c5-4dae-4351-a08b-852100eff3bc" alt="Logo" className=' ' />
</Link>
        <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" class="flex p-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 ">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
              </button>
              </div>

      </div>
    </nav>
 <div  className='relative'>
            {
                orderPop === true &&
                (
              loading === true ?
              <div  class="bg-white orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">


              <div
                className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                role="status"></div>
           
            
              </div>
              :
              success === true ?
              <div style={{backgroundColor:'#00ff59'}} class=" orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">
   <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:'12vw',height:'12vw'}} fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<h1 className='header' style={{fontSize:'5vw'}}>Order Completed</h1>
<p className='barlow'style={{fontSize:'3.5vw'}}>{selectedOrder.totalAmount} ETB</p>

            </div>

              :
              allSet === true ?
              <div  className='orderPop shadow-xl border flex flex-col  justify-center  items-center '>
             <ol style={{padding:'3vw'}} class="flex  items-center justify-center mb-6 w-full text-sm font-medium text-center text-gray-500  sm:text-base">
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
     
   <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
          
        </span>
    </li>
    <li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

<span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
 
<svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
      
    </span>
</li>
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

<span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
 
<svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
      
    </span>
</li>
    <li class="flex ">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
           
    <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        </span>
    </li>
</ol>
                
        <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
          
          <div className="">
            <h1 className='barlow' style={{fontSize:'6vw'}}>Cake Baked! Waiting to be picked up.</h1>
             
            <dl class="flex items-center justify-between mb-2 ">
                <dt class="par font-normal text-gray-500" style={{fontSize:'3vw'}}>Customer's Phone Number - <span style={{fontSize:'2.8vw'}}class="text-base font-medium header text-blue-600">
                 {selectedOrder&& selectedOrder.phoneNumber}
                </span></dt>
                </dl>
                      {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
                        <div key={cake.id}>
                          <CakeGallery
                            cake={cake}
                            currentIndex={currentIndexes[cake.id] || 0}
                            handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                            handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                          />
                        </div>
                      ))}
                                  <div className='flex mt-10 justify-center flex-col items-center'>
                                  <button onClick={orderPopClose}  class="mt-10 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-5 pb-5 rounded-lg fjalla px-3" style={{fontSize:'3vw',height:'13vw',width:'90%'}}>
                            
                            All Set
                             
                             </button>
                             <p onClick={orderCompletedSet} style={{fontSize:'3vw'}}className='text-center hover:underline par cursor-pointer m-2'>Delivered?</p>
                                                            <p onClick={declineTransaction} className='text-center hover:underline par cursor-pointer m-2'>Decline</p>
                               
                   
                         
                      </div>
                    </div>
                        </div>
                        :

              paymentConfirmed=== true ?
                <div  className='orderPop shadow-xl border flex flex-col  justify-center  items-center '>
   
<ol style={{padding:'3vw'}} class="flex items-center justify-center mb-6 w-full text-sm font-medium text-center text-gray-500  sm:text-base">
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
     
   <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
          
        </span>
    </li>
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block ">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">

            <svg class="w-4 h-4 text-blue-600 lg:w-5 lg:h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
            </svg>
        </span>
    </li>
        <li class=" justify-center flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12  shrink-0">
    <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
       
        </span>
    </li>
    <li class="flex ">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12  shrink-0">
           
            <svg class="w-4 h-4 text-gray-500 lg:w-5 lg:h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
    </li>
</ol>
<svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<div className="">
            {selectedOrder.cakes && selectedOrder.cakes.map((cake) => (
              <div key={cake.id}>
                <CakeGallery
                  cake={cake}
                  currentIndex={currentIndexes[cake.id] || 0}
                  handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                  handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                />
              </div>
            ))}
                        <div className='flex mt-10 justify-center flex-col items-center'>
                        <button onClick={handleGoToCakeConfirmation}  class="mt-10 flex barlow items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'3vw',width:'90%',height:'13vw'}}>
                  
                  Prepare Cake
                   
                   </button>
                        <button  onClick={backToTransactionConfirm}  class="mt-5 flex barlow items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'3vw',width:'90%',height:'13vw'}}>
                   
                   Back
                    
                    </button>
        
               
            </div>
          </div>
              </div>
              :
              cakeMade === true ?
              <div  className='orderPop shadow-xl border flex flex-col  justify-center  items-center '>
             
<ol style={{padding:'3vw'}} class="flex items-center justify-center mb-6 w-full text-sm font-medium text-center text-gray-500  sm:text-base">
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
     
   <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
          
        </span>
    </li>
    <li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

<span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
 
<svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
      
    </span>
</li>
        <li class=" justify-center flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
    <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
       
        </span>
    </li>
    <li class="flex ">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12  shrink-0">
           
            <svg class="w-4 h-4 text-gray-500 lg:w-5 lg:h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
    </li>
</ol>
          <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
          
          <div className="">
          <div>
            <h1 className='barlow text-center' style={{fontSize:'5vw'}}>Now is the time to prepare the cake!</h1>
        </div>
                  <img style={{width:'70%',margin:'auto'}} src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cake.gif?alt=media&token=8646f696-e66f-45fc-b5f8-0adbf7cd3ff3" alt="" />
                                  <div className='flex mt-10 justify-center flex-col items-center'>
                       
                      <button onClick={handleGoToCakeDelivery}  class="mt-4 flex barlow items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'3vw',width:'90%',height:'13vw'}}>
                            
                      The cake is ready
                             
                             </button>
                             <button  onClick={handlegoToCakeDetails}  class="mt-5 flex barlow items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{margin:0,fontSize:'3vw',width:'90%',height:'13vw'}}>
                             
                             Back
                              
                              </button>
                      </div>
                    </div>
                        </div>
                        :
              readyToBeDelivered === true ?

              <div  className='orderPop shadow-xl border flex flex-col  justify-center  items-center '>
       <ol style={{padding:'3vw'}} class="flex items-center justify-center mb-6 w-full text-sm font-medium text-center text-gray-500  sm:text-base">
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
     
   <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
          
        </span>
    </li>
    <li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

<span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
 
<svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
      
    </span>
</li>
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

<span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
 
<svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
      
    </span>
</li>
    <li class="flex ">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
           
            <svg class="w-4 h-4 text-blue-500 lg:w-5 lg:h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
    </li>
</ol>
          <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
          <div className="mx-auto" style={{width:'90%'}}>
          <div>
            <h1 className='header ' style={{fontSize:'5vw'}}>Tell the customer the cake is ready to be picked up now</h1>
            <p className='barlow mb-4 text-gray-600'style={{fontSize:'3vw'}}>You are all set, just wait for the customer to pick it up</p>
        
            <dl class="flex items-center justify-between ">
                <dt class="text-base font-normal text-gray-500 barlow"style={{fontSize:'3vw'}}>Customer's Phone Number - <span style={{fontSize:'2.8vw'}}class="text-base font-medium header text-blue-600">
                 {selectedOrder&& selectedOrder.phoneNumber}
                </span></dt>
                
              </dl>
          
        </div>
                                  <div className='flex  justify-center flex-col items-center'>
                            
               <div onClick={handleGoToAllSet}  class="mt-10  flex barlow items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{fontSize:'3vw',width:'90%',height:'13vw'}}>
                            
                            Done
                                   
                                   </div>
                             <div  onClick={handlegoToCakeDetails}  class=" flex barlow items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-3 pb-3 rounded-lg fjalla px-3" style={{fontSize:'3vw',width:'90%',height:'13vw'}}>
                             
                             Back
                              
                              </div>
                         
                      </div>
                    </div>
                  </div>   
                  :   

                <div  className='orderPop shadow-xl border flex flex-col  justify-center  items-center '>
        
        <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
           


{
    selectedOrder.status === 'completed'?
    <ol style={{padding:'3vw',marginTop:'5vw'}} class="flex items-center justify-center mb-6 w-full text-sm font-medium text-center text-gray-500  sm:text-base">
    <li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">
        <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
        <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
        </span>
    </li>
    <li class="flex w-full items-center justify-center after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">
        <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
        <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
        </span>
    </li>
    <li class="flex w-full items-center justify-center after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">
        <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
        <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
        </span>
    </li>
    <li class="flex  ">
        <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
        <svg tyle={{marginRight:'1vw',width:'6vw',height:'6vw'}} class="text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
        </span>
    </li>
</ol>

    :
    selectedOrder.status === 'declined'?
<ol style={{padding:'3vw'}} class="flex items-center justify-center mb-6 w-full text-sm font-medium text-center text-gray-500  sm:text-base">
    <li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-red-100 after:border-4 after:inline-block ">
        <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-red-100 rounded-full lg:h-12 lg:w-12  shrink-0">
        <img style={{marginRight:'1vw',width:'100%',height:'100%'}}  src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

        </span>
    </li>
    <li class="flex w-full items-center justify-center after:content-[''] after:w-full after:h-1 after:border-b after:border-red-100 after:border-4 after:inline-block ">
        <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-red-100 rounded-full lg:h-12 lg:w-12  shrink-0">
        <img style={{marginRight:'1vw',width:'100%',height:'100%'}}  src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

        </span>
    </li>
    <li class="flex w-full items-center justify-center after:content-[''] after:w-full after:h-1 after:border-b after:border-red-100 after:border-4 after:inline-block ">
        <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-red-100 rounded-full lg:h-12 lg:w-12  shrink-0">
        <img style={{marginRight:'1vw',width:'100%',height:'100%'}}  src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

        </span>
    </li>
    <li class="flex  ">
        <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-red-100 rounded-full lg:h-12 lg:w-12  shrink-0">
        <img style={{marginRight:'1vw',width:'100%',height:'100%'}}  src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/remove.png?alt=media&token=20493d05-a2d6-42eb-9552-85c96c71f91b" alt="" />

        </span>
    </li>
</ol>

   :


<ol style={{padding:'3vw'}} class="flex items-center justify-center mb-6 w-full text-sm font-medium text-center text-gray-500  sm:text-base">
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block ">

    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full lg:h-12 lg:w-12  shrink-0">
     
    <svg class="w-3.5 h-3.5 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
          
        </span>
    </li>
<li class="flex w-full items-center justify-center   after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block ">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12  shrink-0">

            <svg class="w-4 h-4 text-gray-500 lg:w-5 lg:h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
            </svg>
        </span>
    </li>
        <li class=" justify-center flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12  shrink-0">
    <svg class="w-3.5 h-3.5 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
       
        </span>
    </li>
    <li class="flex ">
    <span style={{width:'6vw',height:'6vw'}} class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full lg:h-12 lg:w-12  shrink-0">
           
            <svg class="w-4 h-4 text-gray-500 lg:w-5 lg:h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z"/>
            </svg>
        </span>
    </li>
</ol>

   
}





<div style={{width:'80%'}}>
    {
        selectedOrder.status && selectedOrder.status ==='accepted' &&
        <div>
            <h1 style={{fontSize:'4vw'}}className='seymour-one-regular'>Transaction Accepted!</h1>
            <p className='par'style={{fontSize:'2.5vw'}}>It's time to start baking</p>
        </div>
    }

{
        selectedOrder.status && selectedOrder.status ==='declined' &&
        <div>
            <h1 style={{fontSize:'4vw'}}className='seymour-one-regular text-red-800'>RECIEPT DECLINED</h1>
            <p className='par'style={{fontSize:'2.5vw'}}>{selectedOrder.reason}</p>
        </div>
    }
       {
        selectedOrder.status && selectedOrder.status ==='completed' &&
        <div>
            <h1 style={{fontSize:'4vw'}}className='seymour-one-regular'>Order Completed!!</h1>
        </div>
    }

{
        selectedOrder.status && selectedOrder.status ==='pending' &&
        <div>
            <h1 style={{fontSize:'4vw'}}className='seymour-one-regular'>Transaction Details</h1>
            <p className='par'style={{fontSize:'2.5vw'}}>Verify whether the transfer has been made by the customer</p>
        </div>
    }

{
        selectedOrder.status && selectedOrder.status ==='preparing cake' &&
        <div>
            <h1 style={{fontSize:'4vw'}}className='seymour-one-regular'>Preparing Cake!</h1>
        </div>
    }

{
        selectedOrder.status && selectedOrder.status =='ready for pickup' &&
        <div>
            <h1 style={{fontSize:'4vw'}}className='seymour-one-regular'>Ready for pickup!</h1>
        </div>
    }
            {
                transactionDetails === true &&
                <div>
              <dl class="flex items-center justify-between  mt-8">
                <dt class="text-base font-normal text-gray-500"style={{fontSize:'3.5vw'}}>Account Holder</dt>
                <dd class="text-base font-medium text-green-600"style={{fontSize:'2.8vw'}}>{
                       selectedOrder.accountHolder && selectedOrder.accountHolder
                    }</dd>
              </dl>
              <dl class="flex items-center justify-between ">
                <dt class="text-base font-normal text-gray-500"style={{fontSize:'3.5vw'}}>Account Number</dt>
                <dd class="text-base font-medium text-green-600"style={{fontSize:'2.8vw'}}>{
                       selectedOrder.accountNumber && selectedOrder.accountNumber
                    }</dd>
              </dl>
              <dl class="flex items-center justify-between ">
                <dt class="text-base font-normal text-gray-500"style={{fontSize:'3.5vw'}}>Transaction Id</dt>
                <dd class="text-base font-medium text-green-600"style={{fontSize:'2.8vw'}}>{
                       selectedOrder.transactionId && selectedOrder.transactionId

                    }</dd>
              </dl>
              <dl class="flex items-center justify-between ">
                <dt class="text-base font-normal text-gray-500"style={{fontSize:'3.5vw'}}>Screenshot</dt>
                <dd class="text-base font-medium text-green-600">{
                       selectedOrder.transactionId &&  <a rel="noreferrer" href={selectedOrder.screenshot} className='text-base font-medium text-green-600 cursor-pointer' target='_blank'style={{fontSize:'2.8vw'}}>Image</a>

                    }</dd>
              </dl>
              <dl class="flex items-center justify-between ">
                <dt class="text-base font-normal text-gray-500"style={{fontSize:'3.5vw'}}>Phone Number</dt>
                <dd class="text-base font-medium text-green-600"style={{fontSize:'2.8vw'}}>{
                    selectedOrder.phoneNumber && selectedOrder.phoneNumber

                    }</dd>
                    
              </dl>
              <dl class="flex items-center justify-between ">
                <dt class="text-base font-normal text-gray-500"style={{fontSize:'3.5vw'}}>Status</dt>
                <dd class="text-base font-medium text-green-600"style={{fontSize:'2.8vw'}}>{
                       selectedOrder.status && selectedOrder.status
                    }</dd>
                    
              </dl>
              <dl class="flex items-center justify-between ">
                <dt class="text-base font-normal text-gray-500"style={{fontSize:'3.5vw'}}>Total Amount</dt>
                <dd class="text-base font-medium text-green-600"style={{fontSize:'2.8vw'}}>{
                       selectedOrder.totalAmount && selectedOrder.totalAmount 
                    } ETB</dd>
                    
              </dl>
              <dl class="flex items-center justify-between ">
                <dt class="text-base font-normal text-gray-500"style={{fontSize:'3.5vw'}}>Prepayment</dt>
                <dd class="text-base font-medium text-green-600"style={{fontSize:'2.8vw'}}>{
                       selectedOrder.prePayment && selectedOrder.prePayment 
                    } ETB</dd>
                    
              </dl>
              <dl class="flex items-center justify-between ">
                <dt class="text-base font-normal text-gray-500"style={{fontSize:'3.5vw'}}>Down Payment</dt>
                <dd class="text-base font-medium text-green-600"style={{fontSize:'2.8vw'}}>{
                       selectedOrder.downPayment && selectedOrder.downPayment 
                    } ETB</dd>
                    
              </dl>
              <dl class="flex items-center justify-between  mb-12">
                <dt class="text-base font-normal text-gray-500"style={{fontSize:'3.5vw'}}>Time</dt>
                <dd class="text-base font-medium text-green-600"style={{fontSize:'2.8vw'}}>{
                       selectedOrder.deliveryDate && getDateFromTimestamp(selectedOrder.deliveryDate)
                    }</dd>
              </dl>
           {
            selectedOrder.status && selectedOrder.status === 'pending'?
            <div className='flex flex-col'>
            <button onClick={confirmTransaction} style={{fontSize:'3vw',height:'13vw',width:'90%'}}  class=" flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" >
                  
                  Confirm Transaction
                   
                   </button>
                   <button  onClick={declineTransaction} style={{fontSize:'3vw',height:'13vw',width:'90%'}}  class=" flex par items-center bg-red-700  text-white text-center justify-center border cursor-pointer  hover:bg-red-800 pt-3 pb-3 rounded-lg fjalla px-3" >
                   
                   Decline Transaction
                    
                    </button>
            </div>
            :
            selectedOrder.status === 'accepted' ?
            <div className='flex flex-col'>
            <button onClick={handlegoToCakeDetails}  style={{fontSize:'3vw',height:'13vw',width:'90%'}}class="mt-10 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" >
                  
                  Cake Details
                   
                   </button>
            </div>
            :
            selectedOrder.status === 'ready for pickup' ?

            <div className='flex flex-col'>
            <button onClick={handleGoToAllSet} style={{fontSize:'3vw',height:'13vw',width:'90%'}} class="mt-10 flex par items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla px-3" >
                  
                 Review Order
                   
                   </button>
                  
            </div>
            :
            
            <div className='flex flex-col'>
           
                  
            </div>
           }
    
                    
            

                </div>
            }
</div>


            {
                orderDetails === true &&
                <div>

                <span className="par text-light-inverse text-md/normal">
                               {selectedOrder.cakes.map((cake, cakeIndex) => {
                                   return (
                                       <React.Fragment key={cakeIndex}>
                                           <Link to={`/admin/cake/${cake.id}`}>
                                           {cake.cakeData.cakeTitle}
                                           {cakeIndex < selectedOrder.cakes.length - 1 ? ', ' : ''}</Link>
                                       </React.Fragment>
                                   );
                               })}
                           </span>
                   </div>
            }
                </div>
               )

            }

            {
                orderSearch === true &&
              <div  class="bg-white orderPop flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">
     <svg onClick={orderPopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
 <div class="relative mx-auto mb-20" style={{width:'80%'}}>
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input type="search" id="default-search" onChange={(e)=>setsearch(e.target.value)} style={{height:'55px'}}  class=" block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
    </div>

    {
        queriedOrder.map((i, index) => {
            const { accountHolder, totalAmount, cakes, time,status,deliveryDate } = i;
            return (
                <tbody key={index}>
                <tr className=" last:border-b-0  ">
                <td className="  pl-0" style={{paddingBottom:'5vw'}}>
                        <div className="flex items-center justify-center">
                            <div className="flex flex-col justify-center">
                                <p style={{fontSize:'3vw'}}className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                            </div>
                        </div>
                    </td>
                    <td className="  pl-0" style={{paddingBottom:'5vw',paddingLeft:'3vw'}}>
                        <div className="flex items-center justify-center">
                            <div className="flex flex-col justify-center">
                                <p style={{fontSize:'3vw'}}className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {accountHolder} </p>
                            </div>
                        </div>
                    </td>
                    <td className="  text-center par"style={{paddingBottom:'5vw',paddingLeft:'3vw'}}>
                        <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="barlow text-light-inverse text-md/normal">{formatNumber(totalAmount)} ETB</span>
                    </td>
                    <td className="  text-center"style={{paddingBottom:'5vw',paddingLeft:'3vw'}}>
                        {
                            status === 'pending'?
                        <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}} className="uppercase bg-yellow-200 text-yellow-600 py-2  rounded barlow ">{status} </span>
                            :
                            status === 'accepted'?
                            <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}} className="uppercase bg-purple-200 text-purple-600 py-2  rounded barlow ">{status} </span>
                           
                            :
                            status === 'declined'?
                        <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="uppercase bg-red-200 text-red-600 py-2  rounded barlow ">Reciept Declined </span>
                            :
                            status === 'ready for pickup'?
                            <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="uppercase bg-blue-200 text-blue-600 py-2  rounded barlow ">{status} </span>
                                :
                                status === 'preparing cake'?
                                <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="uppercase bg-pink-200 text-pink-600 py-2  rounded barlow ">{status} </span>
                                    :
                        <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}} class="uppercase bg-green-200 text-green-600 py-2  rounded barlow " >{status}</span>
         
         
                        }
                    </td>
                    <td className="  text-center par" style={{paddingBottom:'5vw',paddingLeft:'3vw'}}>
                  <button onClick={()=>SelectedOrderPopset(i)} style={{width:'6vw',height:'6vw'}}  className="ml-auto bg-blue-700  relative hover:text-primary flex items-center border  text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none justify-center">
                            <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                                <svg style={{width:'4vw',height:'4vw'}}xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>
                            </span>
                        </button>
                    </td>
             
                </tr>
            </tbody>
            );
        })
    }

</div>
            }



{
                declineTransactionPop === true &&
                <div id="deleteModal" class="">
    <div class="relative p-4 w-full max-w-md h-full flex items-center justify-center ">
        <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5">
            <button onClick={declineTransactionClose}type="button" class="text-gray-900 absolute top-2 right-2 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Close modal</span>
            </button>
            <p class="mb-4 text-gray-900 header" style={{fontSize:'4vw'}}>Are you sure you want to Decline this Transaction?</p>
            
            <div className="relative m-auto " style={{width:"90%",marginBottom:"3vw",lineHeight:"4vw"}}>

<p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"1vw",paddingRight:"2vw"}}>Reason</p>


<textarea
  style={{
    padding: '3vw 3.5vw',
    fontSize: '3vw',
    minHeight: '50vw',
    maxHeight: '80vw',
  }}
  placeholder="Your screenshot does not contain the transactional ID"
  onChange={e => reasonSet(e)}
  className="border text-gray-600 focus:outline-none focus:border-black w-full text-base block bg-white border-gray-300 rounded-md"
  maxLength={1000}
></textarea>
<p className='fjalla' style={{fontSize: '3vw'}}>{reason.length + '/ 1,000'}</p>
   <div className={`${reasonError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Reason Can't be empty

</div>
</div>
</div> 

</div>
            <div class="flex justify-center items-center space-x-4">
                <button onClick={declineTransactionClose} style={{fontSize:'3vw',padding:'2vw 3vw'}} data-modal-toggle="deleteModal" type="button" class="par py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10   dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    No, cancel
                </button>
                <button onClick={handleDeclineTransaction} style={{fontSize:'3vw',padding:'2vw 3vw'}} type="submit" class="py-2 px-3 text-sm par font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900">
                    Yes, I'm sure
                </button>
            </div>
        </div>
    </div>
</div>
            }
            {
                confirmTransactionPop === true &&
            
                    <div id="deleteModal" class="">
        <div class="relative p-4 w-full max-w-md h-full md:h-auto flex items-center justify-center">
            <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5">
                <button onClick={confirmTransactionClose}type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <p class="mb-4 text-gray-900 header" style={{fontSize:'4vw'}}>Are you sure you want to confirm this transaction?</p>
                <div class="flex justify-center items-center space-x-4">
                    <button onClick={confirmTransactionClose}style={{fontSize:'3vw',padding:'2vw 3vw'}}  data-modal-toggle="deleteModal" type="button" class="par py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10   dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        No, cancel
                    </button>
                    <button onClick={handleConfirmTransaction}style={{fontSize:'3vw',padding:'2vw 3vw'}}  type="submit" class="py-2 px-3 text-sm par font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-900">
                        Yes, I'm sure
                    </button>
                </div>
            </div>
        </div>
    </div>
            }

{
                orderCompleted === true &&
            
                    <div id="deleteModal" class="">
        <div class="relative p-4 w-full max-w-md h-full md:h-auto flex items-center justify-center">
            <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5 ">
                <button onClick={orderCompletedClose}type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <p class="mb-4 text-gray-900 header"style={{fontSize:'4vw'}}>Could you please confirm that the customer picked up the cake ?</p>
                <div class="flex justify-center items-center space-x-4">
                    <button onClick={orderCompletedClose}style={{fontSize:'3vw',padding:'2vw 3vw'}}  data-modal-toggle="deleteModal" type="button" class="par py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10   dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        No, cancel
                    </button>

                    <button onClick={handleOrderComplete} style={{fontSize:'3vw',padding:'2vw 3vw'}} type="submit" class="py-2 px-3 text-sm par font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-900">
                        Yes, I'm sure
                    </button>
                </div>
            </div>
        </div>
    </div>
            }
            {

            }
            <div >
          
         
      
            {
                    orders.length !==0 &&
                    <div>

                <div className="  mb-5  " style={{padding:'5vw 2vw'}}>
                    <div className="w-full max-w-full  mb-6  mx-auto">
                        <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white ">
                        <div className="relative flex flex-col min-w-0 break-words  bg-clip-border rounded-2xl border-stone-200 bg-light/30">
                        
                            
                            <div className="flex-auto block py-8  ">
                            <div className="overflow-x-auto">
                                <table className="w-full my-0 align-middle text-dark border-neutral-200">
                                <thead className="align-bottom ">
                                    <tr className="font-semibold text-[0.95rem] text-gray-800">
                                    <th className="pb-3 text-center  header  "></th>
            
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>Full Name</th>
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>Cake</th>
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>Price</th>
                                    <th className="pb-3 text-center  header "style={{fontSize:'3vw'}}>STATUS</th>
                                    </tr>
                                </thead>
                                {
                orders.sort((a, b) => a.deliveryDate - b.deliveryDate).slice(0,10).map((i, index) => {
                    const { accountHolder, totalAmount, cakes, time,status,deliveryDate } = i;
                    return (
                        <tbody key={index}>
                        <tr className=" last:border-b-0  ">
                        <td className="  pl-0" style={{paddingBottom:'5vw'}}>
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p style={{fontSize:'3vw'}}className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {index + 1} </p>
                                    </div>
                                </div>
                            </td>
                            <td className="  pl-0" style={{paddingBottom:'5vw',paddingLeft:'3vw'}}>
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-col justify-center">
                                        <p style={{fontSize:'3vw'}}className="mb-1 barlow transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary justify-center"> {accountHolder} </p>
                                    </div>
                                </div>
                            </td>
                            <td className="  text-center "style={{paddingBottom:'5vw',paddingLeft:'3vw'}}>
                                <span style={{fontSize:'3vw'}}className="barlow text-light-inverse text-md/normal">
                                    {cakes.map((cake, cakeIndex) => {
                                        return (
                                            <React.Fragment key={cakeIndex}>
                                                {cake.cakeData.cakeTitle}
                                                {cakeIndex < cakes.length - 1 ? <br/> : ''}
                                            </React.Fragment>
                                        );
                                    })}
                                </span>
                            </td>
                            <td className="  text-center par"style={{paddingBottom:'5vw',paddingLeft:'3vw'}}>
                                <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="barlow text-light-inverse text-md/normal">{formatNumber(totalAmount)} ETB</span>
                            </td>
                            <td className="  text-center"style={{paddingBottom:'5vw',paddingLeft:'3vw'}}>
                                {
                                    status === 'pending'?
                                <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}} className="uppercase bg-yellow-200 text-yellow-600 py-2  rounded barlow ">{status} </span>
                                    :
                                    status === 'accepted'?
                                    <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}} className="uppercase bg-purple-200 text-purple-600 py-2  rounded barlow ">{status} </span>
                                   
                                    :
                                    status === 'declined'?
                                <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="uppercase bg-red-200 text-red-600 py-2  rounded barlow ">Reciept Declined </span>
                                    :
                                    status === 'ready for pickup'?
                                    <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="uppercase bg-blue-200 text-blue-600 py-2  rounded barlow ">{status} </span>
                                        :
                                        status === 'preparing cake'?
                                        <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}}className="uppercase bg-pink-200 text-pink-600 py-2  rounded barlow ">{status} </span>
                                            :
                                <span style={{fontSize:'2.8vw',padding:'2vw 1.5vw'}} class="uppercase bg-green-200 text-green-600 py-2  rounded barlow " >{status}</span>
                 
                 
                                }
                            </td>
                            <td className="  text-center par" style={{paddingBottom:'5vw',paddingLeft:'3vw'}}>
                          <button onClick={()=>SelectedOrderPopset(i)} style={{width:'6vw',height:'6vw'}}  className="ml-auto bg-blue-700  relative hover:text-primary flex items-center border  text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none justify-center">
                                    <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                                        <svg style={{width:'4vw',height:'4vw'}}xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-white">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </span>
                                </button>
                            </td>
                     
                        </tr>
                    </tbody>
                    );
                })
            }
                              
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    </div>

                }

</div>

        </div>
  </div>

}
  
</div>
    
            </div>
    </div>
  )
}
