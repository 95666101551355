import { useState, useEffect } from 'react';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { firestore } from '../firebase';
import { Link } from 'react-router-dom';
import Nav from './nav'
import Footer from './footer';

export default function Home() {
    const [cakes, setCakes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndexes, setCurrentIndexes] = useState({});

  const [screenHeight, setScreenHeight] = useState(window.screen.height);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.screen.height);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchCakes = async () => {
      const document = collection(firestore, 'Cakes');
      const q = query(document, orderBy('postedTime'));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        let temp = [];
        snapshot.docs.forEach((doc) => {
          temp.push({ ...doc.data(), id: doc.id });
        });
        setCakes(temp);
        setIsLoading(false);
      }, (error) => {
        console.error('Error fetching cakes:', error);
        setIsLoading(false);
      });

      // Clean up the listener when the component unmounts
      return unsubscribe;
    };

    fetchCakes();
  }, []);
  useEffect(() => {
    // Initialize currentIndexes state with the first index for each cake
    const initialIndexes = cakes.reduce((acc, cake) => {
      acc[cake.id] = 0;
      return acc;
    }, {});
    setCurrentIndexes(initialIndexes);
  }, [cakes]);

  const handlePrevious = (cakePhotos, id) => {
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [id]: (prevIndexes[id] === 0 ? cakePhotos.length - 1 : prevIndexes[id] - 1),
    }));
  };
  
  const handleNext = (cakePhotos, id) => {
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [id]: (prevIndexes[id] === cakePhotos.length - 1 ? 0 : prevIndexes[id] + 1),
    }));
  };
  
  function CakeGallery({ cake, currentIndex, handlePrevious, handleNext }) {
    return (
  
      <div>
     <div id='mobile'>

<div id="gallery" className="relative w-full  " data-carousel="slide">
  <div className="relative overflow-hidden rounded-lg">
    <div className="relative overflow-hidden rounded-lg shadow-xl">
      <div className="duration-700 ease-in-out " data-carousel-item>
        {cake.cakePhotos.map((photo, index) => (
          <img
            key={index}
            src={photo.cakePhotos}
            alt={`Selected file ${index}`}
            style={{ height: '200px', objectFit: 'cover' }}
            className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
          />
        ))}
        {cake.cakePhotos.length === 0 && (
          <img
            className="h-auto w-full rounded-lg"
            style={{ height: '300px', objectFit: 'cover' }}
            src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
            alt="description"
          />
        )}
      </div>

      {cake.cakePhotos.length >= 2 && (
           <button
           type="button"
         style={{padding:'2vw 0vw'}}

           className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
           data-carousel-prev
         >
         <span onClick={() => handlePrevious(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">

             <svg
               style={{width:'4vw',height:'4vw'}}
               className=" text-gray-800 dark:text-gray-800 rtl:rotate-180"
               aria-hidden="true"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 6 10"
             >
               <path
                 stroke="currentColor"
                 stroke-linecap="round"
                 stroke-linejoin="round"
                 stroke-width="2"
                 d="M5 1 1 5l4 4"
               />
             </svg>
             <span className="sr-only">Previous</span>
           </span>
         </button>
     
      )}

      {cake.cakePhotos.length >= 2 && (
         <div
         type="div"
         style={{padding:'2vw 0vw'}}
         className="absolute top-0 end-0 z-30 flex items-center justify-center h-full   group focus:outline-none"
         data-carousel-next
       >
         <span onClick={() => handleNext(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
           <svg
             style={{width:'4vw',height:'4vw'}}
             className=" text-gray-800 dark:text-gray-800 rtl:rotate-180"
             aria-hidden="true"
             xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 6 10"
           >
             <path
               stroke="currentColor"
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="2"
               d="m1 9 4-4-4-4"
             />
           </svg>
           <span className="sr-only">Next</span>
         </span>
       </div>

      )}
    </div>
  </div>
  <div className="flex relative justify-center  flex-col">
<div style={{paddingLeft:'3vw',paddingRight:'3vw'}} className="pb-5 relative flex w-full flex-col rounded-lg border border-gray-100 bg-white shadow-md">
  <h5 className="mt-4 par text-left  text-slate-900" style={{ fontSize: '3.5vw' }}>
    { cake.cakeTitle}
  </h5>
  <div className="mt-2 mb-5 flex items-center justify-start">
    <p>
      <span className="header font-bold text-slate-900" style={{ fontSize: '4vw' }}>
        {cake.cakePrice + ' ETB'}
      </span>
      {
cake.cakeColor && cake.cakeColor.length != 0 &&
  <span className='flex mt-4'>
{cake.cakeColor.map((color, index) => (
<div  class="   mx-1  rounded-full shadow border  " style={{backgroundColor:color,width:'4vw',height:'4vw'}}></div>

))}
</span>
}
    </p>
  </div>
 <Link to={`/cake/${cake.id}`}>
 <button style={{fontSize:'2.5vw',padding:'2vw 4vw'}} className="par w-full flex items-center justify-center rounded-md bg-slate-900  text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
  <svg
  style={{width:'5vw',height:'5vw',marginRight:'1.4vw'}}
    xmlns="http://www.w3.org/2000/svg"
    className=""
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
    />
  </svg>
  Buy Now
</button>

 </Link>
</div>
</div>
</div>
</div>
      <div id='desktop'>
      <div id="gallery" className="relative w-full  " data-carousel="slide">
        <div className="relative overflow-hidden rounded-lg">
          <div className="relative overflow-hidden rounded-lg shadow-xl">
            <div className="duration-700 ease-in-out " data-carousel-item>
              {cake.cakePhotos.map((photo, index) => (
                <img
                  key={index}
                  src={photo.cakePhotos}
                  alt={`Selected file ${index}`}
                  style={{ height: '300px', objectFit: 'cover' }}
                  className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
                />
              ))}
              {cake.cakePhotos.length === 0 && (
                <img
                  className="h-auto w-full rounded-lg"
                  style={{ height: '300px', objectFit: 'cover' }}
                  src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                  alt="description"
                />
              )}
            </div>
  
            {cake.cakePhotos.length >= 2 && (
                 <button
                 type="button"
                 className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-2 cursor-pointer group focus:outline-none"
                 data-carousel-prev
               >
               <span onClick={() => handlePrevious(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
  
                   <svg
                     
                     className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 6 10"
                   >
                     <path
                       stroke="currentColor"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                       stroke-width="2"
                       d="M5 1 1 5l4 4"
                     />
                   </svg>
                   <span className="sr-only">Previous</span>
                 </span>
               </button>
           
            )}
  
            {cake.cakePhotos.length >= 2 && (
               <div
               type="div"
               className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-2  group focus:outline-none"
               data-carousel-next
             >
               <span onClick={() => handleNext(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                 <svg
                   
                   className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                   aria-hidden="true"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 6 10"
                 >
                   <path
                     stroke="currentColor"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                     stroke-width="2"
                     d="m1 9 4-4-4-4"
                   />
                 </svg>
                 <span className="sr-only">Next</span>
               </span>
             </div>
  
            )}
          </div>
        </div>
        <div className="flex relative justify-center  flex-col">
      <div className="px-5 pb-5 relative flex w-full flex-col rounded-lg border border-gray-100 bg-white shadow-md">
        <h5 className="mt-4 par text-left  text-slate-900" style={{ fontSize: '15px' }}>
          { cake.cakeTitle}
        </h5>
        <div className="mt-2 mb-5 flex items-center justify-start">
          <p>
            <span className="header font-bold text-slate-900" style={{ fontSize: '20px' }}>
              {cake.cakePrice + ' ETB'}
            </span>
            {
      cake.cakeColor && cake.cakeColor.length != 0 &&
        <span className='flex mt-4'>
      {cake.cakeColor.map((color, index) => (
      <div  class=" w-7 h-7  mx-1  rounded-full shadow border  " style={{backgroundColor:color}}></div>

    ))}
      </span>
      }
          </p>
        </div>
       <Link to={`/cake/${cake.id}`}>
       <button className="par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        Buy Now
      </button>
  
       </Link>
      </div>
    </div>
      </div>
      </div>

      <div id="tablet">
      <div id="gallery" className="relative w-full  " data-carousel="slide">
        <div className="relative overflow-hidden rounded-lg">
          <div className="relative overflow-hidden rounded-lg shadow-xl">
            <div className="duration-700 ease-in-out " data-carousel-item>
              {cake.cakePhotos.map((photo, index) => (
                <img
                  key={index}
                  src={photo.cakePhotos}
                  alt={`Selected file ${index}`}
                  style={{ height: '100%', objectFit: 'cover' }}
                  className={`rounded-t-xl w-full h-auto ${index === currentIndex ? 'block' : 'hidden'}`}
                />
              ))}
              {cake.cakePhotos.length === 0 && (
                <img
                  className="h-auto w-full rounded-lg"
                  style={{ height: '100%', objectFit: 'cover' }}
                  src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true"
                  alt="description"
                />
              )}
            </div>
  
            {cake.cakePhotos.length >= 2 && (
                 <button
                 type="button"
                 className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-2 cursor-pointer group focus:outline-none"
                 data-carousel-prev
               >
               <span onClick={() => handlePrevious(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
  
                   <svg
                     
                     className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 6 10"
                   >
                     <path
                       stroke="currentColor"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                       stroke-width="2"
                       d="M5 1 1 5l4 4"
                     />
                   </svg>
                   <span className="sr-only">Previous</span>
                 </span>
               </button>
           
            )}
  
            {cake.cakePhotos.length >= 2 && (
               <div
               type="div"
               className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-2  group focus:outline-none"
               data-carousel-next
             >
               <span onClick={() => handleNext(cake.cakePhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                 <svg
                   
                   className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                   aria-hidden="true"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 6 10"
                 >
                   <path
                     stroke="currentColor"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                     stroke-width="2"
                     d="m1 9 4-4-4-4"
                   />
                 </svg>
                 <span className="sr-only">Next</span>
               </span>
             </div>
  
            )}
          </div>
        </div>
        <div className="flex relative justify-center  flex-col">
      <div className="px-3 pb-5 relative flex w-full flex-col rounded-lg border border-gray-100 bg-white shadow-md">
        <h5 className="barlow mt-3 par text-left  text-slate-900" style={{ fontSize: '18px' }}>
          { cake.cakeTitle}
        </h5>
        <div className="mt-2 mb-5 flex items-center justify-start">
          <p>
            <span className="header font-bold text-slate-900" style={{ fontSize: '15px' }}>
              {cake.cakePrice + ' ETB'}
            </span>
            {
      cake.cakeColor && cake.cakeColor.length != 0 &&
        <span className='flex mt-4'>
      {cake.cakeColor.map((color, index) => (
      <div  class=" w-5 h-5  mx-1  rounded-full shadow border  " style={{backgroundColor:color}}></div>

    ))}
      </span>
      }
          </p>
        </div>
       <Link to={`/cake/${cake.id}`}>
       <button className="par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        Buy Now
      </button>
  
       </Link>
      </div>
    </div>
      </div>
      </div>
      </div>
    );
  }
  return (
    isLoading === true ?
    <div>
    <div className="flex flex-col items-center justify-center w-full  " style={{minHeight:'100vh'}}>
    <div
      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
      role="status"></div>

    </div>
  
    
  
  </div>
  :
    <div>
        <div id='desktop'>
            <Nav component={'Home'}/>
            <section class="bg-white mt-28" style={{height:'90vh'}}>
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
<h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">Baked with <mark class="px-2 text-white bg-blue-700 rounded ">Love.</mark>  </h1>

        <p style={{fontSize:'13px'}} class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 par">No matter what the occasion might be, we are sure to have a cake that will go perfectly with any event, whether or not it is a birthday, a wedding, a graduation, or any other special event    We offer a wide variety of flavors and fillings, and our cakes are baked fresh to order. Our cakes are tailored to your budget and taste, and we strive to bring the perfect cake for any occasion.</p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
         <Link to='/cakes'>
        <button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-7 py-4 me-2 mb-2">Shop Now</button> 

         </Link>
         </div>
    </div>
</section>
           
<h1 class="mb-8 ml-4  text-4xl barlow leading-none tracking-tight text-gray-700 md:text-5xl lg:text-6xl">Popular Cakes</h1>

            <div className=" cakeGrid">
            {cakes.slice(0,6).map((cake) => (
              <div key={cake.id}>
                <CakeGallery
                  cake={cake}
                  currentIndex={currentIndexes[cake.id] || 0}
                  handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                  handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                />
              </div>
            ))}
          </div>

          <section class="bg-white ">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
        <div class="   border-gray-200  rounded-lg p-8 md:p-12 mb-8">
      
            <h1 class="text-gray-900  text-3xl md:text-5xl font-extrabold mb-2">Here's how you can easily order our delicious cakes</h1>
       
        </div>
        <div class="grid md:grid-cols-2 gap-8">
            <div class=" border-gray-200  rounded-lg p-8 md:p-12">
                <div class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md   mb-2">
                   
                    1
                </div>
                <h2 class="text-gray-900  text-3xl font-extrabold mb-2">Pick a cake that you like</h2>
                <p class="par font-normal text-gray-500  mb-4">We have a large selection of cakes here. Pick a cake you like and click the buy now button</p>
            <Link to='/cakes'>
            <a  class="text-blue-600 par hover:underline font-medium text-lg inline-flex items-center">Cakes
                    <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
                </a></Link>
            </div>
            <div class=" border-gray-200  rounded-lg p-8 md:p-12">
            <div class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md   mb-2">
                   
                   2
               </div>
                <h2 class="text-gray-900  text-3xl font-extrabold mb-2">Proceed to Checkout</h2>
                <p class="par font-normal text-gray-500  mb-4">Once you have selected your cake from the product page, you will be able to see all the details about it. Then click on the Proceed to Checkout button </p>
               
            </div>
        </div>
        <div class="grid md:grid-cols-2 gap-8 mt-5">
            <div class=" border-gray-200  rounded-lg p-8 md:p-12">
                <div class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md   mb-2">
                   
                    3
                </div>
                <h2 class="text-gray-900  text-3xl font-extrabold mb-2">Place your cake order </h2>
                <p class="par font-normal text-gray-500  mb-4">Specify the number of cakes you want. Click on the Proceed to Checkout button and you'll be asked to pay half the price as a down payment </p>
          
            </div>
            <div class=" border-gray-200  rounded-lg p-8 md:p-12">
            <div class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md   mb-2">
                   
                   4
               </div>
                <h2 class="text-gray-900  text-3xl font-extrabold mb-2">Pickup your Cake</h2>
                <p class="par font-normal text-gray-500  mb-4">Once we confirm your order, we will begin making your cake. As soon as your cake is finished, we will notify you. Make the remaining half-payment and pick up your cake</p>
               
            </div>
        </div>
    </div>
</section>
          <h1 class="mb-8 ml-4  text-4xl barlow leading-none tracking-tight text-gray-700 md:text-5xl lg:text-6xl" style={{marginTop:'200px'}}> Our Cake Collection</h1>

<div class="grid grid-cols-2 md:grid-cols-4 gap-4 mx-auto" style={{width:'90%',marginBottom:'100px'}}>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-09_12-00-42-Photoroom.png?alt=media&token=76601164-040d-4b86-8265-5e88b1f3f40e" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-51-32-Photoroom.png?alt=media&token=f09a58e9-e85e-40d6-8bb0-5588932c07d1" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-bakery.appspot.com/o/photo_2024-06-11_16-28-46-Photoroom.png?alt=media&token=7e285bc2-9873-493a-860a-d14c35b5ef9f" ></img>
        </div>
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-42-59-Photoroom.png?alt=media&token=10ed1885-15de-4667-b391-8354f85064a0" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-43-20-Photoroom.png?alt=media&token=c75fed36-e174-4646-89cd-080ffd134f82" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-28-59-Photoroom.png?alt=media&token=99d7cf89-002c-4469-81bb-65c12ae73037" ></img>
        </div>
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-28-14-Photoroom.png?alt=media&token=7bfda325-d587-47a5-95dd-46673e9470ab" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-bakery.appspot.com/o/photo_2024-06-11_16-29-40-Photoroom.png?alt=media&token=5c91e245-a7b3-4e3e-acad-c033b514f9eb" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_16-45-01-Photoroom.png?alt=media&token=cd241087-9c2a-4623-b992-ef10883b76fc" ></img>
        </div>
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_16-44-39-Photoroom.png?alt=media&token=cdeb897a-3a05-4444-a161-adf53adbb1f0" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_16-42-47-Photoroom.png?alt=media&token=bd690e49-764b-4cbd-a1cb-33f15f28f82e" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_16-45-26-Photoroom.png?alt=media&token=f6bd96ee-95ed-45a9-9f2f-bd6163f92da6" ></img>
        </div>
    </div>
</div>






          <section class="bg-white">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl ">Become a Cake Expert in No Time</h1>
        <p style={{fontSize:'13px'}} class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 par">We will take you through the basics of cake making, from selecting the right ingredients and mixing them properly to shaping and decorating the cake. We will also provide you with tips and tricks that will make your cakes stand out from the rest.  </p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
         <Link to='/courseprogram'>
        <button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2">Enroll Now</button> 

         </Link>
         </div>
    </div>
</section>
      
          <Footer/>
        </div>

        <div id='tablet'>
            <Nav component={'Home'}/>
            <section class="bg-white " style={{marginTop:'15%',marginBottom:'40%'}}>
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
<h1 style={{lineHeight:'55px',fontSize:'40px'}}class="mb-4  text-5xl font-extrabold leading-none tracking-tight text-gray-900 ">Baked with <mark class="px-2 text-white bg-blue-700 rounded ">Love.</mark>  </h1>

        <p style={{fontSize:'13px'}} class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 par">No matter what the occasion might be, we are sure to have a cake that will go perfectly with any event, whether or not it is a birthday, a wedding, a graduation, or any other special event    We offer a wide variety of flavors and fillings, and our cakes are baked fresh to order. Our cakes are tailored to your budget and taste, and we strive to bring the perfect cake for any occasion.</p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
         <Link to='/cakes'>
        <button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-7 py-4 me-2 mb-2">Shop Now</button> 

         </Link>
         </div>
    </div>
</section>
           

<h1 class="mb-8 ml-4  text-4xl eading-none tracking-tight text-gray-800 "style={{fontSize:'30px'}}>Popular Cakes</h1>

            <div className=" cakeGrid">
            {cakes.slice(0,6).map((cake) => (
              <div key={cake.id}>
                <CakeGallery
                  cake={cake}
                  currentIndex={currentIndexes[cake.id] || 0}
                  handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                  handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                />
              </div>
            ))}
          </div>
          <section class="bg-white ">
    <div class="py-8 px-4 mx-auto  lg:py-16">
        <div class="   border-gray-200  rounded-lg p-8 md:p-12 mb-8">
      
<h1 style={{lineHeight:'50px',fontSize:'40px'}}class="mb-4  text-5xl font-extrabold leading-none tracking-tight text-gray-900 ">Here's how you can easily order our delicious cakes</h1>
       
        </div>
        <div class="flex">
            <div style={{width:'50%'}} class=" border-gray-200  rounded-lg p-8 md:p-12">
                <div class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md   mb-2">
                   
                    1
                </div>
                <h2 class="text-gray-900  text-3xl font-extrabold mb-2">Pick a cake that you like</h2>
                <p class="par font-normal text-gray-500  mb-4">We have a large selection of cakes here. Pick a cake you like and click the buy now button</p>
            <Link to='/cakes'>
            <a  class="text-blue-600 par hover:underline font-medium text-lg inline-flex items-center">Cakes
                    <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
                </a></Link>
            </div>
            <div style={{width:'50%'}}class=" border-gray-200  rounded-lg p-8 md:p-12">
            <div class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md   mb-2">
                   
                   2
               </div>
                <h2 class="text-gray-900  text-3xl font-extrabold mb-2">Proceed to Checkout</h2>
                <p class="par font-normal text-gray-500  mb-4">Once you have selected your cake from the product page, you will be able to see all the details about it. Then click on the Proceed to Checkout button </p>
               
            </div>
        </div>
        <div class="flex mt-5">
            <div style={{width:'50%'}}class=" border-gray-200  rounded-lg p-8 md:p-12">
                <div class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md   mb-2">
                   
                    3
                </div>
                <h2 class="text-gray-900  text-3xl font-extrabold mb-2">Place your cake order </h2>
                <p class="par font-normal text-gray-500  mb-4">Specify the number of cakes you want. Click on the Proceed to Checkout button and you'll be asked to pay half the price as a down payment </p>
          
            </div>
            <div style={{width:'50%'}}class=" border-gray-200  rounded-lg p-8 md:p-12">
            <div class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md   mb-2">
                   
                   4
               </div>
                <h2 class="text-gray-900  text-3xl font-extrabold mb-2">Pickup your Cake</h2>
                <p class="par font-normal text-gray-500  mb-4">Once we confirm your order, we will begin making your cake. As soon as your cake is finished, we will notify you. Make the remaining half-payment and pick up your cake</p>
               
            </div>
        </div>
    </div>
</section>
<h1 class="mb-8 ml-4  text-4xl eading-none tracking-tight text-gray-800 "style={{fontSize:'30px',marginTop:'200px'}}>Our Cake Collection</h1>


          <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mx-auto" style={{width:'90%',marginBottom:'100px'}}>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-09_12-00-42-Photoroom.png?alt=media&token=76601164-040d-4b86-8265-5e88b1f3f40e" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-51-32-Photoroom.png?alt=media&token=f09a58e9-e85e-40d6-8bb0-5588932c07d1" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-bakery.appspot.com/o/photo_2024-06-11_16-28-46-Photoroom.png?alt=media&token=7e285bc2-9873-493a-860a-d14c35b5ef9f" ></img>
        </div>
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-42-59-Photoroom.png?alt=media&token=10ed1885-15de-4667-b391-8354f85064a0" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-43-20-Photoroom.png?alt=media&token=c75fed36-e174-4646-89cd-080ffd134f82" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-28-59-Photoroom.png?alt=media&token=99d7cf89-002c-4469-81bb-65c12ae73037" ></img>
        </div>
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-28-14-Photoroom.png?alt=media&token=7bfda325-d587-47a5-95dd-46673e9470ab" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-bakery.appspot.com/o/photo_2024-06-11_16-29-40-Photoroom.png?alt=media&token=5c91e245-a7b3-4e3e-acad-c033b514f9eb" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_16-45-01-Photoroom.png?alt=media&token=cd241087-9c2a-4623-b992-ef10883b76fc" ></img>
        </div>
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_16-44-39-Photoroom.png?alt=media&token=cdeb897a-3a05-4444-a161-adf53adbb1f0" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_16-42-47-Photoroom.png?alt=media&token=bd690e49-764b-4cbd-a1cb-33f15f28f82e" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_16-45-26-Photoroom.png?alt=media&token=f6bd96ee-95ed-45a9-9f2f-bd6163f92da6" ></img>
        </div>
    </div>
</div>
          

          <section class="bg-white">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl ">Become a Cake Expert in No Time</h1>
        <p style={{fontSize:'13px'}} class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 par">We will take you through the basics of cake making, from selecting the right ingredients and mixing them properly to shaping and decorating the cake. We will also provide you with tips and tricks that will make your cakes stand out from the rest.  </p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
         <Link to='/courseprogram'>
        <button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2">Enroll Now</button> 

         </Link>
         </div>
    </div>
</section>
      
          <Footer/>
        </div>

        <div id='mobile'>
            <Nav component={'Home'}/>
            <section class="bg-white " style={{height:'50vh',marginTop:'20%'}}>
    <div style={{padding:'4vw 2vw'}}class="mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
<h1 style={{fontSize:'9vw',lineHeight:'11vw'}}class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">Baked with <mark class="px-2 text-white bg-blue-700 rounded ">Love.</mark>  </h1>

        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
         <Link to='/cakes'>
        <button style={{fontSize:'2.8vw',padding:'3vw 4vw'}}  type="button" class="bg-white border border-2 border-gray-950 text-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm  me-2 mb-2">Shop Now</button> 

         </Link>
         </div>
    </div>
</section>
           
<h1 style={{fontSize:'8vw',padding:'3vw 4vw'}}class="mb-8 ml-4  text-4xl barlow leading-none tracking-tight text-gray-700 ">Popular Cakes</h1>


            <div className=" cakeGrid">
            {cakes.slice(0,6).map((cake) => (
              <div key={cake.id}>
                <CakeGallery
                  cake={cake}
                  currentIndex={currentIndexes[cake.id] || 0}
                  handlePrevious={(cakePhotos) => handlePrevious(cakePhotos, cake.id)}
                  handleNext={(cakePhotos) => handleNext(cakePhotos, cake.id)}
                />
              </div>
            ))}
          </div>
<h1 style={{fontSize:'8vw',padding:'3vw 4vw',marginTop:'30vw'}}class="mb-8 ml-4  text-4xl barlow leading-none tracking-tight text-gray-700 ">Our Cake Collection</h1>

          <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mx-auto" style={{width:'90%',marginBottom:'30vw'}}>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-09_12-00-42-Photoroom.png?alt=media&token=76601164-040d-4b86-8265-5e88b1f3f40e" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-51-32-Photoroom.png?alt=media&token=f09a58e9-e85e-40d6-8bb0-5588932c07d1" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-bakery.appspot.com/o/photo_2024-06-11_16-28-46-Photoroom.png?alt=media&token=7e285bc2-9873-493a-860a-d14c35b5ef9f" ></img>
        </div>
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-42-59-Photoroom.png?alt=media&token=10ed1885-15de-4667-b391-8354f85064a0" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-43-20-Photoroom.png?alt=media&token=c75fed36-e174-4646-89cd-080ffd134f82" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-28-59-Photoroom.png?alt=media&token=99d7cf89-002c-4469-81bb-65c12ae73037" ></img>
        </div>
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_21-28-14-Photoroom.png?alt=media&token=7bfda325-d587-47a5-95dd-46673e9470ab" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-bakery.appspot.com/o/photo_2024-06-11_16-29-40-Photoroom.png?alt=media&token=5c91e245-a7b3-4e3e-acad-c033b514f9eb" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_16-45-01-Photoroom.png?alt=media&token=cd241087-9c2a-4623-b992-ef10883b76fc" ></img>
        </div>
    </div>
    <div class="grid gap-4">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_16-44-39-Photoroom.png?alt=media&token=cdeb897a-3a05-4444-a161-adf53adbb1f0" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_16-42-47-Photoroom.png?alt=media&token=bd690e49-764b-4cbd-a1cb-33f15f28f82e" ></img>
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/photo_2024-06-08_16-45-26-Photoroom.png?alt=media&token=f6bd96ee-95ed-45a9-9f2f-bd6163f92da6" ></img>
        </div>
    </div>
</div>
          

          <section class="bg-white">
    <div style={{padding:'4vw 2vw'}}class="mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
    <h1 style={{fontSize:'8vw',lineHeight:'9vw'}}class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">Become a Cake Expert in No Time</h1>
    <p style={{fontSize:'2.8vw',lineHeight:'3.5vw'}} class="mb-8 text-lg font-normal text-gray-500  par">We will take you through the basics of cake making, from selecting the right ingredients and mixing them properly to shaping and decorating the cake. We will also provide you with tips and tricks that will make your cakes stand out from the rest.  </p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
         <Link to='/courseprogram'>
        <button style={{fontSize:'2.8vw',padding:'3vw 4vw'}}  type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm  me-2 mb-2">Enroll Now</button> 

         </Link>
         </div>
    </div>
</section>
      
          <Footer/>
        </div>

    </div>
  )
}
