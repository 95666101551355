import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyDAO2ixX5xf5cQE83CvOcpjopfT3Nc4JlE",
    authDomain: "shewit-18d54.firebaseapp.com",
    projectId: "shewit-18d54",
    storageBucket: "shewit-18d54.appspot.com",
    messagingSenderId: "378757262406",
    appId: "1:378757262406:web:9db378b2f4c5016adcae47"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
